import * as Store from "../store.js";
import * as CompanyRepository from "../../components/repositories/company-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import includeFiles from "../../components/helpers/include-files.js";
import * as ObjectsHelper from "../../components/helpers/objects-helper.js";
import * as StoreHelper from "../helpers/store-helper.js";
import { handleResponse } from "../helpers/request-helper.js";

export function loadCompanyLayout(id, params) {
    if (!id) {
        debugMessage("company id is undefined", id);
        return null;
    }

    Store.setStore(true, "data.loading.show");

    return handleResponse(
        CompanyRepository.getLayout(id, params),
        (response) => {
            if (!response) {
                return false;
            }
            Store.setStore(response);
            return true;
        }
    );
}

export function loadCompanyAddPopup() {
    Store.setStore(true, "data.loading.show");

    CompanyRepository.getCompanyAddPopup()
        .then((response) => {
            if (response) {
                Store.setStore(
                    response.companyAddPopup,
                    "data.companyAddPopup"
                );
                Store.setStore(true, "data.companyAddPopup.show");
                Store.setStore(false, "data.loading.show");
            }
        })
        .catch((error) => {
            Store.setStore(false, "data.loading.show");
            LayoutHandlers.handleRequestFail(error);
        });
}

export function closeCompanyAddPopup() {
    Store.setStore(null, "data.companyAddPopup");
}

export function addCompanyForm(form) {
    if (!form.validateForm()) {
        return null;
    }

    Store.setStore(true, "data.loading.show");

    return CompanyRepository.addCompany(form.getFormData())
        .then((response) => {
            if (response.errors) {
                Store.setStore(
                    response.errors,
                    "data.companyAddPopup.form.errors"
                );
            }
            if (response.success) {
                Store.setStore(null, "data.companyAddPopup");
            }
            if (response.messages) {
                // попросили убрать DOG-10
                // Store.setStore(response.messages, 'data.popupMessages.list');
            }
        })
        .catch((error) => {
            LayoutHandlers.handleRequestFail(error);
        })
        .then(() => {
            Store.setStore(false, "data.loading.show");
        });
}

export function setGraphsFilterDateValue(value) {
    StoreHelper.updateChecklist("data.graphs.filter.date.list", value);
}

export function setGraphsFilterDateRange(range) {
    let activeLabel, activeValue;

    if (range.from && range.to) {
        activeLabel = range.from.dateString + " - " + range.to.dateString;
        activeValue = "datepicker";
    }

    StoreHelper.updateChecklist(
        "data.graphs.filter.date.list",
        activeValue,
        activeLabel
    );
}

export function loadChartHelpers() {
    includeFiles("https://www.gstatic.com/charts/loader.js", () => {
        Store.setStore(true, "data.graphs.helpersLoaded");
    });
}

export function refreshOperations(companyId) {
    return handleResponse(
        CompanyRepository.getOperations(companyId),
        (response) => {
            if (!response.operations) {
                return false;
            }
            Store.setStore(response.operations, "data.operations");
            return true;
        }
    );
}

export function refreshSidebarAndHeader(companyId) {
    return handleResponse(
        CompanyRepository.getSidebarAndHeader(companyId),
        (response) => {
            if (response.companySidebar) {
                Store.setStore(response.companySidebar, "data.companySidebar");
            }
            if (response.companyHeader) {
                Store.setStore(response.companyHeader, "data.companyHeader");
            }
            return true;
        }
    );
}

export function setCompanyHistoryState(id, response) {
    const data = { companyId: id };
    const companies = ObjectsHelper.getNestedValue(
        response.data,
        "companyHeader",
        "selector",
        "list"
    );
    let title = "Dogma";
    if (companies && companies.length) {
        for (let company of companies) {
            if (company.active) {
                title = company.label;
                break;
            }
        }
    }
    document.title = title;
    history.pushState(data, title, "/company?id=" + id);
}

/* HELPERS */

function debugMessage(message, data) {
    if (Store.config.debug) {
        console.log(message, data);
    }
}
