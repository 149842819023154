import {
    getCaretPosition,
    setCaretPosition,
} from "../../../../components/helpers/html-helper.js";
import MoneyFilter from "../filters/money.js";

export default class Price extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.input = React.createRef();
        this.backgroundText = React.createRef();
        this.focus = false;
        this.lastCaretPosition = 0;

        this.state = {
            // да, он controlled, но локально, только тут =)
            value: this.props.value || "",
        };
    }

    componentDidUpdate() {
        if (this.focus) {
            this.input.current.focus();
            setCaretPosition(this.input.current, this.lastCaretPosition);
        }
    }

    render() {
        return (
            <div className="field-price-input-container">
                <div className="unit-box">
                    <span
                        className="unit-background-text"
                        ref={this.backgroundText}
                    >
                        {this.state.value}
                    </span>
                    <span className="unit-background-text-space"></span>
                    <span
                        className={"unit unit-" + (this.props.unit || "ruble")}
                    />
                </div>
                <input
                    className="form-input"
                    type="text"
                    inputMode="decimal"
                    name={this.props.name}
                    defaultValue={this.state.value}
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    ref={this.input}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                />
            </div>
        );
    }

    handleChange(e) {
        // Мы должны избежать перерисовки
        // Кейс:
        // 1. Открываем мобилку
        // 2. Открываем диалог с полем для ввода деняк
        // 3. Жмем на поле
        // 4. Вылезает клавиатура. Клавиатура занимает часть экрана и появляется боковой скролл
        // 5. После ввода в это поле - у поля пропадает фокус. Мы его заботливо ставим на место и даже каретку двигаем в нужное место
        // Но. Между потерей фокуса и его установкой проходят доли секунды, клавиатура считается убраной, скролл пропадает, затем снова появляется.
        // На айфонах это видно и мы наблюдаем скачки формы

        // this.focus = true;
        // this.lastCaretPosition = getCaretPosition(this.input.current);
        // this.setState({
        //     value: e.target.value,
        // });

        const value = MoneyFilter(e.target.value);
        this.input.current.value = value;
        this.backgroundText.current.innerText = value;

        const handler = this.props.onChange || function () {};
        handler(e, this);
    }

    handleFocus(e) {
        const handler = this.props.onFocus || function () {};
        handler(e);
    }

    handleBlur(e) {
        this.focus = false;
        const handler = this.props.onBlur || function () {};
        handler(e);
    }

    setValue(value) {
        this.setState({
            value: value,
        });
    }
}
