import Price from "../../price.jsx";
import LetterItemHelper from "../../../helpers/letter-item-helper.js";
import { rgba2hex } from "../../../../components/helpers/html-helper.js";

export default class StatsGraph extends React.Component {
    constructor(props) {
        super(props);
        this.letterHelper = new LetterItemHelper(5);
        this.chartRef = React.createRef();
        this.colors = [
            "#B9E398",
            "#7BB0F9",
            "#C581DD",
            "#E9EB86",
            "#F671C3",
            "#EFD47B",
            "#9DA4FA",
        ];
    }

    componentDidMount() {
        this.handleChart();
    }

    componentDidUpdate() {
        this.handleChart();
    }

    render() {
        this.letterHelper.resetCounter();

        let chart, note;
        if (this.props.chartData) {
            chart = (
                <div
                    className="pie-chart"
                    data-chart={JSON.stringify(this.props.chartData)}
                    ref={this.chartRef}
                ></div>
            );
        } else {
            chart = <div className="pie-chart-empty" ref={this.chartRef}></div>;
            note = <p className="chart-note">{this.props.noDataLabel}</p>;
        }

        let markers = [];
        if (this.props.markers) {
            for (let i = 0; i < this.props.markers.length; i++) {
                let item = this.props.markers[i],
                    style;

                if (item.styleColor) {
                    style = { backgroundColor: item.styleColor };
                }

                markers.push(
                    <div key={i} className="markers-list-item">
                        <div
                            className={
                                "list-item-col icon " +
                                this.letterHelper.getLetterColor()
                            }
                            style={style}
                        ></div>
                        <button
                            className="list-item-col caption"
                            title={item.label}
                            onClick={this.handleMarkerClick.bind(this, item)}
                        >
                            {item.label}
                        </button>
                        <div className="list-item-col value">{item.value}</div>
                    </div>
                );
            }
        }

        return (
            <div className="stats-graph">
                <div className="left-col">
                    <button
                        type="button"
                        className="graph-header"
                        onClick={this.props.onHeaderClick}
                    >
                        {this.props.label}
                    </button>
                    {chart}
                    {note}
                </div>
                <div className="right-col">
                    <Price value={this.props.totalPrice} />
                    <div className="graph-markers-list">{markers}</div>
                </div>
            </div>
        );
    }

    handleChart() {
        if (!this.chartRef.current || typeof google == "undefined" || !google) {
            return false;
        }

        google.charts.load("current", { packages: ["corechart"] });
        google.charts.setOnLoadCallback(() => {
            this.drawChart(this.getColors());
        });
    }

    handleMarkerClick(item, e) {
        const handler = this.props.onMarkerClick || function () {};
        handler(e, item);
    }

    drawChart(colors) {
        if (!this.chartRef.current) {
            return null;
        }

        this.chartRef.current.innerHTML = "";

        const chart = new google.visualization.PieChart(this.chartRef.current);
        chart.clearChart();

        let chartData;
        try {
            chartData = JSON.parse(this.chartRef.current.dataset["chart"]);
        } catch (e) {
            // ignore
        }

        if (!chartData) {
            return null;
        }

        let slices = {};

        for (let i = 0; i < colors.length; i++) {
            slices[i] = { color: colors[i] };
        }

        const data = google.visualization.arrayToDataTable(chartData.markers),
            options = {
                legend: "none",
                pieSliceText: "none",
                tooltip: { trigger: "none" },
                chartArea: {
                    width: "100%",
                    height: "100%",
                },
                enableInteractivity: false,
                pieHole: 0.8,
                slices: slices,
            };

        chart.draw(data, options);
    }

    getColors() {
        let colors = [],
            colorCounter = 0;
        if (this.props.markers) {
            for (let i = 0; i < this.props.markers.length; i++) {
                let item = this.props.markers[i];

                if (item.styleColor) {
                    let colorValue = item.styleColor;
                    if (colorValue.indexOf("rgb") > -1) {
                        colorValue = rgba2hex(colorValue);
                    }
                    colors.push(colorValue);
                } else {
                    colors.push(this.colors[colorCounter++]);
                }
            }
        }

        return colors;
    }
}
