import Container from '../container.jsx';
import Bar from '../../components/layouts/analytics/bar.jsx';

export default class BarContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.analyticsSectionBar');
    }

    render(){
        return <Bar
            {...this.props}
            {...this.state.state} />;
    };
}
