import * as InvestmentsHandlers from "../handlers/investments-handlers.js";
import * as CompanyHandlers from "../handlers/company-handlers.js";

export function handleInvestmentAddClick(e) {
    return InvestmentsHandlers.loadInvestmentAddPopup();
}

export function handleInvestmentAddPopupClose() {
    return InvestmentsHandlers.closeInvestmentAddPopup();
}

export function handleInvestmentAddPopupSubmit(e, form) {
    e.preventDefault();
    return InvestmentsHandlers.addInvestmentForm(form).then((response) => {
        InvestmentsHandlers.refreshInvestments();
    });
}

export function handleInvestmentEditClick(e, id) {
    return InvestmentsHandlers.loadInvestmentEditPopup({
        id: id,
    });
}

export function handleInvestmentActionClick(e, id, name) {
    switch (name) {
        case "add":
            return InvestmentsHandlers.loadInvestmentAddPopup({
                parent_id: id,
            });
    }
}

export function handleCompanyClick(e, params) {
    return CompanyHandlers.loadCompanyLayout(params.item.id);
}

export function handleInvestmentPopupActionClick(e, params) {
    params = params || {};

    switch (params.name) {
        case "remove":
            return InvestmentsHandlers.loadInvestmentRemovePopup(params);
    }
}

export function handleCloseInvestmentRemovePopup(e, field) {
    if (field && field.getFieldName() !== "cancel") {
        return null;
    }
    InvestmentsHandlers.closeInvestmentRemovePopup();
}

export function handleInvestmentRemovePopupSubmit(e, form, companyId) {
    e.preventDefault();
    InvestmentsHandlers.removeInvestmentForm(form).then((response) => {
        InvestmentsHandlers.refreshInvestments();
    });
}
