import Container from "../container.jsx";
import CompaniesSidebar from "../../components/layouts/companies/companies-sidebar.jsx";

export default class CompaniesSidebarContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.companySidebar");
    }

    render() {
        if (!this.state.state) {
            return null;
        }

        return <CompaniesSidebar {...this.props} {...this.state.state} />;
    }
}
