import { request, queryRequest } from "./repository.js";

export function getLayout(params) {
    return queryRequest({
        pageHeader: {
            menu: { active: "targets" },
        },
        pageSocialLinks: {},
        companySidebar: {},
        targets: params,
    }).then((response) => {
        return {
            layout: "targets",
            data: response,
        };
    });
}

export function getTable(params) {
    return queryRequest({
        targets: params,
    });
}

export function getAddPopup(params) {
    return queryRequest({
        targetEditPopup: params,
    });
}

export function getAddForm(params) {
    return queryRequest({
        targetEditForm: params,
    });
}

export function getEditPopup(params) {
    return queryRequest({
        targetEditPopup: params,
    });
}

export function getEditForm(params) {
    return queryRequest({
        targetEditForm: params,
    });
}

export function getRemovePopup(params) {
    return queryRequest({
        targetRemovePopup: params,
    });
}
