import * as Store from "../store.js";
import * as Repository from "../../components/repositories/repository.js";
import * as AuthRepository from "../../components/repositories/auth-repository.js";
import * as Auth from "../../components/auth.js";
import * as ObjectsHelper from "../../components/helpers/objects-helper.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse } from "../helpers/request-helper.js";

export function authorizeForm(form) {
    if (!form.validateForm()) {
        return null;
    }

    Store.setStore(true, "data.loading.show");
    Store.setStore("Authorizing", "data.loading.message");

    const formData = form.getFormData();
    Auth.authorize(formData)
        .then((response) => {
            // видимо авторизовались
            Store.setStore("Tuning", "data.loading.message");
            // проверяем статус и создаем приложение если пользователь новый
            handleUserStatus()
                .then(() => {
                    // перейдем на домашнюю страницу
                    Repository.getHomeLayout()
                        .then((response) => {
                            if (response) {
                                Store.setStore(response);
                                loadLicenseWarning();
                            } else {
                                LayoutHandlers.loadErrorLayout();
                            }
                            Store.setStore(false, "data.loading.show");
                        })
                        .catch((error) => {
                            LayoutHandlers.loadErrorLayout(error);
                            Store.setStore(false, "data.loading.show");
                        });
                })
                .catch((error) => {
                    LayoutHandlers.loadErrorLayout();
                    Store.setStore(false, "data.loading.show");
                });
        })
        .catch((error) => {
            let data = ObjectsHelper.getNestedValue(error.response, "data");
            if (data && data.errors) {
                Store.setStore(data.errors, "data.authForm.errors");
            } else {
                LayoutHandlers.loadErrorLayout();
            }
            Store.setStore(false, "data.loading.show");
        });
}

export function registerForm(form) {
    if (!form.validateForm()) {
        return null;
    }

    Store.setStore(true, "data.loading.show");

    const formData = form.getFormData();
    AuthRepository.registration(formData)
        .then((response) => {
            if (response.errors) {
                Store.setStore(response.errors, "data.registrationForm.errors");
            } else if (response.success && response.messages) {
                Store.setStore(null, "data.registrationForm.errors");
                Store.setStore(
                    response.messages,
                    "data.registrationForm.messages"
                );
                Store.setStore(true, "data.registrationForm.hideForm");
            } else {
                LayoutHandlers.loadErrorLayout();
            }
            Store.setStore(false, "data.loading.show");
        })
        .catch((error) => {
            let data = ObjectsHelper.getNestedValue(error.response, "data");
            if (data && data.errors) {
                Store.setStore(data.errors, "data.registrationForm.errors");
            } else {
                LayoutHandlers.loadErrorLayout();
            }
            Store.setStore(false, "data.loading.show");
        });
}

export function restoreForm(form) {
    if (!form.validateForm()) {
        return null;
    }

    Store.setStore(true, "data.loading.show");

    const formData = form.getFormData();
    AuthRepository.restoreUserAccess(formData)
        .then((response) => {
            if (response.errors) {
                Store.setStore(response.errors, "data.restoreForm.errors");
            } else if (response.success && response.messages) {
                Store.setStore(null, "data.restoreForm.errors");
                Store.setStore(response.messages, "data.restoreForm.messages");
                Store.setStore(true, "data.restoreForm.hideForm");
            } else {
                LayoutHandlers.loadErrorLayout();
            }
            Store.setStore(false, "data.loading.show");
        })
        .catch((error) => {
            let data = ObjectsHelper.getNestedValue(error.response, "data");
            if (data && data.errors) {
                Store.setStore(data.errors, "data.restoreForm.errors");
            } else {
                LayoutHandlers.loadErrorLayout();
            }
            Store.setStore(false, "data.loading.show");
        });
}

export function handleUserStatus() {
    return new Promise((resolve, reject) => {
        const status = AuthRepository.getUserStatus();
        switch (status) {
            case 1: // подтвержденный созданный и все хорошо у него
                resolve();
                break;
            case 2: // пользователь подтвержден, но приложение для него еще не создано
                Store.setStore("Creating workspace", "data.loading.message");
                AuthRepository.createWorkspace()
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject();
                    });
                break;
            case 3: // пользователь подтвержден и сейчас идет процесс создания приложения
                Store.setStore("Creating workspace", "data.loading.message");
                AuthRepository.waitWorkspaceCreation()
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject();
                    });
                break;
            default:
                reject(new Error("unknown-status " + status));
                break;
        }
    });
}

export function logout() {
    Store.setStore(true, "data.loading.show");
    Store.setStore("Logging out", "data.loading.message");

    Auth.logout().then(() => {
        return LayoutHandlers.loadHomeLayout();
    });
}

export function loadLicenseWarning() {
    return handleResponse(AuthRepository.getLicenseWarning(), (response) => {
        if (!response || !response.licenseWarning) {
            return true;
        }
        Store.setStore(response.licenseWarning, "data.licenseExpired");
        return true;
    });
}
