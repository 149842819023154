import Price from "../../price.jsx";

export default class OperationsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openedActionsItemId: null,
        };

        this.handleDocumentClick = (e) => {
            if (!e.target.closest(".actions-button")) {
                this.setState({
                    openedActionsItemId: null,
                });
            }
        };

        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    render() {
        if (!this.props.list) {
            return null;
        }

        let list = [];
        for (let i = 0; i < this.props.list.length; i++) {
            let item = this.props.list[i],
                actions = this.renderItemActions(item);

            list.push(
                <div
                    key={i}
                    className="operations-list-item"
                    title={item.caption}
                    onClick={this.handleItemClick.bind(this, item)}
                >
                    <div className="list-item-col icon">
                        <div
                            className="icon-content"
                            style={{ background: item.styleColor }}
                        ></div>
                    </div>
                    <div className="list-item-col caption">
                        <p className="text">{item.caption}</p>
                        <p className="note" title={item.account}>
                            {item.account}
                        </p>
                    </div>
                    <div className="list-item-col price">
                        <p className={"text " + item.type}>
                            <Price value={item.price} />
                        </p>
                        <p className="note" title={item.object}>
                            {item.object}
                        </p>
                    </div>
                    {actions}
                </div>
            );
        }

        return <div className="operations-list">{list}</div>;
    }

    renderItemActions(item) {
        if (!item.actions) {
            return null;
        }

        let actions = [];

        item.actions.map((action) => {
            actions.push(
                <button
                    className={"action-item action-" + action.name}
                    key={action.name}
                    onClick={this.handleActionClick.bind(this, item, action)}
                >
                    {action.label}
                </button>
            );
        });

        return (
            <div
                className={
                    "list-item-col actions" +
                    (item.id == this.state.openedActionsItemId ? " opened" : "")
                }
            >
                <button
                    className="actions-button"
                    onClick={this.handleActionsButtonClick.bind(this, item)}
                >
                    {String.fromCharCode(8943)}
                </button>
                <div className="actions-popup">{actions}</div>
            </div>
        );
    }

    handleItemClick(item, e) {
        if (e.target.closest(".actions")) {
            return null;
        }
        const handler = this.props.onItemClick || function () {};
        handler(e, item);
    }

    handleActionClick(item, action, e) {
        const handler = this.props.onActionClick || function () {};
        handler(e, item, action);
    }

    handleActionsButtonClick(item) {
        this.setState({
            openedActionsItemId: item.id,
        });
    }
}
