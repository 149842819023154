import Checkput from "./custom-checkput.jsx";
import LicenseAgreementPopup from "../../layouts/auth/license-agreement.jsx";

export default class LicenseAgreementField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseAgreementShow: false,
        };
    }

    render() {
        let labelButton;
        if (this.props.data && this.props.data.labelButton) {
            labelButton = (
                <button
                    type="button"
                    onClick={this.handleAgreementLabelButtonClick.bind(
                        this,
                        this.props
                    )}
                >
                    {this.props.data.labelButton}
                </button>
            );
        }

        let licenseAgreement;
        if (this.state.licenseAgreementShow) {
            licenseAgreement = (
                <LicenseAgreementPopup
                    onCloseButtonClick={this.handleLicenseAgreementCloseButtonClick.bind(
                        this
                    )}
                />
            );
        }

        return (
            <div className={this.props.fieldClassName}>
                <Checkput
                    {...this.props}
                    type={"checkbox"}
                    id={this.props.fieldId}
                    onChange={this.props.onFieldChange}
                    ref={this.fieldRef}
                />
                <label htmlFor={this.props.fieldId} className="form-label">
                    {this.props.label}
                </label>
                {labelButton}
                {licenseAgreement}
            </div>
        );
    }

    handleAgreementLabelButtonClick(field, e) {
        this.setState({
            licenseAgreementShow: true,
        });
        this.props.onFieldClick(e, null, field);
    }

    handleLicenseAgreementCloseButtonClick() {
        this.setState({
            licenseAgreementShow: false,
        });
    }
}
