export default class CheckList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.list) {
            return null;
        }

        let list = [],
            activeIndexList = [];

        // первый раз бежим чтобы понять кто активен
        for (let i = 0; i < this.props.list.length; i++) {
            let item = this.props.list[i];
            if (item.active) {
                if (this.props.defaultFirst) {
                    activeIndexList = [];
                }
                activeIndexList.push(i);
            }
            if (
                !item.active &&
                !activeIndexList.length &&
                this.props.defaultFirst
            ) {
                activeIndexList.push(i);
            }
        }

        const labelField = this.props.labelField || "label";

        for (let i = 0; i < this.props.list.length; i++) {
            let item = this.props.list[i];
            list.push(
                <button
                    type="button"
                    key={i}
                    onClick={this.handleClick.bind(this, i)}
                >
                    <span
                        className={
                            activeIndexList.indexOf(i) > -1
                                ? "icon-checked"
                                : "icon"
                        }
                    ></span>
                    <span className="text">{item[labelField]}</span>
                </button>
            );
        }

        return <div className="checklist">{list}</div>;
    }

    handleClick(index, e) {
        const handler = this.props.onItemClick || function () {};
        handler(e, this.props.list[index]);
    }
}
