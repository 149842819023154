import PageHeader from "../../page-header.jsx";
import CompaniesSidebar from "../../../containers/companies/companies-sidebar.jsx";
import TargetAddPopup from "../../../containers/targets/target-add-popup.jsx";
import TargetEditPopup from "../../../containers/targets/target-edit-popup.jsx";
import TargetAddForm from "../../../containers/targets/target-add-form.jsx";
import TargetEditForm from "../../../containers/targets/target-edit-form.jsx";
import TargetRemovePopup from "../../../containers/targets/target-remove-popup.jsx";
import TargetsList from "../../../containers/targets/targets-list.jsx";
import { stretchLastPageElement } from "../../../../components/helpers/html-helper.js";

export default class TargetsLayout extends React.Component {
    constructor(props) {
        super(props);
        document.body.classList.add("dashboard");
    }

    componentDidMount() {
        stretchLastPageElement(document.querySelector(".targets-plate"));
    }

    componentDidUpdate() {
        stretchLastPageElement(document.querySelector(".targets-plate"));
    }

    render() {
        let actions = [];
        if (this.props.targets) {
            if (this.props.targets.actions) {
                for (let i = 0; i < this.props.targets.actions.length; i++) {
                    let action = this.props.targets.actions[i];
                    actions.push(
                        <button
                            key={i}
                            onClick={this.handleActionClick.bind(this, action)}
                        >
                            {action.label}
                        </button>
                    );
                }
                if (actions.length) {
                    actions = (
                        <div className="layout-actions targets-actions">
                            {actions}
                        </div>
                    );
                }
            }
        }

        return (
            <div>
                <PageHeader
                    {...this.props.pageHeader}
                    onMenuItemClick={
                        this.props.handlePageHeaderMenuItemClick ||
                        function () {}
                    }
                    onUserbarClick={
                        this.props.handlePageHeaderUserbarClick ||
                        function () {}
                    }
                    onLogoClick={
                        this.props.handlePageHeaderLogoClick || function () {}
                    }
                    onUserbarMenuButtonClick={
                        this.props.handlePageHeaderUserbarMenuButtonClick ||
                        function () {}
                    }
                    onSearchSubmit={
                        this.props.handlePageHeaderSearchSubmit ||
                        function () {}
                    }
                    onFeedbackBarMenuButtonClick={
                        this.props.handlePageHeaderFeedbackBarMenuButtonClick
                    }
                />

                <div className="page-limiter targets-layout">
                    <CompaniesSidebar
                        {...this.props.companySidebar}
                        activeId={this.props.targets.companyId}
                        onCompanyClick={
                            this.props.handleCompanyClick || function () {}
                        }
                        onActionClick={
                            this.props.handleActionClick || function () {}
                        }
                        onActiveCompanyClick={
                            this.props.handleActiveCompanyClick
                        }
                        onCompaniesActionClick={
                            this.props.handleCompaniesActionClick
                        }
                        onCompanyMenuItemClick={
                            this.props.handleCompanyMenuItemClick
                        }
                    />

                    <div
                        className={
                            "targets-content layout-" + this.props.layout
                        }
                    >
                        <div className="layout-title">
                            <h1>{this.props.targets.title}</h1>
                        </div>
                        {actions}
                        {this.renderContent()}
                    </div>
                </div>

                <TargetAddPopup
                    className="add-investment-popup"
                    {...this.props.targetAddPopup}
                    handleCloseButtonClick={
                        this.props.handleTargetAddPopupClose || function () {}
                    }
                    handleSubmit={this.handleTargetAddPopupSubmit.bind(this)}
                />
                <TargetEditPopup
                    {...this.props.targetEditPopup}
                    className="edit-operation-popup"
                    handleCloseButtonClick={
                        this.props.handleTargetEditPopupClose || function () {}
                    }
                    handleSubmit={this.handleTargetEditPopupSubmit.bind(this)}
                />
                <TargetRemovePopup
                    {...this.props.targetRemovePopup}
                    className="remove-operation-popup"
                    handleCloseButtonClick={
                        this.props.handleTargetRemovePopupClose ||
                        function () {}
                    }
                    handleSubmit={this.handleTargetRemovePopupSubmit.bind(this)}
                    handleFieldClick={
                        this.props.handleTargetRemovePopupFieldClick ||
                        function () {}
                    }
                />
            </div>
        );
    }

    renderContent() {
        switch (this.props.layout) {
            case "add":
                return (
                    <div className="layout-plate targets-edit-form">
                        <TargetAddForm
                            {...this.props.targetAddForm}
                            handleSubmit={this.handleTargetAddFormSubmit.bind(
                                this
                            )}
                            handleActionClick={this.handleTargetFormActionClick.bind(
                                this
                            )}
                        />
                    </div>
                );
            case "edit":
                return (
                    <div className="layout-plate targets-edit-form">
                        <TargetEditForm
                            {...this.props.targetAddForm}
                            handleSubmit={this.handleTargetEditFormSubmit.bind(
                                this
                            )}
                            handleActionClick={this.handleTargetFormActionClick.bind(
                                this
                            )}
                        />
                    </div>
                );
            default:
                return (
                    <div className="layout-plate targets-plate">
                        <TargetsList
                            {...this.props.targets}
                            onActionClick={this.handleTargetActionClick.bind(
                                this
                            )}
                        />
                    </div>
                );
        }
    }

    handleActionClick(action, e) {
        const handler = this.props.handleTargetsActionClick || function () {};
        handler(e, action, this.props.targets.companyId);
    }

    handleTargetActionClick(e, item, action) {
        const handler = this.props.handleTargetActionClick || function () {};
        handler(e, item, action, this.props.targets.companyId);
    }

    handleTargetAddPopupSubmit(e, form) {
        const handler = this.props.handleTargetAddPopupSubmit || function () {};
        handler(e, form, this.props.targets.companyId);
    }

    handleTargetAddFormSubmit(e, form) {
        const handler = this.props.handleTargetAddFormSubmit || function () {};
        handler(e, form, this.props.targets.companyId);
    }

    handleTargetEditPopupSubmit(e, form) {
        const handler =
            this.props.handleTargetEditPopupSubmit || function () {};
        handler(e, form, this.props.targets.companyId);
    }

    handleTargetEditFormSubmit(e, form) {
        const handler = this.props.handleTargetEditFormSubmit || function () {};
        handler(e, form, this.props.targets.companyId);
    }

    handleTargetRemovePopupSubmit(e, form) {
        const handler =
            this.props.handleTargetRemovePopupSubmit || function () {};
        handler(e, form, this.props.targets.companyId);
    }

    handleTargetFormActionClick(e, params) {
        const handler = this.props.handleTargetsActionClick || function () {};
        handler(e, params, this.props.targets.companyId);
    }
}
