import Container from "../container.jsx";
import LicenseExpiredPopup from "../../components/license-expired-popup.jsx";

export default class LicenseExpiredPopupContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.licenseExpired");
    }

    render() {
        return <LicenseExpiredPopup {...this.state.state} {...this.props} />;
    }
}
