import { request, queryRequest } from "./repository.js";

let config = {};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function authorize(params) {
    return queryRequest({
        bankIntegrationAuthorize: params,
    });
}
