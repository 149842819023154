import Container from '../container.jsx';
import AddCompany from '../../components/layouts/cabinet/add-company.jsx';

export default class AddCompanyContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.cabinetAddCompanyLayout');
    }

    render(){
        return <AddCompany
            {...this.props}
            {...this.state.state} />;
    };
}
