export default class Autocomplete extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="form-autocomplete-input">
            <input type="hidden" name={this.props.name} value={this.props.value || ''}/>
            <input className="form-input"
                      type="text"
                      name={this.props.name+'-input'}
                      defaultValue={this.props.inputValue || ''}
                      id={this.props.id}
                      placeholder={this.props.placeholder}
                      autoComplete={this.props.autocomplete ? "on" : "off"}
                      onChange={this.props.onChange || function(){}}
                      onFocus={this.props.onFocus || function(){}}
                      onBlur={this.props.onBlur || function(){}}
                      readOnly={this.props.data.loading}
                      autoFocus />
            {this.renderOptions(this.props.data.options)}
        </div>;
    }

    renderOptions(){
        const options = this.props.data.options;
        if (!options || !options.length) {
            return null;
        }

        let list = [];

        for(let i=0; i<options.length; i++) {
            list.push(
                <button key={i} type="button" data-value={options[i].value} onClick={this.props.onChange || function(){}}>{options[i].label}</button>
            );
        }

        return <div className="form-autocomplete-dropdown">{list}</div>;
    }
}
