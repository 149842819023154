import Container from '../container.jsx';
import CabinetLayout from '../../components/layouts/cabinet/cabinet.jsx';

export default class CabinetLayoutContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.cabinet');
    }

    render(){
        return <CabinetLayout
            {...this.props}
            {...this.state.state} />;
    };
}
