import * as Store from "../store.js";
import { getFieldPath } from "./store-helper.js";

/* устанавливаем значения в форму из FormData */
export function applyFilterFormData(form, fieldsPath, formData) {
    // т.к. у нас uncontrolled инпуты - то будем работать с домом
    // а где-то и controlled... вобщем будем и туда и туда прописывать
    for (let pair of formData.entries()) {
        let fieldPath = getFieldPath(fieldsPath, pair[0]);
        const fieldType = Store.getStore(fieldPath + ".type");
        applyFieldData(fieldType, form, fieldPath, pair[0], pair[1]);
    }
}

/* устанавливаем значения в форму из key-value объекта */
export function applyFilterData(form, fieldsPath, data) {
    if (!data) {
        return null;
    }

    for (let name in data) {
        let fieldPath = getFieldPath(fieldsPath, name);
        const fieldType = Store.getStore(fieldPath + ".type");
        applyFieldData(fieldType, form, fieldPath, name, data[name]);
    }
}

/* получить keyvalue объект из FormData */
export function getFormDataData(formData) {
    let data = {};
    for (let pair of formData.entries()) {
        data[pair[0]] = pair[1];
    }

    return data;
}

/* HELPERS */

function setDefaultFieldValue(form, fieldPath, name, value) {
    if (form) {
        let field = form.querySelector("[name=" + name + "]");
        if (field) {
            field.value = value;
        }
    }

    if (fieldPath) {
        Store.setStore(value, fieldPath + ".value");
    }
}

function setDatepickerFieldValue(form, fieldPath, name, value) {
    setChecklistFieldValue(form, fieldPath, name, value);
}

function setChecklistFieldValue(form, fieldPath, name, value) {
    if (fieldPath) {
        const options = Store.getStore(fieldPath + ".data.options");
        if (options && options.length) {
            for (var i = 0; i < options.length; i++) {
                let option = options[i];
                if (option.value == value) {
                    Store.setStore(
                        true,
                        fieldPath + ".data.options." + i + ".active"
                    );
                }
            }
        }
    }
}

function applyFieldData(fieldType, form, fieldPath, name, value) {
    switch (fieldType) {
        case "datepicker":
            setDatepickerFieldValue(form, fieldPath, name, value);
            break;
        case "checklist":
        case "filterable-checklist":
            setChecklistFieldValue(form, fieldPath, name, value);
            break;
        default:
            setDefaultFieldValue(form, fieldPath, name, value);
            break;
    }
}
