import { request, queryRequest } from "./repository.js";

let config = {};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function getLayout(id, params) {
    if (id) {
        let operationsParams = {
            companyId: id,
            date_range: "all-time",
            limit: 6,
        };
        if (params && params.operations) {
            operationsParams = { ...operationsParams, ...params.operations };
        }
        return queryRequest({
            pageHeader: {
                menu: { active: "home" },
            },
            pageSocialLinks: {},
            companySidebar: { companyId: id },
            companyHeader: { companyId: id },
            paymentAccounts: { companyId: id },
            operations: operationsParams,
            graphs: { companyId: id },
            debts: { companyId: id },
        }).then((response) => {
            response.companyId = id;
            return {
                layout: "company",
                data: response,
            };
        });
    }

    return request("get", config.baseUrl + "/json/company.json");
}

export function getCompanyAddPopup() {
    return queryRequest({
        companyAddPopup: {},
    });
}

export function addCompany(data) {
    return request("post", config.baseUrl + config.addCompanyUrl, data);
}

export function getOperations(companyId) {
    return queryRequest({
        operations: { companyId: companyId, date_range: "all-time", limit: 6 },
    });
}

export function getSidebarAndHeader(companyId) {
    return queryRequest({
        companySidebar: { companyId: companyId },
        companyHeader: { companyId: companyId },
    });
}
