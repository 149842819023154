import { getNestedValue } from "../../../../components/helpers/objects-helper.js";

export default class Select extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.selectRef = React.createRef();

        this.state = {
            value: this.props.value,
        };
    }

    render() {
        return (
            <div className="select-container">
                {this.renderSelect()}
                {this.renderNote()}
            </div>
        );
    }

    renderSelect() {
        let options = [],
            optionsData = this.getOptions();

        if (optionsData && optionsData.length) {
            for (let i = 0; i < optionsData.length; i++) {
                const option = optionsData[i];
                options.push(
                    <option key={i} value={option.value}>
                        {option.label}
                    </option>
                );
            }
        }

        return (
            <select
                name={this.props.name}
                defaultValue={this.props.value}
                id={this.props.id}
                ref={this.selectRef}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            >
                {options}
            </select>
        );
    }

    renderNote() {
        let optionsData = this.getOptions(),
            firstOption;

        if (optionsData && optionsData.length) {
            for (let i = 0; i < optionsData.length; i++) {
                const option = optionsData[i];
                if (option.value == this.state.value) {
                    if (!option.note) {
                        return null;
                    }
                    return <p className="note">{option.note}</p>;
                }
                if (!firstOption) {
                    firstOption = option;
                }
            }
        }

        if (!firstOption || !firstOption.note) {
            return null;
        }

        return <p className="note">{firstOption.note}</p>;
    }

    handleChange(e) {
        this.setState({
            value: e.target.value,
        });
        const handler = this.props.onChange || function () {};
        handler(e);
    }

    handleFocus(e) {
        const handler = this.props.onFocus || function () {};
        handler(e);
    }

    handleBlur(e) {
        const handler = this.props.onBlur || function () {};
        handler(e);
    }

    getOptions() {
        if (this.props.options && this.props.options.length) {
            return this.props.options;
        }

        return getNestedValue(this.props, "data", "options");
    }
}
