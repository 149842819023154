export default class Messages extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.list) {
            return null;
        }

        let list = [];
        for (let i = 0; i < this.props.list.length; i++) {
            list.push(
                <p key={i} className="message-item">
                    {this.handleFormatting(this.props.list[i])}
                </p>
            );
        }

        return <div className="form-messages-list">{list}</div>;
    }

    handleFormatting(text) {
        const strings = text.split("\\n");
        let first = true;
        let counter = 0;
        let output = [];
        strings.map((string) => {
            if (first) {
                first = false;
            } else {
                output.push(<br key={counter++} />);
            }
            output.push(string);
        });

        return output;
    }
}
