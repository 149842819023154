import * as Store from "../store.js";
import * as InvestmentsRepository from "../../components/repositories/investments-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse, submitForm } from "../helpers/request-helper.js";
import { getNestedValue } from "../../components/helpers/objects-helper.js";

export function loadLayout() {
    Store.setStore(true, "data.loading.show");

    return handleResponse(InvestmentsRepository.getLayout(), (response) => {
        if (!response) {
            return false;
        }
        Store.setStore(response);
        return true;
    });
}

export function setLayoutHistoryState(response) {
    let title = "Dogma";

    const menu = getNestedValue(response.data, "pageHeader", "menu");
    if (menu && menu.length) {
        for (let item of menu) {
            if (item.name == "investments") {
                title = item.label;
                break;
            }
        }
    }

    document.title = title;
    history.pushState({}, title, "/investments");
}

export function loadInvestmentAddPopup(data) {
    return loadInvestmentEditPopup(data);
}

export function loadInvestmentEditPopup(data) {
    Store.setStore(true, "data.loading.show");

    return InvestmentsRepository.getInvestmentEditPopup(data)
        .then((response) => {
            if (response) {
                LayoutHandlers.handlePopupErrors(response.operationAddPopup);
                Store.setStore(
                    response.investmentEditPopup,
                    "data.investmentAddPopup"
                );
                Store.setStore(true, "data.investmentAddPopup.show");
                Store.setStore(false, "data.loading.show");
            }
        })
        .catch((error) => {
            Store.setStore(false, "data.loading.show");
            LayoutHandlers.handleRequestFail(error);
        });
}

export function closeInvestmentAddPopup() {
    Store.setStore(null, "data.investmentAddPopup");
}

export function addInvestmentForm(form) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupErrors(response);
        if (response && response.success) {
            // попросили убрать DOG-10
            // Store.setStore({list: response.messages}, 'data.popupMessages');
            Store.setStore(null, "data.investmentAddPopup");
        }
        return response;
    });
}

export function refreshInvestments() {
    return handleResponse(
        InvestmentsRepository.getInvestments(),
        (response) => {
            if (!response || !response.investments) {
                return false;
            }
            Store.setStore(response.investments, "data.investments");
            return true;
        }
    );
}

export function loadInvestmentRemovePopup(params) {
    Store.setStore(true, "data.loading.show");
    return handleResponse(
        InvestmentsRepository.getInvestmentRemovePopup({ id: params.id }),
        (response) => {
            if (!response) {
                return false;
            }
            LayoutHandlers.handlePopupErrors(response.operationRemovePopup);
            Store.setStore(
                response.investmentRemovePopup,
                "data.investmentRemovePopup"
            );
            Store.setStore(true, "data.investmentRemovePopup.show");
            return true;
        }
    );
}

export function closeInvestmentRemovePopup() {
    Store.setStore(null, "data.investmentRemovePopup");
    LayoutHandlers.unsetDialogHistoryState();
}

export function removeInvestmentForm(form) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupErrors(response);
        if (response && response.success) {
            Store.setStore(null, "data.investmentRemovePopup");
            Store.setStore(null, "data.investmentAddPopup");
            LayoutHandlers.unsetDialogHistoryState();
        }
        return response;
    });
}
