import * as Store from "../store.js";
import * as SearchRepository from "../../components/repositories/search-repository.js";
import { handleResponse } from "../helpers/request-helper.js";

export function loadLayout(params) {
    return handleResponse(SearchRepository.getLayout(params), (response) => {
        if (!response) {
            return false;
        }
        Store.setStore(response);
        return true;
    });
}
