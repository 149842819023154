import Container from "../container.jsx";
import CompanyTabList from "../../components/layouts/cabinet/company-tab-list.jsx";
import * as Store from "../../store.js";

export default class CompanyTabListContainer extends Container {
    constructor(props) {
        super(props);
    }

    render() {
        let props = { ...this.props };
        if (props.actions && props.actions.length) {
            const config = Store.getStore("config");
            for (var i = 0; i < props.actions.length; i++) {
                let action = props.actions[i];
                if (action.url && action.url.indexOf("http") !== 0) {
                    props.actions[i].url =
                        config.repository.baseUrl + action.url;
                }
            }
        }
        return <CompanyTabList {...props} />;
    }
}
