import PageHeader from "../../page-header.jsx";
import CompaniesSidebar from "../../../containers/companies/companies-sidebar.jsx";
import Content from "../../../containers/company-about/content.jsx";

export default class CompanyAboutLayout extends React.Component {
    constructor(props) {
        super(props);
        document.body.classList.add("dashboard");
    }

    render() {
        return (
            <div>
                <PageHeader
                    {...this.props.pageHeader}
                    onMenuItemClick={this.props.handlePageHeaderMenuItemClick}
                    onUserbarClick={this.props.handlePageHeaderUserbarClick}
                    onLogoClick={this.props.handlePageHeaderLogoClick}
                    onUserbarMenuButtonClick={
                        this.props.handlePageHeaderUserbarMenuButtonClick
                    }
                    onSearchSubmit={this.props.handlePageHeaderSearchSubmit}
                    onFeedbackBarMenuButtonClick={
                        this.props.handlePageHeaderFeedbackBarMenuButtonClick
                    }
                />

                <div className="page-limiter company-about-layout">
                    <CompaniesSidebar
                        {...this.props.companySidebar}
                        activeId={this.props.cabinetCompany.companyId}
                        onCompanyClick={this.handleCompanyClick.bind(this)}
                        onActionClick={
                            this.props.handleActionClick || function () {}
                        }
                        onActiveCompanyClick={
                            this.props.handleActiveCompanyClick
                        }
                        onCompaniesActionClick={
                            this.props.handleCompaniesActionClick
                        }
                        onCompanyMenuItemClick={
                            this.props.handleCompanyMenuItemClick
                        }
                    />
                    <Content
                        {...this.props.cabinetCompany}
                        onCompanyActionClick={
                            this.props.handleCompanyActionClick
                        }
                        onTabFormCancelButtonClick={
                            this.props.handleCompanyTabFormCancelButtonClick
                        }
                        onTabFormFieldChange={
                            this.props.handleCompanyTabFormFieldChange
                        }
                        onTabFormFieldBlur={
                            this.props.handleCompanyTabFormFieldBlur
                        }
                        onTabFormSubmit={this.props.handleCompanyTabFormSubmit}
                        onDeleteFormSubmit={
                            this.props.handleCompanyDeleteFormSubmit
                        }
                        onCompanyClick={this.props.handleActiveCompanyClick}
                    />
                </div>
            </div>
        );
    }

    handleCompanyClick(e, params) {
        const handler = this.props.handleCompanyClick || function () {};
        handler(e, params, "about");
    }
}
