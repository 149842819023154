import * as Store from "../store.js";
import * as Repository from "../../components/repositories/repository.js";
import * as AuthRepository from "../../components/repositories/auth-repository.js";
import * as ObjectsHelper from "../../components/helpers/objects-helper.js";
import * as Auth from "../../components/auth.js";
import * as AuthHandlers from "./auth-handlers.js";
import * as CabinetHandlers from "./cabinet-handlers.js";
import * as CompanyHandlers from "./company-handlers.js";
import * as InvestmentsHandlers from "./investments-handlers.js";
import * as TransactionsHandlers from "./transactions-handlers.js";
import * as AnalyticsHandlers from "./analytics-handlers.js";
import * as SearchHandlers from "./search-handlers.js";
import * as TargetsHandlers from "./targets-handlers.js";
import * as CompanyDictionaryHandlers from "./company-dictionary-handlers.js";
import * as CompanyAboutHandlers from "./company-about-handlers.js";
import { handleResponse } from "../helpers/request-helper.js";

export function loadHomeLayout() {
    Store.setStore(true, "data.loading.show");

    return handleResponse(Repository.getHomeLayout(), (response) => {
        if (!response) {
            return false;
        }
        Store.setStore(response);
        return true;
    });
}

export function loadErrorLayout(error) {
    debugMessage(error);
    Store.setStore({
        layout: "error",
    });
}

export function loadLayoutByName(name, params) {
    const companyId =
        Store.getStore("data.companyId") ||
        getLastCompanyId() ||
        getSingleCompanyId();

    setLastCompanyId(companyId);

    params = params || {};
    const pushHistoryState = params.pushHistoryState;
    switch (name) {
        case "home":
            if (companyId) {
                return CompanyHandlers.loadCompanyLayout(companyId).then(
                    (response) => {
                        CompanyHandlers.setCompanyHistoryState(
                            companyId,
                            response
                        );
                    }
                );
            }
            return loadHomeLayout().then((response) => {
                if (pushHistoryState) {
                    setLayoutHistoryState(response);
                }
                return response;
            });
        case "investments":
            return InvestmentsHandlers.loadLayout().then((response) => {
                if (pushHistoryState) {
                    InvestmentsHandlers.setLayoutHistoryState(response);
                }
                return response;
            });
        case "cabinet":
            return CabinetHandlers.loadLayout(params.submenu, params).then(
                (response) => {
                    if (pushHistoryState) {
                        CabinetHandlers.setLayoutHistoryState(response);
                    }
                    return response;
                }
            );
        case "transactions":
            return TransactionsHandlers.loadLayout(
                companyId,
                params.params
            ).then((response) => {
                if (pushHistoryState) {
                    TransactionsHandlers.setLayoutHistoryState(response);
                }
                return response;
            });
        case "analytics":
            return AnalyticsHandlers.loadLayout(companyId, params.params).then(
                (response) => {
                    if (pushHistoryState) {
                        AnalyticsHandlers.setLayoutHistoryState(response);
                    }
                    return response;
                }
            );
        case "targets":
            let layoutParams = params.params || {};
            layoutParams.companyId = companyId;
            return TargetsHandlers.loadLayout(layoutParams).then((response) => {
                if (pushHistoryState) {
                    TargetsHandlers.setLayoutHistoryState(response);
                }
                return response;
            });
        // заменить на то, что ниже, если надо будет прыгать в кабинет, а не лейаут целей
        // params.submenu = "companies";
        // params.defaultCompanyTabName = "targets";
        // params.loadDefaultCompany = true;
        // if (companyId) {
        //     params.companyId = companyId;
        // }
        // return loadLayoutByName("cabinet", params);
        case "search":
            return SearchHandlers.loadLayout(params);
        case "company-dictionary":
            return CompanyDictionaryHandlers.loadLayout(params).then(
                (response) => {
                    CompanyDictionaryHandlers.setCompanyHistoryState(
                        params,
                        response
                    );
                }
            );
        case "company-about":
            return CompanyAboutHandlers.loadLayout(params).then((response) => {
                CompanyAboutHandlers.setCompanyHistoryState(params, response);
            });
    }

    return Promise.reject(new Error('Unknown layout name "' + name + '"'));
}

export function handleRequestFail(error) {
    debugMessage(error);
    if (error && error.message.indexOf("auth.") == 0) {
        // запрос неавторизован - перезагрузим страницу просто
        location.href = location.href;
        // или мжб загрузим домашнюю...
        // loadHomeLayout(); // тут будет бесконечный цикл запросов домашней страницы (например если нажать Выйти в кабинете)
    } else {
        // что-то другое
        // loadErrorLayout();
        // или мжб всплываху...
        Store.setStore(
            {
                list: ["Ошибка загрузки, обратитесь в техподдержку"],
            },
            "data.popupErrors"
        );
    }
}

export function handlePopupErrors(response) {
    if (response && response.errors) {
        Store.setStore({ list: response.errors }, "data.popupErrors");
    }
}

export function handlePopupMessages(response) {
    if (response && response.messages) {
        Store.setStore({ list: response.messages }, "data.popupMessages");
    }
}

export function closeErrorsPopup() {
    Store.setStore(null, "data.popupErrors");
}

export function closeMessagesPopup() {
    Store.setStore(null, "data.popupMessages");
}

export function setLayoutHistoryState(response) {
    let title = "Dogma";
    document.title = title;
    history.pushState({}, title, "/");
}

export function setDialogHistoryState() {
    let currentUrl = location.href.split("#")[0];
    history.pushState({}, document.title, currentUrl + "#dialog");
}

export function unsetDialogHistoryState() {
    let currentUrl = location.href.split("#")[0];
    history.pushState({}, document.title, currentUrl);
}

export function setActiveCompanyMenuItem(activeItem) {
    let menu = Store.getStore("data.companySidebar.companyMenu");
    if (menu) {
        menu.map((item, index) => {
            if (item.name == activeItem.name) {
                Store.setStore(
                    true,
                    "data.companySidebar.companyMenu." + index + ".active"
                );
            }
        });
    }
}

export function closeLicenseExpiredPopup() {
    Store.setStore(null, "data.licenseExpired");
}

export function printPage() {
    window.print();
}

/* HELPERS */

function debugMessage(message, data) {
    if (Store.config.debug) {
        console.log(message, data);
    }
}

function getSingleCompanyId() {
    const companies = Store.getStore("data.companySidebar.list");
    if (!companies || !companies.length || companies.length > 1) {
        return null;
    }

    const company = companies[0];
    return company.id;
}

let lastCompanyId;
function getLastCompanyId() {
    return lastCompanyId;
}
function setLastCompanyId(value) {
    lastCompanyId = value;
}
