import newId from '../utils/new-id.js';
import Picker from 'vanilla-picker';

export default class ColorpickerPalette extends React.Component {
    constructor(props) {
        super(props);

        this.pickerButtonRef = React.createRef();
        this.picker;
        this.defaultColor = 'rgba(100,100,100,1)';
        this.paletteColors = this.props.paletteColors || [
            '#F6F8AF', '#FFC798', '#F97BB7',
            '#9DA4FA', '#7BB0F9', '#B9E398',
            '#EFD47B', '#FF8F6B', '#F671C3',
            '#956DBB', '#BED6F9', '#D9E398',
            '#EFEA7C', '#FFB5B5', '#CF91AF',
            '#DFADF1', '#A5DDF5', '#68BEAF',
            '#F9E7B8', '#E95E77', '#FFB8F8',
            '#C581DD', '#BFFBDE', '#B4E8D5'
        ];
        this.state = {
            color: this.props.value || this.defaultColor,
            showPalette: false
        };
    }

    componentDidMount() {
        this.initializePicker();
    }

    componentDidUpdate() {
        this.initializePicker();
    }

    componentWillUnmount() {
        if (this.picker) {
            this.picker.destroy();
        }
    }

    render() {
        let palette, paletteOverlay;

        if (this.state.showPalette) {
            let colorButtons = [];
            for(let i=0; i<this.paletteColors.length; i++) {
                let color = this.paletteColors[i];
                colorButtons.push(
                    <button key={i}
                        type="button"
                        className="palette-color-button"
                        style={{backgroundColor: color}}
                        onClick={this.handlePaletteColorClick.bind(this, color)} ></button>
                );
            }
            palette = <div className="picker-palette">
                <p className="palette-header">{this.props.label}</p>
                <button type="button" className="palette-corner-close-button" onClick={this.closePalette.bind(this)}>{String.fromCharCode(215)}</button>
                <div className="palette-color-buttons">
                    {colorButtons}
                </div>
                <div className="palette-bottom-buttons">
                    <button type="button" ref={this.pickerButtonRef}>{this.props.data.anotherLabel}</button>
                </div>
            </div>;
            paletteOverlay = <div className="picker-palette-overlay" onClick={this.closePalette.bind(this)}></div>
        }

        return (
            <div className="colorpicker">
                <input type="hidden"
                    name={this.props.name}
                    value={this.state.color || this.props.value || this.defaultColor}
                    key={newId('input-key-')}
                    id={this.props.id}
                    onChange={this.props.onChange || function(){}}
                    onFocus={this.props.noFocus || function(){}}
                    onBlur={this.props.onBlur || function(){}} />

                <button type="button"
                    className="picker-button"
                    style={{backgroundColor: this.state.color || this.props.value || this.defaultColor}}
                    onClick={this.handlePickerButtonClick.bind(this)} />

                {paletteOverlay}
                {palette}
            </div>
        );
    }

    handlePickerButtonClick(e) {
        this.setState({
            showPalette: true
        });
    }

    handlePaletteColorClick(color, e) {
        this.setState({
            color: color,
            showPalette: false
        });
    }

    closePalette() {
        this.setState({
            showPalette: false
        });
    }

    initializePicker() {
        if (this.pickerButtonRef && this.pickerButtonRef.current) {
            if (this.picker) {
                this.picker.destroy();
            }
            this.picker = new Picker({
                parent: this.pickerButtonRef.current,
                color: this.state.color
            });
            this.picker.onDone = (color) => {
                this.setState({
                    showPalette: false,
                    color: color.rgbaString
                });
            };
        }
    }
}
