import { getNestedValue } from "../../../../components/helpers/objects-helper.js";
import CustomSelect from "./custom-select.jsx";

export default class SearchSelect extends CustomSelect {
    constructor(props) {
        super(props);
        this.state.inputValue = "";
        this.state.selectedOptionValue = null;
        this.selectionValues = [];

        // ВНИМАНИЕ!
        // active - выбренный в селекте элемент, то есть текущее значение селекта
        // selected - выбранный курсором элемент, но не обязательно являющийся текущим значением селекта
    }

    componentDidUpdate(prevProps) {
        this.scrollToSelectedOption();
    }

    render() {
        let select = this.renderSelect(),
            searchField = this.renderSearchField(),
            list = this.renderCustomList();

        this.selectionValues = this.getSelectionValues();

        return (
            <div
                className={
                    "search-select" + (this.state.showList ? " show-list" : "")
                }
                ref={this.styledSelectRef}
            >
                {select}
                <div className="select-list-container">{list}</div>
                {searchField}
            </div>
        );
    }

    renderSearchField() {
        return (
            <div className="search-field">
                <input
                    type="text"
                    placeholder={this.state.activeLabel}
                    value={this.state.inputValue}
                    autoComplete={this.props.autocomplete ? "on" : "off"}
                    onChange={this.handleInputChange.bind(this)}
                    onFocus={this.handleInputFocus.bind(this)}
                    onKeyDown={this.handleInputKeyDown.bind(this)}
                />
                <div className="field-dropdown-icon"></div>
            </div>
        );
    }

    renderCustomListButton(index, item) {
        if (item.value && this.selectionValues.indexOf(item.value) <= -1) {
            return null;
        }

        return super.renderCustomListButton(index, item);
    }

    handleInputChange(e) {
        this.setState({
            inputValue: e.target.value,
        });
    }

    handleInputFocus(e) {
        this.setState({
            showList: true,
        });
    }

    handleInputKeyDown(e) {
        switch (e.keyCode) {
            case 13:
                e.preventDefault();
                e.stopPropagation();
                this.submitOption(e);
                break;
            case 40:
                e.stopPropagation();
                this.selectNextOption();
                break;
            case 38:
                e.stopPropagation();
                this.selectPrevOption();
                break;
        }
    }

    handleListButtonClick(value, e) {
        super.handleListButtonClick(value, e);
        this.setState({
            inputValue: "",
        });
    }

    hasCustomButton() {
        return true; // действия с activeLabel, activeValue происходят, если тут true
    }

    hasCustomList() {
        return true; // действия с showList происходят, если тут true
    }

    checkOption(item) {
        if (!this.state.inputValue) {
            return true;
        }
        return (
            item.label
                .toLowerCase()
                .indexOf(this.state.inputValue.toLowerCase()) > -1
        );
    }

    submitOption(e) {
        if (this.state.selectedOptionValue) {
            this.handleListButtonClick(this.state.selectedOptionValue, e);
        }
    }

    selectNextOption() {
        this.setState({
            showList: true,
        });

        if (!this.state.selectedOptionValue) {
            return this.selectFirstOption();
        }

        const valuePos = this.selectionValues.indexOf(
            this.state.selectedOptionValue
        );
        if (valuePos <= -1) {
            return this.selectFirstOption();
        }

        const nextValue = this.selectionValues[valuePos + 1];
        if (nextValue) {
            return this.setState({
                selectedOptionValue: nextValue,
            });
        }

        return this.selectFirstOption();
    }

    selectPrevOption() {
        this.setState({
            showList: true,
        });

        if (!this.state.selectedOptionValue) {
            return this.selectLastOption();
        }

        const valuePos = this.selectionValues.indexOf(
            this.state.selectedOptionValue
        );
        if (valuePos <= -1) {
            return this.selectLastOption();
        }

        const nextValue = this.selectionValues[valuePos - 1];
        if (nextValue) {
            return this.setState({
                selectedOptionValue: nextValue,
            });
        }

        return this.selectLastOption();
    }

    selectFirstOption() {
        return this.setState({
            selectedOptionValue: this.selectionValues[0],
        });
    }

    selectLastOption() {
        return this.setState({
            selectedOptionValue: this.selectionValues[
                this.selectionValues.length - 1
            ],
        });
    }

    getListButtonClassName(item) {
        let className = super.getListButtonClassName(item);

        if (item.value == this.state.selectedOptionValue) {
            className += " selected";
        }

        return className;
    }

    getSelectionValues() {
        const options = this.getOptions();
        if (!options || !options.length) {
            return [];
        }

        let values = [];
        for (let i = 0; i < options.length; i++) {
            if (this.checkOption(options[i])) {
                values.push(options[i].value);
            }
        }

        return values;
    }

    scrollToSelectedOption() {
        // забили на state смотрим в dom
        if (!this.styledSelectRef || !this.styledSelectRef.current) {
            return null;
        }

        const elem = this.styledSelectRef.current;
        if (!elem.classList.contains("show-list")) {
            return null;
        }

        const selectedOption = elem.querySelector(".selected");
        if (!selectedOption) {
            return null;
        }

        const container = selectedOption.closest(".select-list");
        // console.log(container.scrollHeight, container.clientHeight, container.scrollTop, selectedOption.offsetHeight, selectedOption.offsetTop);
        if (selectedOption.offsetTop < container.scrollTop) {
            // элемент выше чем видимая область
            container.scrollTop = selectedOption.offsetTop;
            return true;
        }

        if (
            selectedOption.offsetTop + selectedOption.offsetHeight >
            container.clientHeight + container.scrollTop
        ) {
            // элемент ниже чем видимая область
            container.scrollTop =
                selectedOption.offsetTop +
                selectedOption.offsetHeight -
                container.clientHeight;
            return true;
        }
    }
}
