export function addUrlParam(url, name, value) {
    let delim = '?';
    if (url.indexOf('?') > -1) {
        delim = '&';
    }
    return url + delim + name + '=' + value;
}

export function addUrlParams(url, params) {
    if (!params || !params.length) {
        return url;
    }

    for(let i=0; i<params.length; i++) {
        url = addUrlParam(url, params[i][0], params[i][1]);
    }

    return url;
}

export function addBodyParam(body, name, value) {
    if (!body) {
        body = {};
    }

    if (body instanceof FormData) {
        body.append(name, value);
    } else {
        body[name] = value;
    }

    return body;
}

export function ensureFormData(body) {
    if (!body) {
        return body;
    }

    if (!(body instanceof FormData)) {
        let newBody = new FormData;
        for(let name in body) {
            newBody.append(name, body[name]);
        }
        return newBody;
    }

    return body;
}

export function ensureQueryString(body) {
    var str = [];
    for (var p in body) {
        if (body.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(body[p]));
        }
    }
    return str.join("&");
}

export function getUrlParam(urlString, name) {
    var url = new URL(urlString);
    return url.searchParams.get(name);
}

export function getUrlHashParam(urlString, name) {
    var url = new URL(urlString),
        hash = url.hash.substr(1),
        pairs = hash.split('&');

    for(let i in pairs) {
        var pair = pairs[i].split('=');
        if (pair[0] == name) {
            return pair[1];
        }
    }
}
