export default class Price extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        if (!this.props.value) {
            return null;
        }

        const value = this.parseValue(this.props.value);

        if (this.props.integer) {
            return(
                <span className="price">
                    <span className="int">{value.int}</span>
                    <span className="unit unit-ruble"></span>
                </span>
            );
        }

        return (
            <span className="price">
                <span className="int">{value.int}</span>
                <span className="delim">{value.delim}</span>
                <span className="float">{value.float}</span>
                <span className="unit unit-ruble"></span>
            </span>
        );
    }

    parseValue(value) {
        let result = {
            int: null,
            float: null,
            delim: ','
        };

        if (value.indexOf('.') > -1) {
            result.delim = '.';
        }

        let parts = value.split(result.delim);
        result.int = parts[0];
        result.float = parts[1];

        if (result.int) {
            result.int = result.int.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        return result;
    }
}
