import * as Store from "../store.js";
import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as Repository from "../../components/repositories/repository.js";

export function handleResponse(request, successHandler) {
    return request
        .then((response) => {
            if (response.licenseExpired) {
                Store.setStore(response.licenseExpired, "data.licenseExpired");
                Store.setStore(false, "data.loading.show");
                return response;
            }
            if (!successHandler(response)) {
                debugMessage("invalid response", response);
                LayoutHandlers.handleRequestFail();
            }
            Store.setStore(false, "data.loading.show");
            return response;
        })
        .catch((error) => {
            Store.setStore(false, "data.loading.show");
            LayoutHandlers.handleRequestFail(error);
        });
}

export function submitForm(form) {
    Store.setStore(true, "data.loading.show");

    if (!form || !form.validateForm() || !form.props.action) {
        Store.setStore(false, "data.loading.show");
        return Promise.reject();
    }

    return handleResponse(
        Repository.request("POST", form.props.action, form.getFormData()),
        (response) => {
            if (!response) {
                return false;
            }

            return true;
        }
    );
}

/* HELPERS */

function debugMessage(message, data) {
    console.log(message, data);
}
