import * as Auth from "../auth.js";
import { request, queryRequest } from "./repository.js";
import { getNestedValue } from "../helpers/objects-helper.js";

let config = {};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function getLayout(companyId, params) {
    let operationsData = params || {},
        graphsData = {};

    operationsData.limit = 10;

    if (companyId) {
        operationsData.companyId = companyId;
        graphsData.companyId = companyId;
    } else {
        operationsData.defaultCompany = true;
        graphsData.defaultCompany = true;
    }

    return queryRequest({
        pageHeader: {
            menu: { active: "transactions" },
        },
        pageSocialLinks: {},
        companySidebar: { companyId: companyId },
        operations: operationsData,
        graphs: graphsData,
    }).then((response) => {
        response.companyId = getNestedValue(
            response,
            "operations",
            "companyId"
        );
        return {
            layout: "transactions",
            data: response,
        };
    });
}

export function getCompany(companyId) {
    return queryRequest({
        operations: { companyId: companyId },
        graphs: { companyId: companyId },
        companySidebar: { companyId: companyId },
    });
}

export function getOperations(params) {
    return queryRequest({
        operations: params,
    });
}

export function getGraphs(params) {
    return queryRequest({
        graphs: params,
    });
}
