import StatsGraph from "./stats-graph.jsx";
import StatsGraphCompact from "./stats-graph-compact.jsx";
import CheckList from "../../check-list.jsx";
import DatepickerPopup from "../../datepicker-popup.jsx";
import Errors from "../../errors.jsx";
import * as ObjectsHelper from "../../../../components/helpers/objects-helper.js";

export default class GraphsIncomeOutcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDateFilterPopup: this.props.openDateFilterPopup || false,
            openDatepickerPopup: this.props.openDatepickerPopup || false,
            compactGraphs: this.isGraphsCompact(),
        };

        this.windowResizeHandler = (e) => {
            this.setState({
                compactGraphs: this.isGraphsCompact(),
            });
        };

        window.addEventListener("resize", this.windowResizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.windowResizeHandler);
    }

    render() {
        let filterDate, filterDatePopup, filterDatepicker;
        if (ObjectsHelper.checkNested(this.props, "filter", "date", "list")) {
            let activeDate;

            for (let i = 0; i < this.props.filter.date.list.length; i++) {
                let item = this.props.filter.date.list[i];
                if (!activeDate || item.active) {
                    activeDate = item.label;
                }
            }

            filterDate = (
                <button
                    className="graphs-filter-button"
                    onClick={this.handleDateFilterClick.bind(this)}
                >
                    <span className="icon icon-date"></span>
                    <span className="text">{activeDate}</span>
                </button>
            );

            filterDatePopup = (
                <div
                    className={
                        "filter-date-popup" +
                        (this.state.openDateFilterPopup ? " show" : "")
                    }
                >
                    <button
                        className="selector-button"
                        onClick={this.handleDateFilterClick.bind(this)}
                    >
                        <span className="icon"></span>
                        <span className="text">
                            {this.props.filter.date.label}
                        </span>
                    </button>
                    <button
                        className="close-button"
                        onClick={this.handleDateFilterClick.bind(this)}
                    ></button>
                    <CheckList
                        list={this.props.filter.date.list}
                        defaultFirst={true}
                        onItemClick={this.handleDateChecklistClick.bind(this)}
                    />
                </div>
            );

            filterDatepicker = (
                <DatepickerPopup
                    show={this.state.openDatepickerPopup}
                    selectRange={true}
                    selectButton={true}
                    onCloseButtonClick={this.handleDatepickerCloseButtonClick.bind(
                        this
                    )}
                    onSelectButtonClick={this.handleDatepickerSelectButtonClick.bind(
                        this
                    )}
                />
            );
        }

        let graphs = [];
        if (ObjectsHelper.checkNested(this.props.list, "length")) {
            for (let i = 0; i < this.props.list.length; i++) {
                let item = this.props.list[i],
                    moreButton;

                if (item.moreButton && !this.state.compactGraphs) {
                    moreButton = (
                        <div className="more-link">
                            <button
                                type="button"
                                onClick={
                                    this.props.onMoreButtonClick ||
                                    function () {}
                                }
                            >
                                {item.moreButton.label}{" "}
                                {String.fromCharCode(8594)}
                            </button>
                        </div>
                    );
                }

                graphs.push(
                    <div key={i} className="graph-container">
                        <div className="graph-info-container">
                            {this.state.compactGraphs ? (
                                <StatsGraphCompact
                                    {...item}
                                    onMarkerClick={this.props.onMarkerClick}
                                    onMoreButtonClick={
                                        this.props.onMoreButtonClick ||
                                        function () {}
                                    }
                                />
                            ) : (
                                <StatsGraph
                                    {...item}
                                    onMarkerClick={this.props.onMarkerClick}
                                    onHeaderClick={this.props.onMoreButtonClick}
                                />
                            )}
                        </div>
                        {moreButton}
                    </div>
                );

                if (i + 1 < this.props.list.length) {
                    graphs.push(
                        <div key={"delim-" + i} className="graphs-delim"></div>
                    );
                }
            }
        }

        return (
            <div className="graphs-income-outcome">
                <Errors list={this.props.errors} />
                <div className="filter-container">
                    <div className="filter">{filterDate}</div>
                    {filterDatePopup}
                    {filterDatepicker}
                </div>
                {graphs}
            </div>
        );
    }

    handleDateFilterClick(e) {
        this.setState({
            openDateFilterPopup: !this.state.openDateFilterPopup,
        });
    }

    handleDateChecklistClick(e, item) {
        this.setState({
            openDateFilterPopup: false,
            openDatepickerPopup: item.value == "datepicker",
        });

        const handler = this.props.onDateChecklistClick || function () {};
        handler(e, item);
    }

    handleDatepickerCloseButtonClick() {
        this.setState({
            openDatepickerPopup: false,
        });
    }

    handleDatepickerSelectButtonClick(e, params) {
        this.setState({
            openDatepickerPopup: false,
        });

        const handler =
            this.props.onDatepickerSelectButtonClick || function () {};
        handler(e, params);
    }

    isGraphsCompact() {
        return window.outerWidth < 500;
    }
}
