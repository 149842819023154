import Price from '../../price.jsx';
import LetterItemHelper from '../../../helpers/letter-item-helper';

export default class PaymentAccountsList extends React.Component {
    constructor(props) {
        super(props);
        this.letterHelper = new LetterItemHelper(6, 4);
    }

    render() {
        if (!this.props.list) {
            return null;
        }

        this.letterHelper.resetCounter();

        let list = [];

        for(let i=0; i<this.props.list.length; i++) {
            let item = this.props.list[i],
                note;

            if (item.note) {
                note = <span className="note" title={item.note}>{item.note}</span>;
            }

            list.push(
                <button key={i} className="account-list-item" onClick={this.handleAccountClick.bind(this, item)}>
                    <span className="list-item-col icon">
                        <span className={'icon-content ' + this.letterHelper.getLetterClass(item)}>
                            {this.letterHelper.getLetterSymbol(item)}
                        </span>
                    </span>
                    <span className="list-item-col caption">
                        <span className="text" title={item.caption}>{item.caption}</span>
                        {note}
                    </span>
                    <span className="list-item-col price">
                        <Price value={item.price} />
                    </span>
                    <span className="list-item-col arrow">
                        <span className="icon-graph"></span>
                        <span className="icon-arrow"></span>
                    </span>
                </button>
            );
        }

        return (
            <div className="payment-accounts-list">
                {list}
            </div>
        );
    }

    handleAccountClick(item, e) {
        const handler = this.props.onAccountClick || function(){};
        handler(e, item);
    }
}
