import Price from '../../price.jsx';
import Errors from '../../errors.jsx';

export default class Debts extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let companies = [];
        if (this.props.list) {
            for(let i=0; i<this.props.list.length; i++) {
                let company = this.props.list[i],
                    items = [];

                if (company.list.length) {
                    for(let j=0; j<company.list.length; j++) {
                        let item = company.list[j];
                        items.push(
                            <div key={j} className="debts-list-item">
                                <p className="list-item-col name">{item.caption}</p>
                                <p className="list-item-col value">
                                    <Price value={item.price} />
                                </p>
                            </div>
                        );
                    }
                }

                companies.push(
                    <div key={i} className="debts-company-item">
                        <p className="caption">{company.caption}</p>
                        {items}
                    </div>
                );

                if ((i+1) < this.props.list.length) {
                    companies.push(<div key={'delim-'+i} className="companies-delim"></div>);
                }
            }
        }

        return (
            <div className="debts">
                <p className="debts-header">{this.props.label}</p>
                <Errors list={this.props.errors} />
                {companies}
            </div>
        );
    }
}
