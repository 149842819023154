import Application from './containers/application.jsx';
import * as Repository from '../components/repositories/repository.js';
import * as Store from './store.js';
import * as Auth from '../components/auth.js';

export default class ApplicationLoader {
    loadApplication(config, container) {
        this.configure(config);
        ReactDOM.render(<Application />, container);
    }

    configure(config) {
        if (!config.repository) {
            config.repository = {};
        }
        if (!config.store) {
            config.store = {};
        }
        if (config.debug) {
            config.repository.debug = config.debug;
            config.store.debug = config.debug;
        }
        Auth.setConfig(config.auth);
        Repository.setConfig(config.repository);
        Store.setConfig(config.store);
        Store.setStore(config, 'config');
    }
}
