import PhoneCodeSelect from "./phone-code-select.jsx";

export default class Phone extends React.Component {
    constructor(props) {
        super(props);

        this.valueCache = "";
    }

    render() {
        let codeData = this.props.data || {};
        codeData.customList = true;
        codeData.customButton = true;
        return (
            <div className="input-phone">
                <div className="phone-country-code">
                    <label className="form-label">{codeData.codeLabel}</label>
                    <PhoneCodeSelect
                        name={this.props.name + "_code"}
                        options={this.props.options}
                        data={codeData}
                        phoneCode={this.props.phone_code}
                    />
                </div>
                <div className="phone-number">
                    <label className="form-label">{this.props.label}</label>
                    <input
                        className="form-input"
                        type="text"
                        defaultValue={this.props.phone_number}
                        name={this.props.name + "_number"}
                        placeholder={this.props.placeholder}
                        autoComplete={this.props.autocomplete ? "on" : "off"}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.props.onFocus || function () {}}
                        onBlur={this.props.onBlur || function () {}}
                    />
                </div>
            </div>
        );
    }

    handleChange(e) {
        if (e.target.value.length >= this.valueCache.length) {
            // форматируем только если идет набор
            e.target.value = this.formatInputValue(e.target.value);
        }
        this.valueCache = e.target.value;

        const handler = this.props.onChange || function () {};
        handler(e);
    }

    formatInputValue(value) {
        let raw = value.replace(/\D+/g, "");
        let part1 = "",
            part2 = "",
            part3 = "",
            part4 = "";
        for (let i = 0; i < raw.length; i++) {
            switch (i) {
                case 0:
                case 1:
                case 2:
                    part1 += raw[i];
                    break;
                case 3:
                case 4:
                case 5:
                    part2 += raw[i];
                    break;
                case 6:
                case 7:
                    part3 += raw[i];
                    break;
                case 8:
                case 9:
                    part4 += raw[i];
                    break;
            }
        }
        let result = part1;
        if (part1.length >= 3) {
            result += " ";
        }
        result += part2;
        if (part2.length >= 3) {
            result += " ";
        }
        result += part3;
        if (part3.length >= 2) {
            result += " ";
        }
        result += part4;

        return result;
    }
}
