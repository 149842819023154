import { request, queryRequest } from "./repository.js";

let config = {};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function getLayout(name, params) {
    return queryRequest({
        pageHeader: {
            menu: { active: "cabinet" },
        },
        pageSocialLinks: {},
        cabinetSidebar: {},
        cabinetCompany: {},
        licenseNotification: {},
    }).then((response) => {
        return {
            layout: "cabinet",
            data: response,
        };
    });
}

export function getCompany(id, tabName) {
    return queryRequest({
        cabinetCompany: {
            companyId: id,
            tab: tabName,
        },
    });
}

export function getSidebar(id) {
    return queryRequest({
        cabinetSidebar: {},
    });
}

export function getSidebarAndCompany(id, tabName) {
    return queryRequest({
        cabinetSidebar: {},
        cabinetCompany: {
            companyId: id,
            tab: tabName,
        },
    });
}

export function getAddCompanyLayout() {
    return queryRequest({
        cabinetAddCompanyLayout: {},
    });
}

export function getCompanyTabAddForm(companyId, tabName) {
    return queryRequest({
        cabinetCompanyTabAddForm: {
            companyId: companyId,
            tab: tabName,
        },
    });
}

export function getCompanyTabEditForm(companyId, tabName, itemId) {
    return queryRequest({
        cabinetCompanyTabEditForm: {
            companyId: companyId,
            tab: tabName,
            itemId: itemId,
        },
    });
}

export function getCompanyTabDeleteForm(companyId, tabName, itemId) {
    return queryRequest({
        cabinetCompanyTabDeleteForm: {
            companyId: companyId,
            tab: tabName,
            itemId: itemId,
        },
    });
}

export function getCompanyTabAccountRemoveIntegrationForm(
    companyId,
    tabName,
    itemId
) {
    return queryRequest({
        paymentAccountRemoveBankIntegration: {
            companyId: companyId,
            tab: tabName,
            itemId: itemId,
        },
    });
}

export function getCompanyTabListItemCard(companyId, tabName, itemId) {
    return queryRequest({
        cabinetCompanyTabListItemCard: {
            companyId: companyId,
            tab: tabName,
            itemId: itemId,
        },
    });
}

export function getCompanyEditForm(companyId) {
    return queryRequest({
        cabinetCompanyEditForm: {
            companyId: companyId,
        },
    });
}

export function getSettingsLayout() {
    return queryRequest({
        cabinetSettingsLayout: {},
    });
}

export function getFeedbackLayout() {
    return queryRequest({
        cabinetFeedbackLayout: {},
    });
}

export function getLicenseLayout() {
    return queryRequest({
        cabinetLicenseLayout: {},
        cabinetLicenseOrders: {},
    });
}

export function getLicenseOrders(params) {
    return queryRequest({
        cabinetLicenseOrders: params,
    });
}

export function getBankIntegrationForm(params) {
    return queryRequest({
        paymentAccountAddBankIntegration: params,
    });
}
