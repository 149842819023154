import CompanyAddPopup from '../../../containers/companies/company-add-popup.jsx';

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.companies) {
            return null;
        }

        let companies = [];
        if (this.props.companies.list) {
            for(let i=0; i<this.props.companies.list.length; i++) {
                let item = this.props.companies.list[i],
                    itemClassName = 'companies-list-item';

                if (this.props.companies.activeCompany == item.id) {
                    itemClassName += ' active';
                }

                companies.push(
                    <button key={i} className={itemClassName} onClick={this.handleCompanyClick.bind(this, i)}>{item.caption}</button>
                );
            }
        }

        let actions = [];
        if (this.props.companies.actions) {
            for(let i=0; i<this.props.companies.actions.length; i++) {
                let item = this.props.companies.actions[i];
                actions.push(
                    <button key={i} className={'action-' + item.name} onClick={this.handleCompaniesActionClick.bind(this, item)}>{item.label}</button>
                );
            }
            if (actions.length) {
                actions = <div className="actions">{actions}</div>;
            }
        }

        return (
            <div className="companies">
                {companies}
                {actions}
                <CompanyAddPopup
                    className="add-company-popup"
                    {...this.props.companyAddPopup}
                    handleCloseButtonClick={this.props.onCompanyAddPopupClose || function(){}}
                    handleSubmit={this.props.onCompanyAddPopupSubmit || function(){}} />
            </div>
        );
    }

    handleCompanyClick(index, e) {
        const item = this.props.companies.list[index];
        this.setState({
            activeCompany: item.id
        });

        const handler = this.props.onCompanyClick || function(){};
        handler(e, item);
    }

    handleCompaniesActionClick(item, e) {
        const handler = this.props.onCompaniesActionClick || function(){};
        handler(e, item.name);
    }
}
