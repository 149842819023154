export default class Datepicker extends React.Component {
    constructor(props) {
        super(props);

        this.months = this.props.months || ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
        this.weekdaysShort = this.props.weekdaysShort || ['Пн','Вт','Ср','Чт','Пт','Сб','Вс'];
        this.strings = {
            selectButton: 'Выбрать'
        };
        this.fixedRows = this.props.fixedRows || true;
        this.currentDate = new Date();
        this.currentYear = this.currentDate.getFullYear();
        this.currentMonth = this.currentDate.getMonth()+1;
        this.currentDay = this.currentDate.getDate();

        this.state = {
            year: this.props.year || this.currentYear,
            month: this.props.month || this.currentMonth,
            selectedDate: null,
            range: {}
        };
    }

    render() {
        let weekdays = [];
        for(let i=0; i<this.weekdaysShort.length; i++) {
            weekdays.push(
                <button key={i} type="button" className="datepicker-cell">{this.weekdaysShort[i]}</button>
            );
        }

        let days = [],
            dayFrom = 1,
            dayTo = this.getMonthDaysCount(this.state.year, this.state.month),
            firstDayWeekday = this.getMonthFirstWeekday(this.state.year, this.state.month),
            lastDayWeekday = this.getMonthLastWeekday(this.state.year, this.state.month),
            prevMonthDayTo = this.getMonthDaysCount(this.state.year, this.state.month-1);

        for(let i=firstDayWeekday-1; i>0; i--) {
            let day = prevMonthDayTo-i+1;
            days.push(
                <button
                    key={'prev-' + i}
                    type="button"
                    className={'datepicker-cell other-month-day ' + this.getTodayClass(this.state.year, this.state.month-1, day) + ' ' + this.getRangeClass(this.state.year, this.state.month-1, day)}
                    data-year={this.state.year}
                    data-month={this.state.month-1}
                    data-day={day}
                    onClick={this.handleDayClick.bind(this)}>{day}</button>
            );
        }

        for(let i=dayFrom; i<=dayTo; i++) {
            days.push(
                <button
                    key={'current-' + i}
                    type="button"
                    className={'datepicker-cell ' + this.getTodayClass(this.state.year, this.state.month, i) + ' ' + this.getRangeClass(this.state.year, this.state.month, i)}
                    data-year={this.state.year}
                    data-month={this.state.month}
                    data-day={i}
                    onClick={this.handleDayClick.bind(this)}>{i}</button>
            );
        }

        let i;
        for(i=1; i<=(7-lastDayWeekday); i++) {
            days.push(<button
                key={'next-' + i}
                type="button"
                className={'datepicker-cell other-month-day ' + this.getTodayClass(this.state.year, this.state.month+1, i) + ' ' + this.getRangeClass(this.state.year, this.state.month+1, i)}
                data-year={this.state.year}
                data-month={this.state.month+1}
                data-day={i}
                onClick={this.handleDayClick.bind(this)}>{i}</button>);
        }

        if (this.fixedRows && (days.length < 42)) {
            // от нас хотят, чтобы кол-во строк в календаре всегда было одно независимо от дней в месяце
            for(let j=0; j<7; j++) {
                days.push(<button
                    key={'next-' + i}
                    type="button"
                    className={'datepicker-cell other-month-day ' + this.getTodayClass(this.state.year, this.state.month+1, i) + ' ' + this.getRangeClass(this.state.year, this.state.month+1, i)}
                    data-year={this.state.year}
                    data-month={this.state.month+1}
                    data-day={i}
                    onClick={this.handleDayClick.bind(this)}>{i}</button>);
                i++;
            }
        }

        let selectButton;
        if (this.props.selectButton) {
            selectButton = <button onClick={this.handleSelectButtonClick.bind(this)}>{this.strings.selectButton}</button>;
        }

        return (
            <div className="datepicker">
                <div className="datepicker-header">
                    <button className="month-prev" type="button" onClick={this.handlePrevMonthClick.bind(this)}></button>
                    <label>{this.getLabel(this.state.year, this.state.month)}</label>
                    <button className="month-next" type="button" onClick={this.handleNextMonthClick.bind(this)}></button>
                </div>
                <div className="datepicker-days datepicker-weekdays">
                    {weekdays}
                </div>
                <div className="datepicker-days">
                    {days}
                </div>
                <div className="datepicker-actions">
                    {selectButton}
                </div>
            </div>
        );
    }

    handleNextMonthClick() {
        let nextMonth = this.state.month+1,
            nextYear = this.state.year;
        if (nextMonth > 12) {
            nextMonth = 1;
            nextYear++;
        }
        this.setState({
            month: nextMonth,
            year: nextYear
        });
    }

    handlePrevMonthClick() {
        let nextMonth = this.state.month-1,
            nextYear = this.state.year;
        if (nextMonth < 1) {
            nextMonth = 12;
            nextYear--;
        }
        this.setState({
            month: nextMonth,
            year: nextYear
        });
    }

    handleDayClick(e) {
        const dateParams = this.getDateParams(
            e.target.dataset.year,
            e.target.dataset.month,
            e.target.dataset.day
        );

        this.setState({
            selectedDate: dateParams
        });

        const handler = this.props.onDayClick;
        if (handler) {
            handler(
                e,
                dateParams.dateString,
                dateParams.year,
                dateParams.month,
                dateParams.day
            );
        }

        if (this.props.selectRange) {
            const dateFrom = this.state.range.from,
                  dateTo = this.state.range.to;

            switch(true) {
                case (!dateFrom && !dateTo): // расчет на то, что у нас либо проставлен range полностью либо полностью пуст
                    this.setState({
                        range: {
                            from: dateParams,
                            to: dateParams
                        }
                    });
                    break;
                case (this.isDateRangeFrom(this.state.range, dateParams)):
                    this.setState({
                        range: {
                            from: dateParams,
                            to: this.state.range.to
                        }
                    });
                    break;
                case (this.isDateRangeTo(this.state.range, dateParams)):
                    this.setState({
                        range: {
                            from: this.state.range.from,
                            to: dateParams
                        }
                    });
                    break;
            }
        }
    }

    handleSelectButtonClick(e) {
        const handler = this.props.onSelectButtonClick;
        if (!handler) {
            return null;
        }

        handler(e, {
            date: this.state.selectedDate,
            range: this.state.range
        });
    }

    getLabel(year, month) {
        return this.getMonthLabel(month) + ' ' + year;
    }

    getMonthLabel(month) {
        return this.months[month-1];
    }

    getMonthDaysCount(year, month) {
        return new Date(year, month, 0).getDate(); // -1 нет из за нуля
    }

    getMonthFirstWeekday(year, month) {
        return this.fixWeekday(new Date(year, month-1, 1).getDay());
    }

    getMonthLastWeekday(year, month) {
        return this.fixWeekday(new Date(year, month, 0).getDay());
    }

    fixWeekday(weekday) {
        if (weekday === 0) {
            return 7;
        }
        return weekday;
    }

    formatDate(year, month, day) {
        let dd = String(day).padStart(2, '0'),
            mm = String(month).padStart(2, '0');

        return dd + '.' + mm + '.' + year;
    }

    getDateParams(year, month, day) {
        if (month < 1) {
            year--;
            month = 12;
        }

        if (month > 12) {
            year++;
            month = 1;
        }

        return {
            year: year,
            month: month,
            day: day,
            date: new Date(year, month, day),
            dateString: this.formatDate(year, month, day)
        };
    }

    getDateNumber(dateParams) {
        // не всегда подходящий способ (например не понять к какой дате в range ближе находится выбранная дата)
        // const mm = String(dateParams.month).padStart(2, '0'),
        //       dd = String(dateParams.day).padStart(2, '0');
        // return parseInt(''+dateParams.year+mm+dd);
        return dateParams.date.getTime();
    }

    /* RANGE HELPERS */

    isDateRangeFrom(range, dateParams) {
        const fromNumber = this.getDateNumber(range.from),
              toNumber = this.getDateNumber(range.to),
              checkNumber = this.getDateNumber(dateParams);

        if (checkNumber <= fromNumber) {
            return true;
        }

        // глядим, что выбранная дата ближе к левому краю
        // при равном значении предпочтение отдадим левой части
        if ((checkNumber-fromNumber) <= (toNumber-checkNumber)) {
            return true;
        }

        return false;
    }

    isDateRangeTo(range, dateParams) {
        const fromNumber = this.getDateNumber(range.from),
              toNumber = this.getDateNumber(range.to),
              checkNumber = this.getDateNumber(dateParams);

        if (checkNumber >= toNumber) {
            return true;
        }

        // глядим, что выбранная дата ближе к правому краю
        if ((checkNumber-fromNumber) > (toNumber-checkNumber)) {
            return true;
        }

        return false;
    }

    inRange(range, dateParams) {
        if (!range || !range.from || !range.to || !dateParams) {
            return false;
        }

        const fromNumber = this.getDateNumber(range.from),
              toNumber = this.getDateNumber(range.to),
              checkNumber = this.getDateNumber(dateParams);

        return checkNumber >= fromNumber && checkNumber <= toNumber;
    }

    getRangeClass(year, month, day) {
        const dateParams = this.getDateParams(year, month, day);

        if (this.state.selectedDate && (this.state.selectedDate.dateString == dateParams.dateString)) {
            return 'selected';
        }

        if (!this.inRange(this.state.range, dateParams)) {
            return '';
        }

        if ((this.state.range.from.dateString == dateParams.dateString) || (this.state.range.to.dateString == dateParams.dateString)) {
            return 'selected';
        }

        return 'range-selected';
    }

    getTodayClass(year, month, day) {
        if ((year == this.currentYear) && (month == this.currentMonth) && (day == this.currentDay)) {
            return 'datepicker-cell-today';
        }

        return '';
    }
}
