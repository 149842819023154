import Price from "../../price.jsx";
import LetterItemHelper from "../../../helpers/letter-item-helper.js";

export default class TargetsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openedActionsItemId: null,
        };

        this.handleDocumentClick = (e) => {
            if (!e.target.closest(".actions-button")) {
                this.setState({
                    openedActionsItemId: null,
                });
            }
        };

        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    render() {
        if (!this.props.list || !this.props.list.length) {
            return null;
        }

        let list = [];
        for (let i = 0; i < this.props.list.length; i++) {
            let item = this.props.list[i];

            let markMain;
            if (item.mainTarget) {
                markMain = (
                    <p className="li-mark item-mark-main">{item.mainTarget}</p>
                );
            }

            list.push(
                <div
                    key={i}
                    className={"list-item" + (item.expired ? " expired" : "")}
                >
                    <div className="list-item-container">
                        <div className="li-title item-amount">
                            <Price value={item.amount} integer={true} />
                        </div>
                        {markMain}
                        <div className="li-field item-caption">
                            <p>{item.caption}</p>
                        </div>
                        <div className="li-field item-date">
                            <p>{item.dateRange}</p>
                        </div>
                        <div className="li-field item-remain">
                            <p>{item.remain}</p>
                        </div>
                        {this.renderItemActions(item)}
                    </div>
                </div>
            );
        }

        return <div className="layout-cards-list targets-list">{list}</div>;
    }

    renderItemActions(item) {
        if (!item.actions) {
            return null;
        }

        let actions = [];

        item.actions.map((action) => {
            actions.push(
                <button
                    className={"action-item action-" + action.name}
                    key={action.name}
                    onClick={this.handleActionClick.bind(this, item, action)}
                />
            );
        });

        return (
            <div
                className={
                    "li-actions" +
                    (item.id == this.state.openedActionsItemId ? " opened" : "")
                }
            >
                {actions}
            </div>
        );
    }

    handleActionClick(item, action, e) {
        const handler = this.props.onActionClick || function () {};
        handler(e, item, action);
    }

    handleActionsButtonClick(item) {
        this.setState({
            openedActionsItemId: item.id,
        });
    }
}
