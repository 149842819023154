import DatepickerPopup from "../datepicker-popup.jsx";
import CheckList from "../check-list.jsx";
import { getNestedValue } from "../../../components/helpers/objects-helper.js";
import { isMobile } from "../../helpers/layout-helper.js";

export default class DatepickerField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openChecklistPopup: this.props.openChecklistPopup || false,
            openDatepickerPopup: this.props.openDatepickerPopup || false,
        };

        this.containerRef = React.createRef();

        this.handleDocumentClick = (e) => {
            if (
                !this.containerRef ||
                !this.containerRef.current ||
                !this.containerRef.current.contains(e.target)
            ) {
                // щелкнули вне попапа
                this.setState({
                    openChecklistPopup: false,
                    // openDatepickerPopup: false,
                });
            }
        };

        this.dateRangeFromCache = "";
        this.dateRangeToCache = "";

        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    render() {
        let activeDate,
            value,
            list = this.props.list || this.props.data.options;

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (!activeDate || item.active) {
                activeDate = item.label;
                value = item.value;
            }
        }

        return (
            <div className="filter-datepicker" ref={this.containerRef}>
                <input type="hidden" name={this.props.name} value={value} />
                <input
                    type="hidden"
                    name={this.props.name + "_from"}
                    value={this.props.data.dateFrom}
                />
                <input
                    type="hidden"
                    name={this.props.name + "_to"}
                    value={this.props.data.dateTo}
                />
                <label className="filter-label">{this.props.label}</label>
                <button
                    type="button"
                    className="filter-select-button"
                    onClick={this.handleDateFilterClick.bind(this)}
                >
                    <span className="text">{activeDate}</span>
                    <span className="icon-arrow"></span>
                </button>
                {/*
                <button type="button" className="filter-label-button" onClick={this.handleDateFilterClick.bind(this)}>
                    <span className="icon icon-date"></span>
                    <span className="text">{activeDate}</span>
                </button>
                */}
                <div
                    className={
                        "filter-date-checklist-popup" +
                        (this.state.openChecklistPopup ? " show" : "")
                    }
                >
                    <button
                        type="button"
                        className="checklist-header"
                        onClick={this.handleDateFilterClick.bind(this)}
                    >
                        <span className="icon"></span>
                        <span className="text">{this.props.label}</span>
                    </button>
                    <button
                        type="button"
                        className="close-button"
                        onClick={this.handleDateFilterClick.bind(this)}
                    ></button>
                    <CheckList
                        list={list}
                        defaultFirst={true}
                        onItemClick={this.handleChecklistClick.bind(this)}
                    />
                    {this.renderDateRangeInputs()}
                    {this.renderApplyButton()}
                </div>
                {/*
                <DatepickerPopup
                    show={this.state.openDatepickerPopup}
                    selectRange={true}
                    selectButton={true}
                    onCloseButtonClick={this.handleDatepickerCloseButtonClick.bind(
                        this
                    )}
                    onSelectButtonClick={this.handleDatepickerSelectButtonClick.bind(
                        this
                    )}
                />
                */}
            </div>
        );
    }

    renderDateRangeInputs() {
        if (!this.state.openDatepickerPopup) {
            return null;
        }

        return (
            <div className="date-range-inputs">
                <div className="range-control">
                    <input
                        type="text"
                        name={this.props.name + "_from"}
                        className="range-input range-from"
                        placeholder="01.01.2023"
                        onInput={this.handleDateFromInput.bind(this)}
                    />
                    <span className="range-delim"> - </span>
                    <input
                        type="text"
                        name={this.props.name + "_to"}
                        className="range-input range-to"
                        placeholder="01.01.2023"
                        onInput={this.handleDateToInput.bind(this)}
                    />
                </div>
            </div>
        );
    }

    handleDateFilterClick(e) {
        this.setState({
            openChecklistPopup: !this.state.openChecklistPopup,
        });
    }

    handleChecklistClick(e, item) {
        if (item.value == "datepicker") {
            this.setState({
                openDatepickerPopup: true,
            });
        } else {
            if (!isMobile(450)) {
                this.setState({
                    openChecklistPopup: false,
                    openDatepickerPopup: false,
                });
            } else {
                this.setState({
                    openDatepickerPopup: false,
                });
            }
        }

        const handler = this.props.onChecklistClick || function () {};
        handler(e, item);
    }

    handleDatepickerCloseButtonClick() {
        // this.setState({
        //     openDatepickerPopup: false,
        // });
    }

    handleDatepickerSelectButtonClick(e, params) {
        this.setState({
            openDatepickerPopup: false,
        });

        const handler =
            this.props.onDatepickerSelectButtonClick || function () {};
        handler(e, params);
    }

    handleDateFromInput(e) {
        if (e.target.value.length >= this.dateRangeFromCache.length) {
            // форматируем только если идет набор
            e.target.value = this.formatDateInputValue(e.target.value);
        }
        this.dateRangeFromCache = e.target.value;
        if (e.target.value.length == 10) {
            const handler =
                this.props.onDatepickerDateRangeChange || function () {};
            handler(e);
        }
    }

    handleDateToInput(e) {
        if (e.target.value.length >= this.dateRangeToCache.length) {
            // форматируем только если идет набор
            e.target.value = this.formatDateInputValue(e.target.value);
        }
        this.dateRangeToCache = e.target.value;
        if (e.target.value.length == 10) {
            const handler =
                this.props.onDatepickerDateRangeChange || function () {};
            handler(e);
        }
    }

    formatDateInputValue(value) {
        let raw = value.replace(/\D+/g, "");
        let day = "",
            month = "",
            year = "";
        for (let i = 0; i < raw.length; i++) {
            switch (i) {
                case 0:
                case 1:
                    day += raw[i];
                    break;
                case 2:
                case 3:
                    month += raw[i];
                    break;
                case 4:
                case 5:
                case 6:
                case 7:
                    year += raw[i];
                    break;
            }
        }
        let result = day;
        if (day.length >= 2) {
            result += ".";
        }
        result += month;
        if (month.length >= 2) {
            result += ".";
        }
        result += year;

        return result;
    }

    renderApplyButton() {
        if (getNestedValue(this.props.data, "applyLabel")) {
            return (
                <button
                    type="button"
                    className="apply-button"
                    onClick={this.handleApplyClick.bind(this)}
                    key="apply-button"
                >
                    {this.props.data.applyLabel}
                </button>
            );
        }
    }

    handleApplyClick(e) {
        this.setState({
            openChecklistPopup: false,
        });

        const handler = this.props.onApplyClick || function () {};
        handler(e);
    }
}
