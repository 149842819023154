import Form from '../../forms/form.jsx';
import Fieldset from '../../forms/fields/fieldset.jsx';
import Field from '../../forms/fields/field.jsx';
import Errors from '../../forms/errors.jsx';
import Messages from '../../forms/messages.jsx';

export default class AuthForm extends Form {
    constructor(props) {
        super(props);
    }

    render() {
        let form;

        if (!this.props.fields) {
            return null;
        }

        if (!this.props.hideForm) {
            let formAttributes = {
                    action: this.props.action || '',
                    method: this.props.method || 'post',
                    onSubmit: this.handleSubmit.bind(this)
                };

            // надо все поля кроме кнопки поместить в филдсет
            let fields = [],
                button = null;

            for(let field of this.props.fields) {
                if (field.type == 'submit') {
                    button = field;
                } else {
                    fields.push(field);
                }
            }

            form = <form {...formAttributes} ref={this.formRef} >
                <Fieldset fields={fields}
                          ref={this.fieldsetRef}
                          onFieldChange={this.handleFieldChange.bind(this)}
                          onFieldClick={this.handleFieldClick.bind(this)}
                          />
                <Field {...button} />
            </form>;
        }

        return (
            <div className="auth-form">
                <Errors list={this.props.errors} />
                <Messages list={this.props.messages} />
                {form}
            </div>
        );
    }
}
