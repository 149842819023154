import Container from '../container.jsx';
import FormPopup from '../../components/form-popup.jsx';

export default class OperationEditPopupContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.operationEditPopup');
    }

    render(){
        if (!this.state.state) {
            return null;
        }

        return <FormPopup {...this.props} {...this.state.state} />;
    };
}
