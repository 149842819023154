import newId from '../utils/new-id.js';
import Picker from 'vanilla-picker';

export default class Colorpicker extends React.Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.buttonRef = React.createRef();
        this.picker;
        this.defaultColor = 'rgba(100,100,100,1)'
    }

    componentDidMount() {
        if (this.buttonRef && this.buttonRef.current) {
            this.picker = new Picker({
                parent: this.buttonRef.current,
                color: this.props.value || this.defaultColor
            });
            this.picker.onChange = (color) => {
                this.inputRef.current.value = color.rgbaString;
                this.buttonRef.current.style.backgroundColor = color.rgbaString;
            };
        }
    }

    componentWillUnmount() {
        if (this.picker) {
            this.picker.destroy();
        }
    }

    render() {
            // все остальные компоненты (textarea, select, chekput) не нуждаются в новом свойстве key
            // они перерисовывются когда приходит новый value
            // но этот инпут почему-то не перерисовывается...
            return (
                <div className="colorpicker">
                    <input type="hidden"
                        name={this.props.name}
                        defaultValue={this.props.value || this.defaultColor}
                        key={newId('input-key-')}
                        id={this.props.id}
                        onChange={this.props.onChange || function(){}}
                        onFocus={this.props.noFocus || function(){}}
                        onBlur={this.props.onBlur || function(){}}
                        ref={this.inputRef} />

                    <button type="button"
                        className="picker-button"
                        ref={this.buttonRef}
                        style={{backgroundColor: this.props.value || this.defaultColor}} />
                </div>
            );
    }
}
