import { getNestedValue } from "../../../../components/helpers/objects-helper.js";

export default class Breadcrumbs extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let list = [];

        if (this.props.rootMenu !== false) {
            list.push(this.renderRootMenu());
        }

        const cabinetMenu = this.renderCabinetMenu();
        if (cabinetMenu && this.props.cabinetMenu !== false) {
            list.push(this.renderDelim(1));
            list.push(cabinetMenu);
        }

        const company = this.renderCompany();
        if (company) {
            list.push(this.renderDelim(2));
            list.push(company);
        }

        return <div className="cabinet-breadcrumbs">{list}</div>;
    }

    renderRootMenu() {
        return (
            <button
                key="root"
                type="button"
                className="breadcrumbs-item"
                onClick={this.props.onRootItemClick || function () {}}
            >
                {getNestedValue(this.props.pageHeader, "userbar", "label")}
            </button>
        );
    }

    renderCabinetMenu() {
        const menu = getNestedValue(this.props.cabinetSidebar, "menu");
        if (!menu || !menu.length) {
            return null;
        }

        const activeMenuName = getNestedValue(
            this.props.cabinetSidebar,
            "activeMenuName"
        );

        let label;
        for (let i = 0; i < menu.length; i++) {
            if (menu[i].active || menu[i].name == activeMenuName) {
                label = menu[i].caption;
                break;
            }
        }

        if (!label) {
            return null;
        }

        return (
            <button
                key="cabinet"
                type="button"
                className="breadcrumbs-item"
                onClick={this.handleCabinetItemClick.bind(this, activeMenuName)}
            >
                {label}
            </button>
        );
    }

    renderCompany() {
        const companies = getNestedValue(
            this.props.cabinetSidebar,
            "companies",
            "list"
        );

        if (!companies || !companies.length) {
            return null;
        }

        const activeCompanyId = getNestedValue(
            this.props.cabinetSidebar,
            "companies",
            "activeCompany"
        );
        if (!activeCompanyId) {
            return null;
        }

        let activeItem;
        for (let i = 0; i < companies.length; i++) {
            if (companies[i].id == activeCompanyId) {
                activeItem = companies[i];
                break;
            }
        }

        if (!activeItem) {
            return null;
        }

        return (
            <button
                key="company"
                type="button"
                className="breadcrumbs-item item-company"
                onClick={this.handleCompanyClick.bind(this, activeItem)}
            >
                {activeItem.caption}
            </button>
        );
    }

    renderDelim(key) {
        return <div key={key} className="breadcrumbs-delim"></div>;
    }

    handleCabinetItemClick(name, e) {
        const handler = this.props.onCabinetItemClick || function () {};
        handler(e, name);
    }

    handleCompanyClick(item, e) {
        const handler = this.props.onCompanyClick || function () {};
        handler(e, item);
    }
}
