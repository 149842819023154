import PageHeader from "../../page-header.jsx";
import Sidebar from "../../../containers/cabinet/sidebar.jsx";
import LicenseNotification from "./license-notification.jsx";
import Company from "../../../containers/cabinet/company.jsx";
import AddCompany from "../../../containers/cabinet/add-company.jsx";
import Settings from "../../../containers/cabinet/settings.jsx";
import Feedback from "../../../containers/cabinet/feedback.jsx";
import License from "../../../containers/cabinet/license.jsx";
import newId from "../../../helpers/new-id.js";
import Errors from "../../errors.jsx";
import Messages from "../../messages.jsx";
import CabinetListMenu from "./cabinet-list-menu.jsx";
import Breadcrumbs from "./breadcrumbs.jsx";
import { isMobile } from "../../../helpers/layout-helper.js";

export default class CabientLayout extends React.Component {
    constructor(props) {
        super(props);
        this.companyRef = React.createRef();
        document.body.classList.add("dashboard");

        this.state = {
            isMobile: this.isMobile(),
        };
    }

    render() {
        return (
            <div>
                <PageHeader
                    {...this.props.pageHeader}
                    onMenuItemClick={
                        this.props.handlePageHeaderMenuItemClick ||
                        function () {}
                    }
                    onUserbarClick={
                        this.props.handlePageHeaderUserbarClick ||
                        function () {}
                    }
                    onLogoClick={
                        this.props.handlePageHeaderLogoClick || function () {}
                    }
                    onUserbarMenuButtonClick={
                        this.props.handlePageHeaderUserbarMenuButtonClick ||
                        function () {}
                    }
                    onSearchSubmit={
                        this.props.handlePageHeaderSearchSubmit ||
                        function () {}
                    }
                    onFeedbackBarMenuButtonClick={
                        this.props.handlePageHeaderFeedbackBarMenuButtonClick
                    }
                />

                <div className="page-limiter cabinet-layout">
                    <Sidebar
                        {...this.props.cabinetSidebar}
                        onMenuItemClick={
                            this.props.handleSidebarMenuItemClick ||
                            function () {}
                        }
                        onCompaniesActionClick={
                            this.props.handleCompaniesActionClick ||
                            function () {}
                        }
                        onCompanyClick={this.handleCompanyClick.bind(this)}
                        onCompanyAddPopupClose={
                            this.props.handleCompanyAddPopupClose ||
                            function () {}
                        }
                        onCompanyAddPopupSubmit={
                            this.props.handleCompanyAddPopupSubmit ||
                            function () {}
                        }
                    />

                    <div className="page-body">
                        {this.renderBreadcrumbs()}
                        {this.renderLicenseNotification()}
                        <Errors
                            className="cabinet-errors"
                            list={this.props.errors}
                        />
                        <Messages
                            className="cabinet-messages"
                            list={this.props.messages}
                        />
                        {this.renderLayout(this.props.layout)}
                    </div>
                </div>
            </div>
        );
    }

    renderLayout(name) {
        switch (name) {
            case "companies":
                return (
                    <CabinetListMenu
                        list={this.props.cabinetSidebar.companies.list}
                        labelField="caption"
                        onMenuItemClick={
                            this.props.handleCompanyClick || function () {}
                        }
                    />
                );
            case "company":
                return (
                    <Company
                        {...this.props.cabinetCompany}
                        ref={this.companyRef}
                        onEditActionClick={
                            this.props.handleCompanyEditActionClick ||
                            function () {}
                        }
                        onCaptionFormSubmit={
                            this.props.handleCompanyCaptionFormSubmit ||
                            function () {}
                        }
                        onDeleteFormSubmit={
                            this.props.handleCompanyDeleteFormSubmit ||
                            function () {}
                        }
                        onTabClick={
                            this.props.handleCompanyTabClick || function () {}
                        }
                        onTabActionClick={
                            this.props.handleCompanyTabActionClick ||
                            function () {}
                        }
                        onTabFormCancelButtonClick={
                            this.props.handleCompanyTabFormCancelButtonClick ||
                            function () {}
                        }
                        onTabFormAlterActionButtonClick={
                            this.props
                                .handleCompanyTabFormAlterActionButtonClick ||
                            function () {}
                        }
                        onTabFormFieldChange={
                            this.props.handleCompanyTabFormFieldChange ||
                            function () {}
                        }
                        onTabFormSubmit={
                            this.props.handleCompanyTabFormSubmit ||
                            function () {}
                        }
                        onTabEditActionClick={
                            this.props.handleCompanyTabEditActionClick ||
                            function () {}
                        }
                        onTabListItemClick={
                            this.props.handleCompanyTabListItemClick ||
                            function () {}
                        }
                        onConfirmDeletePopupSubmit={
                            this.props
                                .handleCompanyTabConfirmDeletePopupSubmit ||
                            function () {}
                        }
                        onConfirmPopupSubmit={
                            this.props.handleCompanyTabConfirmPopupSubmit ||
                            function () {}
                        }
                        onConfirmPopupClose={
                            this.props.handleCompanyTabConfirmPopupClose ||
                            function () {}
                        }
                    />
                );
            // при каждом рендере этого компонента будет создан новый key
            // компонент с новым key будет заново примонтирован с новым состоянием
            // без этого, если мы, к примеру заполнили форму и нажмем на +Добавить фирму
            // мы увидим ту же самую заполненную форму, а хотелось бы пустую
            // плюс можно забыть про componentWillRecieveProps и тп
            case "add-company":
                return (
                    <AddCompany
                        key={newId("add-company-layout-")}
                        {...this.props.cabinetAddCompanyLayout}
                        onSubmit={
                            this.props.handleAddCompanySubmit || function () {}
                        }
                    />
                );
            case "settings":
                return (
                    <Settings
                        {...this.props.cabinetSettingsLayout}
                        onSubmit={
                            this.props.handleSettingsSubmit || function () {}
                        }
                    />
                );
            case "feedback":
                return (
                    <Feedback
                        {...this.props.cabinetFeedbackLayout}
                        onSubmit={
                            this.props.handleFeedbackSubmit || function () {}
                        }
                    />
                );
            case "license":
                return (
                    <License
                        {...this.props.cabinetLicenseLayout}
                        orders={this.props.cabinetLicenseOrders}
                        onOrdersScrollEdge={
                            this.props.handleOrdersScrollEdge || function () {}
                        }
                    />
                );
            default:
                if (isMobile({ mobileWidth: 640 })) {
                    return (
                        <CabinetListMenu
                            list={this.props.cabinetSidebar.menu}
                            labelField="caption"
                            onMenuItemClick={this.handleCabinetMenuItemClick.bind(
                                this
                            )}
                        />
                    );
                }
                return null;
        }
    }

    renderLicenseNotification() {
        //     if (isMobile({ mobileWidth: 640 })) {
        //         return null;
        //     }

        return <LicenseNotification {...this.props.licenseNotification} />;
    }

    renderBreadcrumbs() {
        if (!isMobile({ mobileWidth: 640 })) {
            return null;
        }

        return (
            <Breadcrumbs
                {...this.props}
                onRootItemClick={
                    this.props.handlePageHeaderUserbarMenuButtonClick ||
                    function () {}
                }
                onCabinetItemClick={
                    this.props.handleCabinetMenuItemClick || function () {}
                }
                onCompanyClick={this.props.handleCompanyClick || function () {}}
            />
        );
    }

    handleCabinetMenuItemClick(e, item) {
        const handler = this.props.handleCabinetMenuItemClick || function () {};
        handler(e, item.name);
    }

    handleCompanyClick(e, item) {
        const handler = this.props.handleCompanyClick || function () {};
        let tabName = "about";
        if (this.companyRef && this.companyRef.current) {
            tabName = this.companyRef.current.getActiveTab();
        }
        handler(e, item, tabName);
    }

    isMobile() {
        return isMobile({
            mobileWidth: 840,
        });
    }
}
