import Fieldset from "./fields/fieldset.jsx";
import Errors from "./errors.jsx";
import Messages from "./messages.jsx";
import { getNestedValue } from "../../../components/helpers/objects-helper.js";

export default class Form extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.fieldsetRef = React.createRef();
    }

    render() {
        let formAttributes = {
            action: this.props.action || "",
            method: this.props.method || "post",
            autoComplete: this.props.autocomplete ? "on" : "off",
            onSubmit: this.handleSubmit.bind(this),
        };

        return (
            <form {...formAttributes} ref={this.formRef}>
                <Errors list={this.props.errors} />
                <Messages list={this.props.messages} />
                <Fieldset
                    fields={this.props.fields}
                    ref={this.fieldsetRef}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    onFieldClick={this.handleFieldClick.bind(this)}
                    onFieldFocus={this.handleFieldFocus.bind(this)}
                    onFieldBlur={this.handleFieldBlur.bind(this)}
                />
            </form>
        );
    }

    handleSubmit(e) {
        const handler = this.props.onSubmit || function () {};
        handler(e, this);
    }

    handleFieldChange(e, props, field, index) {
        const handler =
            getNestedValue(this.props.fields, index, "onChange") ||
            this.props.onFieldChange ||
            function () {};
        handler(e, props, field, this);
    }

    handleFieldClick(e, field, index, params) {
        const handler =
            getNestedValue(this.props.fields, index, "onClick") ||
            this.props.onFieldClick ||
            function () {};
        handler(e, field, this, params);
    }

    handleFieldFocus(e, props, field, index) {
        const handler =
            getNestedValue(this.props.fields, index, "onFocus") ||
            this.props.onFieldFocus ||
            function () {};
        handler(e, props, field, this);
    }

    handleFieldBlur(e, props, field, index) {
        const handler =
            getNestedValue(this.props.fields, index, "onBlur") ||
            this.props.onFieldBlur ||
            function () {};
        handler(e, props, field, this);
    }

    validateForm() {
        return this.fieldsetRef.current.validateFields();
    }

    getFormData() {
        let formData = new FormData(this.formRef.current);
        formData = this.fieldsetRef.current.prepareFormData(formData);
        return formData;
    }
}
