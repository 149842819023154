import { Auth } from './auth/auth.js';
import { ParamsAuth } from './auth/handlers/params-auth.js';
import { Cryptor } from './auth/cryptor.js';

let auth = new Auth();

const userStatusName = '31fa597bcd2c28742aafd86c174775b2';

let config = {
    debug: false
};

export function setConfig(newConfig) {
    if (newConfig) {
        for(let name in newConfig) {
            config[name] = newConfig[name];
        }
    }

    auth = new Auth({
        handler: new ParamsAuth(config),
        cryptor: new Cryptor(),
        tokenParser: response => {
            if (!response || !response.data || !response.data.token) {
                return null;
            }
            return {
                access_token: response.data.token.accessToken,
                expires_in: response.data.token.lifetime,
                refresh_token: response.data.token.refreshToken,
                user_status: response.data.token.userStatus
            };
        },
        on: {
            'setToken' : token => {
                setUserStatus(token.user_status);
            },
            'debugMessage' : message => {
                if (config.debug) {
                    console.log('AUTH: ' + message);
                }
            }
        }
    });
}

export function authorize(params) {
    return auth.login(params);
}

export function logout() {
    return auth.logout();
}

export function checkAuthorization() {
    return auth.checkAuthorization();
}

export function request(method, url, body, options) {
    options = options || {};
    options.method = method;
    options.body = body;
    return auth.request(url, options);
}

export function getUserStatus() {
    return parseInt(sessionStorage.getItem(userStatusName));
}

export function setUserStatus(status) {
    sessionStorage.setItem(userStatusName, status);
}
