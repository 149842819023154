import Container from "../container.jsx";
import AuthForm from "../../components/layouts/auth/auth-form.jsx";

export default class AuthFormContainer extends Container {
    constructor(props) {
        super(props);

        let storePath = "data.authForm";

        switch (this.props.name) {
            case "registration":
                storePath = "data.registrationForm";
                break;
            case "restore":
                storePath = "data.restoreForm";
                break;
        }

        this.connectStore(storePath);
    }

    render() {
        return (
            <AuthForm
                {...this.state.state}
                onSubmit={this.props.onSubmit || function () {}}
                onFieldClick={this.props.onFieldClick || function () {}}
            />
        );
    }
}
