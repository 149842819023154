export default class CompanyMenu extends React.Component {
    render() {
        let companyMenu = [];
        if (this.props.list) {
            this.props.list.map((item, index) => {
                companyMenu.push(
                    <button
                        key={index}
                        className={item.active ? "active" : ""}
                        onClick={this.handleMenuClick.bind(this, item)}
                    >
                        <span className={"icon icon-" + item.name}></span>
                        <span className="text">{item.label}</span>
                    </button>
                );
            });
        }

        return <div className="company-menu">{companyMenu}</div>;
    }

    handleMenuClick(item, e) {
        const handler = this.props.onMenuClick || function () {};
        handler(e, item);
    }
}
