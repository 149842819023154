import LoadingLayout from "../components/layouts/loading.jsx";
import ErrorLayout from "../components/layouts/error.jsx";
import AuthLayout from "../components/layouts/auth/auth.jsx";
import CompaniesLayout from "../components/layouts/companies/companies.jsx";
import CompanyLayout from "../components/layouts/companies/company.jsx";
import InvestmentsLayout from "../components/layouts/investments/investments.jsx";
import CabinetLayout from "../containers/cabinet/cabinet.jsx";
import TransactionsLayout from "../containers/transactions/transactions.jsx";
import AnalyticsLayout from "../containers/analytics/analytics.jsx";
import TargetsLayout from "../containers/targets/targets.jsx";
import CompanyDictionaryLayout from "../components/layouts/company-dictionary/company-dictionary.jsx";
import CompanyAboutLayout from "../components/layouts/company-about/company-about.jsx";
import LoadingPopup from "./components/loading-popup.jsx";
import MessagesPopup from "./components/messages-popup.jsx";
import ErrorsPopup from "./components/errors-popup.jsx";
import LicenseExpiredPopup from "./components/license-expired-popup.jsx";

import * as LayoutActions from "../actions/layout-actions.js";
import * as AuthActions from "../actions/auth-actions.js";
import * as CompaniesActions from "../actions/companies-actions.js";
import * as CompanyActions from "../actions/company-actions.js";
import * as InvestmentsActions from "../actions/investments-actions.js";
import * as CabinetActions from "../actions/cabinet-actions.js";
import * as RouterActions from "../actions/router-actions.js";
import * as TransactionsActions from "../actions/transactions-actions.js";
import * as AnalyticsActions from "../actions/analytics-actions.js";
import * as TargetsActions from "../actions/targets-actions.js";
import * as CompanyAboutActions from "../actions/company-about-actions.js";

import * as Store from "../store.js";

import * as ObjectsHelper from "../../components/helpers/objects-helper.js";

export default class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                loading: {
                    show: true,
                },
            },
        };

        this.browserStateListener = function (e) {
            RouterActions.loadUrlLayout(document.location.href);
        };

        Store.onUpdate((params) => {
            if (!params.path) {
                let newState;
                newState = Store.getStore();
                this.setState(newState);
            }
        });
    }

    componentDidMount() {
        RouterActions.loadUrlLayout(document.location.href);
        window.addEventListener("popstate", this.browserStateListener);
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.browserStateListener);
    }

    render() {
        return (
            <div className="page-layouts-container">
                {this.getLayout()}
                <LicenseExpiredPopup
                    {...(ObjectsHelper.getNestedValue(
                        this.state,
                        "data",
                        "licenseExpired"
                    ) || {})}
                    {...LayoutActions}
                />
                <LoadingPopup
                    {...(ObjectsHelper.getNestedValue(
                        this.state,
                        "data",
                        "loading"
                    ) || {})}
                />
                <MessagesPopup
                    {...(ObjectsHelper.getNestedValue(
                        this.state,
                        "data",
                        "popupMessages"
                    ) || {})}
                    {...LayoutActions}
                />
                <ErrorsPopup
                    {...(ObjectsHelper.getNestedValue(
                        this.state,
                        "data",
                        "popupErrors"
                    ) || {})}
                    {...LayoutActions}
                />
            </div>
        );
    }

    getLayout() {
        switch (this.state.layout) {
            case "loading":
                return <LoadingLayout {...this.state.data} />;
            case "error":
                return <ErrorLayout {...this.state.data} />;
            case "auth":
                return <AuthLayout {...this.state.data} {...AuthActions} />;
            case "companies":
                return (
                    <CompaniesLayout
                        {...this.state.data}
                        {...{ ...LayoutActions, ...CompaniesActions }}
                    />
                );
            case "company":
                return (
                    <CompanyLayout
                        {...this.state.data}
                        {...{ ...LayoutActions, ...CompanyActions }}
                    />
                );
            case "investments":
                return (
                    <InvestmentsLayout
                        {...this.state.data}
                        {...{ ...LayoutActions, ...InvestmentsActions }}
                    />
                );
            case "cabinet":
                return (
                    <CabinetLayout
                        {...this.state.data}
                        {...{ ...LayoutActions, ...CabinetActions }}
                    />
                );
            case "transactions":
                return (
                    <TransactionsLayout
                        {...this.state.data}
                        {...{ ...LayoutActions, ...TransactionsActions }}
                    />
                );
            case "analytics":
                return (
                    <AnalyticsLayout
                        {...this.state.data}
                        {...{ ...LayoutActions, ...AnalyticsActions }}
                    />
                );
            case "targets":
                return (
                    <TargetsLayout
                        {...this.state.data}
                        {...{ ...LayoutActions, ...TargetsActions }}
                    />
                );
            case "company-dictionary":
                return (
                    <CompanyDictionaryLayout
                        {...this.state.data}
                        {...{ ...LayoutActions, ...CabinetActions }}
                    />
                );
            case "company-about":
                return (
                    <CompanyAboutLayout
                        {...this.state.data}
                        {...{
                            ...LayoutActions,
                            ...CabinetActions,
                            ...CompanyAboutActions,
                        }}
                    />
                );
        }
    }
}
