import * as Auth from "../auth.js";
import * as AuthRepository from "./auth-repository.js";
import * as CompaniesRepository from "./companies-repository.js";
import * as CompanyRepository from "./company-repository.js";
import * as InvestmentsRepository from "./investments-repository.js";
import * as CabinetRepository from "./cabinet-repository.js";
import * as OperationsRepository from "./operations-repository.js";

let config = {
    baseUrl: "",
    queryUrl: "/query",
    authLayoutUrl: "/auth",
    registrationUrl: "/user/registration",
    restoreUserAccessUrl: "/user/restore",
    createWorkspaceUrl: "/user/create-workspace",
    checkWorkspaceCreationUrl: "/user/check-workspace-creation",
    addCompanyUrl: "/companies/add",
    debug: false,
    fakeDelay: null,
    homeLayout: "companies",
};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
    AuthRepository.setConfig(config);
    CompaniesRepository.setConfig(config);
    CompanyRepository.setConfig(config);
    InvestmentsRepository.setConfig(config);
    CabinetRepository.setConfig(config);
    OperationsRepository.setConfig(config);
}

export function getHomeLayout() {
    switch (config.homeLayout) {
        case "auth":
            return AuthRepository.getLayout();
        case "companies":
            return CompaniesRepository.getLayout();
        case "company":
            return CompanyRepository.getLayout();
        case "investments":
            return InvestmentsRepository.getLayout();
        case "cabinet":
            return CabinetRepository.getLayout();
    }
}

export function request(method, url, body) {
    if (url.indexOf("http") <= -1) {
        url = config.baseUrl + url;
    }
    return Auth.request(method, url, body);
}

export function queryRequest(query) {
    return request("post", config.baseUrl + config.queryUrl, {
        query: JSON.stringify(query),
    });
}

/* HELPERS */

function debugMessage(message) {
    if (!config.debug) {
        return null;
    }

    console.log("REPOSITORY:", message);
}
