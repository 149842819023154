import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as CabinetHandlers from "../handlers/cabinet-handlers.js";

export function handleCompanyDeleteFormSubmit(e, form) {
    e.preventDefault();
    return CabinetHandlers.deleteCompany(form).then(() => {
        return LayoutHandlers.loadHomeLayout();
    });
}

export function handleActionClick(e, action) {
    switch (action.name) {
        case "license":
            return LayoutHandlers.loadLayoutByName("cabinet", {
                pushHistoryState: true,
            }).then((response) => {
                return CabinetHandlers.loadMenuLayout("license");
            });
    }
}
