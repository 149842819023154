import Container from "../container.jsx";
import CompanyTabAbout from "../../components/layouts/cabinet/company-tab-about.jsx";
import * as Store from "../../store.js";

export default class CompanyTabAboutContainer extends Container {
    constructor(props) {
        super(props);
    }

    render() {
        let props = { ...this.props };
        if (
            props.downloadLink &&
            props.downloadLink.url.indexOf("http") !== 0
        ) {
            const config = Store.getStore("config");
            // props.downloadLink.url =
            //     config.repository.baseUrl + props.downloadLink.url;
        }
        return <CompanyTabAbout {...props} />;
    }
}
