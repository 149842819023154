import RepeaterSubform from '../../forms/repeater-subform.jsx';
import Fieldset from '../../forms/fields/fieldset.jsx';
import newId from '../../../helpers/new-id.js';

export default class AddCompanyOwnerSubform extends RepeaterSubform {
    constructor(props) {
        super(props);
    }

    render(){
        let forms = [];

        // чистим рефы
        // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
        this.fieldsets.splice(0, this.fieldsets.length);
        this.formsCounter = 0;

        for(let i=0; i<this.state.items.length; i++) {
            forms.push(
                this.renderForm(
                    this.props.form,
                    this.state.items[i]
                )
            );
        }

        let button;
        if (this.props.addButton) {
            button = <button type="button"
                className="add-owner"
                onClick={this.handleAddButtonClick.bind(this)}>+ {this.props.addButton.label}</button>;
        }

        return (
            <div className="subform">
                <p className="subform-title">{this.props.title}</p>
                {forms}
                {button}
            </div>
        );
    }

    renderForm(form, data) {
        // откопируем форму
        let formItem = this.copyObject(form)
        const id = data.id || newId('new-');

        // надо убрать кнопки с сабмитом и добавить значения
        let fields = [];
        for(let i=0; i<formItem.fields.length; i++) {
            let field = formItem.fields[i];
            if (field.type == 'submit') {
                continue;
            }

            if ((field.name == 'caption') && (this.state.items.length > 1)) {
                field.label += ' #'+(++this.formsCounter);
            }

            field.value = data[field.name] || '';
            field.name = this.props.fieldNamePrefix + '['+id+'][' + field.name + ']';

            fields.push(field);
        }

        let deleteButton;
        if (this.state.items.length > 1) {
            deleteButton = <button type="button"
                className="delete-owner"
                onClick={this.handleDeleteButtonClick.bind(this, id)}>{String.fromCharCode(215)}</button>
        }

        return <div key={id} className="owner-item-form">
            <Fieldset
                fields={fields}
                onFieldChange={this.handleFieldChange.bind(this, id)}
                ref={(elem) => {
                    this.fieldsets.push(elem);
                }} />
            {deleteButton}
        </div>;
    }
}
