import Datepicker from "./datepicker.jsx";
import Checklist from "./checklist.jsx";
import FilterableChecklist from "./filterable-checklist.jsx";
import SearchString from "./search-string.jsx";
import PriceRange from "./price-range.jsx";
import Select from "../forms/fields/select-parser.jsx";
import { isMobile } from "../../helpers/layout-helper.js";

export default class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    render() {
        if (!this.props.fields) {
            return null;
        }

        let fields = [];
        for (let i = 0; i < this.props.fields.length; i++) {
            let field = this.props.fields[i];
            fields.push(
                <div
                    key={i}
                    data-index={i}
                    className={"filter-field field-name-" + field.name}
                >
                    <div className="filter-control">
                        {this.getControl(field)}
                    </div>
                </div>
            );
        }

        return (
            <form
                method="post"
                className={this.props.className || "filter"}
                onSubmit={this.handleSubmit.bind(this)}
                ref={this.formRef}
            >
                {fields}
            </form>
        );
    }

    getControl(field) {
        switch (field.type) {
            case "datepicker":
                return (
                    <Datepicker
                        {...field}
                        onChecklistClick={this.handleChecklistClick.bind(this)}
                        onDatepickerSelectButtonClick={this.handleDatepickerSelectButtonClick.bind(
                            this
                        )}
                        onDatepickerDateRangeChange={this.handleDatepickerDateRangeChange.bind(
                            this
                        )}
                        onApplyClick={this.handleSubmit.bind(this)}
                    />
                );
            case "checklist":
                return (
                    <Checklist
                        {...field}
                        onChecklistClick={this.handleChecklistClick.bind(this)}
                        onCheckedItemClick={this.handleChecklistClick.bind(
                            this
                        )}
                        onApplyClick={this.handleChecklistApply.bind(this)}
                        onResetClick={this.handleChecklistReset.bind(this)}
                    />
                );
            case "filterable-checklist":
                return (
                    <FilterableChecklist
                        {...field}
                        onChecklistClick={this.handleChecklistClick.bind(this)}
                        onCheckedItemClick={this.handleChecklistClick.bind(
                            this
                        )}
                        onApplyClick={this.handleChecklistApply.bind(this)}
                        onResetClick={this.handleChecklistReset.bind(this)}
                    />
                );
            case "search-string":
                return (
                    <SearchString
                        {...field}
                        onFieldChange={this.handleSearchStringChange.bind(this)}
                    />
                );
            case "price-range":
                return (
                    <PriceRange
                        {...field}
                        onSelectButtonClick={this.handlePriceRangeSelectButtonClick.bind(
                            this
                        )}
                    />
                );
            case "select":
                return (
                    <>
                        <label className="filter-label">{field.label}</label>
                        <Select
                            {...field}
                            onChange={this.handleSelectChange.bind(this)}
                        />
                    </>
                );
            case "button":
                return (
                    <button
                        name={field.name}
                        className="filter-button"
                        type="button"
                        onClick={this.handleFieldClick.bind(this)}
                    >
                        <span className="button-icon"></span>
                        <span className="button-text">{field.label}</span>
                    </button>
                );
            case "submit":
                return (
                    <button
                        name={field.name}
                        className="filter-button"
                        type="submit"
                        onClick={this.handleFieldClick.bind(this)}
                    >
                        <span className="button-icon"></span>
                        <span className="button-text">{field.label}</span>
                    </button>
                );
        }
    }

    handleChecklistClick(e, item) {
        const handler = this.props.onFieldChange;
        if (handler) {
            const index = e.target.closest(".filter-field").dataset.index;
            handler(e, {
                field: this.props.fields[index],
                checklistItem: item,
            });
        }
    }

    handleChecklistApply(e) {
        const handler = this.props.onFieldChange;
        if (handler) {
            const index = e.target.closest(".filter-field").dataset.index;
            handler(e, {
                field: this.props.fields[index],
                action: "apply",
            });
        }
    }

    handleChecklistReset(e) {
        const handler = this.props.onFieldChange;
        if (handler) {
            const index = e.target.closest(".filter-field").dataset.index;
            handler(e, {
                field: this.props.fields[index],
                action: "reset",
            });
        }
    }

    handleDatepickerSelectButtonClick(e, params) {
        const handler = this.props.onFieldChange;
        if (handler) {
            const index = e.target.closest(".filter-field").dataset.index;
            handler(e, {
                field: this.props.fields[index],
                datepickerParams: params,
            });
        }
    }

    handleDatepickerDateRangeChange(e) {
        if (!isMobile(450)) {
            const handler = this.props.onSubmit || function () {};
            handler(e, this);
        }
    }

    handlePriceRangeSelectButtonClick(e, params) {
        const handler = this.props.onFieldChange;
        if (handler) {
            const index = e.target.closest(".filter-field").dataset.index;
            handler(e, {
                field: this.props.fields[index],
                rangeParams: params,
            });
        }
    }

    handleSelectChange(e) {
        const handler = this.props.onFieldChange;
        if (handler) {
            const index = e.target.closest(".filter-field").dataset.index;
            handler(e, {
                field: this.props.fields[index],
            });
        }
    }

    handleSearchStringChange(e) {
        const handler = this.props.onFieldChange;
        if (handler) {
            const index = e.target.closest(".filter-field").dataset.index;
            handler(e, {
                field: this.props.fields[index],
                value: e.target.value,
            });
        }
    }

    handleFieldClick(e, field) {
        const handler = this.props.onFieldClick || function () {};
        const index = e.target.closest(".filter-field").dataset.index;
        handler(e, this.props.fields[index]);
    }

    handleSubmit(e) {
        const handler = this.props.onSubmit || function () {};
        handler(e, this);
    }

    getFormData() {
        return new FormData(this.formRef.current);
    }
}
