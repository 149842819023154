import Price from "../../price.jsx";
import LicenseOrders from "../../../containers/cabinet/license-orders.jsx";

export default class License extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="cabinet-license-layout">
                <div className="layout-title">
                    <p>{this.props.header}</p>
                </div>
                <div className="layout-body">
                    <div className="license-tariffs-list">
                        {this.props.tariffs &&
                            this.props.tariffs.map((item, index) => {
                                return (
                                    <div className="tariff-item" key={index}>
                                        <p className="caption">{item.label}</p>
                                        <Price
                                            value={item.price}
                                            integer={true}
                                        />
                                        <p className="body">{item.body}</p>
                                        <a
                                            className="button"
                                            href={item.button.url}
                                            target="_blank"
                                        >
                                            {item.button.label}
                                        </a>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <LicenseOrders
                    {...this.props.orders}
                    onOrdersScrollEdge={
                        this.props.onOrdersScrollEdge || function () {}
                    }
                />
            </div>
        );
    }
}
