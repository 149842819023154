import PageHeader from "../../page-header.jsx";
import CompaniesSidebar from "../../../containers/companies/companies-sidebar.jsx";
import OperationAddPopup from "../../../containers/transactions/operation-add-popup.jsx";
import OperationEditPopup from "../../../containers/transactions/operation-edit-popup.jsx";
import OperationRemovePopup from "../../../containers/transactions/operation-remove-popup.jsx";
import OperationTransferPopup from "../../../containers/transactions/operation-transfer-popup.jsx";
import Operations from "../../../containers/transactions/operations.jsx";
import OperationsActions from "./operations-actions.jsx";
import GraphsIncomeOutcome from "../../../containers/companies/graphs-income-outcome.jsx";
import { stretchLastPageElement } from "../../../../components/helpers/html-helper.js";
import { isMobile } from "../../../helpers/layout-helper.js";

export default class TransactionsLayout extends React.Component {
    constructor(props) {
        super(props);
        document.body.classList.add("dashboard");

        this.operationsFilterRef = React.createRef();

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        // отложим загрузку доп. скриптов напоследок
        const handler = this.props.loadChartHelpers || function () {};
        handler();
        stretchLastPageElement(document.querySelector(".operations"));
        window.addEventListener("scroll", this.handleScroll);
    }

    componentDidUpdate() {
        stretchLastPageElement(document.querySelector(".operations"));
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        let operations,
            operationsActions = [],
            backlink;
        if (this.props.operations) {
            if (this.props.companyId) {
                operationsActions = (
                    <OperationsActions
                        actions={this.props.operations.actions}
                        compact={isMobile(450)}
                        onActionClick={this.handleOperationActionClick.bind(
                            this
                        )}
                    />
                );
            }

            operations = (
                <Operations
                    {...this.props.operations}
                    ref={this.operationsFilterRef}
                    onFilterFieldChange={
                        this.props.handleOperationsFilterFieldChange ||
                        function () {}
                    }
                    onFilterSubmit={this.handleOperationsFilterSubmit.bind(
                        this
                    )}
                    onOperationsScrollEdge={
                        this.props.handleOperationsScrollEdge || function () {}
                    }
                    onOperationClick={
                        this.props.handleOperationClick || function () {}
                    }
                    onOperationActionClick={
                        this.props.handleOperationActionClick || function () {}
                    }
                />
            );

            if (this.props.operations.backlink) {
                backlink = (
                    <div className="back-link-container">
                        <button
                            className="back-link"
                            onClick={this.handleBacklinkClick.bind(
                                this,
                                this.props.operations.backlink
                            )}
                        >
                            {this.props.operations.backlink.label}
                        </button>
                    </div>
                );
            }
        }

        let graphsPlate;
        if (this.props.graphs) {
            graphsPlate = (
                <div className="graphs-plate sticky">
                    <GraphsIncomeOutcome
                        {...this.props.graphs}
                        onDateChecklistClick={
                            this.props.handleGraphsFilterDateChecklistClick ||
                            function () {}
                        }
                        onDatepickerSelectButtonClick={
                            this.props
                                .handleGraphsDatepickerSelectButtonClick ||
                            function () {}
                        }
                        onMoreButtonClick={
                            this.props.handleGraphsMoreButtonClick ||
                            function () {}
                        }
                        onMarkerClick={this.handleGraphsMarkerClick.bind(this)}
                    />
                </div>
            );
        }

        return (
            <div>
                <PageHeader
                    {...this.props.pageHeader}
                    onMenuItemClick={
                        this.props.handlePageHeaderMenuItemClick ||
                        function () {}
                    }
                    onUserbarClick={
                        this.props.handlePageHeaderUserbarClick ||
                        function () {}
                    }
                    onLogoClick={
                        this.props.handlePageHeaderLogoClick || function () {}
                    }
                    onUserbarMenuButtonClick={
                        this.props.handlePageHeaderUserbarMenuButtonClick ||
                        function () {}
                    }
                    onSearchSubmit={
                        this.props.handlePageHeaderSearchSubmit ||
                        function () {}
                    }
                    onFeedbackBarMenuButtonClick={
                        this.props.handlePageHeaderFeedbackBarMenuButtonClick
                    }
                />

                <div className="page-limiter transactions-layout">
                    <CompaniesSidebar
                        {...this.props.companySidebar}
                        activeId={this.props.companyId}
                        onCompanyClick={
                            this.props.handleCompanyClick || function () {}
                        }
                        onActionClick={
                            this.props.handleActionClick || function () {}
                        }
                        onActiveCompanyClick={
                            this.props.handleActiveCompanyClick
                        }
                        onCompaniesActionClick={
                            this.props.handleCompaniesActionClick
                        }
                        onCompanyMenuItemClick={
                            this.props.handleCompanyMenuItemClick
                        }
                    />

                    <div className="company-page-left-col">
                        {backlink}
                        <div className="operations-actions-container">
                            {operationsActions}
                        </div>
                        {operations}
                    </div>

                    <div className="company-page-right-col">{graphsPlate}</div>
                </div>

                <OperationAddPopup
                    {...this.props.operationAddPopup}
                    handleClosePopup={
                        this.props.handleCloseOperationAddPopup ||
                        function () {}
                    }
                    handleSubmit={this.handleOperationAddPopupSubmit.bind(this)}
                    handleFieldClick={this.handleOperationAddPopupFieldClick.bind(
                        this
                    )}
                />

                <OperationEditPopup
                    {...this.props.operationEditPopup}
                    className="edit-operation-popup"
                    handleCloseButtonClick={
                        this.props.handleCloseOperationEditPopup ||
                        function () {}
                    }
                    handleSubmit={this.handleOperationEditPopupSubmit.bind(
                        this
                    )}
                    handleActionClick={
                        this.props.handleOperationEditPopupActionClick ||
                        function () {}
                    }
                    handleFieldClick={this.handleOperationEditPopupFieldClick.bind(
                        this
                    )}
                />

                <OperationRemovePopup
                    {...this.props.operationRemovePopup}
                    className="remove-operation-popup"
                    handleCloseButtonClick={
                        this.props.handleCloseOperationRemovePopup ||
                        function () {}
                    }
                    handleSubmit={this.handleOperationRemovePopupSubmit.bind(
                        this
                    )}
                    handleFieldClick={this.handleOperationRemovePopupFieldClick.bind(
                        this
                    )}
                />

                <OperationTransferPopup
                    {...this.props.operationTransferPopup}
                    className="edit-operation-popup"
                    handleCloseButtonClick={
                        this.props.handleCloseOperationTransferPopup ||
                        function () {}
                    }
                    handleSubmit={this.handleOperationTransferPopupSubmit.bind(
                        this
                    )}
                    handleFieldClick={this.handleOperationEditPopupFieldClick.bind(
                        this
                    )}
                />
            </div>
        );
    }

    handleOperationActionClick(e, name) {
        switch (name) {
            case "add-operation":
                return this.handleOperationAddClick(e);
            case "add-income-operation":
                return this.handleOperationAddClick(e, "income");
            case "add-outcome-operation":
                return this.handleOperationAddClick(e, "outcome");
            case "upload":
                return this.handleOperationAddClick(e, "upload");
            case "transfer":
                return this.handleOperationAddClick(e, "transfer");
        }
    }

    handleOperationAddClick(e, operationName) {
        const handler = this.props.handleOperationAddClick || function () {};
        handler(e, this.props.companyId, operationName);
    }

    handleOperationAddPopupSubmit(e, form) {
        const handler =
            this.props.handleOperationAddPopupSubmit || function () {};

        handler(e, form, this.props.companyId, this.getOperationsFilterForm());
    }

    handleOperationAddPopupFieldClick(e, field, component, params) {
        const handler =
            this.props.handleOperationAddPopupFieldClick || function () {};
        handler(e, field, component, params, this.props.companyId);
    }

    handleOperationEditPopupSubmit(e, form) {
        const handler =
            this.props.handleOperationEditPopupSubmit || function () {};
        handler(e, form, this.props.companyId, this.getOperationsFilterForm());
    }

    handleOperationEditPopupFieldClick(e, field, component, params) {
        const handler =
            this.props.handleOperationEditPopupFieldClick || function () {};
        handler(e, field, component, params, this.props.companyId);
    }

    handleOperationsFilterSubmit(e, form) {
        const handler =
            this.props.handleOperationsFilterSubmit || function () {};
        handler(e, form, this.props.companyId);
    }

    handleOperationRemovePopupSubmit(e, form) {
        const handler =
            this.props.handleOperationRemovePopupSubmit || function () {};
        handler(e, form, this.props.companyId);
    }

    handleOperationTransferPopupSubmit(e, form) {
        const handler =
            this.props.handleOperationTransferPopupSubmit || function () {};
        handler(e, form, this.props.companyId);
    }

    handleOperationTransferPopupFieldClick(e, field, component, params) {
        const handler =
            this.props.handleOperationTransferPopupFieldClick || function () {};
        handler(e, field, params, this.props.companyId);
    }

    handleOperationRemovePopupFieldClick(e, field) {
        switch (field.getFieldName()) {
            case "cancel":
                const handler = this.props.handleCloseOperationRemovePopup;
                if (handler) {
                    handler(e);
                }
                break;
        }
    }

    handleBacklinkClick(backlink, e) {
        const handler = this.props.handleBacklinkClick || function () {};
        console.log(backlink);
        handler(e, backlink, this.props.companyId);
    }

    handleGraphsMarkerClick(e, marker) {
        const handler = this.props.handleGraphsMarkerClick || function () {};
        handler(e, marker, this.props.companyId);
    }

    getOperationsFilterForm() {
        let filterForm;
        if (this.operationsFilterRef && this.operationsFilterRef.current) {
            filterForm = this.operationsFilterRef.current.getFilterForm();
        }

        return filterForm;
    }

    handleScroll() {
        // липкие кнопки для операций
        const windowTopPos =
            window.pageYOffset || document.documentElement.scrollTop;

        const container = document.querySelector(
            ".operations-actions-container"
        );
        const containerRect = container.getBoundingClientRect();
        const containerTopPos = containerRect.top + windowTopPos;

        if (windowTopPos >= containerTopPos) {
            container.classList.add("sticky");
        } else {
            container.classList.remove("sticky");
        }
    }
}
