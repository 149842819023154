import CustomSelect from "./custom-select.jsx";

export default class PhoneCodeSelect extends CustomSelect {
    constructor(props) {
        super(props);
        
        let activeLabel = props.phoneCode || null,
        activeValue = props.phoneCode || null,
        options = this.getOptions();

        if (options && options.length) {
            for(let i=0; i<options.length; i++) {
                let item = options[i];
                if (activeValue === null) {
                    activeValue = item.value;
                }
                if (activeLabel === null) {
                    activeLabel = item.label;
                }
                if (item.active) {
                    activeValue = item.value;
                    activeLabel = item.label;
                }
                if (item.value == this.props.value) {
                    activeValue = item.value;
                    activeLabel = item.label;
                }
            }
        }

        this.state = {
            activeValue: activeValue,
            activeLabel: activeLabel
        };
    }

    renderCustomButton() {
        return (
            <button
                className="select-button"
                type="button"
                onClick={this.handleSelectButtonClick.bind(this)}
            >
                <span className="pre-icon"></span>
                <span className="button-text">{this.state.activeValue}</span>
                <span className="post-icon"></span>
            </button>
        );
    }

    renderSelect() {
        let options = [],
            optionsData = this.getOptions();

        if (optionsData && optionsData.length) {
            for (let i = 0; i < optionsData.length; i++) {
                const option = optionsData[i];
                options.push(
                    <option key={i} value={option.value}>
                        {option.label}
                    </option>
                );
            }
        }

        return (
            <select
                name={this.props.name}
                defaultValue={this.props.phoneCode}
                id={this.props.id}
                ref={this.selectRef}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            >
                {options}
            </select>
        );
    }
}
