import Container from "../container.jsx";
import Content from "../../components/layouts/company-about/content.jsx";

export default class ContentContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.cabinetCompany");
    }

    render() {
        return <Content {...this.props} {...this.state.state} />;
    }
}
