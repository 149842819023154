const RequisitesValidator = function (input, rule) {
    if (!input || typeof input == "undefined") {
        return true;
    }

    if (!/^\d+$/.test(input)) {
        return false;
    }

    input = input + "";

    switch (rule) {
        case "inn":
            if (input.length == 10 || input.length == 12) {
                return true;
            }
            break;
        case "kpp":
            if (input.length == 9) {
                return true;
            }
            break;
        case "ogrn":
            if (input.length == 13 || input.length == 15) {
                return true;
            }
            break;
        case "bank_account_number":
            if (input.length == 20) {
                return true;
            }
            break;
        case "bik":
            if (input.length == 9) {
                return true;
            }
            break;
    }

    return false;
};

export default RequisitesValidator;
