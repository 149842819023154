import Container from '../container.jsx';
import Company from '../../components/layouts/cabinet/company.jsx';
import CompanyMobile from '../../components/layouts/cabinet/company-mobile.jsx';
import { isMobile } from '../../helpers/layout-helper.js';
import newId from '../../helpers/new-id.js';

export default class CompanyContainer extends Container {
    constructor(props) {
        super(props);
        this.companyRef = React.createRef();
        this.connectStore('data.cabinetCompany');
    }

    render(){
        if (isMobile({mobileWidth: 640})) {
            return <CompanyMobile key={newId('cabinet-company-layout-')} // надо каждый раз обновлять компонент, иначе траблы с табами
                ref={this.companyRef}
                {...this.props}
                {...this.state.state} />;
        }

        return <Company
            ref={this.companyRef}
            {...this.props}
            {...this.state.state} />;
    };

    getActiveTab() {
        if (this.companyRef && this.companyRef.current) {
            return this.companyRef.current.getActiveTab();
        }
        return null;
    }
}
