import { request, queryRequest } from "./repository.js";

let config = {};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function getLayout() {
    return queryRequest({
        pageHeader: {
            menu: { active: "investments" },
        },
        pageSocialLinks: {},
        companySidebar: {},
        investments: {},
    }).then((response) => {
        return {
            layout: "investments",
            data: response,
        };
    });
}

export function getInvestmentEditPopup(data) {
    return queryRequest({
        investmentEditPopup: data || {},
    });
}

export function getInvestments() {
    return queryRequest({
        investments: {},
    });
}

export function getInvestmentRemovePopup(data) {
    return queryRequest({
        investmentRemovePopup: data || {},
    });
}
