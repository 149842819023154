import Container from '../container.jsx';
import Graphs from '../../components/layouts/analytics/graphs.jsx';

export default class GraphsContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.analyticsGraphs');
    }

    render(){
        return <Graphs
            {...this.props}
            graphs={this.state.state} />;
    };
}
