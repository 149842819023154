export default class Messages extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.list) {
            return null;
        }

        let list = [];
        for(let i=0; i<this.props.list.length; i++) {
            list.push(
                <p key={i} className="message-item">{this.props.list[i]}</p>
            );
        }

        return (
            <div className={this.props.className || 'std-messages-list'}>
                {list}
            </div>
        );
    }
}
