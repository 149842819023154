import AddCompanyForm from './add-company-form.jsx';

export default class AddCompany extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="cabinet-add-company-layout">
                <div className="layout-title">
                    <p>{this.props.header}</p>
                </div>
                <div className="layout-body">
                    <AddCompanyForm {...this.props} />
                </div>
            </div>
        );
    }
}
