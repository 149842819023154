import CustomActionsSelect from './custom-actions-select.jsx';

export default class ColorSelect extends CustomActionsSelect {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setActiveColor();
    }

    componentDidUpdate() {
        this.setActiveColor();
    }

    setActiveColor() {
        if (!this.state.activeValue || !this.styledSelectRef || !this.styledSelectRef.current) {
            return null;
        }

        const options = this.getOptions();
        if (!options || !options.length) {
            return null;
        }

        const selectButton = this.styledSelectRef.current.querySelector('.select-button');
        if (!selectButton) {
            return null;
        }

        for (var i = 0; i < options.length; i++) {
            let option = options[i];
            if (option.value == this.state.activeValue) {
                selectButton.style.backgroundColor = option.backgroundColor || 'none';
            }
        }
    }
}
