import * as Store from "../store.js";
import * as Repository from "../../components/repositories/targets-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse, submitForm } from "../helpers/request-helper.js";
import { getNestedValue } from "../../components/helpers/objects-helper.js";
import { request } from "../../components/repositories/repository.js";

export function loadLayout(params) {
    Store.setStore(true, "data.loading.show");

    if (!params.companyId) {
        params.defaultCompany = true;
    }

    return handleResponse(Repository.getLayout(params), (response) => {
        if (!response) {
            return false;
        }
        Store.setStore(response);
        Store.setStore(
            params.companyId || getDefaultCompanyId(response),
            "data.companyId"
        );
        setLayout("targets");
        return true;
    });
}

export function loadTable(params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(Repository.getTable(params), (response) => {
        if (!response.targets) {
            return false;
        }
        Store.setStore(response.targets, "data.targets");
        Store.setStore(params.companyId, "data.companySidebar.activeId");
        Store.setStore(params.companyId, "data.companyId");
        return true;
    });
}

export function loadAddFormPopup(params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(Repository.getAddPopup(params), (response) => {
        if (!response.targetEditPopup) {
            return false;
        }
        Store.setStore(response.targetEditPopup, "data.targetAddPopup");
        Store.setStore(true, "data.targetAddPopup.show");
        return true;
    });
}

export function loadAddForm(params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(Repository.getAddForm(params), (response) => {
        if (!response.targetEditForm) {
            return false;
        }
        Store.setStore(response.targetEditForm, "data.targetAddForm");
        setLayout("add");
        return true;
    });
}

export function loadEditFormPopup(params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(Repository.getEditPopup(params), (response) => {
        if (!response.targetEditPopup) {
            return false;
        }
        Store.setStore(response.targetEditPopup, "data.targetEditPopup");
        Store.setStore(true, "data.targetEditPopup.show");
        return true;
    });
}

export function loadEditForm(params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(Repository.getEditForm(params), (response) => {
        if (!response.targetEditForm) {
            return false;
        }
        Store.setStore(response.targetEditForm, "data.targetEditForm");
        setLayout("edit");
        return true;
    });
}

export function loadRemoveFormPopup(params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(Repository.getRemovePopup(params), (response) => {
        if (!response.targetRemovePopup) {
            return false;
        }
        Store.setStore(response.targetRemovePopup, "data.targetRemovePopup");
        Store.setStore(true, "data.targetRemovePopup.show");
        return true;
    });
}

export function setAddConfirmForm(form) {
    // Store.setStore(form, "data.targetAddPopup");
    // Store.setStore(true, "data.targetAddPopup.show");
    Store.setStore(form, "data.targetAddForm");
}

export function setEditConfirmForm(form) {
    Store.setStore(form, "data.targetEditForm");
    // Store.setStore(form, "data.targetEditPopup");
    // Store.setStore(true, "data.targetEditPopup.show");
}

export function closeAddPopup(params) {
    Store.setStore(null, "data.targetAddPopup");
}

export function closeEditPopup(params) {
    Store.setStore(null, "data.targetEditPopup");
}

export function closeRemovePopup(params) {
    Store.setStore(null, "data.targetRemovePopup");
}

export function submitPopupForm(form) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupErrors(response);
        return response;
    });
}

export function setLayoutHistoryState(response) {
    let title = "Dogma";

    const menu = getNestedValue(response.data, "pageHeader", "menu");
    if (menu && menu.length) {
        for (let item of menu) {
            if (item.name == "targets") {
                title = item.label;
                break;
            }
        }
    }

    document.title = title;
    history.pushState({}, title, "/targets");
}

export function sendResultBannerShown(url) {
    return request("POST", url);
}

export function setLayout(value) {
    return Store.setStore(value, "data.layout");
}

function getDefaultCompanyId(response) {
    return getNestedValue(response.data, "companySidebar", "list", 0, "id");
}
