export default class CompanyTabAbout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let fields = [];

        if (this.props.fields) {
            for (var i = 0; i < this.props.fields.length; i++) {
                let field = this.props.fields[i],
                    value;

                if (field.value) {
                    value = <p className="value">{field.value}</p>;
                }

                if (field.values) {
                    value = [];
                    field.values.map((valueItem, idx) => {
                        value.push(
                            <p key={idx} className="value">
                                {valueItem}
                            </p>
                        );
                    });
                }

                fields.push(
                    <div key={i} className="about-field">
                        <p className="label">{field.label}</p>
                        {value}
                    </div>
                );
            }
        }

        let actions = [];
        if (this.props.actions) {
            this.props.actions.map((action, index) => {
                actions.push(
                    <button
                        key={index}
                        className={"tab-action action-" + action.name}
                        onClick={this.handleActionClick.bind(this, action)}
                    >
                        {action.label}
                    </button>
                );
            });
        }

        let downloadButton;
        if (this.props.downloadLink) {
            downloadButton = (
                <a
                    className="about-download-button"
                    href={this.props.downloadLink.url}
                    target="_blank"
                >
                    {this.props.downloadLink.label}
                </a>
            );
        }

        let header;
        if (this.props.header) {
            header = (
                <div className="table-header">
                    <p>{this.props.header}</p>
                </div>
            );
        }

        return (
            <div className="company-tab-about">
                {header}
                {actions}
                {downloadButton}
                <div className="about-content">{fields}</div>
            </div>
        );
    }

    handleActionClick(action, e) {
        const handler = this.props.onActionClick || function () {};
        handler(e, action);
    }
}
