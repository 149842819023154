import Price from "../../price.jsx";
import Filter from "../../filter/filter.jsx";
import { getNestedValue } from "../../../../components/helpers/objects-helper.js";

export default class CompanyTabTable extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.filterRef = React.createRef();

        this.state = {
            openedActionsItemId: null,
            filterParams: {},
        };

        this.handleDocumentClick = (e) => {
            if (!e.target.closest(".actions-button")) {
                this.setState({
                    openedActionsItemId: null,
                });
            }
        };

        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    render() {
        let actions = [];
        if (this.props.actions && this.props.actions.length) {
            for (var i = 0; i < this.props.actions.length; i++) {
                let action = this.props.actions[i];
                if (action.name == "download" && action.url) {
                    actions.push(
                        <a
                            key={i}
                            href={action.url}
                            className="tab-action download"
                            target="_blank"
                        >
                            {action.label}
                        </a>
                    );
                } else {
                    actions.push(
                        <button
                            key={i}
                            className="tab-action"
                            onClick={this.handleListActionClick.bind(
                                this,
                                action.name
                            )}
                        >
                            {action.label}
                        </button>
                    );
                }
            }
        }

        let list = [];

        if (this.props.headers) {
            let row = [];

            for (let name in this.props.headers) {
                let header = this.props.headers[name];
                row.push(
                    <div key={name} className={"list-item-col " + name}>
                        <p title={header} className="header-col-value">
                            {header}
                        </p>
                    </div>
                );
            }

            list.push(
                <div
                    key="headers"
                    data-index={"headers"}
                    className="list-item list-headers"
                >
                    {row}
                </div>
            );
        }

        if (this.props.list && this.props.list.length) {
            for (var i = 0; i < this.props.list.length; i++) {
                let item = this.props.list[i],
                    row = [],
                    itemActions = [],
                    editActionsPopup;

                if (item.fields) {
                    for (let name in item.fields) {
                        let flag = getNestedValue(item.flags, name);
                        if (flag) {
                            flag = <span className="field-flag">{flag}</span>;
                        }
                        let value = (
                            <p title={item.fields[name]}>
                                {flag}
                                <span className="field-value">
                                    {item.fields[name]}
                                </span>
                            </p>
                        );
                        if (name == "amount") {
                            value = (
                                <Price
                                    value={item.fields[name]}
                                    integer={true}
                                />
                            );
                        }
                        if (name == "style") {
                            value = (
                                <div
                                    className="style-box"
                                    style={{
                                        backgroundColor:
                                            item.fields[name] || "none",
                                    }}
                                />
                            );
                        }
                        row.push(
                            <div key={name} className={"list-item-col " + name}>
                                {value}
                            </div>
                        );
                    }
                } else {
                    row.push(
                        <div key="label" className="list-item-col label">
                            <p title={item.label}>{item.label}</p>
                        </div>
                    );
                }

                row.push(this.renderItemActions(item));

                list.push(
                    <div
                        key={i}
                        data-index={i}
                        className={"list-item " + this.getFilteredClass(item)}
                        onClick={this.handleListItemClick.bind(this, item.id)}
                    >
                        {row}
                    </div>
                );
            }
        }

        let header;
        if (this.props.header) {
            header = (
                <div className="table-header">
                    <p>{this.props.header}</p>
                </div>
            );
        }

        let filterForm;
        if (this.props.filterForm) {
            filterForm = (
                <Filter
                    className="table-filter"
                    {...this.props.filterForm}
                    onFieldChange={this.handleFilterFieldChange.bind(this)}
                    onSubmit={this.handleFilterSubmit.bind(this)}
                    ref={this.filterRef}
                />
            );
        }

        return (
            <div className="company-tab-table" ref={this.containerRef}>
                {header}
                {actions}
                {filterForm}
                <div className="table-content">{list}</div>
            </div>
        );
    }

    renderItemActions(item) {
        if (!item.editActions) {
            return null;
        }

        let actions = [];

        item.editActions.map((action) => {
            actions.push(
                <button
                    className={"action-item action-" + action.name}
                    key={action.name}
                    onClick={this.handleActionClick.bind(this, item, action)}
                >
                    {action.label}
                </button>
            );
        });

        return (
            <div
                className={
                    "list-item-col actions" +
                    (item.id == this.state.openedActionsItemId ? " opened" : "")
                }
                key="actions"
            >
                <button
                    className="actions-button"
                    onClick={this.handleActionsButtonClick.bind(this, item)}
                >
                    {String.fromCharCode(8943)}
                </button>
                <div className="actions-popup">
                    {actions}
                    <button className="close-button"></button>
                </div>
            </div>
        );
    }

    handleListActionClick(name, e) {
        const handler = this.props.onActionClick || function () {};
        handler(e, name);
    }

    handleActionClick(item, action, e) {
        const handler = this.props.onEditActionClick || function () {};
        handler(action.name, item.id);
    }

    handleActionsButtonClick(item) {
        if (this.state.openedActionsItemId == item.id) {
            this.setState({
                openedActionsItemId: null,
            });
        } else {
            this.setState({
                openedActionsItemId: item.id,
            });
        }
    }

    handleListItemClick(itemId, e) {
        if (e.target.closest(".actions")) {
            return null;
        }
        const handler = this.props.onListItemClick || function () {};
        handler(itemId);
    }

    handleFilterFieldChange(e) {
        this.setFilterParams();
    }

    handleFilterSubmit(e) {
        e.preventDefault();
        this.setFilterParams();
    }

    setFilterParams(e) {
        if (!this.filterRef) {
            return null;
        }
        const formData = this.filterRef.current.getFormData();
        let filterParams = {};

        for (var pair of formData.entries()) {
            filterParams[pair[0]] = pair[1];
        }

        this.setState({
            filterParams: filterParams,
        });
    }

    getFilteredClass(item) {
        let searchString = getNestedValue(
            this.state.filterParams,
            "search_string"
        );

        if (!searchString) {
            return "";
        }

        searchString = searchString.toLowerCase();

        for (let name in item.fields) {
            if (this.testString(item.fields[name], searchString)) {
                console.log(item.fields.label, item.fields[name], searchString);
                return "";
            }
        }

        return "filter-out";
    }

    testString(string, searchString) {
        return string.toLowerCase().indexOf(searchString) > -1;
    }
}
