import * as Store from "../store.js";
import * as TransactionsRepository from "../../components/repositories/transactions-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse, submitForm } from "../helpers/request-helper.js";
import { getFieldPath } from "../helpers/store-helper.js";
import * as FilterHelper from "../helpers/filter-helper.js";
import { getNestedValue } from "../../components/helpers/objects-helper.js";

let loading = false;

export function loadLayout(companyId, params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        TransactionsRepository.getLayout(companyId, params),
        (response) => {
            if (!response) {
                return false;
            }
            Store.setStore(response);
            return true;
        }
    );
}

export function loadCompany(companyId) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        TransactionsRepository.getCompany(companyId),
        (response) => {
            if (!response) {
                return false;
            }
            Store.setStore(companyId, "data.companyId");
            Store.setStore(response.operations, "data.operations");
            Store.setStore(response.graphs, "data.graphs");
            Store.setStore(response.companySidebar, "data.companySidebar");
            return true;
        }
    );
}

export function loadMoreOperations(companyId, offset, form) {
    if (loading) {
        return Promise.resolve();
    }

    loading = true;

    Store.setStore(true, "data.loading.show");

    let params = {
        companyId: companyId,
        offset: offset,
    };

    params = appendFormParams(params, form);

    return handleResponse(
        TransactionsRepository.getOperations(params),
        (response) => {
            loading = false;
            if (!getNestedValue(response, "operations", "list")) {
                return false;
            }
            Store.addList(response.operations.list, "data.operations.list");

            rememberOperationsFilter(params, {
                offset: 0,
                limit: getOperationsDatedListCount(
                    Store.getStore("data.operations.list")
                ),
            });

            return true;
        }
    );
}

export function filterOperations(companyId, form) {
    if (loading) {
        return Promise.resolve();
    }

    loading = true;

    Store.setStore(true, "data.loading.show");

    let params = {
        companyId: companyId,
    };

    if (form) {
        params = appendFormParams(params, form);
        rememberOperationsFilter(params);
    } else if (lastOperationsFilter) {
        params = lastOperationsFilter;
    }

    return handleResponse(
        TransactionsRepository.getOperations(params),
        (response) => {
            loading = false;
            if (!getNestedValue(response, "operations", "list")) {
                return false;
            }
            Store.setStore(response.operations.list, "data.operations.list");
            Store.setStore(
                response.operations.totalCount,
                "data.operations.totalCount"
            );
            Store.setStore(
                response.operations.xlsxLink,
                "data.operations.xlsxLink"
            );

            return true;
        }
    );
}

export function filterGraphs(companyId, form) {
    if (loading) {
        return Promise.resolve();
    }

    loading = true;

    Store.setStore(true, "data.loading.show");

    let params = {
        companyId: companyId,
    };

    if (form) {
        params = appendFormParams(params, form);
        rememberOperationsFilter(params);
    } else {
        params = lastOperationsFilter;
    }

    return handleResponse(
        TransactionsRepository.getGraphs(params),
        (response) => {
            loading = false;
            if (response.graphs) {
                Store.setStore(response.graphs, "data.graphs");
            }
            return true;
        }
    );
}

export function applyFilterFormData(formData) {
    if (!formData) {
        return null;
    }

    const form = document.querySelector(".operations-filter"),
        fieldsPath = "data.operations.filter.fields";

    return FilterHelper.applyFilterFormData(form, fieldsPath, formData);
}

export function setLayoutHistoryState(response) {
    const title = getSectionTitle(response);

    document.title = title;
    history.pushState({}, title, "/transactions");
}

export function setCompanyHistoryState(id, response) {
    const data = { companyId: id };
    const title = getSectionTitle(response);

    document.title = title;
    history.pushState(data, title, "/transactions?id=" + id);
}

/* HELPERS */

function getSectionTitle(response) {
    let title = "Dogma";
    const menu = getNestedValue(response, "data", "pageHeader", "menu");
    if (menu && menu.length) {
        for (let item of menu) {
            if (item.name == "transactions") {
                title = item.label;
                break;
            }
        }
    }

    return title;
}

function appendFormParams(params, form) {
    if (!form) {
        return params;
    }

    let formData;
    if (form instanceof FormData) {
        formData = form;
    } else {
        formData = form.getFormData();
    }
    for (let pair of formData.entries()) {
        params[pair[0]] = pair[1];
    }

    return params;
}

let lastOperationsFilter;
function rememberOperationsFilter(params, override) {
    if (override) {
        for (let key in override) {
            params[key] = override[key];
        }
    }
    lastOperationsFilter = params;
}

function getOperationsDatedListCount(datedList) {
    if (!datedList || !datedList.length) {
        return 0;
    }

    let count = 0;
    datedList.forEach((item) => {
        if (item.list && item.list.length) {
            count += item.list.length;
        }
    });

    return count;
}
