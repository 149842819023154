import CheckList from "../check-list.jsx";
import { getNestedValue } from "../../../components/helpers/objects-helper.js";

export default class ChecklistField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openChecklistPopup: false,
        };

        this.containerRef = React.createRef();

        this.handleDocumentClick = (e) => {
            if (
                !this.containerRef ||
                !this.containerRef.current ||
                !this.containerRef.current.contains(e.target)
            ) {
                // щелкнули вне попапа
                this.setState({
                    openChecklistPopup: false,
                });
            }
        };

        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    render() {
        let list =
                this.props.list || getNestedValue(this.props.data, "options"),
            checkedValue = null,
            firstValue = null,
            checkedCount = 0,
            value = [];

        if (list) {
            for (let i = 0; i < list.length; i++) {
                let item = list[i];
                if (item.active) {
                    value.push(item.value);
                    if (checkedCount <= 0) {
                        firstValue = item.label;
                    }
                    checkedCount++;
                }
            }
        }

        checkedValue = <span className="text-value">{firstValue}</span>;

        if (checkedCount > 1) {
            checkedValue = <span className="count-value">{checkedCount}</span>;
        }

        return (
            <div className="filter-checklist" ref={this.containerRef}>
                <input
                    type="hidden"
                    name={this.props.name}
                    value={value.join(",")}
                />
                <label className="filter-label">{this.props.label}</label>
                <button
                    type="button"
                    className="checklist-value-button"
                    onClick={this.handleLabelButtonClick.bind(this)}
                >
                    {checkedValue}
                    <span className="button-icon"></span>
                </button>
                <div
                    className={
                        "filter-checklist-popup" +
                        (this.state.openChecklistPopup ? " show" : "")
                    }
                >
                    {this.renderPopupContent(list)}
                </div>
            </div>
        );
    }

    renderPopupContent(list) {
        return [
            this.renderChecklistHeader(),
            this.renderCloseButton(),
            this.renderChecklist(list),
            this.renderApplyButton(),
            this.renderResetButton(),
        ];
    }

    renderChecklistHeader() {
        return (
            <button
                type="button"
                className="checklist-header"
                onClick={this.handleLabelButtonClick.bind(this)}
                key="checklist-header"
            >
                <span className="text">{this.props.label}</span>
            </button>
        );
    }

    renderCloseButton() {
        return (
            <button
                type="button"
                className="close-button"
                onClick={this.handleLabelButtonClick.bind(this)}
                key="close-button"
            ></button>
        );
    }

    renderChecklist(list) {
        return (
            <CheckList
                list={list}
                onItemClick={this.handleChecklistClick.bind(this)}
                key="checklist"
            />
        );
    }

    renderApplyButton() {
        if (getNestedValue(this.props.data, "applyLabel")) {
            return (
                <button
                    type="button"
                    className="apply-button"
                    onClick={this.handleApplyClick.bind(this)}
                    key="apply-button"
                >
                    {this.props.data.applyLabel}
                </button>
            );
        }
    }

    renderResetButton() {
        if (getNestedValue(this.props.data, "resetLabel")) {
            return (
                <button
                    type="button"
                    className="reset-button"
                    onClick={this.props.onResetClick || function () {}}
                    key="reset-button"
                >
                    {this.props.data.resetLabel}
                </button>
            );
        }
    }

    handleLabelButtonClick(e) {
        this.setState({
            openChecklistPopup: !this.state.openChecklistPopup,
        });
    }

    handleChecklistClick(e, item) {
        const handler = this.props.onChecklistClick || function () {};
        handler(e, item);
    }

    handleApplyClick(e) {
        this.setState({
            openChecklistPopup: false,
        });

        const handler = this.props.onApplyClick || function () {};
        handler(e);
    }
}
