export default class SearchStringField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="filter-search-string">
                <input
                    type="text"
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onFieldChange}
                />
                <button className="icon" type="submit"></button>
            </div>
        );
    }
}
