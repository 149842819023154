export default class Textarea extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.fieldRef = React.createRef();
    }

    componentDidMount() {
        this.resizeField();
    }

    render() {
        return <textarea type={this.props.type}
                         name={this.props.name}
                         defaultValue={this.props.value}
                         id={this.props.id}
                         placeholder={this.props.placeholder}
                         onChange={this.handleChange}
                         onFocus={this.handleFocus}
                         onBlur={this.handleBlur}
                         ref={this.fieldRef}
                         rows={this.props.rows || 1}/>;
    }

    handleChange(e) {
        if (this.props.autoresize) {
            this.resizeField();
        }

        const handler = this.props.onChange || function(){};
        handler(e);
    }

    handleFocus(e) {
        const handler = this.props.onFocus || function(){};
        handler(e);
    }

    handleBlur(e) {
        const handler = this.props.onBlur || function(){};
        handler(e);
    }

    // http://jsfiddle.net/hmelenok/WM6Gq/
    // https://stackoverflow.com/questions/454202/creating-a-textarea-with-auto-resize
    resizeField() {
        // var padding = parseInt($(text).css('padding-top')) + parseInt($(text).css('padding-bottom'));
        // const padding = 0;
        const computedStyle = window.getComputedStyle(this.fieldRef.current, null);
        const padding = parseInt(computedStyle.getPropertyValue('padding-top') || 0) + parseInt(computedStyle.getPropertyValue('padding-bottom') || 0);
        this.fieldRef.current.style.height = 'auto';
        this.fieldRef.current.style.height = (this.fieldRef.current.scrollHeight - padding)+'px';
    }
}
