import {getNestedValue} from '../../../../components/helpers/objects-helper.js';

export default class TextList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: this.splitValue()
        };
    }

    render() {
        let fields = [],
            fieldsCount = Object.keys(this.state.values).length;

        for(let i in this.state.values) {
            fields.push(this.renderField(i, fieldsCount > 1));
        }

        return (
            <div className="field-text-list-container">
                <input type="hidden"
                    name={this.props.name}
                    value={Object.values(this.state.values).join(',')} />
                {fields}
                <button type="button"
                    className="add-text-item"
                    onClick={this.handleAddButtonClick.bind(this)}>+ {getNestedValue(this.props.options, 'addButton') || ''}</button>
            </div>
        );
    }

    renderField(index, renderRemoveButton) {
        let removeButton;

        if (renderRemoveButton) {
            removeButton = <button type="button"
                className="remove-text-item"
                onClick={this.handleRemoveButtonClick.bind(this, index)}>{String.fromCharCode(215)}</button>;
        }

        return <div className="text-list-item" key={index}>
            <input className="form-input"
                type="text"
                name={this.props.name + '-item[' + index + ']'}
                value={this.state.values[index] || ''}
                placeholder={this.props.placeholder}
                autoComplete={this.props.autocomplete ? "on" : "off"}
                onChange={this.handleListItemChange.bind(this, index)}
                onFocus={this.props.onFocus || function(){}}
                onBlur={this.props.onBlur || function(){}} />
            {removeButton}
        </div>;
    }

    splitValue() {
        const value = this.props.value || '';
        const delim = this.props.options.delim || ',';
        return {...value.split(delim)}; // тут нужен объект, чтобы при удалении индекса оставшиеся индексы остались прежними
    }

    handleListItemChange(index, e) {
        let values = this.state.values;
        values[index] = e.target.value;
        this.setState({
            values: values
        });

        const handler = this.props.onChange || function(){};
        handler(e);
    }

    handleAddButtonClick(e) {
        let values = this.state.values;
        let keys = Object.keys(values),
            lastKey = parseInt(keys[keys.length-1]);

        values[lastKey+1] = '';

        this.setState({
            values: values
        });
    }

    handleRemoveButtonClick(index, e) {
        let values = this.state.values;

        delete values[index];

        this.setState({
            values: values
        });
    }
}
