let _loadedFiles = [];
const debug = true; // ОБРАТИТЬ ВНИМАНИЕ

export default function includeFiles(url, callback) {
    callback = callback || function(){};

    switch (true) {
        case (Array.isArray(url)):
            var size = url.length,
                c = 0;
            for(var i in url) {
                if (debug) url[i] += '?rand='+Math.random();
                includeFilesItem(url[i], function(){
                    // console.log(url[i], 'загружен');
                    c++;
                    if(c>=size) {
                        callback();
                    }
                });
            }
            break;
        default:
            if (debug) url += '?rand='+Math.random();
            includeFilesItem(url, callback);
            break;
    }
}

function includeFilesItem(url, callback) {
    // Adding the script tag to the head as suggested before
    var head = document.getElementsByTagName('head')[0],
        src = url;
    if (src.indexOf('//') == -1) { // Внимание!
      src = document.location.protocol+'//'+document.location.hostname+src;
    }

    var type = url.split('?')[0].split('.').pop(),
        scripts;

    switch(type)
    {
        case 'css':
            scripts = document.getElementsByTagName('link');
            for (var i in scripts) {
              if (scripts[i].href && (scripts[i].href.split('?')[0] == src.split('?')[0])) {
                  if (callback) callback();
                  return true;
              }
            }
            var script = document.createElement('link');
            script.rel = 'stylesheet';
            script.type = 'text/css';
            script.href = url;
            break;
        default:
            scripts = document.getElementsByTagName('script');
            for (var i in scripts) {
              if (scripts[i].src && (scripts[i].src.split('?')[0] == src.split('?')[0])) {
                  // скрипт на страничке есть. но загрузился ли он?
                  if ((_loadedFiles.indexOf(scripts[i].src) > -1) && callback) callback();
                  // if (callback) callback();
                  return true;
              }
            }
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            break;
    }

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    script.onreadystatechange = onLoad;
    script.onload = onLoad;

    // Fire the loading
    head.appendChild(script);

    function onLoad() {
        _loadedFiles.push(this.src);
        callback();
    }
}
