export default class LicenseNotification extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let actions = [];
        if (this.props.actions) {
            for (let i = 0; i < this.props.actions.length; i++) {
                let item = this.props.actions[i];
                actions.push(<button key={i}>{item.label}</button>);
            }
            if (actions.length) {
                actions = <div className="notification-actions">{actions}</div>;
            }
        }

        return (
            <div className={"license-notification " + (this.props.type || "")}>
                <div className="notification-logo"></div>
                <p className="notification-header">{this.props.header}</p>
                {actions}
            </div>
        );
    }
}
