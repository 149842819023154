export default class OperationsActions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleActions: this.props.compact
                ? ["add-operation", "upload"]
                : [
                      "add-income-operation",
                      "transfer",
                      "add-outcome-operation",
                      "upload",
                  ],
        };
    }

    render() {
        if (!this.props.actions) {
            return null;
        }

        let actions = [];

        for (let i = 0; i < this.props.actions.length; i++) {
            let action = this.props.actions[i];

            if (this.state.visibleActions.indexOf(action.name) < 0) {
                continue;
            }

            actions.push(
                <button
                    key={i}
                    title={action.label}
                    className={"action-" + action.name}
                    onClick={this.handleActionClick.bind(this, action.name)}
                >
                    <span className="action-icon"></span>
                    <span className="action-label">{action.label}</span>
                </button>
            );
        }

        return <div className="operations-actions">{actions}</div>;
    }

    handleActionClick(name, e) {
        switch (name) {
            case "add-operation":
                this.setState({
                    visibleActions: [
                        "add-income-operation",
                        "transfer",
                        "add-outcome-operation",
                        "close",
                    ],
                });
                break;
            case "add-income-operation":
            case "add-outcome-operation":
            case "upload":
            case "transfer":
                const handler = this.props.onActionClick || function () {};
                handler(e, name);

                // this.setState({
                //     visibleActions: ["add-operation", "upload"],
                // });
                break;
            case "close":
                this.setState({
                    visibleActions: ["add-operation", "upload"],
                });
                break;
        }
    }
}
