import * as CompanyHandlers from "../handlers/company-handlers.js";
import * as OperationsHandlers from "../handlers/operations-handlers.js";
import * as TransactionsHandlers from "../handlers/transactions-handlers.js";
import * as TargetsHandlers from "../handlers/targets-handlers.js";
import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as CabinetHandlers from "../handlers/cabinet-handlers.js";
import { getNestedValue } from "../../components/helpers/objects-helper.js";

export function handleCompanyClick(e, params) {
    CompanyHandlers.loadCompanyLayout(params.item.id).then((response) => {
        CompanyHandlers.setCompanyHistoryState(params.item.id, response);
    });
}

export function handleHeaderCompanySelectSelect(e, item) {
    CompanyHandlers.loadCompanyLayout(item.id).then((response) => {
        CompanyHandlers.setCompanyHistoryState(item.id, response);
    });
}

export function handleOperationAddClick(e, companyId, operationName) {
    switch (operationName) {
        case "transfer":
            return OperationsHandlers.loadOperationTransferPopup({
                companyId: companyId,
            });
        default:
            // рудимент
            return OperationsHandlers.loadOperationAddPopup(
                { companyId: companyId },
                operationName
            );
    }
}

export function handleCloseOperationAddPopup() {
    OperationsHandlers.closeOperationAddPopup();
}

export function handleCloseOperationEditPopup() {
    OperationsHandlers.closeOperationEditPopup();
}

export function handleCloseOperationRemovePopup() {
    OperationsHandlers.closeOperationRemovePopup();
}

export function handleCloseOperationTransferPopup() {
    OperationsHandlers.closeOperationTransferPopup();
}

export function handleOperationAddPopupSubmit(e, form, companyId) {
    e.preventDefault();
    OperationsHandlers.addOperationForm(form).then((response) => {
        // CompanyHandlers.refreshOperations(companyId);
        if (response && response.success) {
            CompanyHandlers.loadCompanyLayout(companyId);
        }
    });
}

export function handleOperationAddPopupFieldClick(
    e,
    field,
    form,
    params,
    companyId
) {
    OperationsHandlers.rememberFormValues(form);
    OperationsHandlers.rememberFormLayout("company");
    const action = getNestedValue(params, "action", "name");
    handleOperationsAction(action, companyId);
}

export function handleOperationEditPopupFieldClick(
    e,
    field,
    form,
    params,
    companyId
) {
    OperationsHandlers.rememberFormValues(form);
    OperationsHandlers.rememberFormLayout("company");
    const action = getNestedValue(params, "action", "name");
    handleOperationsAction(action, companyId);
}

export function handleOperationEditPopupSubmit(e, form, companyId, params) {
    e.preventDefault();
    OperationsHandlers.editOperationForm(form).then((response) => {
        // CompanyHandlers.refreshOperations(companyId);
        // если мы уже догружали операции - надо обновить компанию с тем жи кол-вом загруженных операций
        loadCompanyLayout(companyId, params);
    });
}

export function handleOperationRemovePopupSubmit(e, form, companyId, params) {
    e.preventDefault();
    OperationsHandlers.removeOperationForm(form).then((response) => {
        // CompanyHandlers.refreshOperations(companyId);
        loadCompanyLayout(companyId, params);
    });
}

export function handleOperationTransferPopupSubmit(e, form, companyId) {
    e.preventDefault();
    OperationsHandlers.transferOperationForm(form).then((response) => {
        // CompanyHandlers.refreshOperations(companyId);
        CompanyHandlers.loadCompanyLayout(companyId);
    });
}

export function handleOperationEditPopupActionClick(e, params) {
    return OperationsHandlers.handleAction(params);
}

export function handleGraphsFilterDateChecklistClick(e, item) {
    CompanyHandlers.setGraphsFilterDateValue(item.value);
}

export function handleGraphsDatepickerSelectButtonClick(e, params) {
    CompanyHandlers.setGraphsFilterDateRange(params.range);
}

export function loadChartHelpers() {
    CompanyHandlers.loadChartHelpers();
}

export function onOperationsFilterFieldChange(e, params) {
    OperationsHandlers.setOperationsFilterParams(params);
}

export function handleActionClick(e, action, companyId) {
    switch (action.name) {
        case "license":
            return LayoutHandlers.loadLayoutByName("cabinet", {
                pushHistoryState: true,
            }).then((response) => {
                return CabinetHandlers.loadMenuLayout("license");
            });
        case "edit":
            return LayoutHandlers.loadLayoutByName("company-about", {
                companyId: companyId,
                tab: "about",
            }).then((response) => {
                LayoutHandlers.setActiveCompanyMenuItem({ name: "about" });
                return CabinetHandlers.loadEditForm(companyId);
            });
    }
}

export function handleCompaniesActionClick(e, name) {
    switch (name) {
        case "add":
            return CompanyHandlers.loadCompanyAddPopup();
    }
}

export function handleCompanyAddPopupClose(e) {
    CompanyHandlers.closeCompanyAddPopup();
}

export function handleCompanyAddPopupSubmit(e, form, companyId) {
    e.preventDefault();
    CompanyHandlers.addCompanyForm(form).then((response) => {
        CompanyHandlers.refreshSidebarAndHeader(companyId);
    });
}

export function handleOperationClick(e, item) {
    switch (item.type) {
        case "transfer-income":
        case "transfer-outcome":
            return OperationsHandlers.loadOperationTransferPopup({
                id: item.id,
            });
        default:
            return OperationsHandlers.loadOperationEditPopup({ id: item.id }, 'edit');
    }
}

export function handleOperationActionClick(e, item, action) {
    switch (action.name) {
        case "edit":
            switch (item.type) {
                case "transfer-income":
                case "transfer-outcome":
                    return OperationsHandlers.loadOperationTransferPopup({
                        id: item.id,
                    });
                default:
                    return OperationsHandlers.loadOperationEditPopup({
                        id: item.id,
                    });
            }
        case "copy":
            return OperationsHandlers.loadOperationAddPopup(
                {
                    companyId: item.companyId,
                    copyOf: item.id,
                },
                item.type
            );
        case "delete":
            return OperationsHandlers.loadOperationRemovePopup(item);
    }
}

export function handleOperationsActionClick(e, action, companyId) {
    switch (action.name) {
        case "show-all":
            return LayoutHandlers.loadLayoutByName("transactions", {
                pushHistoryState: true,
            });
        case "load-more":
            const form = new FormData();
            form.append("date_range", "all-time");
            return TransactionsHandlers.loadMoreOperations(
                companyId,
                action.itemsCount,
                form
            );
    }
}

export function handleOperationsScrollEdge(itemsCount, companyId) {
    const form = new FormData();
    form.append("date_range", "all-time");
    return TransactionsHandlers.loadMoreOperations(companyId, itemsCount, form);
}

export function handleGraphsMoreButtonClick() {
    LayoutHandlers.loadLayoutByName("analytics", {
        pushHistoryState: true,
    });
}

export function handleTargetsActionClick(e, action, params) {
    switch (action.name) {
        case "add":
            return LayoutHandlers.loadLayoutByName("targets", {
                pushHistoryState: true,
            }).then((response) => {
                return TargetsHandlers.loadAddFormPopup(params);
            });
        case "result-banner-shown":
            return TargetsHandlers.sendResultBannerShown(action.url);
        case "result-banner-new-target":
            TargetsHandlers.sendResultBannerShown(action.url);
            return LayoutHandlers.loadLayoutByName("targets", {
                pushHistoryState: true,
            }).then((response) => {
                return TargetsHandlers.loadAddFormPopup(params);
            });
    }
}

export function handleGraphsMarkerClick(e, marker, companyId) {
    console.log(marker);
    if (!marker.id) {
        return null;
    }

    const formData = new FormData();

    // а на страницу транзакций попать с фильтром по категории
    formData.append("categories", marker.id);

    LayoutHandlers.loadLayoutByName("transactions", {
        pushHistoryState: true,
        params: {
            backlink: {
                name: "analytics",
            },
        },
    }).then((response) => {
        // параллельно заполним данные формы, чтоб было видно
        TransactionsHandlers.applyFilterFormData(formData);
        return TransactionsHandlers.filterOperations(companyId, formData);
    });
}

export function handlePaymentAccountsActionClick(e, companyId, name) {
    switch (name) {
        case "add":
            return LayoutHandlers.loadLayoutByName("company-dictionary", {
                companyId: companyId,
                tab: "accounts",
            }).then((response) => {
                return CabinetHandlers.loadCompanyTabAddForm(
                    companyId,
                    "accounts"
                );
            });
        case "transfer":
            return OperationsHandlers.loadOperationTransferPopup({
                companyId: companyId,
            });
    }
}

export function handlePaymentAccountClick(e, item, companyId) {
    LayoutHandlers.loadLayoutByName("analytics", {
        pushHistoryState: true,
        params: {
            filter: {
                accounts: item.id,
            },
        },
    });
}

export function handleCompanyDeletePopupSubmit(e, form) {
    e.preventDefault();
    console.log(form);
    return CabinetHandlers.deleteCompany(form).then((response) => {
        LayoutHandlers.loadHomeLayout().then(() => {
            LayoutHandlers.handlePopupMessages(response);
        });
    });
}

function handleOperationsAction(name, companyId) {
    let menuNames = {
        "add-account": "accounts",
        "add-service": "categories",
        "add-client": "partners",
    };

    let menuName = menuNames[name];

    if (menuName) {
        return LayoutHandlers.loadLayoutByName("company-dictionary", {
            companyId: companyId,
            tab: menuName,
        }).then((response) => {
            LayoutHandlers.setActiveCompanyMenuItem({
                name: menuName,
            });
            return CabinetHandlers.loadCompanyTabAddForm(companyId, menuName);
        });
    }
}

function loadCompanyLayout(companyId, params) {
    let layoutParams = {};
    if (params && params.operationsLimit) {
        layoutParams.operations = {
            limit: params.operationsLimit,
        };
    }
    return CompanyHandlers.loadCompanyLayout(companyId, layoutParams);
}
