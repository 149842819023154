import { getNestedValue } from "../../../../components/helpers/objects-helper.js";

export default class Breadcrumbs extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let list = [];

        const company = this.renderCompany();
        if (company) {
            list.push(company);
        }

        return <div className="cabinet-breadcrumbs">{list}</div>;
    }

    renderCompany() {
        if (!this.props.companyId || !this.props.title) {
            return null;
        }

        let activeItem = {
            id: this.props.companyId,
            caption: this.props.title,
        };

        return (
            <button
                key="company"
                type="button"
                className="breadcrumbs-item item-company"
                onClick={this.handleCompanyClick.bind(this, activeItem)}
            >
                {activeItem.caption}
            </button>
        );
    }

    renderDelim(key) {
        return <div key={key} className="breadcrumbs-delim"></div>;
    }

    handleCompanyClick(item, e) {
        const handler = this.props.onCompanyClick || function () {};
        handler(e, item);
    }
}
