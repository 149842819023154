import CompaniesHeader from "./companies-header.jsx";
import CompaniesCompactList from "./companies-compact-list.jsx";
import CompanyMenu from "./company-menu.jsx";
import LetterItemHelper from "../../../helpers/letter-item-helper";
import Price from "../../price.jsx";

export default class CompaniesSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.letterHelper = new LetterItemHelper(1);

        this.sidebarRef = React.createRef();

        this.state = {
            showOtherCompanies: false,
        };
    }

    componentDidMount() {
        this.stickSidebar();
        this.handleStickySidebarHeight();
    }

    componentDidUpdate() {
        this.handleStickySidebarHeight();
    }

    render() {
        let header;
        if (this.props.header) {
            header = (
                <CompaniesHeader
                    {...this.props.header}
                    onActionClick={this.props.onActionClick || function () {}}
                />
            );
        }

        let list, activeCompany;
        if (this.props.list) {
            list = (
                <CompaniesCompactList
                    className={
                        "companies-compact-list" +
                        (this.state.showOtherCompanies ? " show" : "")
                    }
                    list={this.props.list}
                    activeId={this.props.activeId}
                    onItemClick={this.handleCompactCompanyClick.bind(this)}
                />
            );

            this.props.list.map((item) => {
                if (item.id == this.props.activeId) {
                    activeCompany = (
                        <div
                            className="active-company"
                            title={item.caption}
                            onClick={this.handleActiveCompanyClick.bind(
                                this,
                                item
                            )}
                        >
                            <span className="list-item-col icon">
                                <span
                                    className={
                                        "icon-content " +
                                        this.letterHelper.getLetterClass(item)
                                    }
                                >
                                    {this.letterHelper.getLetterSymbol(item)}
                                </span>
                            </span>
                            <span className="list-item-col info">
                                <span className="caption">
                                    <span>{item.caption}</span>
                                </span>
                                <span className="price">
                                    <Price value={item.balance} />
                                </span>
                            </span>
                        </div>
                    );
                }
            });
        }

        let action;
        if (this.props.action) {
            action = (
                <div
                    className={
                        "companies-action" +
                        (this.state.showOtherCompanies ? " show" : "")
                    }
                >
                    <button onClick={this.handleActionClick.bind(this)}>
                        {this.props.action.label}
                    </button>
                </div>
            );
        }

        let dropdownButton;
        if (this.props.moreLabel) {
            dropdownButton = (
                <button
                    className="dropdown-button"
                    onClick={this.handleCompaniesDropdownClick.bind(this)}
                >
                    {this.props.moreLabel}
                </button>
            );
        }

        return (
            <div className="companies-sidebar" ref={this.sidebarRef}>
                <div className="sidebar-plate">
                    {header}
                    <div className="block-delim"></div>
                    {dropdownButton}
                    <label className="companies-label">
                        {this.props.label}
                    </label>
                    {list}
                    {action}
                    {activeCompany}
                    <CompanyMenu
                        list={this.props.companyMenu}
                        onMenuClick={this.handleCompanyMenuButtonClick.bind(
                            this
                        )}
                    />
                </div>
            </div>
        );
    }

    handleCompactCompanyClick(e, params) {
        const handler = this.props.onCompanyClick || function () {};
        handler(e, params);
        this.setState({
            showOtherCompanies: false,
        });
    }

    handleActionClick(e) {
        const handler = this.props.onCompaniesActionClick || function () {};
        handler(e, "add");
    }

    handleCompaniesDropdownClick(e) {
        this.setState({
            showOtherCompanies: !this.state.showOtherCompanies,
        });
    }

    handleCompanyMenuButtonClick(e, item) {
        const handler = this.props.onCompanyMenuItemClick || function () {};
        handler(e, this.props.activeId, item);
    }

    handleActiveCompanyClick(item, e) {
        const handler = this.props.onActiveCompanyClick || function () {};
        handler(e, item);
    }

    stickSidebar() {
        const sidebar = this.sidebarRef.current;
        if (!sidebar) {
            return true;
        }

        sidebar.classList.add("sticky");

        // надо заполнить пустоту, образовавшуюся после
        let dummy = document.createElement("div");
        dummy.classList.add("companies-sidebar");
        sidebar.parentNode.insertBefore(dummy, sidebar);
    }

    handleStickySidebarHeight() {
        const sidebar = this.sidebarRef.current;
        if (!sidebar) {
            return true;
        }

        sidebar.style.height = null;
        sidebar.classList.remove("overflowed");

        const viewportOffset = sidebar.getBoundingClientRect();
        const maxHeight = window.innerHeight - viewportOffset.top - 20;

        if (sidebar.offsetHeight > maxHeight) {
            sidebar.style.height = maxHeight + "px";
            sidebar.classList.add("overflowed");
        }
    }
}
