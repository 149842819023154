import Container from "../container.jsx";
import Operations from "../../components/layouts/transactions/operations.jsx";

export default class OperationsContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.operations");
        this.operationsRef = React.createRef();
    }

    render() {
        return (
            <Operations
                {...this.props}
                {...this.state.state}
                ref={this.operationsRef}
            />
        );
    }

    getFilterForm() {
        return this.operationsRef.current.getFilterForm();
    }
}
