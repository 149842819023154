import SidebarCompanies from '../../../containers/cabinet/sidebar-companies.jsx';

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let menu = [];
        if (this.props.menu) {
            for(let i=0; i<this.props.menu.length; i++) {
                let item = this.props.menu[i],
                    active = (item.name == this.props.activeMenuName) || (!this.props.activeMenuName && item.active);
                menu.push(
                    <div key={i} className={'menu-item' + (active ? ' active' : '')}>
                        <button onClick={this.handleMenuItemClick.bind(this, item.name)}>
                            <span className={'icon icon-' + item.name}></span>
                            <span className="text">{item.caption}</span>
                        </button>
                        {this.getMenuSubsection(item)}
                    </div>
                );
            }
        }

        return (
            <div className="cabinet-sidebar">
                <div className="userbar">
                    <p className="username">
                        <span className="icon"></span>
                        {this.props.username}
                    </p>
                </div>
                {menu}
            </div>
        );
    }

    getMenuSubsection(item) {
        switch(item.name) {
            case 'companies': return <SidebarCompanies {...this.props}
                onCompanyClick={this.props.onCompanyClick || function(){}}
                onCompaniesActionClick={this.props.onCompaniesActionClick || function(){}}
                onCompanyAddPopupClose={this.props.onCompanyAddPopupClose || function(){}}
                onCompanyAddPopupSubmit={this.props.onCompanyAddPopupSubmit || function(){}} />;
        }
    }

    handleMenuItemClick(name, e) {
        const handler = this.props.onMenuItemClick || function(){};
        handler(e, name);
    }
}
