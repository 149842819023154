import {getNestedValue} from '../../../../components/helpers/objects-helper.js';
import Select from './select.jsx';
import CustomActionsSelect from './custom-actions-select.jsx';
import SearchActionsSelect from './search-actions-select.jsx';

export default class SelectParser extends React.Component {
    render() {
        switch(true) {
            case (this.hasCustomList()):
                return <CustomActionsSelect {...this.props} />;
            case (this.hasSearchList()):
                return <SearchActionsSelect {...this.props} />;
            default:
                return <Select {...this.props} />;
        }
    }

    hasCustomList() {
        return this.props.customList || getNestedValue(this.props, 'data', 'customList');
    }

    hasSearchList() {
        return this.props.customList || getNestedValue(this.props, 'data', 'searchList');
    }
}
