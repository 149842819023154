import Form from "../components/forms/form.jsx";
import Errors from "../components/forms/errors.jsx";
import Messages from "../components/forms/messages.jsx";

export default class FormPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.form) {
            return null;
        }

        let className = this.props.className || "form-popup";
        if (this.props.show) {
            className += " show";
        }

        let body;
        if (this.props.body) {
            body = <p className="body">{this.props.body}</p>;
        }

        let actions = [];
        if (this.props.actions) {
            for (let i = 0; i < this.props.actions.length; i++) {
                let action = this.props.actions[i];
                actions.push(
                    <button
                        key={i}
                        onClick={this.handleActionClick.bind(this, action)}
                    >
                        {action.label}
                    </button>
                );
            }
            actions = <div className="popup-actions">{actions}</div>;
        }

        return (
            <div className={className}>
                <div className="popup-container">
                    <div className="popup-content">
                        <p className="header">{this.props.header}</p>
                        {body}
                        <Errors list={this.props.errors} />
                        <Messages list={this.props.messages} />
                        <Form
                            {...this.props.form}
                            onFieldClick={
                                this.props.handleFieldClick || function () {}
                            }
                            onSubmit={this.props.handleSubmit || function () {}}
                        />
                        {actions}
                    </div>
                    <button
                        className="close-button"
                        onClick={
                            this.props.handleCloseButtonClick || function () {}
                        }
                    ></button>
                </div>
            </div>
        );
    }

    handleActionClick(params, e) {
        const handler = this.props.handleActionClick;
        if (handler) {
            handler(e, params);
        }
    }
}
