import Form from '../../forms/form.jsx';
import Fieldset from '../../forms/fields/fieldset.jsx';
import Field from '../../forms/fields/field.jsx';
import Errors from '../../forms/errors.jsx';
import Messages from '../../forms/messages.jsx';
import AddCompanyOwnerSubform from './add-company-owner-subform.jsx';
import AddCompanyAccountSubform from './add-company-account-subform.jsx';
import {copyDataObject as copyObject} from '../../../../components/helpers/objects-helper.js';

export default class AddCompanyForm extends Form {
    constructor(props) {
        super(props);
        this.fieldsets = [];
    }

    render() {
        let formAttributes = {
            action: this.props.action || '',
            method: this.props.method || 'post',
            onSubmit: this.handleSubmit.bind(this)
        };

        // чистим рефы
        // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
        this.fieldsets.splice(0, this.fieldsets.length);

        let form;
        // ну а вдруг что-то не пришло - надо проверить
        if (this.props.companyForm || this.props.ownerForm || this.props.requisitesForm || this.props.accountForm) {
            let subforms = [];

            if (this.props.companyForm) {
                subforms.push(
                    <div key="company" className="subform">
                        <p className="subform-title">{this.props.companyHeader}</p>
                        {this.renderForm(this.props.companyForm, 'Company')}
                    </div>
                );
            }

            if (this.props.ownerForm) {
                subforms.push(
                    <AddCompanyOwnerSubform
                        key="owners"
                        title={this.props.ownersHeader}
                        form={this.props.ownerForm}
                        items={this.props.owners}
                        addButton={this.props.addOwnerButton}
                        fieldNamePrefix="Owner"
                        onFieldChange={this.handleFieldChange.bind(this)}/>
                );
            }

            if (this.props.requisitesForm) {
                subforms.push(
                    <div key="requisites" className="subform">
                        <p className="subform-title">{this.props.requisitesHeader}</p>
                        {this.renderForm(this.props.requisitesForm, 'Requisites')}
                    </div>
                );
            }

            if (this.props.accountForm) {
                subforms.push(
                    <AddCompanyAccountSubform
                        key="accounts"
                        title={this.props.accountsHeader}
                        form={this.props.accountForm}
                        items={this.props.accounts}
                        addButton={this.props.addAccountButton}
                        fieldNamePrefix="Account"
                        onFieldChange={this.handleFieldChange.bind(this)}/>
                );
            }
            form = <form {...formAttributes} ref={this.formRef} >
                {subforms}
                <div className="form-submit-button">
                    <Field {...this.props.saveButton} />
                </div>
            </form>;
        }

        return (
            <div className="add-company-form">

                <Errors list={this.props.errors} />
                <Messages list={this.props.messages} />
                {form}
            </div>
        );
    }

    renderForm(form, fieldnamePrefix) {
        // надо убрать кнопки с сабмитом
        let fields = [];
        for(let i=0; i<form.fields.length; i++) {
            // везде приходится копировать, иначе все связано и в след раз это будут уже измененные данные
            let field = copyObject(form.fields[i]);
            if (field.type == 'submit') {
                continue;
            }
            field.name = fieldnamePrefix + '[' + field.name + ']';
            fields.push(field);
        }

        return <Fieldset fields={fields}
            onFieldChange={this.handleFieldChange.bind(this)}
            onFieldClick={this.handleFieldClick.bind(this)}
            ref={(elem) => {
                this.fieldsets.push(elem);
            }} />;
    }

    validateForm() {
        let valid = true;
        if (this.fieldsets.length) {
            for(let i=0; i<this.fieldsets.length; i++) {
                if (!this.fieldsets[i].validateFields()) {
                    valid = false;
                }
            }
        }

        return valid;
    }
}
