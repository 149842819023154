export default class Agreement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: this.props.checked || this.props.value,
        };
    }

    render() {
        return (
            <div
                className={
                    "custom-checkput" + (this.state.checked ? " checked" : "")
                }
            >
                <input
                    className="form-input"
                    type="checkbox"
                    name={this.props.name}
                    defaultChecked={this.props.checked || this.props.value}
                    id={this.props.id}
                    onChange={this.handleChange.bind(this)}
                />
                <label
                    className="checkput-icon"
                    htmlFor={this.props.id}
                ></label>
            </div>
        );
    }

    handleChange(e) {
        this.setState({
            checked: e.target.checked,
        });

        const handler = this.props.onChange || function () {};
        handler(e);
    }
}
