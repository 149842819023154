import Container from "../container.jsx";
import TargetsList from "../../components/layouts/targets/list.jsx";

export default class TargetsListContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.targets");
    }

    render() {
        return <TargetsList {...this.props} {...this.state.state} />;
    }
}
