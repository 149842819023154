import * as Auth from "../auth.js";
import { request, queryRequest } from "./repository.js";

let config = {};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function getLayout() {
    return request("get", config.baseUrl + config.authLayoutUrl);
}

export function registration(formData) {
    return request("post", config.baseUrl + config.registrationUrl, formData);
}

export function restoreUserAccess(formData) {
    return request(
        "post",
        config.baseUrl + config.restoreUserAccessUrl,
        formData
    );
}

export function getUserStatus() {
    return Auth.getUserStatus();
}

export function createWorkspace() {
    debugMessage("start create workspace");
    return request("post", config.baseUrl + config.createWorkspaceUrl).then(
        (response) => {
            if (response.success) {
                Auth.setUserStatus(response.status);
                return true;
            }

            if (response.errors) {
                debugMessage(response.errors);
                throw new Error("repository.create-workspace-error");
            }

            if (response.status == 3) {
                Auth.setUserStatus(response.status);
                return waitWorkspaceCreation();
            }

            debugMessage("unknown response");
            throw new Error("repository.create-workspace-error");
        }
    );
}

export function waitWorkspaceCreation() {
    debugMessage("start waiting workspace creation");
    let tries = 0,
        triesLimit = 20;
    return checkWorkspaceCreation(tries, triesLimit);
}

export function getLicenseWarning() {
    return queryRequest({
        licenseWarning: {},
    });
}

/* HELPERS */

function checkWorkspaceCreation(tries, triesLimit) {
    if (tries >= triesLimit) {
        debugMessage("tries limit exceeded");
        throw new Error("repository.check-workspace-creation-fail");
    }
    debugMessage("try " + ++tries);
    return request(
        "post",
        config.baseUrl + config.checkWorkspaceCreationUrl
    ).then((response) => {
        if (!response || (!response.status && !response.errors)) {
            debugMessage("check workspace fail");
            throw new Error("repository.check-workspace-creation-fail");
        }

        if (response.errors) {
            debugMessage("check workspace error: " + response.errors);
            throw new Error("repository.check-workspace-creation-fail");
        }

        switch (response.status) {
            case "1":
            case 1:
                Auth.setUserStatus(response.status);
                return true;
            default:
                Auth.setUserStatus(parseInt(response.status));
                return delay(2000).then(() => {
                    return checkWorkspaceCreation(tries, triesLimit);
                });
        }
    });
}

function debugMessage(message) {
    if (!config.debug) {
        return null;
    }

    console.log("REPOSITORY:", message);
}

function delay(ms) {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, ms);
    });
}
