import Container from '../container.jsx';
import ErrorsPopup from '../../components/errors-popup.jsx';

export default class ErrorsPopupContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.popupErrors');
    }

    render(){
        return <ErrorsPopup {...this.state.state}
            handleErrorsPopupCloseButtonClick={this.props.handleErrorsPopupCloseButtonClick || function(){}} />;
    };
}
