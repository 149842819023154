import * as Store from "../store.js";
import * as CompanyAboutRepository from "../../components/repositories/company-about-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse, submitForm } from "../helpers/request-helper.js";

export function loadLayout(params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        CompanyAboutRepository.getLayout(params),
        (response) => {
            if (!response) {
                return false;
            }
            Store.setStore(response);
            Store.setStore(params.companyId, "data.companyId");
            return true;
        }
    );
}

export function setCompanyHistoryState(params, response) {
    history.pushState(params, null, "/company/about?id=" + params.companyId);
}
