import SidebarCompanies from '../../../containers/cabinet/sidebar-companies.jsx';

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let menu = [],
            labelField = this.props.labelField || 'label';
        if (this.props.list) {
            for(let i=0; i<this.props.list.length; i++) {
                let item = this.props.list[i];
                menu.push(
                    <div key={i} className="menu-item">
                        <button onClick={this.handleMenuItemClick.bind(this, item)}>
                            <span className="text">{item[labelField]}</span>
                        </button>
                    </div>
                );
            }
        }

        return (
            <div className="cabinet-list-menu">
                {menu}
            </div>
        );
    }

    handleMenuItemClick(item, e) {
        const handler = this.props.onMenuItemClick || function(){};
        handler(e, item);
    }
}
