import Price from "../../price.jsx";
import LetterItemHelper from "../../../helpers/letter-item-helper";

export default class CompaniesCompactList extends React.Component {
    constructor(props) {
        super(props);
        this.letterHelper = new LetterItemHelper(3);
    }

    render() {
        this.letterHelper.resetCounter();

        let list = [];

        if (this.props.list) {
            for (let i = 0; i < this.props.list.length; i++) {
                let item = this.props.list[i];
                let className = "companies-list-item";
                if (item.id == this.props.activeId) {
                    className += " active";
                }

                list.push(
                    <button
                        key={i}
                        data-index={i}
                        className={className}
                        title={item.caption || item.label}
                        onClick={this.handleItemClick.bind(this)}
                    >
                        <span className="list-item-col icon">
                            <span
                                className={
                                    "icon-content " +
                                    this.letterHelper.getLetterClass(item)
                                }
                            >
                                {this.letterHelper.getLetterSymbol(item)}
                            </span>
                        </span>
                        <span className="list-item-col info">
                            <span className="caption">
                                <span>{item.caption || item.label}</span>
                            </span>
                            <span className="price">
                                <Price value={item.balance} />
                            </span>
                        </span>
                    </button>
                );
            }
        }

        return (
            <div className={this.props.className || "companies-compact-list"}>
                {list}
            </div>
        );
    }

    handleItemClick(e) {
        const handler = this.props.onItemClick || function () {},
            index = e.target.closest("button").dataset.index,
            item = this.props.list[index];

        handler(e, {
            index: index,
            item: item,
        });
    }
}
