import * as Store from '../store.js';
import * as CompaniesRepository from '../../components/repositories/companies-repository.js';
import {handleResponse} from '../helpers/request-helper.js';

export function refreshCompanies() {
    return handleResponse(
        CompaniesRepository.getCompanies(),
        (response) => {
            if (!response.companies) {
                return false;
            }
            Store.setStore(response.companies, 'data.companies');
            return true;
        }
    );
}
