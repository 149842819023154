import Container from '../container.jsx';
import Operations from '../../components/layouts/companies/operations.jsx';

export default class OperationsContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.operations');
    }

    render(){
        return <Operations {...this.props} {...this.state.state} />;
    };
}
