import { request, queryRequest } from "./repository.js";
import { getNestedValue } from "../helpers/objects-helper.js";

let config = {};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function getLayout(companyId, params) {
    params = params || {};

    let graphsParams = {
        companyId: companyId,
        graphs: {
            limit: 5,
        },
    };
    if (!graphsParams.companyId) {
        graphsParams.defaultCompany = true;
    }

    if (params.filter) {
        params.filter.companyId = companyId;
        for (let name in params.filter) {
            graphsParams[name] = params.filter[name];
        }
    } else {
        params.filter = { companyId };
    }

    return queryRequest({
        pageHeader: {
            menu: { active: "analytics" },
        },
        pageSocialLinks: {},
        companySidebar: {},
        analyticsFilter: params.filter || {},
        analyticsSectionBar: graphsParams || {},
        analyticsGraphs: graphsParams,
    }).then((response) => {
        // response.companyId = getNestedValue(response, 'operations', 'companyId');
        return {
            layout: "analytics",
            data: response,
        };
    });
}

export function getCompany(params) {
    return queryRequest({
        analyticsGraphs: params,
        analyticsFilter: params,
    });
}

export function getGraphs(params) {
    return queryRequest({
        analyticsGraphs: params,
        analyticsSectionBar: params,
    });
}
