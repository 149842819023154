import newId from './helpers/new-id.js';

let store = {
        data: {
            loading: {
                show: true,
                message: 'Loading'
            }
        }
    },
    handlers = {
        onUpdate: {}
    }

export let config = {
    debug : false
};

export function setConfig(newConfig) {
    if (newConfig) {
        for(let name in newConfig) {
            config[name] = newConfig[name];
        }
    }

    if (config.debug) {
        /* доступ к стору снаружи всех измерений */
        window.dogmaGetStore = () => {
            return store;
        }
        window.dogmaSetStore = (value, path) => {
            setStore(value, path);
        }
    }
}

export function getStore(path) {
    if (!path) {
        return store;
    }

    const levels = path.split('.');
    let storeLevel = store;
    for(let i=0; i<levels.length; i++) {
        if (!storeLevel) {
            return null;
        }
        storeLevel = storeLevel[levels[i]];
    }

    return storeLevel;
}

export function setStore(value, path) {
    if (path) {
        setStorePath(value, path);
    } else {
        const storeConfig = store.config;
        store = value;
        if (!store.config) {
            store.config = storeConfig;
        }
    }
    fireHandlers('onUpdate', {path: path});
}

export function addList(list, path) {
    addListPath(list, path);
    fireHandlers('onUpdate', {path: path});
}

export function onUpdate(handler) {
    const handlerId = newId('store-handler-');
    handlers.onUpdate[handlerId] = handler;
    return handlerId;
}

export function offUpdate(handlerId) {
    handlers.onUpdate[handlerId] = null;
}

function fireHandlers(name, params) {
    if (config.debug) {
        console.log('STORE:', params.path || 'full store', 'updated');
    }

    for(let id in handlers[name]) {
        let handler = handlers[name][id];
        if (!handler) {
            // его может небыть после offUpdate
            continue;
        }
        handlers[name][id](params);
    }
}

//https://stackoverflow.com/questions/18936915/dynamically-set-property-of-nested-object
function setStorePath(value, path) {
    let schema = store;  // a moving reference to internal objects within obj
    const pList = path.split('.'),
        len = pList.length;
    for(let i = 0; i < len-1; i++) {
        const elem = pList[i];
        if( !schema[elem] ) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len-1]] = value;
}

function addListPath(list, path) {
    let schema = store;  // a moving reference to internal objects within obj
    const pList = path.split('.'),
        len = pList.length;
    for(let i = 0; i < len-1; i++) {
        const elem = pList[i];
        if( !schema[elem] ) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len-1]] = schema[pList[len-1]].concat(list);
}
