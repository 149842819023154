import Price from "../../price.jsx";
import LetterItemHelper from "../../../helpers/letter-item-helper.js";

export default class InvestmentsTable extends React.Component {
    constructor(props) {
        super(props);
        this.letterHelper = new LetterItemHelper(4);
    }

    render() {
        if (!this.props.list || !this.props.list.length) {
            return null;
        }

        let headers = [],
            headersLabels = {};
        if (this.props.headers) {
            for (let i = 0; i < this.props.headers.length; i++) {
                let header = this.props.headers[i];
                headers.push(
                    <p key={i} className={"list-item-col " + header.type}>
                        {header.label}
                    </p>
                );
                headersLabels[header.type] = header.label;
            }
            headers.push(<p key="edit" className="list-item-col edit"></p>);
            headers.push(
                <p
                    key="icon-dropdown"
                    className="list-item-col icon-dropdown"
                ></p>
            );
        }

        let investments = [];
        for (let i = 0; i < this.props.list.length; i++) {
            let item = this.props.list[i],
                operations = [],
                actions = [];

            if (item.operations) {
                for (let j = 0; j < item.operations.length; j++) {
                    operations.push(
                        this.renderInvestmentRow(
                            item.operations[j],
                            j,
                            headersLabels,
                            { lastChild: j + 1 == item.operations.length }
                        )
                    );
                }
            }

            if (item.actions) {
                for (let j = 0; j < item.actions.length; j++) {
                    let action = item.actions[j];
                    actions.push(
                        <button
                            key={j}
                            className="investment-action"
                            onClick={this.handleInvestmentActionClick.bind(
                                this,
                                item.id,
                                action.name
                            )}
                        >
                            {action.label}
                        </button>
                    );
                }
                actions = <div className="investment-actions">{actions}</div>;
            }

            let letterColor = this.letterHelper.getLetterColor(item);

            investments.push(
                <div
                    key={i}
                    className={
                        "investment-container " +
                        letterColor +
                        (!item.operations || !item.operations.length
                            ? " no-children"
                            : "")
                    }
                    onClick={this.handleInvestmentContainerClick.bind(this)}
                >
                    {this.renderInvestmentRow(item, i, headersLabels, {
                        letterColor,
                    })}
                    <div className="investment-dropdown">
                        {operations}
                        {actions}
                    </div>
                </div>
            );
        }

        return (
            <div className="investments-table">
                <div className="investments-headers">{headers}</div>
                <div className="investments-list">{investments}</div>
            </div>
        );
    }

    renderInvestmentRow(item, i, headersLabels, params) {
        let itemCols = [],
            comment,
            actionEdit;

        params = params || {};

        if (item.comment) {
            comment = (
                <p title={item.commentText}>
                    <span className="icon"></span>
                    <span className="value">{item.comment}</span>
                </p>
            );
        }

        if (item.id) {
            actionEdit = (
                <button
                    className="edit-button"
                    onClick={this.handleEditClick.bind(this, item.id)}
                ></button>
            );
        }

        return (
            <div
                key={i}
                className={
                    "investment-item" + (params.lastChild ? " last-child" : "")
                }
            >
                <div key={i + "-icon"} className="list-item-col icon">
                    <p className={"icon-content " + (params.letterColor || "")}>
                        {this.letterHelper.getFirstLetter(item.caption)}
                    </p>
                </div>
                <div key={i + "-caption"} className="list-item-col caption">
                    <p title={item.caption}>{item.caption}</p>
                </div>
                <div className="list-item-col sum">
                    <label>{headersLabels.sum}</label>
                    <Price value={item.sum} />
                </div>
                <div className="list-item-col date">
                    <p>{item.date}</p>
                </div>
                <div className="list-item-col profit">
                    <label>{headersLabels.profit}</label>
                    <Price value={item.profit} />
                </div>
                <div className="list-item-col comment">{comment}</div>
                <div className="list-item-col edit">{actionEdit}</div>
                <div className="list-item-col icon-dropdown">
                    <p className="dropdown-show">{item.showLabel}</p>
                    <p className="dropdown-hide">{item.hideLabel}</p>
                    <div className="icon"></div>
                </div>
            </div>
        );
    }

    handleEditClick(id, e) {
        e.stopPropagation();
        const handler = this.props.onEditClick || function () {};
        handler(e, id);
    }

    handleInvestmentActionClick(id, name, e) {
        e.stopPropagation();
        const handler = this.props.onInvestmentActionClick || function () {};
        handler(e, id, name);
    }

    handleInvestmentContainerClick(e) {
        const container = e.target.closest(".investment-container");
        container.classList.toggle("opened");
    }
}
