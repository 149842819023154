import { request, queryRequest } from "./repository.js";

let config = {};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function getLayout(params) {
    return queryRequest({
        pageHeader: {},
        pageSocialLinks: {},
        search: params,
    }).then((response) => {
        return {
            layout: "search",
            data: response,
        };
    });
}
