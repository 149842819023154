import * as RequestHelper from '../request-helper.js';

export class ParamsAuth {
    constructor(config) {
        config = config || {};

        this.tokenUrl = config.tokenUrl;
        this.refreshTokenUrl = config.refreshTokenUrl;

        this.debugMessages = [];
    }

    /**
     * Запрос на сервер для авторизации по переданным параметрам
     * Также шлем fingerprint, чтобы этот токен работал только для этого браузера
     * @param params object or FormData
     */
    getToken(params) {
        this.debugMessage('request token');
        params = RequestHelper.addBodyParam(
            params,
            'fingerprint',
            this.getFingerprint()
        );
        return this.request(
            'post',
            this.tokenUrl,
            params
        );
    }

    refreshToken(refreshToken) {
        this.debugMessage('refresh token');
        return this.request(
            'post',
            this.refreshTokenUrl,
            {
                refreshToken: refreshToken,
                fingerprint: this.getFingerprint()
            }
        );
    }

    /* HELPERS */

    getFingerprint() {
        // https://github.com/darkskyapp/string-hash
        function checksum(str) {
            var hash = 5381,
                i = str.length;

            while (i--) hash = (hash * 33) ^ str.charCodeAt(i);

            return hash >>> 0;
        }

        // http://stackoverflow.com/a/4167870/1250044
        function map(arr, fn){
            var i = 0, len = arr.length, ret = [];
            while(i < len){
                ret[i] = fn(arr[i++]);
            }
            return ret;
        }

        return checksum([
            navigator.userAgent,
            [screen.height, screen.width, screen.colorDepth].join('x'),
            new Date().getTimezoneOffset(),
            !!window.sessionStorage,
            !!window.localStorage,
            map(navigator.plugins, function (plugin) {
                return [
                    plugin.name,
                    plugin.description,
                    map(plugin, function (mime) {
                        return [mime.type, mime.suffixes].join('~');
                    }).join(',')
                ].join("::");
            }).join(';')
        ].join('###'));
    }

    debugMessage(message) {
        this.debugMessages.push(message);
    }

    getDebugMessages() {
        return this.debugMessages;
    }

    request(method, url, body) {
        this.debugMessage('prepare request');

        if (body) {
            body = RequestHelper.ensureFormData(body);
        }

        return fetch(url, {
            method: method,
            body: body
        }).then(response => {
            this.debugMessage('response status: ' + response.status);
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            }
            throw new ParamsAuthError('Request fail', response);
        });
    }
}

function ParamsAuthError(message, response) {
  this.name = "ParamsAuthError";
  this.message = (message || "");
  this.response = response;
}
ParamsAuthError.prototype = Object.create(Error.prototype);
