import newId from "../utils/new-id.js";
import DatepickerPopup from "../../datepicker-popup.jsx";

export default class Datepicker extends React.Component {
    constructor(props) {
        super(props);
        this.input = null;
        this.state = {
            showDatepicker: false,
            value: this.props.value || "",
        };
    }

    render() {
        // все остальные компоненты (textarea, select, chekput) не нуждаются в новом свойстве key
        // они перерисовывются когда приходит новый value
        // но этот инпут почему-то не перерисовывается...
        return (
            <div>
                <input
                    className="form-input"
                    type={this.props.type}
                    name={this.props.name}
                    value={this.state.value}
                    key={newId("input-key-")}
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange.bind(this)}
                    onFocus={this.handleFocus.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    ref={(input) => (this.input = input)}
                />
                <DatepickerPopup
                    show={this.state.showDatepicker}
                    onCloseButtonClick={this.handleDatepickerCloseButtonClick.bind(
                        this
                    )}
                    onDayClick={this.handleDatepickerDayClick.bind(this)}
                />
            </div>
        );
    }

    handleChange(e) {
        const handler = this.props.onChange || function () {};
        handler(e);
    }

    handleFocus(e) {
        this.showDatepicker();
    }

    handleBlur(e) {
        this.hideDatepicker();
    }

    handleDatepickerCloseButtonClick() {
        this.hideDatepicker();
    }

    handleDatepickerDayClick(e, dateString) {
        this.setState(
            {
                value: dateString,
            },
            () => {
                if (this.input) {
                    // не работает, хз почему
                    // this.input.dispatchEvent(new Event('change', {bubbles: true}));
                    const handler = this.props.onChange || function () {};
                    handler({
                        target: {
                            value: dateString,
                        },
                    });
                }
            }
        );

        this.hideDatepicker();
    }

    showDatepicker() {
        this.setState({
            showDatepicker: true,
        });
    }

    hideDatepicker() {
        this.setState({
            showDatepicker: false,
        });
    }
}
