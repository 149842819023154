import Form from "../components/forms/form.jsx";
import Errors from "../components/forms/errors.jsx";
import Messages from "../components/forms/messages.jsx";

export default class FormPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.form) {
            return null;
        }

        let backButton;
        if (this.props.backButton) {
            backButton = (
                <div className="back-button">
                    <button
                        onClick={this.handleActionClick.bind(
                            this,
                            this.props.backButton
                        )}
                    >
                        {this.props.backButton.label}
                    </button>
                </div>
            );
        }

        let body;
        if (this.props.body) {
            body = <p className="body">{this.props.body}</p>;
        }

        let actions = [];
        if (this.props.actions) {
            for (let i = 0; i < this.props.actions.length; i++) {
                let action = this.props.actions[i];
                actions.push(
                    <button
                        key={i}
                        className={"action-" + action.name}
                        onClick={this.handleActionClick.bind(this, action)}
                    >
                        {action.label}
                    </button>
                );
            }
            actions = <div className="layout-actions">{actions}</div>;
        }

        return (
            <div className="form-layout">
                <div className="form-content">
                    {backButton}
                    <p className="header">{this.props.header}</p>
                    {body}
                    <Errors list={this.props.errors} />
                    <Messages list={this.props.messages} />
                    <Form
                        {...this.props.form}
                        onFieldClick={
                            this.props.handleFieldClick || function () {}
                        }
                        onSubmit={this.props.handleSubmit || function () {}}
                    />
                    {actions}
                </div>
            </div>
        );
    }

    handleActionClick(params, e) {
        const handler = this.props.handleActionClick;
        if (handler) {
            handler(e, params);
        }
    }
}
