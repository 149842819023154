import Price from "../../price.jsx";

export default class LicenseOrders extends React.Component {
    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        let message;
        if (this.props.message) {
            message = <p className="message">{this.props.message}</p>;
        }

        let list = [];
        if (this.props.list && this.props.list.length) {
            this.props.list.map((item, index) => {
                list.push(
                    <div
                        key={index}
                        className={"list-item status-" + item.status}
                    >
                        <div className="list-item-container">
                            <div className="li-title">
                                <p>{item.label}</p>
                            </div>
                            <div className="li-field id">
                                <p>{item.idLabel}</p>
                            </div>
                            <div className="li-field date">
                                <p>{item.date}</p>
                            </div>
                            <div className="li-field price">
                                <Price value={item.price} integer={true} />
                            </div>
                            <div className="li-field status">
                                <p>{item.statusLabel}</p>
                            </div>
                        </div>
                    </div>
                );
            });

            if (list.length) {
                list = <div className="orders-list layout-body">{list}</div>;
            }
        }

        return (
            <div className="license-orders">
                <div className="layout-title">
                    <p>{this.props.title}</p>
                </div>
                {message}
                {list}
            </div>
        );
    }

    handleScroll(e) {
        // для бесконечной подгрузки надо понимать, когда низ экрана ниже чем низ списка операций
        const windowTopPos =
            window.pageYOffset || document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const container = document.querySelector(".orders-list");
        const containerRect = container.getBoundingClientRect();
        const containerTopPos = containerRect.top + windowTopPos;
        const containerHeight = container.offsetHeight;

        if (windowTopPos + windowHeight >= containerTopPos + containerHeight) {
            const itemsCount = container.querySelectorAll(".list-item").length;
            if (itemsCount < this.props.totalCount) {
                const handler = this.props.onOrdersScrollEdge || function () {};
                handler({ itemsCount });
            }
        }
    }
}
