import Container from '../container.jsx';
import MessagesPopup from '../../components/messages-popup.jsx';

export default class MessagesPopupContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.popupMessages');
    }

    render(){
        return <MessagesPopup {...this.state.state}
            handleMessagesPopupCloseButtonClick={this.props.handleMessagesPopupCloseButtonClick || function(){}} />;
    };
}
