import Messages from '../components/forms/messages.jsx';

export default class MessagesPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.list) {
            return null;
        }

        return (
            <div className="messages-popup show">
                <div className="popup-container">
                    <div className="popup-content">
                        <Messages list={this.props.list} />
                    </div>
                    <button className="close-button" onClick={this.props.handleMessagesPopupCloseButtonClick || function(){}}></button>
                </div>
            </div>
        );
    }
}
