import PageHeader from "../../page-header.jsx";
import CompaniesHeader from "./companies-header.jsx";
import CompaniesList from "../../../containers/companies/companies-list.jsx";
import CompanyAddPopup from "../../../containers/companies/company-add-popup.jsx";

export default class CompaniesLayout extends React.Component {
    constructor(props) {
        super(props);
        document.body.classList.add("dashboard");
        document.body.classList.add("accent-page");
    }

    componentWillUnmount() {
        document.body.classList.remove("accent-page");
    }

    render() {
        const companies = this.props.companies || {};

        let header;
        if (companies.header) {
            header = <CompaniesHeader {...companies.header} />;
        }

        let list;
        if (companies.list) {
            list = (
                <CompaniesList
                    list={companies.list}
                    handleItemClick={this.handleCompaniesItemClick.bind(this)}
                />
            );
        }

        let actions = [];
        if (companies.actions) {
            for (let i = 0; i < companies.actions.length; i++) {
                let action = companies.actions[i];
                actions.push(
                    <div key={i} className="companies-action">
                        <button
                            onClick={this.handleCompaniesActionClick.bind(
                                this,
                                action.name
                            )}
                        >
                            {action.label}
                        </button>
                    </div>
                );
            }
        }

        return (
            <div>
                <PageHeader
                    {...this.props.pageHeader}
                    menu={false}
                    searchForm={false}
                    onMenuItemClick={
                        this.props.handlePageHeaderMenuItemClick ||
                        function () {}
                    }
                    onUserbarClick={
                        this.props.handlePageHeaderUserbarClick ||
                        function () {}
                    }
                    onUserbarMenuButtonClick={
                        this.props.handlePageHeaderUserbarMenuButtonClick ||
                        function () {}
                    }
                    onSearchSubmit={
                        this.props.handlePageHeaderSearchSubmit ||
                        function () {}
                    }
                    onFeedbackBarMenuButtonClick={
                        this.props.handlePageHeaderFeedbackBarMenuButtonClick
                    }
                />

                <div className="page-limiter">
                    <div className="companies-layout">
                        {header}
                        {list}
                        {actions}
                    </div>
                </div>
                <CompanyAddPopup
                    className="add-company-popup"
                    {...this.props.companyAddPopup}
                    handleCloseButtonClick={this.handleCompanyAddPopupClose.bind(
                        this
                    )}
                    handleSubmit={this.handleCompanyAddPopupSubmit.bind(this)}
                />
            </div>
        );
    }

    handleCompaniesItemClick(e, index, item) {
        const handler = this.props.handleCompaniesItemClick || function () {};
        handler(e, index, item);
    }

    handleCompaniesActionClick(name, e) {
        const handler = this.props.handleCompaniesActionClick || function () {};
        handler(e, name);
    }

    handleCompanyAddPopupClose(e) {
        const handler = this.props.handleCompanyAddPopupClose || function () {};
        handler(e, name);
    }

    handleCompanyAddPopupSubmit(e, form) {
        const handler =
            this.props.handleCompanyAddPopupSubmit || function () {};
        handler(e, form);
    }
}
