import Graph from "./graph.jsx";

export default class Graphs extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let graphs = [];

        if (this.props.graphs) {
            for (let i = 0; i < this.props.graphs.length; i++) {
                graphs.push(
                    <Graph
                        {...this.props.graphs[i]}
                        key={i}
                        onShowAllButtonClick={this.props.onShowAllButtonClick}
                        onMarkerClick={this.props.onMarkerClick}
                    />
                );
            }
        }

        return <div className="analytics-graphs">{graphs}</div>;
    }
}
