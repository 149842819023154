import * as Store from '../store.js';

export function updateChecklist(fieldname, activeValue, activeLabel) {
    Store.setStore(
        getUpdatedChecklist(
            Store.getStore(fieldname),
            activeValue,
            activeLabel
        ),
        fieldname
    );
}

export function getUpdatedChecklist(list, activeValue, activeLabel) {
    if (!list || !list.length) {
        return list;
    }

    for(let i=0; i<list.length; i++) {
        if (list[i].value == activeValue) {
            list[i].active = true;
            if (activeLabel) {
                list[i].label = activeLabel;
            }
        } else {
            list[i].active = false;
        }
    }
    return list;
}

export function toggleChecklist(fieldname, activeValue) {
    Store.setStore(
        getToggledChecklist(
            Store.getStore(fieldname),
            activeValue
        ),
        fieldname
    );
}

export function resetChecklist(fieldname) {
    Store.setStore(
        getResetChecklist(
            Store.getStore(fieldname)
        ),
        fieldname
    );
}

export function getToggledChecklist(list, activeValue) {
    if (!list || !list.length) {
        return list;
    }

    for(let i=0; i<list.length; i++) {
        if (list[i].value == activeValue) {
            list[i].active = !list[i].active;
        }
    }
    return list;
}

export function getResetChecklist(list, fieldname) {
    if (!list || !list.length) {
        return list;
    }

    for(let i=0; i<list.length; i++) {
        list[i].active = false;
    }
    return list;
}

export function getFieldPath(fieldsPath, name) {
    const fields = Store.getStore(fieldsPath);

    if (!fields || !fields.length) {
        return null;
    }

    for(let f=0; f<fields.length; f++) {
        let field = fields[f];
        if (field.name == name) {
            return fieldsPath + '.' + f;
        }
    }
}
