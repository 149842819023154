export function stretchLastPageElement(element) {
    if (!element) {
        return null;
    }

    var rect = element.getBoundingClientRect(),
        windowScroll = window.pageYOffset || document.documentElement.scrollTop,
        windowHeight = document.documentElement.clientHeight;

    if (windowScroll <= 0) {
        var style = window.getComputedStyle(element, null),
            padding =
                parseInt(style.getPropertyValue("padding-top")) +
                parseInt(style.getPropertyValue("padding-bottom"));
        var minHeight = windowHeight - rect.top - padding - 24;
        element.style.minHeight = minHeight + "px";
    }
}

// https://stackoverflow.com/questions/49974145/how-to-convert-rgba-to-hex-color-code-using-javascript#:~:text=Since%20the%20alpha%20channel%20in,%2Fg%2C%20'').
export function rgba2hex(rgbaString, addAlpha) {
    addAlpha = addAlpha || false;

    var a,
        isPercent,
        rgb = rgbaString
            .replace(/\s/g, "")
            .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
        alpha = ((rgb && rgb[4]) || "").trim(),
        hex = rgb
            ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
              (rgb[2] | (1 << 8)).toString(16).slice(1) +
              (rgb[3] | (1 << 8)).toString(16).slice(1)
            : rgbaString;

    if (!addAlpha) {
        return "#" + hex;
    }

    if (alpha !== "") {
        a = alpha;
    } else {
        a = 1;
    }
    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;

    return "#" + hex;
}

export function getCaretPosition(elem) {
    // Initialize
    var iCaretPos = 0;

    // IE Support
    if (document.selection) {
        // Set focus on the element
        elem.focus();

        // To get cursor position, get empty selection range
        var oSel = document.selection.createRange();

        // Move selection start to 0 position
        oSel.moveStart("character", -elem.value.length);

        // The caret position is selection length
        iCaretPos = oSel.text.length;
    }

    // Firefox support
    else if (elem.selectionStart || elem.selectionStart == "0")
        iCaretPos =
            elem.selectionDirection == "backward"
                ? elem.selectionStart
                : elem.selectionEnd;

    // Return results
    return iCaretPos;
}

export function setCaretPosition(elem, caretPos) {
    if (elem != null) {
        if (elem.createTextRange) {
            var range = elem.createTextRange();
            range.move("character", caretPos);
            range.select();
        } else {
            if (elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            } else elem.focus();
        }
    }
}
