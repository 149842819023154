import Container from '../container.jsx';
import TransactionsLayout from '../../components/layouts/transactions/transactions.jsx';

export default class TransactionsLayoutContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.companyId');
    }

    render(){
        return <TransactionsLayout
            {...this.props}
            companyId={this.state.state} />;
    };
}
