import Container from '../container.jsx';
import Sidebar from '../../components/layouts/cabinet/sidebar.jsx';

export default class SidebarContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.cabinetSidebar');
    }

    render(){
        return <Sidebar
            {...this.props}
            {...this.state.state} />;
    };
}
