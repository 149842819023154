export default class CompanyTabItemCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let fields = [];

        if (this.props.fields) {
            for (var i = 0; i < this.props.fields.length; i++) {
                let field = this.props.fields[i];
                fields.push(
                    <div key={i} className="card-field">
                        <p className="label">{field.label}</p>
                        <p className="value">{field.value}</p>
                    </div>
                );
            }
        }

        let actions = [];
        if (this.props.actions) {
            for (let i = 0; i < this.props.actions.length; i++) {
                let action = this.props.actions[i];
                actions.push(
                    <button
                        key={i}
                        className="edit-action"
                        title={action.label}
                        onClick={this.handleActionClick.bind(this, action)}
                    ></button>
                );
            }
        }

        let backButton = null;
        if (this.props.backLabel) {
            backButton = (
                <div className="back-link-container">
                    <button
                        className="back-link"
                        onClick={this.handleBackLinkClick.bind(this)}
                    >
                        {String.fromCharCode(8592)} {this.props.backLabel}
                    </button>
                </div>
            );
        }

        return (
            <div className="company-tab-item-card">
                {backButton}
                <div className="card-label-container">
                    <p className="card-label">{this.props.label}</p>
                    {actions}
                </div>
                {fields}
            </div>
        );
    }

    handleActionClick(action, e) {
        const handler = this.props.onActionClick || function () {};
        handler(action.name, this.props.id);
    }

    handleBackLinkClick(e) {
        const handler = this.props.onBackLinkClick || function () {};
        handler();
    }
}
