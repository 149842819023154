import * as Store from "../store.js";
import * as Repository from "../../components/repositories/repository.js";
import * as AuthRepository from "../../components/repositories/auth-repository.js";
import * as Auth from "../../components/auth.js";
import * as AuthHandlers from "./auth-handlers.js";
import * as CompanyHandlers from "./company-handlers.js";
import * as CompanyAboutHandlers from "./company-about-handlers.js";
import * as CompanyDictionaryHandlers from "./company-dictionary-handlers.js";
import * as CabinetHandlers from "./cabinet-handlers.js";
import * as InvestmentsHandlers from "./investments-handlers.js";
import * as TransactionsHandlers from "./transactions-handlers.js";
import * as AnalyticsHandlers from "./analytics-handlers.js";
import * as TargetsHandlers from "./targets-handlers.js";
import * as LayoutHandlers from "./layout-handlers.js";
import * as BankIntegrationHandlers from "./bank-integration-handlers.js";

export function loadUrlLayout(url) {
    Auth.checkAuthorization()
        .then((response) => {
            // проверяем статус и создаем приложение если пользователь новый
            AuthHandlers.handleUserStatus()
                .then(() => {
                    return parseUrlLayout(url);
                })
                .catch((error) => {
                    Auth.logout();
                    LayoutHandlers.loadErrorLayout(error);
                });
        })
        .catch((error) => {
            const layout = getUrlLayout(url);
            let activeForm;
            switch (layout) {
                case "registration":
                    activeForm = "registration";
                    break;
            }
            AuthRepository.getLayout()
                .then((response) => {
                    response.data.activeForm = activeForm;
                    Store.setStore(response);
                })
                .catch(() => {
                    LayoutHandlers.loadErrorLayout(error);
                });
        });
}

/* HELPERS */

function parseUrlLayout(url) {
    const handler = new URL(url);
    const path = handler.pathname;
    let id = handler.searchParams.get("id");
    const parts = getPathParts(path);
    const layout = parts[0];

    switch (layout) {
        case "company":
            if (id) {
                const subsection = parts[1];
                switch (subsection) {
                    case "about":
                        return CompanyAboutHandlers.loadLayout({
                            companyId: id,
                            tab: "about",
                        });
                    case "dictionary":
                        return CompanyDictionaryHandlers.loadLayout({
                            companyId: id,
                            tab: parts[2],
                        });
                    default:
                        return CompanyHandlers.loadCompanyLayout(id);
                }
            }
            break;
        case "cabinet":
            let menu = parts[1];
            if (menu) {
                switch (menu) {
                    case "company":
                        const id = parts[2];
                        const tabName = parts[3];
                        return CabinetHandlers.loadLayout().then(() => {
                            return CabinetHandlers.loadCompany(id, tabName);
                        });
                    default:
                        return CabinetHandlers.loadLayout().then(() => {
                            return CabinetHandlers.loadMenuLayout(menu);
                        });
                }
            }
            return CabinetHandlers.loadLayout();
        case "investments":
            return InvestmentsHandlers.loadLayout();
        case "transactions":
            return TransactionsHandlers.loadLayout(id);
        case "analytics":
            return AnalyticsHandlers.loadLayout(id);
        case "targets":
            return TargetsHandlers.loadLayout({
                companyId: id,
            });
        case "bank-integration":
            let params = {
                integrationName: parts[2],
            };
            for (const entry of handler.searchParams.entries()) {
                params[entry[0]] = entry[1];
            }
            BankIntegrationHandlers.authorize(params);
            break;
    }

    return LayoutHandlers.loadHomeLayout();
}

function getPathParts(path) {
    return path.replace(/^\/+|\/+$/g, "").split("/");
}

function getUrlLayout(url) {
    const handler = new URL(url);
    const path = handler.pathname;
    const parts = getPathParts(path);
    return parts[0];
}
