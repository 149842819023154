import AuthForm from "../../../containers/auth/auth-form.jsx";
import { isMobile } from "../../../helpers/layout-helper.js";

export default class AuthLayout extends React.Component {
    constructor(props) {
        super(props);

        document.body.classList.remove("dashboard");
        document.body.classList.add("accent-page");

        this.containerRef = React.createRef();

        this.state = {
            activeForm: props.activeForm || "auth",
        };
    }

    componentDidMount() {
        this.positionContainer();
    }

    render() {
        let form = [];

        switch (this.state.activeForm) {
            case "restore":
                form.push(
                    <AuthForm
                        key="restore-form"
                        {...this.props.restoreForm}
                        name="restore"
                        onSubmit={this.handleAuthFormSubmit.bind(
                            this,
                            this.state.activeForm
                        )}
                    />
                );
                form.push(
                    <div className="auth-links" key="links">
                        <div className="auth-link">
                            <button
                                onClick={this.handleAuthLinkClick.bind(this)}
                            >
                                {this.props.authButton.label}
                            </button>
                        </div>
                        <div className="auth-link">
                            <button
                                onClick={this.handleRegistrationLinkClick.bind(
                                    this
                                )}
                            >
                                {this.props.registrationButton.label}
                            </button>
                        </div>
                    </div>
                );
                break;
            case "registration":
                form.push(
                    <AuthForm
                        key="reg-form"
                        {...this.props.registrationForm}
                        name="registration"
                        onSubmit={this.handleAuthFormSubmit.bind(
                            this,
                            this.state.activeForm
                        )}
                        onFieldClick={this.handleAuthFormFieldClick.bind(
                            this,
                            this.state.activeForm
                        )}
                    />
                );
                form.push(
                    <div className="auth-links" key="links">
                        <div className="auth-link">
                            <button
                                onClick={this.handleAuthLinkClick.bind(this)}
                            >
                                {this.props.authButton.label}
                            </button>
                        </div>
                        <div className="auth-link">
                            <button
                                onClick={this.handleRestoreLinkClick.bind(this)}
                            >
                                {this.props.restoreButton.label}
                            </button>
                        </div>
                    </div>
                );
                break;
            default:
                form.push(
                    <AuthForm
                        key="auth-form"
                        {...this.props.authForm}
                        name="auth"
                        onSubmit={this.handleAuthFormSubmit.bind(
                            this,
                            this.state.activeForm
                        )}
                    />
                );
                form.push(
                    <div className="auth-links" key="links">
                        <div className="auth-link">
                            <button
                                onClick={this.handleRestoreLinkClick.bind(this)}
                            >
                                {this.props.restoreButton.label}
                            </button>
                        </div>
                        <div className="auth-link">
                            <button
                                onClick={this.handleRegistrationLinkClick.bind(
                                    this
                                )}
                            >
                                {this.props.registrationButton.label}
                            </button>
                        </div>
                    </div>
                );
                break;
        }

        return (
            <div className="auth-float-container" ref={this.containerRef}>
                <div className="auth-logo"></div>
                {form}
            </div>
        );
    }

    handleAuthFormSubmit(formName, e, form) {
        let handler;
        switch (formName) {
            case "restore":
                handler = this.props.handleRestoreFormSubmit || function () {};
                break;
            case "registration":
                handler =
                    this.props.handleRegistrationFormSubmit || function () {};
                break;
            default:
                handler = this.props.handleAuthFormSubmit || function () {};
                break;
        }
        handler(e, form);
    }

    handleAuthFormFieldClick(formName, e, field, element, params) {}

    handleAuthLinkClick(e) {
        this.setState(
            {
                activeForm: "auth",
            },
            () => {
                this.positionContainer();
            }
        );
    }

    handleRestoreLinkClick(e) {
        this.setState(
            {
                activeForm: "restore",
            },
            () => {
                this.positionContainer();
            }
        );
    }

    handleRegistrationLinkClick(e) {
        this.setState(
            {
                activeForm: "registration",
            },
            () => {
                this.positionContainer();
            }
        );
    }

    positionContainer() {
        if (isMobile(590)) {
            return null;
        }

        const height = this.containerRef.current.offsetHeight;
        const screenHeight = window.innerHeight;

        if (screenHeight > height) {
            this.containerRef.current.classList.remove("unfloat");
            this.containerRef.current.style.marginTop = "-" + height / 2 + "px";
        } else {
            this.containerRef.current.classList.add("unfloat");
        }
    }
}
