import Container from '../container.jsx';
import OperationAddPopup from '../../components/layouts/transactions/operation-add-popup.jsx';

export default class OperationAddPopupContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.operationAddPopup');
    }

    render(){
        if (!this.state.state) {
            return null;
        }

        return <OperationAddPopup {...this.props} {...this.state.state}/>;
    };
}
