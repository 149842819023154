import * as CompaniesHandlers from "../handlers/companies-handlers.js";
import * as CompanyHandlers from "../handlers/company-handlers.js";
import * as AuthHandlers from "../handlers/auth-handlers.js";
import * as CabinetHandlers from "../handlers/cabinet-handlers.js";
import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as FormHandlers from "../handlers/form-handlers.js";
import * as OperationsHandlers from "../handlers/operations-handlers.js";
import * as TransactionsHandlers from "../handlers/transactions-handlers.js";

export function handleSidebarMenuItemClick(e, name) {
    return handleCabinetMenuItemClick(e, name, {
        loadDefaultCompany: true,
        defaultCompanyTabName: "about",
    });
}

export function handleCabinetMenuItemClick(e, name, params) {
    switch (name) {
        case "logout":
            return AuthHandlers.logout();
        default:
            return CabinetHandlers.loadMenuLayout(name, params).then(() => {
                CabinetHandlers.setMenuHistoryState(name);
            });
    }
}

export function handleCompaniesActionClick(e, name) {
    switch (name) {
        case "add":
            // CompaniesHandlers.loadCompanyAddPopup();
            return CabinetHandlers.loadAddCompanyLayout();
    }
}

export function handleCompanyClick(e, item, tabName) {
    return CabinetHandlers.loadCompany(item.id || item.item.id, tabName).then(
        (response) => {
            return response;
            // CabinetHandlers.setCompanyHistoryState(
            //     item.id || item.item.id,
            //     tabName
            // );
        }
    );
}

export function handleActiveCompanyClick(e, params) {
    return LayoutHandlers.loadLayoutByName("home", {
        pushHistoryState: true,
    });
}

export function handleCompanyAddPopupClose(e) {
    return CompaniesHandlers.closeCompanyAddPopup();
}

export function handleCompanyAddPopupSubmit(e, form) {
    e.preventDefault();
    return CompaniesHandlers.addCompanyForm(form);
}

export function handleAddCompanySubmit(e, form) {
    e.preventDefault();
    return CabinetHandlers.addCompany(form).then(() => {
        CabinetHandlers.refreshSidebarCompanies();
    });
}

export function handleCompanyCaptionFormSubmit(e, form, companyId, tabName) {
    e.preventDefault();
    return CabinetHandlers.saveCompany(form).then(() => {
        CabinetHandlers.refreshSidebarAndCompany(companyId, tabName);
    });
}

export function handleCompanyDeleteFormSubmit(e, form) {
    e.preventDefault();
    return CabinetHandlers.deleteCompany(form).then(() => {
        CabinetHandlers.refreshSidebarCompanies();
        CabinetHandlers.clearCompany();
    });
}

export function handleCompanyTabClick(companyId, tabName) {
    return CabinetHandlers.loadCompany(companyId, tabName).then(() => {
        CabinetHandlers.setCompanyHistoryState(companyId, tabName);
    });
}

export function handleCompanyTabActionClick(companyId, tabName, actionName) {
    switch (actionName) {
        case "add":
            return CabinetHandlers.loadCompanyTabAddForm(companyId, tabName);
        case "bank-integration":
            return CabinetHandlers.loadBankIntegrationForm(companyId);
    }
}

export function handleCompanyTabFormActionClick(e, action) {
    switch (action.name) {
        case "cancel":
            return CabinetHandlers.closeCompanyTabForm();
    }
}

export function handleCompanyTabFormCancelButtonClick() {
    CabinetHandlers.closeCompanyTabForm();
}

export function handleCompanyTabFormAlterActionButtonClick(e, name, data) {
    switch (name) {
        case "select-user":
            return CabinetHandlers.loadCompanyTabAddForm(data.companyId, name);
    }
}

export function handleCompanyTabFormSubmit(e, form, companyId, tabName) {
    e.preventDefault();
    CabinetHandlers.submitCompanyTabForm(form, companyId, tabName).then(
        (response) => {
            CompanyHandlers.refreshSidebarAndHeader(companyId);
            if (response) {
                if (needToLoadLastForm(tabName)) {
                    return loadLastForm(
                        companyId,
                        tabName,
                        response.id || response.ids
                    );
                }
            }
            return response;
        }
    );
}

export function handleCompanyTabFormFieldChange(e, props, field, form) {
    FormHandlers.handleFieldChange(
        e,
        props,
        field,
        form,
        "data.cabinetCompany.tabForm.form"
    );
}

export function handleCompanyTabFormFieldBlur(e, props, field, form) {
    FormHandlers.handleFieldBlur(
        e,
        props,
        field,
        form,
        "data.cabinetCompany.tabForm.form"
    );
}

export function handleCompanyTabEditActionClick(
    companyId,
    tabName,
    actionName,
    itemId
) {
    switch (actionName) {
        case "back-to-list":
            if (needToLoadLastForm(tabName)) {
                return loadLastForm(companyId);
            }
            return CabinetHandlers.refreshCompanyTab(companyId, tabName);
        default:
            return CabinetHandlers.loadTabEditActionForm(
                companyId,
                tabName,
                actionName,
                itemId
            );
    }
}

export function handleCompanyTabConfirmDeletePopupSubmit(
    e,
    form,
    companyId,
    tabName
) {
    e.preventDefault();
    CabinetHandlers.submitCompanyTabForm(form, companyId, tabName);
}

export function handleCompanyTabConfirmPopupSubmit(
    e,
    form,
    companyId,
    tabName
) {
    e.preventDefault();
    CabinetHandlers.submitCompanyTabForm(form, companyId, tabName);
}

export function handleCompanyTabConfirmPopupClose(e) {
    e.preventDefault();
    CabinetHandlers.closeCompanyTabConfirmForm();
}

export function handleCompanyEditActionClick(e, name, companyId) {
    switch (name) {
        case "edit-requisites":
            return CabinetHandlers.loadEditForm(companyId);
    }
}

export function handleCompanyActionClick(e, companyId, action) {
    switch (action.name) {
        case "edit":
            return CabinetHandlers.loadEditForm(companyId);
    }
}

export function handleCompanyTabListItemClick(companyId, tabName, itemId) {
    CabinetHandlers.loadTabListItemCard(companyId, tabName, itemId);
}

export function handleSettingsSubmit(e, form) {
    e.preventDefault();
    CabinetHandlers.submitSettingsForm(form);
}

export function handleFeedbackSubmit(e, form) {
    e.preventDefault();
    CabinetHandlers.submitFeedbackForm(form);
}

export function handleOrdersScrollEdge(params) {
    CabinetHandlers.loadMoreOrders(params);
}

export function handleActionClick(e, action) {
    switch (action.name) {
        case "license":
            return LayoutHandlers.loadLayoutByName("cabinet", {
                pushHistoryState: true,
            }).then((response) => {
                return CabinetHandlers.loadMenuLayout("license");
            });
    }
}

export function handleBankIntegrationPopupSubmit(e, form, companyId) {
    e.preventDefault();
    return CabinetHandlers.submitBankIntegrationPopup(form, companyId);
}

export function handleBankIntegrationPopupClose(e) {
    return CabinetHandlers.closeBankIntegrationPopup();
}

export function handleRemoveBankIntegrationPopupSubmit(e, form, companyId) {
    e.preventDefault();
    return CabinetHandlers.submitRemoveBankIntegrationPopup(form, companyId);
}

/* HELPERS */

function needToLoadLastForm(tabName) {
    return (
        ["accounts", "categories", "partners"].indexOf(tabName) > -1 &&
        OperationsHandlers.hasUnfinishedForm()
    );
}

function loadLastForm(companyId, tabName, itemId) {
    let values = {};

    // надо, чтобы подставилось только что добавленное значение
    switch (tabName) {
        case "accounts":
            values["account"] = itemId;
            break;
        case "categories":
            switch (OperationsHandlers.getLastFormName()) {
                case "income":
                    values["service"] = itemId["income"];
                    break;
                case "outcome":
                    values["service"] = itemId["outcome"];
                    break;
                case "edit":
                    values["service"] = Object.values(itemId)[0];
                    break;
            }
            break;
        case "partners":
            values["client"] = itemId;
            break;
    }

    switch (OperationsHandlers.getLastFormLayout()) {
        case "company":
            if (OperationsHandlers.getLastFormName() === 'edit') {
                return CompanyHandlers.loadCompanyLayout(companyId).then(() => {
                    OperationsHandlers.loadOperationEditPopup(
                        { id: OperationsHandlers.getLastFormTransactionId() },
                        OperationsHandlers.getLastFormName(),
                        values
                    ).then(() => {
                        OperationsHandlers.resetFormValues();
                    });
                });
            } else {
                return CompanyHandlers.loadCompanyLayout(companyId).then(() => {
                    OperationsHandlers.loadOperationAddPopup(
                        { companyId: companyId },
                        OperationsHandlers.getLastFormName(),
                        values
                    ).then(() => {
                        OperationsHandlers.resetFormValues();
                    });
                });
            }
        case "transactions":
            if (OperationsHandlers.getLastFormName() === 'edit') {
                return TransactionsHandlers.loadLayout(companyId).then(() => {
                    OperationsHandlers.loadOperationEditPopup(
                        { id: OperationsHandlers.getLastFormTransactionId() },
                        OperationsHandlers.getLastFormName(),
                        values
                    ).then(() => {
                        OperationsHandlers.resetFormValues();
                    });
                });
            } else {
                return TransactionsHandlers.loadLayout(companyId).then(() => {
                    OperationsHandlers.loadOperationAddPopup(
                        { companyId: companyId },
                        OperationsHandlers.getLastFormName(),
                        values
                    ).then(() => {
                        OperationsHandlers.resetFormValues();
                    });
                });
            }
    }
}
