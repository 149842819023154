const RequisitesValidator = function (input, rule) {
    if (!input || typeof input == "undefined") {
        return input;
    }

    input = input.replace(/\D/g, "");

    const maxValues = {
        inn: 12,
        kpp: 9,
        ogrn: 15,
        bank_account_number: 20,
        bik: 9,
    };

    const maxValue = maxValues[rule];
    if (!maxValue) {
        return input;
    }

    return input.substring(0, maxValue);
};

export default RequisitesValidator;
