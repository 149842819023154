import Container from "../container.jsx";
import LicenseOrders from "../../components/layouts/cabinet/license-orders.jsx";

export default class LicenseOrdersContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.cabinetLicenseOrders");
    }

    render() {
        return <LicenseOrders {...this.props} {...this.state.state} />;
    }
}
