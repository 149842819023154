export default class Files extends React.Component {
    constructor(props) {
        super(props);
        this.files = [];

        this.state = {
            files: []
        };
    }

    render() {
        const multiple = this.isMultiple();

        let files = [];

        if (this.state.files.length) {
            for(let i=0; i<this.state.files.length; i++) {
                let item = this.state.files[i];
                files.push(
                    <div className="files-list-item" key={i}>
                        <p className="list-item-col icon">
                            {item.ext}
                        </p>
                        <p className="list-item-col caption">
                            {item.name}
                        </p>
                        <div className="list-item-col remove">
                            <button type="button" onClick={this.handleFileItemRemoveButtonClick.bind(this, i)}>{String.fromCharCode(215)}</button>
                        </div>
                    </div>
                );
            }
        }

        return <div className="files-loader-container">
            <div className="files-loader-input-container">
                <p className="input-label-area">{this.props.label}</p>
                <input type="file"
                    name={this.getFieldName()}
                    multiple={!(multiple === false)}
                    id={this.props.id}
                    onChange={this.handleChange.bind(this)} />
            </div>
            <div className="files-loader-list">
                {files}
            </div>
        </div>;
    }

    handleChange(e) {
        const handler = this.props.onChange || function(){};
        handler(e);

        if (e.target.files) {
            const inputFiles = Array.from(e.target.files);
            if (this.isMultiple()) {
                this.files = this.files.concat(inputFiles);
            } else {
                this.files = inputFiles;
            }
            this.updateFilesState();
            e.target.value = ''; // чтоб можно было новые выбрать
        }
    }

    handleFileItemRemoveButtonClick(index, e) {
        this.files.splice(index, 1);
        this.updateFilesState();
    }

    isMultiple() {
        // насколько я понимаю по умолчанию у нас multiple
        if (this.props.multiple === false) {
            return false;
        }

        if (this.props.options && (this.props.options.multiple === false)) {
            return false;
        }

        return true;
    }

    updateFilesState() {
        let filesState = [];

        if (this.files.length) {
            for(let i=0; i<this.files.length; i++) {
                filesState.push(this.getFileStateItem(this.files[i]));
            }
        }

        this.setState({
            files: filesState
        });
    }

    getFileStateItem(file) {
        let item = {
            name: file.name,
            ext: file.name.split('.').pop()
        };

        return item;
    }

    getFieldName() {
        let name = this.props.name;
        if (name.indexOf('[]') === (name.length-2)) {
            name = name.slice(0,-2);
        }
        return name + (this.isMultiple() ? '[]' : '')
    }

    prepareFormData(formData) {
        formData.delete(this.props.name);
        formData.delete(this.props.name + '[]');

        let name = this.getFieldName();

        if (this.files.length) {
            for(let i=0; i<this.files.length; i++) {
                formData.append(name, this.files[i]);
            }
        }

        return formData;
    }
}
