import FormPopup from '../../form-popup.jsx';

export default class OperationAddPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTypePopup: false,
            showIncomePopup: false,
            showOutcomePopup: false,
            showUploadPopup: false
        };

        if (this.props.popupName) {
            switch(this.props.popupName) {
                case 'income':
                    this.state.showIncomePopup = true;
                    break;
                case 'outcome':
                    this.state.showOutcomePopup = true;
                    break;
                case 'upload':
                    this.state.showUploadPopup = true;
                    break;
                default:
                    this.state.showTypePopup = true;
                    break;
            }
        } else {
            this.state.showTypePopup = true;
        }
    }

    render() {
        return (
            <div>
                <FormPopup
                    className="add-operation-popup"
                    {...this.props.typePopup}
                    show={this.state.showTypePopup}
                    handleCloseButtonClick={this.props.handleClosePopup || function(){}}
                    handleFieldClick={this.handleTypeButtonClick.bind(this)} />

                <FormPopup
                    className="add-income-popup"
                    {...this.props.incomePopup}
                    show={this.state.showIncomePopup}
                    handleCloseButtonClick={this.props.handleClosePopup || function(){}}
                    handleSubmit={this.props.handleSubmit || function(){}}
                    handleFieldClick={this.props.handleFieldClick || function(){}} />

                <FormPopup
                    className="add-outcome-popup"
                    {...this.props.outcomePopup}
                    show={this.state.showOutcomePopup}
                    handleCloseButtonClick={this.props.handleClosePopup || function(){}}
                    handleSubmit={this.props.handleSubmit || function(){}}
                    handleFieldClick={this.props.handleFieldClick || function(){}} />

                <FormPopup
                    className="upload-popup"
                    {...this.props.uploadPopup}
                    show={this.state.showUploadPopup}
                    handleCloseButtonClick={this.props.handleClosePopup || function(){}}
                    handleSubmit={this.props.handleSubmit || function(){}}
                    handleFieldClick={this.props.handleFieldClick || function(){}} />
            </div>
        );
    }

    handleTypeButtonClick(e, field, form) {
        switch(field.props.name) {
            case 'income':
                this.setState({
                    showTypePopup: false,
                    showIncomePopup: true,
                    showOutcomePopup: false,
                    showUploadPopup: false
                });
                break;
            case 'outcome':
                this.setState({
                    showTypePopup: false,
                    showIncomePopup: false,
                    showOutcomePopup: true,
                    showUploadPopup: false
                });
                break;
            case 'upload':
                this.setState({
                    showTypePopup: false,
                    showIncomePopup: false,
                    showOutcomePopup: false,
                    showUploadPopup: true
                });
                break;
        }
    }
}
