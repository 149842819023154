const MoneyFilter = function (input) {
    let value = input.replace(/[^0-9.,]/g, "");
    value = value.replace(",", ".");
    if (value.indexOf(".") <= -1) {
        return value;
    }
    let parts = value.split(".");
    let intValue = parts.shift(),
        floatValue = parts.join("");

    return intValue + "." + floatValue.substring(0, 2);
};

export default MoneyFilter;
