import * as Store from "../store.js";
import * as CabinetRepository from "../../components/repositories/cabinet-repository.js";
import * as BankIntegrationRepository from "../../components/repositories/bank-integration-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse, submitForm } from "../helpers/request-helper.js";
import { getNestedValue } from "../../components/helpers/objects-helper.js";

let loading = false;

export function loadLayout(name, params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(CabinetRepository.getLayout(), (response) => {
        if (!response) {
            return false;
        }
        Store.setStore(response);
        if (name) {
            return loadMenuLayout(name, params);
        }
        return true;
    });
}

export function loadMenuLayout(name, params) {
    params = params || {};
    switch (name) {
        case "companies":
            if (params.companyId || params.loadDefaultCompany) {
                if (!params.companyId && params.loadDefaultCompany) {
                    if (!Store.getStore("data.cabinetCompany.companyId")) {
                        // компания не загрузилась - пробуем загрузить первую попавшуюся
                        const companies = Store.getStore(
                            "data.cabinetSidebar.companies.list"
                        );
                        if (companies && companies.length) {
                            params.companyId = companies[0].id;
                        }
                    }
                }
                if (params.companyId) {
                    return loadCompany(
                        params.companyId,
                        params.defaultCompanyTabName
                    ).then(() => {
                        return setCompanyHistoryState(
                            params.companyId,
                            params.defaultCompanyTabName
                        );
                    });
                }
            } else {
                setLayout("companies");
            }
            return Promise.resolve();
        case "settings":
            return loadSettingsLayout();
        case "feedback":
            return loadFeedbackLayout();
        case "license":
            return loadLicenseLayout();
    }
    return Promise.resolve();
}

export function loadCompany(id, tabName) {
    if (!id) {
        debugMessage("company id is undefined", id);
        return null;
    }

    Store.setStore(true, "data.loading.show");
    // Store.setStore(null, "data.cabinetCompany");

    return handleResponse(
        CabinetRepository.getCompany(id, tabName),
        (response) => {
            if (!response || !response.cabinetCompany) {
                return false;
            }
            setLayout("company");
            Store.setStore(response.cabinetCompany, "data.cabinetCompany");
            Store.setStore(tabName, "data.cabinetCompany.activeTab");
            Store.setStore(id, "data.cabinetSidebar.companies.activeCompany");
            Store.setStore(id, "data.companySidebar.activeId");
            LayoutHandlers.handlePopupErrors(response.cabinetCompany);
            return true;
        }
    );
}

export function loadAddCompanyLayout() {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        CabinetRepository.getAddCompanyLayout(),
        (response) => {
            if (!response || !response.cabinetAddCompanyLayout) {
                return false;
            }
            Store.setStore(
                response.cabinetAddCompanyLayout,
                "data.cabinetAddCompanyLayout"
            );
            setLayout("add-company");
            return true;
        }
    );
}

export function loadCompanyTabAddForm(companyId, tabName) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        CabinetRepository.getCompanyTabAddForm(companyId, tabName),
        (response) => {
            if (!response || !response.cabinetCompanyTabAddForm) {
                return false;
            }
            Store.setStore(
                response.cabinetCompanyTabAddForm,
                "data.cabinetCompany.tabForm"
            );
            return true;
        }
    );
}

export function loadTabEditActionForm(companyId, tabName, actionName, itemId) {
    Store.setStore(true, "data.loading.show");

    switch (actionName) {
        case "edit":
            return handleResponse(
                CabinetRepository.getCompanyTabEditForm(
                    companyId,
                    tabName,
                    itemId
                ),
                (response) => {
                    if (!response || !response.cabinetCompanyTabEditForm) {
                        return false;
                    }
                    Store.setStore(
                        response.cabinetCompanyTabEditForm,
                        "data.cabinetCompany.tabForm"
                    );
                    return true;
                }
            );
        case "delete":
            return handleResponse(
                CabinetRepository.getCompanyTabDeleteForm(
                    companyId,
                    tabName,
                    itemId
                ),
                (response) => {
                    if (!response || !response.cabinetCompanyTabDeleteForm) {
                        return false;
                    }
                    Store.setStore(
                        response.cabinetCompanyTabDeleteForm,
                        "data.cabinetCompany.deleteForm"
                    );
                    return true;
                }
            );
        case "remove-integration":
            return handleResponse(
                CabinetRepository.getCompanyTabAccountRemoveIntegrationForm(
                    companyId,
                    tabName,
                    itemId
                ),
                (response) => {
                    if (
                        !response ||
                        !response.paymentAccountRemoveBankIntegration
                    ) {
                        return false;
                    }
                    Store.setStore(
                        response.paymentAccountRemoveBankIntegration,
                        "data.cabinetCompany.removeIntegrationForm"
                    );
                    return true;
                }
            );
        default:
            Store.setStore(false, "data.loading.show");
            return Promise.resolve();
    }
}

export function loadEditForm(companyId) {
    Store.setStore(true, "data.loading.show");
    return handleResponse(
        CabinetRepository.getCompanyEditForm(companyId),
        (response) => {
            if (!response || !response.cabinetCompanyEditForm) {
                return false;
            }
            Store.setStore(
                response.cabinetCompanyEditForm,
                "data.cabinetCompany.tabForm"
            );
            LayoutHandlers.setDialogHistoryState();
            return true;
        }
    );
}

export function loadTabListItemCard(companyId, tabName, itemId) {
    Store.setStore(true, "data.loading.show");
    return handleResponse(
        CabinetRepository.getCompanyTabListItemCard(companyId, tabName, itemId),
        (response) => {
            if (!response || !response.cabinetCompanyTabListItemCard) {
                return false;
            }
            Store.setStore(
                response.cabinetCompanyTabListItemCard,
                "data.cabinetCompany.listItemCard"
            );
            return true;
        }
    );
}

export function loadSettingsLayout() {
    Store.setStore(true, "data.loading.show");
    return handleResponse(CabinetRepository.getSettingsLayout(), (response) => {
        if (!response || !response.cabinetSettingsLayout) {
            return false;
        }
        Store.setStore(
            response.cabinetSettingsLayout,
            "data.cabinetSettingsLayout"
        );
        setLayout("settings");
        return true;
    });
}

export function loadFeedbackLayout() {
    Store.setStore(true, "data.loading.show");
    return handleResponse(CabinetRepository.getFeedbackLayout(), (response) => {
        if (!response || !response.cabinetFeedbackLayout) {
            return false;
        }
        Store.setStore(
            response.cabinetFeedbackLayout,
            "data.cabinetFeedbackLayout"
        );
        setLayout("feedback");
        return true;
    });
}

export function loadLicenseLayout() {
    Store.setStore(true, "data.loading.show");
    return handleResponse(CabinetRepository.getLicenseLayout(), (response) => {
        if (!response || !response.cabinetLicenseLayout) {
            return false;
        }
        Store.setStore(
            response.cabinetLicenseLayout,
            "data.cabinetLicenseLayout"
        );
        Store.setStore(
            response.cabinetLicenseOrders || {},
            "data.cabinetLicenseOrders"
        );
        setLayout("license");
        return true;
    });
}

export function loadBankIntegrationForm(companyId) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        CabinetRepository.getBankIntegrationForm({ company_id: companyId }),
        (response) => {
            if (!response || !response.paymentAccountAddBankIntegration) {
                return false;
            }
            Store.setStore(
                response.paymentAccountAddBankIntegration,
                "data.cabinetCompany.paymentAccountAddBankIntegration"
            );
            return true;
        }
    );
}

export function addCompany(form) {
    return submitForm(form).then((response) => {
        Store.setStore(response, "data.cabinetAddCompanyLayout");
    });
}

export function saveCompany(form) {
    return submitForm(form);
}

export function deleteCompany(form) {
    return submitForm(form).then((response) => {
        if (response.messages) {
            Store.setStore(
                {
                    layout: null,
                    messages: response.messages,
                },
                "data.cabinet"
            );
        }
        return response;
    });
}

export function submitCompanyTabForm(form, companyId, tabName) {
    return submitForm(form).then((response) => {
        // попросили убрать в DOG-10
        // LayoutHandlers.handlePopupMessages(response);

        if (response) {
            if (response.errors) {
                Store.setStore(
                    response.errors,
                    "data.cabinetCompany.tabForm.form.errors"
                );
            }

            if (response.success) {
                closeCompanyTabForm();
                if (response.confirmForm) {
                    Store.setStore(
                        response.confirmForm,
                        "data.cabinetCompany.confirmForm"
                    );
                } else {
                    refreshCompanyTab(companyId, tabName);
                }
            }
        }

        return response;
    });
}

export function closeCompanyTabConfirmForm() {
    Store.setStore(null, "data.cabinetCompany.confirmForm");
    return Promise.resolve();
}

export function refreshSidebarCompanies() {
    return handleResponse(CabinetRepository.getSidebar(), (response) => {
        const companies = getNestedValue(
            response,
            "cabinetSidebar",
            "companies"
        );
        if (!companies) {
            return false;
        }
        Store.setStore(companies, "data.cabinetSidebar.companies");
        return true;
    });
}

export function refreshSidebarAndCompany(companyId, tabName) {
    return handleResponse(
        CabinetRepository.getSidebarAndCompany(companyId, tabName),
        (response) => {
            const companies = getNestedValue(
                    response,
                    "cabinetSidebar",
                    "companies"
                ),
                company = getNestedValue(response, "cabinetCompany");
            if (companies) {
                Store.setStore(companies, "data.cabinetSidebar.companies");
            }
            if (company) {
                Store.setStore(company, "data.cabinetCompany");
            }
            return true;
        }
    );
}

export function refreshCompanyTab(companyId, tabName) {
    return loadCompany(companyId, tabName);
}

export function clearCompany() {
    Store.setStore(null, "data.cabinetCompany");
}

export function closeCompanyTabForm() {
    Store.setStore(null, "data.cabinetCompany.tabForm");
}

export function submitSettingsForm(form) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupMessages(response);
        return true;
    });
}

export function submitFeedbackForm(form) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupMessages(response);
        if (response.success) {
            // обнулим форму
            Store.setStore(
                Store.getStore("data.cabinetFeedbackLayout"),
                "data.cabinetFeedbackLayout"
            );
        }
        return true;
    });
}

export function loadMoreOrders(params) {
    params = params || {};

    if (loading) {
        return Promise.resolve();
    }

    loading = true;

    Store.setStore(true, "data.loading.show");

    return handleResponse(
        CabinetRepository.getLicenseOrders(params),
        (response) => {
            loading = false;
            if (!getNestedValue(response, "cabinetLicenseOrders", "list")) {
                return false;
            }
            Store.addList(
                response.cabinetLicenseOrders.list,
                "data.cabinetLicenseOrders.list"
            );
            return true;
        }
    );
}

export function submitBankIntegrationPopup(form, companyId) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupMessages(response);
        if (response.url) {
            location.href = response.url;
        }
        return true;
    });
}

export function closeBankIntegrationPopup(form, companyId) {
    Store.setStore(
        null,
        "data.cabinetCompany.paymentAccountAddBankIntegration"
    );
}

export function submitRemoveBankIntegrationPopup(form, companyId) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupMessages(response);
        if (response.success) {
            refreshCompanyTab(companyId, "accounts");
        }
        return true;
    });
}

/* HISTORY STATE */

export function setLayoutHistoryState(response) {
    const title = getBaseTitle(response);

    document.title = title;
    history.pushState({}, title, "/cabinet");
}

export function setCompanyHistoryState(companyId, tabName) {
    let title = getBaseTitle(),
        companyTitle = Store.getStore("data.cabinetCompany.title"),
        url = "/cabinet";

    if (companyTitle) {
        title += " > " + companyTitle;
    }

    if (companyId) {
        url += "/company/" + companyId;

        if (tabName) {
            url += "/" + tabName;
        }
    }

    document.title = title;
    history.pushState({}, title, url);
}

export function setMenuHistoryState(name) {
    let title = getBaseTitle(),
        url = "/cabinet/" + name,
        menu = Store.getStore("data.cabinetSidebar.menu");

    if (menu && menu.length) {
        for (let i = 0; i < menu.length; i++) {
            let item = menu[i];
            if (item.name == name) {
                title += " > " + item.caption;
                break;
            }
        }
    }

    document.title = title;
    history.pushState({}, title, url);
}

/* HELPERS */

function debugMessage(message, data) {
    if (Store.config.debug) {
        console.log(message, data);
    }
}

function setLayout(name) {
    Store.setStore(
        {
            layout: name,
            messages: null,
            errors: null,
        },
        "data.cabinet"
    );

    Store.setStore(null, "data.cabinetSidebar.companies.activeCompany");

    switch (name) {
        case "company":
        case "add-company":
            Store.setStore("companies", "data.cabinetSidebar.activeMenuName");
            break;
        default:
            Store.setStore(name, "data.cabinetSidebar.activeMenuName");
            break;
    }
}

function getBaseTitle(response) {
    let title = "Dogma";

    let userbar;

    if (response) {
        userbar = getNestedValue(response.data, "pageHeader", "userbar");
    }

    if (!userbar) {
        userbar = Store.getStore("data.pageHeader.userbar");
    }

    if (userbar && userbar.label) {
        title = userbar.label;
    }

    return title;
}
