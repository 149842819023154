import * as Store from "../store.js";
import * as BankIntegrationRepository from "../../components/repositories/bank-integration-repository.js";
import * as CompanyDictionaryHandlers from "./company-dictionary-handlers.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse } from "../helpers/request-helper.js";

export function authorize(params) {
    return handleResponse(
        BankIntegrationRepository.authorize(params),
        (response) => {
            console.log(response.bankIntegrationAuthorize);
            if (!response.bankIntegrationAuthorize) {
                return false;
            }

            if (response.bankIntegrationAuthorize.companyId) {
                return CompanyDictionaryHandlers.loadLayout({
                    companyId: response.bankIntegrationAuthorize.companyId,
                    tab: "accounts",
                }).then(() => {
                    LayoutHandlers.handlePopupErrors(
                        response.bankIntegrationAuthorize
                    );
                    LayoutHandlers.handlePopupMessages(
                        response.bankIntegrationAuthorize
                    );
                });
            }
            return true;
        }
    );
}
