import PageHeader from "../../page-header.jsx";
import CompaniesSidebar from "../companies/companies-sidebar.jsx";
import Filter from "../../../containers/analytics/filter.jsx";
import AnalyticsBar from "../../../containers/analytics/bar.jsx";
import Graphs from "../../../containers/analytics/graphs.jsx";
import { stretchLastPageElement } from "../../../../components/helpers/html-helper.js";
import { isMobile } from "../../../helpers/layout-helper.js";
import PrintMenuCompanyHeader from "../../print-menu-company-header.jsx";

export default class AnalyticsLayout extends React.Component {
    constructor(props) {
        super(props);
        document.body.classList.add("dashboard");
        this.filterRef = React.createRef();
        this.fieldChangeSubmitTimeout;
        this.fieldChangeSubmitTimeoutDelay = 500;
        this.graphItemsLimit = 5;
    }

    componentDidMount() {
        // отложим загрузку доп. скриптов напоследок
        const handler = this.props.loadChartHelpers || function () {};
        handler();
        stretchLastPageElement(document.querySelector(".analytics-plate"));
    }

    componentWillUnmount() {
        this.graphItemsLimit = 5;
    }

    componentDidUpdate() {
        stretchLastPageElement(document.querySelector(".analytics-plate"));
    }

    render() {
        return (
            <div>
                <PageHeader
                    {...this.props.pageHeader}
                    onMenuItemClick={
                        this.props.handlePageHeaderMenuItemClick ||
                        function () {}
                    }
                    onUserbarClick={
                        this.props.handlePageHeaderUserbarClick ||
                        function () {}
                    }
                    onLogoClick={
                        this.props.handlePageHeaderLogoClick || function () {}
                    }
                    onUserbarMenuButtonClick={
                        this.props.handlePageHeaderUserbarMenuButtonClick ||
                        function () {}
                    }
                    onSearchSubmit={
                        this.props.handlePageHeaderSearchSubmit ||
                        function () {}
                    }
                    onFeedbackBarMenuButtonClick={
                        this.props.handlePageHeaderFeedbackBarMenuButtonClick
                    }
                />

                <PrintMenuCompanyHeader {...this.props} />

                <div className="page-limiter analytics-layout">
                    <CompaniesSidebar
                        {...this.props.companySidebar}
                        activeId={this.props.companyId}
                        onCompanyClick={this.handleCompanyClick.bind(this)}
                        onActionClick={
                            this.props.handleSidebarActionClick ||
                            function () {}
                        }
                        onActiveCompanyClick={
                            this.props.handleActiveCompanyClick
                        }
                        onCompaniesActionClick={
                            this.props.handleCompaniesActionClick
                        }
                        onCompanyMenuItemClick={
                            this.props.handleCompanyMenuItemClick
                        }
                    />

                    <div className="analytics-section-container">
                        <Filter
                            className="analytics-filter"
                            {...this.props.analyticsFilter}
                            onFieldChange={this.handleFilterFieldChange.bind(
                                this
                            )}
                            onSubmit={this.handleFilterSubmit.bind(this)}
                            ref={this.filterRef}
                        />

                        <div className="analytics-plate">
                            <AnalyticsBar
                                {...this.props.analyticsSectionBar}
                                onViewChange={
                                    this.props.handleViewChange ||
                                    function () {}
                                }
                                onActionClick={
                                    this.props.handleActionClick ||
                                    function () {}
                                }
                            />
                            <div className="analytics-section">
                                <Graphs
                                    graphs={this.props.analyticsGraphs}
                                    onShowAllButtonClick={this.handleShowAllButtonClick.bind(
                                        this
                                    )}
                                    onMarkerClick={this.handleGraphMarkerClick.bind(
                                        this
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleFilterSubmit(e, form, companyId, params) {
        const handler = this.props.handleFilterSubmit || function () {};
        handler(e, this.filterRef.current, this.props.companyId, {
            graphs: {
                limit: this.graphItemsLimit,
            },
        });
    }

    handleFilterFieldChange(e, params) {
        const handler = this.props.handleFilterFieldChange || function () {};
        handler(e, params);
        const form = e.target.closest("form");
        if (form) {
            if (
                !isMobile(450) ||
                params.action == "apply"
            ) {
                // мобилка фильтрует только кнопкой Применить
                clearTimeout(this.fieldChangeSubmitTimeout);
                this.fieldChangeSubmitTimeout = setTimeout(() => {
                    const handler =
                        this.props.handleFilterSubmit || function () {};
                    handler(e, this.filterRef.current, this.props.companyId, {
                        graphs: {
                            limit: this.graphItemsLimit,
                        },
                    });
                }, this.fieldChangeSubmitTimeoutDelay);
            }
        }
    }

    handleCompanyClick(e, params) {
        const handler = this.props.handleCompanyClick || function () {};
        handler(e, params, this.filterRef.current, {
            graphs: {
                limit: this.graphItemsLimit,
            },
        });
    }

    handleShowAllButtonClick(e) {
        this.graphItemsLimit = null;
        const handler = this.props.handleFilterSubmit || function () {};
        handler(e, this.filterRef.current, this.props.companyId, {
            graphs: {
                limit: this.graphItemsLimit,
            },
        });
    }

    handleGraphMarkerClick(e, item) {
        const handler = this.props.handleGraphMarkerClick || function () {};
        handler(e, item, this.filterRef.current, this.props.companyId);
    }
}
