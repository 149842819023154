import * as ObjectsHelper from "../../../../components/helpers/objects-helper.js";

export default class Targets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showBanner: true,
        };
    }

    render() {
        let targets = [],
            banner;
        if (ObjectsHelper.checkNested(this.props.list, "length")) {
            for (let i = 0; i < this.props.list.length; i++) {
                let item = this.props.list[i];

                if (item.target) {
                    let progressLine, percent;
                    const percentValue = item.target.percent || 0;
                    progressLine = (
                        <div className="progress-line">
                            <div
                                className="filling"
                                style={{ width: percentValue + "%" }}
                            ></div>
                        </div>
                    );
                    if (percentValue) {
                        percent = (
                            <p className="value">{item.target.percent}%</p>
                        );
                    }

                    let value;
                    if (item.target.value) {
                        value = (
                            <p className="value">
                                <span className="text">
                                    {item.target.value + " "}
                                </span>
                                <span className="unit unit-ruble"></span>
                            </p>
                        );
                    }

                    let actions = [];
                    if (item.target.actions) {
                        item.target.actions.map((action, i) => {
                            actions.push(
                                <button
                                    type="button"
                                    key={i}
                                    onClick={this.handleActionClick.bind(
                                        this,
                                        action
                                    )}
                                >
                                    {action.label}
                                </button>
                            );
                        });
                        actions = <div className="actions">{actions}</div>;
                    }

                    targets.push(
                        <div className="target-price" key={i}>
                            <p className="label">{item.target.label}</p>
                            {value}
                            {actions}
                            {progressLine}
                            {percent}
                        </div>
                    );

                    if (item.target.resultBanner) {
                        const resultBanner = item.target.resultBanner;
                        let sumItems = [];
                        if (resultBanner.incomeAmount) {
                            sumItems.push(
                                <span
                                    className="target-amount"
                                    key="income-amount"
                                >
                                    {resultBanner.incomeAmount}
                                </span>
                            );
                            sumItems.push(
                                <span
                                    className="target-amount-delim"
                                    key="delim"
                                >
                                    /
                                </span>
                            );
                        }
                        if (resultBanner.amount) {
                            sumItems.push(
                                <span className="target-amount" key="amount">
                                    {resultBanner.amount}
                                </span>
                            );
                            sumItems.push(
                                <span className="unit-ruble" key="unit"></span>
                            );
                        }
                        let bannerClass = "targets-result-banner";
                        if (this.state.showBanner) {
                            bannerClass += " show";
                        }
                        let buttons = [];
                        resultBanner.buttons.map((button, i) => {
                            buttons.push(
                                <button
                                    type="button"
                                    key={i}
                                    className={button.class}
                                    onClick={this.handleActionClick.bind(
                                        this,
                                        button
                                    )}
                                >
                                    {button.label}
                                </button>
                            );
                        });
                        banner = (
                            <div className={bannerClass}>
                                <div className="popup-container">
                                    <div className="popup-content">
                                        <div className="header">
                                            <div className="header-icon"></div>
                                            <p>{resultBanner.title}</p>
                                        </div>
                                        <div className="sum-container">
                                            {sumItems}
                                        </div>
                                        <div className="popup-buttons">
                                            {buttons}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }
            }
        }

        if (!targets.length) {
            return banner || null;
        }

        return (
            <div className="targets-plate">
                {targets}
                {banner}
            </div>
        );
    }

    handleActionClick(action, e) {
        switch (action.name) {
            case "result-banner-shown":
                this.setState({ showBanner: false });
                break;
        }

        const handler = this.props.onActionClick || function () {};
        handler(e, action);
    }
}
