import Container from '../container.jsx';
import Settings from '../../components/layouts/cabinet/settings.jsx';

export default class SettingsContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.cabinetSettingsLayout');
    }

    render(){
        return <Settings
            {...this.props}
            {...this.state.state} />;
    };
}
