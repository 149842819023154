import * as AnalyticsHandlers from "../handlers/analytics-handlers.js";
import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as TransactionHandlers from "../handlers/transactions-handlers.js";
import * as CabinetHandlers from "../handlers/cabinet-handlers.js";
import * as FilterHelper from "../helpers/filter-helper.js";

export function loadChartHelpers() {
    AnalyticsHandlers.loadChartHelpers();
}

export function handleCompanyClick(e, params, form, addParams) {
    AnalyticsHandlers.loadCompany(params.item.id, form, addParams).then(
        (response) => {
            AnalyticsHandlers.setCompanyHistoryState(params.item.id, response);
        }
    );
}

export function handleActiveCompanyClick(e, params) {
    return LayoutHandlers.loadLayoutByName("home", {
        pushHistoryState: true,
    });
}

export function handleFilterFieldChange(e, params) {
    AnalyticsHandlers.setOperationsFilterParams(params);
}

export function handleFilterSubmit(e, form, companyId, params) {
    e.preventDefault();
    AnalyticsHandlers.filterCharts(companyId, form, params);
}

export function handleViewChange(viewName) {
    AnalyticsHandlers.changeView(viewName);
}

export function handleActionClick(item) {
    switch (item.name) {
        case "print":
            LayoutHandlers.printPage();
            break;
        case "pdf":
        case "xlsx":
        case "word":
            if (item.url) {
                window.open(item.url, '_blank');
            }
            break;
    }
}

export function handleGraphMarkerClick(e, marker, filterForm, companyId) {
    if (!marker.id) {
        return null;
    }

    const formData = filterForm.getFormData();

    // для обратной ссылки нам надо вернуться в тот же фильтр, то есть без фильтра по категориям
    const backlinkdFilter = FilterHelper.getFormDataData(formData);
    AnalyticsHandlers.saveFilter(backlinkdFilter);

    // а на страницу транзакций попать с фильтром по категории
    formData.append("categories", marker.id);

    LayoutHandlers.loadLayoutByName("transactions", {
        pushHistoryState: true,
        params: {
            backlink: {
                name: "analytics",
                params: {
                    filter: backlinkdFilter,
                },
            },
        },
    }).then((response) => {
        // параллельно заполним данные формы, чтоб было видно
        TransactionHandlers.applyFilterFormData(formData);
        return TransactionHandlers.filterOperations(companyId, formData);
    });
}

export function handleSidebarActionClick(e, action) {
    switch (action.name) {
        case "license":
            return LayoutHandlers.loadLayoutByName("cabinet", {
                pushHistoryState: true,
            }).then((response) => {
                return CabinetHandlers.loadMenuLayout("license");
            });
    }
}
