export function isMobile(params) {
    params = params || {};

    if (params === parseInt(params)) {
        params = {
            mobileWidth: params,
        };
    }

    const mobileWidth = params.mobileWidth || 720;

    return window.innerWidth <= mobileWidth;
}
