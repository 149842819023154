export default class LicenseAgreement extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="license-agreement-popup show">
                <div className="popup-container">
                    <div className="popup-content">
                        <p>
                            ВНИМАНИЕ! Перед началом использования Сервиса по
                            учету финансов для ЭВМ Dogma, размещенного в сети
                            Интернет по адресу: http://dogmafin.com,
                            предоставленного доступа к Сервису и технической
                            поддержки Сервиса внимательно ознакомьтесь с
                            условиями его использования, содержащимися в
                            настоящем Лицензионном соглашении. Установка, запуск
                            или иное начало использования доступа к Сервису
                            означает надлежащее заключение настоящего
                            Лицензионного соглашения и Ваше полное согласие со
                            всеми его условиями. Если Вы не согласны
                            безоговорочно принять условия настоящего
                            Лицензионного соглашения, Вы не имеете права
                            использовать доступ к Сервису.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p className="header">Пользовательское соглашение</p>
                        <p>Пользовательское соглашение</p>
                        <p>
                            Данное Пользовательское соглашение (далее
                            Соглашение) применяется к Программе для ЭВМ — Dogma.
                        </p>
                        <p>1. ОСНОВНЫЕ ТЕРМИНЫ</p>
                        <p>2. ПРЕДМЕТ СОГЛАШЕНИЯ</p>
                        <p>3. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПРОГРАММЫ</p>
                        <p>4. ТАРИФЫ</p>
                        <p>5. ПОРЯДОК РАСЧЕТОВ И ДОКУМЕНТООБОРОТ</p>
                        <p>6. ТЕХНИЧЕСКАЯ И КОНСУЛЬТАЦИОННАЯ ПОДДЕРЖКА</p>
                        <p>7. СРОК ДЕЙСТВИЯ И РАСТОРЖЕНИЕ СОГЛАШЕНИЯ</p>
                        <p>8. АВТОРСКИЕ ПРАВА И ТОВАРНЫЕ ЗНАКИ</p>
                        <p>9. ОГРАНИЧЕННАЯ ГАРАНТИЯ И ОТВЕТСТВЕННОСТЬ</p>
                        <p>10. КОНФИДЕНЦИАЛЬНОСТЬ И ПЕРСОНАЛЬНЫЕ ДАННЫЕ</p>
                        <p>11. КОНТАКТНАЯ ИНФОРМАЦИЯ</p>
                        <p>1. ОСНОВНЫЕ ТЕРМИНЫ</p>
                        <p>
                            <br />
                        </p>
                        <p>
                            1.1. «Лицензиар» — Общество с ограниченной
                            ответственностью «Догма»
                        </p>
                        <p>
                            1.2. «Лицензиат» — физическое или юридическое лицо,
                            которое имеет право на использование Программы Dogma
                            в своем интересе в соответствии с требованиями
                            действующего законодательства РФ и настоящего
                            Соглашения.
                        </p>
                        <p>
                            1.3. «Программа» — Программа для ЭВМ Dogma (как в
                            целом, так и ее компоненты), являющаяся
                            представленной в объективной форме совокупностью
                            данных и команд, в том числе исходного текста, базы
                            данных, аудиовизуальных произведений, включённых
                            Лицензиаром в состав указанной программы для ЭВМ, а
                            также любая документация по ее использованию.
                        </p>
                        <p>
                            1.4. «Простая неисключительная лицензия» (далее
                            «Лицензия») — предоставление Лицензиату права
                            временного использования Программы, как результата
                            интеллектуальной деятельности Лицензиара с
                            сохранением за Лицензиаром права выдачи Лицензий
                            другим лицам.
                        </p>
                        <p>
                            1.5. «Регистрация» — действие Лицензиата,
                            направленное на создание Аккаунта, осуществляемое в
                            порядке и для целей, предусмотренных Лицензией
                            соответствующего типа.
                        </p>
                        <p>
                            1.6. «Использование Программы» — запуск и
                            использование функциональных возможностей Программы
                            (или части возможностей Программы) в порядке,
                            определенном пользовательской (технической)
                            документацией и настоящим Соглашением.
                        </p>
                        <p>
                            1.7. «Аккаунт» — информационный ресурс, являющийся
                            совокупностью данных одной копии Программы с
                            уникальным идентификатором, с помощью которого
                            группируются объекты Программы для их совместного
                            отображения и использования.
                        </p>
                        <p>
                            1.8. «Присоединенный пользователь» — пользователь,
                            зарегистрированный Лицензиатом в Программе и
                            авторизовавшийся в ней как минимум один раз.
                            Лицензиат (владелец Аккаунта) отвечает за действия
                            присоединенных пользователей как за свои
                            собственные. Нарушение присоединенным пользователем
                            условий настоящего Соглашения влечет возможные
                            ограничения в использовании Программы для
                            Лицензиата, вплоть до блокировки, или удаления
                            Аккаунта.
                        </p>
                        <p>
                            1.9. «Техническая и консультационная поддержка»
                            (далее «Поддержка») — мероприятия, осуществляемые
                            Лицензиаром в установленных им пределах и объемах
                            для обеспечения функционирования Программы, а также
                            консультационной помощи Лицензиата по вопросам
                            использования Программы.
                        </p>
                        <p>
                            1.10. «Договор» — документ, на основании которого
                            Лицензиар предоставил Лицензиату Программу для ее
                            использования. Договор на бумажном носителе
                            составляется и подписывается Сторонами по требованию
                            любой из Сторон. При отсутствии подписанного
                            Договора Стороны руководствуются условиями
                            настоящего Соглашения.
                        </p>
                        <p>
                            1.11. «Учетная запись» — запись в системе Лицензиара
                            (пара логин/пароль или специальный api-ключ) в
                            которой сохраняются данные, позволяющие
                            идентифицировать и авторизовать Лицензиата и
                            Присоединенного пользователя.
                        </p>
                        <p>
                            1.12. «Тариф» — стоимость использования Программы
                            Лицензиатом на определенных условиях, которые
                            опубликованы на сайте Программы и описаны в
                            настоящем Соглашении.
                        </p>
                        <p>
                            1.13. «Период» — интервал времени, на который
                            Лицензиат приобретает у Лицензиара Лицензию
                            Программы.
                        </p>
                        <p>
                            1.14. «Обновление Программы» — частичная, или полная
                            замена действующей Программы, или её дополнение,
                            производимое Лицензиаром. Все обновления являются
                            неотъемлемой частью Программы, к обновлениям
                            применяются все условия настоящего Соглашения.
                        </p>
                        <p>
                            1.15. «Конфиденциальная информация» — информация,
                            которая может быть представлена Лицензиатом,
                            Лицензиару в письменной, устной форме, в электронном
                            виде и являющаяся собственностью Лицензиата.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>2. ПРЕДМЕТ СОГЛАШЕНИЯ</p>
                        <p>
                            2.1. Лицензиар предоставляет Лицензиату, а Лицензиат
                            принимает от Лицензиара право использования (простую
                            неисключительную Лицензию) Программы в рамках ее
                            функциональных возможностей путем воспроизведения
                            программы (подключения к Программе через сеть
                            Интернет) исключительно для самостоятельного
                            использования Лицензиатом без права
                            сублицензирования третьим лицам.
                        </p>
                        <p>
                            2.2. Лицензиар предоставляет Лицензиату право
                            использования Программы без ограничения по
                            территории в порядке и на условиях, предусмотренных
                            действующим законодательством РФ и настоящим
                            Соглашением.
                        </p>
                        <p>
                            2.3. Настоящее Соглашение считается безоговорочно и
                            полностью принятым Сторонами с момента Регистрации
                            Лицензиата в Программе и действует на протяжении
                            всего срока ее использования Лицензиатом.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>3. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПРОГРАММЫ</p>
                        <p>
                            3.1. Лицензиат вправе в ограниченный период времени
                            бесплатно использовать Программу в рамках
                            ознакомительной Лицензии в течении 14 дней. Началом
                            срока действия ознакомительной Лицензии является
                            дата создания Лицензиатом Учетной записи и Аккаунта
                            (дата Регистрации).{" "}
                        </p>
                        <p>
                            3.2. Для приобретения Лицензии Лицензиат вправе
                            самостоятельно выбирать Тариф и Период
                            использования, которые размещены на сайте Программы{" "}
                        </p>
                        <p>
                            3.3. При поступлении денежных средств от Лицензиата
                            на расчетный счет Лицензиара новая Лицензия
                            активируется (или начинается): не позднее 1 (одного)
                            рабочего дня с момента оплаты. Дата начала и период
                            использования Лицензии не могут быть изменены по
                            желанию Лицензиата.
                        </p>
                        <p>
                            3.4. В случае окончания срока действия Лицензии и
                            неприобретения Лицензиатом в течение 1 (одного)
                            календарного дня новой Лицензии, дальнейшее
                            использование Программы Лицензиатом будет
                            ограничено.{" "}
                        </p>
                        <p>
                            3.5. Лицензиар вправе в одностороннем порядке
                            изменять Тарифы и Периоды использования Программы.
                            Оплаченный Лицензиатом Тариф не меняется до
                            окончания Периода.
                        </p>
                        <p>
                            3.6. Лицензиат самостоятельно несет полную
                            ответственность за содержание своего Аккаунта и
                            данных, указанных в нём. Лицензиат обязан
                            использовать Программу в соответствии с
                            законодательством РФ и условиями настоящего
                            Соглашения. Лицензиар не осуществляет контроля за
                            содержанием размещаемой Лицензиатом информации и не
                            может нести за неё ответственность.
                        </p>
                        <p>3.7. Регистрация.</p>
                        <p>
                            3.7.1. Чтобы воспользоваться Программой Лицензиату
                            необходимо пройти процедуру Регистрации, в
                            результате которой для Лицензиата будет создана
                            уникальная Учетная запись и Аккаунт. Для добавления
                            ,присоединенных пользователей Лицензиату необходимо
                            самостоятельно средствами Программы создать им
                            Учетные записи и дать доступ к Аккаунту. Количество
                            Присоединенных пользователей в рамках одного
                            Аккаунта определяется Лицензиатом
                        </p>
                        <p>
                            3.7.2. Для регистрации Лицензиат обязуется
                            предоставить достоверную и полную информацию о себе
                            и Присоединенных пользователях по вопросам,
                            предлагаемым в форме регистрации, и поддерживать эту
                            информацию в актуальном состоянии/либо вовремя
                            информировать об изменении информации. Если
                            Лицензиат предоставляет неверную информацию или у
                            Лицензиара есть основания полагать, что
                            предоставленная Лицензиатом информация неполна или
                            недостоверна, Лицензиар имеет право по своему
                            усмотрению заблокировать либо удалить Учетную запись
                            Лицензиата.
                        </p>
                        <p>3.7.3. Регистрируясь в Программе, Лицензиат:</p>
                        <p>
                            безоговорочно принимает условия настоящего
                            Соглашения;
                        </p>
                        <p>
                            соглашается на обработку, хранение и использование
                            Лицензиаром персональных данных Лицензиата;
                            Обработка персональных данных включает в себя: сбор,
                            запись, систематизацию, накопление, хранение,
                            уточнение (обновление, изменение), извлечение,
                            использование, передачу (предоставление, доступ),
                            обезличивание, блокирование, удаление, и
                            уничтожение.
                        </p>
                        <p>
                            соглашается на получение посредством телефонии,
                            электронной почты и смс рекламно-информационных
                            сообщений, касающихся продукции и услуг Лицензиара
                            или его партнеров. Лицензиат имеет возможность
                            отменить получение рассылок по электронной почте и
                            смс, сообщив о своём решении в чате Программы, либо
                            нажав соответствующую ссылку в письме.
                        </p>
                        <p>
                            3.8. Логин и пароль для доступа к Учетной записи
                            Лицензиата или Присоединенного пользователя.
                        </p>
                        <p>
                            3.8.1. При регистрации Лицензиат или Присоединенный
                            пользователь самостоятельно выбирает себе логин
                            (уникальное символьное имя Учетной записи Лицензиата
                            или Присоединенного пользователя) и пароль для
                            доступа к Учетной записи. Лицензиар вправе запретить
                            использование определенных логинов, а также
                            устанавливать требования к логину и паролю (длина,
                            допустимые символы и т.д.).
                        </p>
                        <p>
                            3.8.2. Лицензиат или Присоединенный пользователь
                            самостоятельно несут ответственность за безопасность
                            (устойчивость к угадыванию) выбранного пароля, а
                            также самостоятельно обеспечивают конфиденциальность
                            своего пароля. Лицензиат самостоятельно несет
                            ответственность за все действия/бездействия (а также
                            их последствия) в рамках или с использованием
                            Программы под своей Учетной записью, включая случаи
                            добровольной передачи или несоблюдения
                            конфиденциальности данных для доступа к его Учетной
                            записи третьим лицам на любых условиях (в том числе
                            по договорам или соглашениям). При использовании
                            Программы под Учетной записью Лицензиата все
                            действия считаются произведенными им самим за
                            исключением случаев, имеющих место после получения
                            Лицензиаром от Лицензиата уведомления о
                            несанкционированном использовании Программы третьими
                            лицами под Учетной записью Лицензиата.
                        </p>
                        <p>
                            3.8.3. Лицензиат обязан немедленно уведомить
                            Лицензиара о любом случае несанкционированного
                            доступа к Программе с использованием его Учетной
                            записи или подозрениях о нарушении
                            конфиденциальности своего пароля. В целях
                            безопасности Лицензиат обязан самостоятельно
                            осуществлять безопасное завершение работы под своей
                            Учетной записью (кнопка «Выход») по окончании каждой
                            сессии работы с Программой. Лицензиар не отвечает за
                            возможную потерю данных, а также другие последствия
                            любого характера, которые могут произойти из-за
                            нарушения Лицензиатом положений этой части
                            Соглашения.
                        </p>
                        <p>3.9. Удаление данных и Учетной записи.</p>
                        <p>
                            3.9.1. Лицензиат в процессе использования Программы
                            может удалять как отдельные, так и все данные
                            Учетной записи по своему усмотрению.
                        </p>
                        <p>
                            3.9.2. В случае нарушения Лицензиатом условий
                            настоящего Соглашения Лицензиар вправе заблокировать
                            доступ в Аккаунт и удалить Учетную запись
                            Лицензиата, включая все имеющиеся данные без
                            предварительного уведомления. С этого момента
                            восстановление данных в Аккаунте Лицензиата может
                            стать невозможным.
                        </p>
                        <p>
                            3.10 Лицензиат и Присоединенные пользователи не
                            вправе совершать действия, которые могут повлечь:
                        </p>
                        <p>
                            - нарушение функционирования оборудования и сети
                            Лицензиара;
                        </p>
                        <p>
                            - нарушение работы Программы или ограничение
                            возможностей других пользователей в использовании
                            Программы;
                        </p>
                        <p>
                            - несанкционированный доступ к Программе, а также
                            информационно-вычислительным и сетевым ресурсам
                            Лицензиара;
                        </p>
                        <p>
                            - причинение либо угрозу причинения ущерба третьим
                            лицам, в том числе путем размещения информации и
                            ссылок на ресурсы сети, содержание которых
                            противоречит действующему законодательству РФ.
                        </p>
                        <p>
                            3.11. Лицензиату запрещено производить модификацию
                            Программы, её копирование и продажу.
                        </p>
                        <p>
                            3.12. Лицензиат самостоятельно обеспечивает наличие
                            оборудования, соответствующего техническим
                            требованиям для использования Программы, и доступа к
                            сети Интернет.
                        </p>
                        <p>
                            3.13. Лицензиат гарантирует, что он имеет все
                            необходимые права на данные, компьютерные программы
                            или сервисы, которые используются им в связи с
                            использованием Программы, и что такие действия не
                            нарушают права третьих лиц.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>4. ТАРИФЫ</p>
                        <p>
                            4.1. Для использования Программы Лицензиат выбирает
                            один из 2-х вариантов тарифа — «1 месяц» либо «1
                            год», описание и стоимость которых размещена в
                            Аккаунте в разделе Оплата.
                        </p>
                        <p>
                            4.2. На обоих тарифах Лицензиар предоставляет
                            Лицензиату:
                        </p>
                        <p>
                            - Лицензию с круглосуточным доступом к полному
                            функционалу Программы;
                        </p>
                        <p>
                            - обратную связь по использованию Программы
                            Лицензиат получает не позднее рабочего дня,
                            следующего за днем обращения.{" "}
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>5. ПОРЯДОК РАСЧЕТОВ И ДОКУМЕНТООБОРОТ</p>
                        <p>
                            5.1. Лицензиат приобретает Лицензию для временного
                            использования Программы в соответствии с Тарифами и
                            Периодами, которые размещены на сайте Программы.{" "}
                        </p>
                        <p>
                            5.2. Лицензиат оплачивает Лицензию на выбранный
                            Период в виде 100% предоплаты путем безналичного
                            перевода денежных средств на расчетный счет
                            Лицензиара несколькими способами.
                        </p>
                        <p>
                            5.2.1. Лицензиат может получить от Лицензиара счет и
                            сделать перевод денежных средств со своего
                            банковского счета на расчетный счет Лицензиара.
                        </p>
                        <p>
                            Сумма денежных средств, полученных Лицензиаром от
                            Лицензиата, должна в точности соответствовать сумме,
                            указанной в выставленном счете.
                        </p>
                        <p>
                            Такой способ оплаты разрешен только для резидентов
                            Российской Федерации.
                        </p>
                        <p>
                            5.2.2. Лицензиат может использовать для оплаты
                            банковскую карту. Для этого необходимо указать
                            реквизиты карты в разделе Оплаты внутри Аккаунта.
                        </p>
                        <p>
                            Такой способ оплаты разрешен для резидентов и
                            нерезидентов Российской Федерации.
                        </p>
                        <p>
                            5.3. Для оплаты в Программе с помощью банковской
                            карты на странице «Оплата» Лицензиату необходимо
                            выбрать способ оплаты «По карте».{" "}
                        </p>
                        <p>
                            После ввода реквизитов Лицензиатом произойдет
                            перенаправление на платежный шлюз выбранного банка.
                            Соединение с платежным шлюзом и передача информации
                            осуществляются в защищенном режиме с использованием
                            протокола шифрования SSL. Если банк Лицензиата
                            поддерживает технологию безопасного проведения
                            интернет-платежей MIR Accept, Verified By Visa,
                            MasterCard SecureCode, J-Secure, то для проведения
                            платежа может потребоваться ввод специального
                            пароля.
                        </p>
                        <p>
                            После оплаты Лицензиату на почту указанную в
                            реквизитах, приходят документы, подтверждающие
                            платеж.
                        </p>
                        <p>
                            Конфиденциальность сообщаемой персональной
                            информации обеспечивается Банком Лицензиара.
                            Введенная Лицензиатом информация не будет
                            предоставлена третьим лицам за исключением случаев,
                            предусмотренных законодательством РФ. Проведение
                            платежей по банковским картам осуществляется в
                            строгом соответствии с требованиями платежных систем
                            МИР, Visa Int., MasterCard Europe Sprl, JCB.
                        </p>
                        <p>
                            5.4. При оплате со счета юридического лица или
                            индивидуального предпринимателя Лицензиар оформляет
                            «Акт на передачу прав» либо «Акт об оказании услуг»
                            (далее «Акт») и направляет скан на адрес электронной
                            почты владельца аккаунта.
                        </p>
                        <p>
                            5.5. Оригинал Акта может быть отправлен Лицензиату
                            через электронный документооборот Контур.Диадок РФ
                            или на электронный адресс Лицензиара, указанных в
                            пункте Контактная информация.
                        </p>
                        <p>
                            5.6. После получения Акта Лицензиат при отсутствии
                            возражений обязан подписать его и направить
                            Лицензиару. Если Лицензиат в течение 10 календарных
                            дней с момента получения Акта не высказывает
                            Лицензиару письменных замечаний по работе Программы,
                            Акт считается подписанным Сторонами.
                        </p>
                        <p>
                            5.7. Лицензиар использует упрощенную систему
                            налогообложения и счета-фактуры не оформляет.
                        </p>
                        <p>
                            5.8. Оплата налогов и сборов, возникающих у сторон в
                            рамках настоящего Соглашения, производится каждой
                            стороной самостоятельно, в соответствии с налоговым
                            законодательством. Стороны не несут ответственности
                            за правильность и своевременность оплаты налогов и
                            сборов другой стороной.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>6. ТЕХНИЧЕСКАЯ И КОНСУЛЬТАЦИОННАЯ ПОДДЕРЖКА</p>
                        <p>
                            6.1. В течение срока действия Соглашения Лицензиар
                            осуществляет техническую поддержку Лицензиата,
                            которая представляет собой ответ на письменное
                            обращение в своем аккаунте, в разделе «обратная
                            связь» в течении 1 рабочего дня.{" "}
                        </p>
                        <p>
                            6.2. В течение срока действия Соглашения Лицензиар
                            осуществляет обратную связь с Лицензиатом по
                            использованию Программы.
                        </p>
                        <p>
                            6.3. Лицензиат вправе обращаться в службу поддержки
                            Лицензиара без выплаты дополнительного
                            вознаграждения.
                        </p>
                        <p>
                            6.4. Для осуществления Поддержки Лицензиар вправе
                            запросить у Лицензиата информацию, касающуюся данных
                            Учетной записи, технических характеристик
                            оборудования и другую необходимую для оказания
                            Поддержки информацию.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>7. СРОК ДЕЙСТВИЯ И РАСТОРЖЕНИЕ СОГЛАШЕНИЯ</p>
                        <p>
                            7.1. Настоящее Соглашение вступает в силу с момента
                            начала использования Программы Лицензиатом и
                            действует до полного исполнения Сторонами своих
                            обязательств.
                        </p>
                        <p>
                            7.2. Лицензиар имеет право в случае нарушения
                            Лицензиатом условий настоящего Соглашения по
                            использованию Программы в одностороннем порядке
                            расторгнуть настоящее Соглашение.
                        </p>
                        <p>
                            7.3. Лицензиар оставляет за собой право в любой
                            момент, по своему усмотрению и причинам, не
                            связанным с действиями Лицензиата, частично или
                            полностью прекратить работу Программы. В этом случае
                            Лицензиар обязан предварительно уведомить Лицензиата
                            не менее чем за 14 (четырнадцать) календарных дней и
                            выплатить Лицензиату остаток неизрасходованного
                            авансового платежа.
                        </p>
                        <p>
                            7.4. Лицензиат имеет право на досрочное расторжение
                            Соглашения по своей инициативе. Он направляет
                            подписанное заявление с реквизитами на возврат
                            неиспользованного авансового платежа на адрес —{" "}
                        </p>
                        <p>
                            Лицензиар рассчитывает сумму возврата от даты
                            получения заявления от Лицензиата и перечисляет
                            денежные средства в течение 5 (пяти) рабочих дней за
                            неиспользованный период.
                        </p>
                        <p>
                            7.5. При расторжении настоящего Соглашения любой
                            Стороной и по любым основаниям доступ в аккаунт
                            Лицензиата может быть заблокирован.
                        </p>
                        <p>
                            7.6 Лицензиар имеет право изменять условия
                            настоящего Соглашения в одностороннем порядке,
                            разместив измененный текста на сайте Сервиса
                        </p>
                        <p>
                            7.7. По всем вопросам, не урегулированным настоящим
                            Соглашением, Стороны руководствуются действующим
                            законодательством РФ.
                        </p>
                        <p>
                            7.8. В случае если компетентный суд признает
                            какие-либо положения настоящего Соглашения
                            недействительными, Соглашение продолжает действовать
                            в остальной части.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>8. АВТОРСКИЕ ПРАВА И ТОВАРНЫЕ ЗНАКИ</p>
                        <p>
                            8.1. Программа является результатом интеллектуальной
                            деятельности и объектом авторских прав Лицензиара,
                            которые регулируются и защищены законодательством РФ
                            об интеллектуальной собственности и нормами
                            международного права.
                        </p>
                        <p>
                            8.2. Лицензиар гарантирует, что обладает всем
                            необходимым объемом прав на Программу для
                            предоставления их Лицензиату, включая документацию к
                            Сервису.
                        </p>
                        <p>
                            8.3. Алгоритмы работы Программы и ее исходные коды
                            (в том числе их части) являются коммерческой тайной
                            Лицензиара. Любое их использование или использование
                            Программы в нарушение условий настоящего Соглашения
                            рассматривается как нарушение прав Лицензиара и
                            является достаточным основанием для привлечения
                            Лицензиата к ответственности и лишения его прав
                            использования Программы.
                        </p>
                        <p>
                            8.4. Лицензиар является правообладателем товарного
                            знака (знака обслуживания) Dogma.
                        </p>
                        <p>
                            8.5. Настоящим Соглашением Лицензиату не
                            предоставляются никакие права на использование
                            товарных знаков, знаков обслуживания Лицензиара и
                            его партнеров.
                        </p>
                        <p>
                            8.6. Ответственность за нарушение авторских прав
                            наступает в соответствии с действующим
                            законодательством РФ.
                        </p>
                        <p>
                            8.7. Лицензиат не может ни при каких условиях
                            удалять или делать малозаметными информацию и
                            сведения об авторских правах, правах на товарные
                            знаки или патенты, указанные в Программе.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>9. ОГРАНИЧЕННАЯ ГАРАНТИЯ И ОТВЕТСТВЕННОСТЬ</p>
                        <p>
                            9.1. Программа предоставляется по принципу «как
                            есть» и Лицензиар не гарантирует, что все ее
                            функциональные возможности будут отвечать ожиданиям
                            Лицензиата и смогут быть применимы для конкретной
                            его цели.
                        </p>
                        <p>
                            9.2. Лицензиар не инициирует и не контролирует
                            размещение Лицензиатом любой информации в процессе
                            использования Программы, не влияет на ее содержание
                            и целостность, а также в момент размещения указанной
                            информации не знает и не может знать — нарушает ли
                            она охраняемые законом права и интересы третьих лиц,
                            международные договоры и действующее
                            законодательство РФ.
                        </p>
                        <p>
                            9.3. Лицензиар не несет ответственности перед
                            Лицензиатом за любой ущерб, потерю доходов, прибыли,
                            информации или сбережений, которые могут возникнуть
                            в результате:
                        </p>
                        <p>
                            9.3.1. Использования или невозможности использования
                            Программы, в том числе в случае предварительного
                            уведомления со стороны Лицензиата о возможности
                            такого ущерба или по любому иску третьей стороны.
                        </p>
                        <p>
                            9.3.2. Оказания Лицензиаром дополнительных услуг по
                            настройке аккаунта Лицензиата, внесению данных,
                            подготовке отчетов, консультированию и т.п.
                            Лицензиат обязан самостоятельно проверять результаты
                            дополнительных услуг, оказываемых Лицензиаром.
                        </p>
                        <p>
                            9.4. Лицензиар обеспечивает доступность и
                            исправность работы Программы, за исключением времени
                            проведения профилактических работ, обновлений, работ
                            по устранению сбоев и неисправностей.
                        </p>
                        <p>
                            9.5. Если при использовании Программы будут
                            обнаружены ошибки, Лицензиар предпримет меры для их
                            исправления. Стороны соглашаются, что точное
                            определение срока устранения ошибки не может быть
                            установлено, так как работоспособность Программы
                            зависит от других программ для ЭВМ сторонних
                            разработчиков, операционных систем и аппаратных
                            ресурсов компьютера Лицензиата.
                        </p>
                        <p>
                            9.6. В случае совершения Лицензиатом действий,
                            запрещенных законодательством РФ, либо нормами
                            настоящего Соглашения, Лицензиар вправе без
                            объяснения причин и какого-либо уведомления
                            Лицензиата предпринять меры, выявляющие указанные
                            нарушения и предотвращающие их появление в
                            дальнейшем.
                        </p>
                        <p>
                            9.7. За нарушение условий настоящего Соглашения
                            Сторонами наступает ответственность, предусмотренная
                            законодательством РФ.
                        </p>
                        <p>10. КОНФИДЕНЦИАЛЬНОСТЬ И ПЕРСОНАЛЬНЫЕ ДАННЫЕ</p>
                        <p>
                            10.1.Каждая из Сторон обязуется не разглашать
                            конфиденциальную информацию, полученную от другой
                            Стороны в связи с исполнением Соглашения.
                        </p>
                        <p>
                            10.2. Предоставляемая Лицензиатом персональная
                            информация (имя, адрес, телефон, E-mail, номер
                            кредитной карты) является конфиденциальной и не
                            подлежит разглашению.
                        </p>
                        <p>
                            10.3. Лицензиар обязуется принимать все необходимые
                            меры для защиты указанных выше персональных данных
                            от неправомерного доступа или раскрытия. Лицензиар
                            обязуется обеспечить, что вся конфиденциальная
                            информация Лицензиата:
                        </p>
                        <p>
                            10.3.1. Хранится как конфиденциальная и не
                            воспроизводится, не копируется (сверх разумно
                            необходимого для целей Соглашения);
                        </p>
                        <p>
                            10.3.2. Не будет передана, раскрыта или другим
                            образом станет доступной любой другой третьей
                            Стороне за исключением:
                        </p>
                        <p>
                            - ограниченного числа работников Лицензиара, которым
                            необходимо использовать конфиденциальную информацию
                            только для реализации целей Соглашения;
                        </p>
                        <p>
                            - ответов Лицензиара на официальные запросы от
                            органов власти либо правоохранительных органов РФ, в
                            соответствии с действующим законодательством РФ. При
                            получении подобного запроса Лицензиар предпримет
                            меры, чтобы поставить в известность об этом
                            Лицензиата, если таковые действия не будут
                            противоречить требованиям законодательства РФ.
                        </p>
                        <p>
                            10.3.3. Не используется ни для какой иной цели, за
                            исключением целей Соглашения;
                        </p>
                        <p>10.3.4. Хранится с соблюдением мер безопасности.</p>
                        <p>10.4. Конфиденциальность платёжных данных.</p>
                        <p>
                            10.4.1. Данные банковской карты передаются только в
                            зашифрованном виде и не сохраняются на Web-сервере
                            Лицензиара.
                        </p>
                        <p>
                            10.4.2. Безопасность обработки Интернет-платежей
                            гарантирует платежная система банка Лицензиара. При
                            передаче информации используется специальные
                            технологии безопасности карточных онлайн-платежей.
                        </p>
                        <p>
                            10.5. Принимая условия настоящего Соглашения,
                            Лицензиат в соответствии с Федеральным законом №
                            152-ФЗ «О персональных данных» от 27.07.2006 года
                            подтверждает, что он ознакомился и согласен с
                            политикой Лицензиара в отношении обработки, хранения
                            и использования персональных данных.
                        </p>
                        <p>
                            10.6. Настоящее согласие действует в течение всего
                            срока действия Соглашения и использования
                            Лицензиатом Программы. Лицензиат понимает и
                            соглашается с тем, что в случае отзыва данного
                            согласия он может лишиться возможности использовать
                            Программу либо отдельных её частей.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>11. КОНТАКТНАЯ ИНФОРМАЦИЯ</p>
                        <p>
                            Лицензиар — Индивидуальный предприниматель Сивкова
                            Анастасия Сергеевна
                        </p>
                        <p>ИНН: 233709094947</p>
                        <p>
                            Юридический адрес: 620014 г. Екатеринбург, ул.
                            Энергостроителей, д.4, к.2 кв. 142
                        </p>
                        <p>БИК: 044525999</p>
                        <p>К/с: 3010181048452500009999</p>
                        <p>Р/с: 40802810602500169201</p>
                        <p>Телефон: +7 (922)1967245</p>
                        <p>
                            Обращения по вопросам условий настоящего Соглашения
                            и Поддержки принимаются по адресу электронной почты
                            —dogmafin@mail.ru
                        </p>
                        <p>
                            Обращения по вопросам расчетов и документооборота
                            принимаются по адресу электронной почты —
                            dogmafin@mail.ru
                        </p>
                    </div>
                    <button
                        className="close-button"
                        onClick={
                            this.props.onCloseButtonClick || function () {}
                        }
                    ></button>
                </div>
            </div>
        );
    }
}
