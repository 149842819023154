import Container from "../container.jsx";
import FormPopup from "../../components/form-popup.jsx";

export default class TargetEditPopupContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.targetEditPopup");
    }

    render() {
        if (!this.state.state) {
            return null;
        }

        return <FormPopup {...this.props} {...this.state.state} />;
    }
}
