import newId from "../utils/new-id.js";
import Field from "./field.jsx";
import ValidatableField from "./validatable-field.jsx";

export default class Fieldset extends React.Component {
    constructor(props) {
        super(props);
        this.fields = [];
        this.validatableFields = [];
    }

    render() {
        let fields = [];

        this.fields = [];
        this.validatableFields = [];

        for (let i in this.props.fields) {
            // компоненты у нас uncontrolled, т.к. у них часто меняется значение, что может сказаться на производительности
            // тем не менее нам их необходимо обновлять, например если захотим отрендерить новую форму
            // обновить компонент мы сможем послав ему новый key, отличный от предыдущего
            // https://stackoverflow.com/a/53313430/8639004
            if (
                this.props.fields[i].validators ||
                this.props.fields[i].filters
            ) {
                fields.push(
                    <ValidatableField
                        key={newId("form-field-key-")}
                        ref={(field) => {
                            this.fields.push(field);
                            this.validatableFields.push(field);
                        }}
                        {...this.props.fields[i]}
                        onChange={this.handleFieldChange.bind(this, i)}
                        onClick={this.handleFieldClick.bind(this, i)}
                        onFocus={this.handleFieldFocus.bind(this, i)}
                        onBlur={this.handleFieldBlur.bind(this, i)}
                    />
                );
            } else {
                fields.push(
                    <Field
                        key={newId("form-field-key-")}
                        ref={(field) => {
                            this.fields.push(field);
                        }}
                        {...this.props.fields[i]}
                        onChange={this.handleFieldChange.bind(this, i)}
                        onClick={this.handleFieldClick.bind(this, i)}
                        onFocus={this.handleFieldFocus.bind(this, i)}
                        onBlur={this.handleFieldBlur.bind(this, i)}
                    />
                );
            }
        }

        return <div className="form-fieldset">{fields}</div>;
    }

    handleFieldChange(index, e, props, field) {
        let handler =
            this.props.onFieldChange ||
            this.props.fields[index].onChange ||
            function () {};
        handler(e, props, field, index);
    }

    handleFieldClick(index, e, field, params) {
        const handler =
            this.props.onFieldClick ||
            this.props.fields[index].onClick ||
            function () {};
        handler(e, field, index, params);
    }

    handleFieldFocus(index, e, props, field) {
        let handler =
            this.props.onFieldFocus ||
            this.props.fields[index].onFocus ||
            function () {};
        handler(e, props, field, index);
    }

    handleFieldBlur(index, e, props, field) {
        let handler =
            this.props.onFieldBlur ||
            this.props.fields[index].onBlur ||
            function () {};
        handler(e, props, field, index);
    }

    validateFields() {
        if (!this.validatableFields.length) {
            return true;
        }

        let valid = true;

        // TODO: если сменить пресет полей, почему-то массив в этом месте может состоять из пустых элементов...
        for (var i in this.validatableFields) {
            if (!this.validatableFields[i]) {
                continue;
            }
            if (!this.validatableFields[i].validate()) {
                valid = false;
            }
        }

        return valid;
    }

    prepareFormData(formData) {
        if (!this.fields.length) {
            return true;
        }

        // TODO: если сменить пресет полей, почему-то массив в этом месте может состоять из пустых элементов...
        for (var i in this.fields) {
            if (!this.fields[i]) {
                continue;
            }
            formData = this.fields[i].prepareFormData(formData);
        }

        return formData;
    }
}
