import * as TargetsHandlers from "../handlers/targets-handlers.js";
import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as CabinetHandlers from "../handlers/cabinet-handlers.js";

export function handleTargetsActionClick(e, action, companyId) {
    switch (action.name) {
        case "add":
            return TargetsHandlers.loadAddForm({
                companyId,
            });
        case "back-to-list":
            return TargetsHandlers.setLayout("targets");
        case "delete":
            return TargetsHandlers.loadRemoveFormPopup({
                id: action.id,
            });
    }
}

export function handleTargetActionClick(e, item, action, companyId) {
    switch (action.name) {
        case "edit":
            return TargetsHandlers.loadEditForm({
                id: item.id,
            });
        case "delete":
            return TargetsHandlers.loadRemoveFormPopup({
                id: item.id,
            });
    }
}

export function handleTargetAddPopupSubmit(e, form, companyId) {
    e.preventDefault();
    return TargetsHandlers.submitPopupForm(form).then((response) => {
        if (response.success) {
            if (response.confirmForm) {
                TargetsHandlers.setAddConfirmForm(response.confirmForm);
            } else {
                TargetsHandlers.closeAddPopup();
                return TargetsHandlers.loadTable({
                    companyId,
                });
            }
        }
    });
}

export function handleTargetAddPopupClose(e) {
    return TargetsHandlers.closeAddPopup();
}

export function handleTargetAddFormSubmit(e, form, companyId) {
    e.preventDefault();
    return TargetsHandlers.submitPopupForm(form).then((response) => {
        if (response.success) {
            if (response.confirmForm) {
                TargetsHandlers.setAddConfirmForm(response.confirmForm);
            } else {
                TargetsHandlers.setLayout("targets");
                return TargetsHandlers.loadTable({
                    companyId,
                });
            }
        }
    });
}

export function handleTargetEditPopupSubmit(e, form, companyId) {
    e.preventDefault();
    return TargetsHandlers.submitPopupForm(form).then((response) => {
        if (response.success) {
            if (response.confirmForm) {
                TargetsHandlers.setEditConfirmForm(response.confirmForm);
            } else {
                TargetsHandlers.closeEditPopup();
                return TargetsHandlers.loadTable({
                    companyId,
                });
            }
        }
    });
}

export function handleTargetEditPopupClose(e) {
    return TargetsHandlers.closeEditPopup();
}

export function handleTargetEditFormSubmit(e, form, companyId) {
    e.preventDefault();
    return TargetsHandlers.submitPopupForm(form).then((response) => {
        if (response.success) {
            if (response.confirmForm) {
                TargetsHandlers.setEditConfirmForm(response.confirmForm);
            } else {
                TargetsHandlers.setLayout("targets");
                return TargetsHandlers.loadTable({
                    companyId,
                });
            }
        }
    });
}

export function handleTargetRemovePopupSubmit(e, form, companyId) {
    e.preventDefault();
    return TargetsHandlers.submitPopupForm(form).then((response) => {
        if (response.success) {
            TargetsHandlers.closeRemovePopup();
            TargetsHandlers.setLayout("targets");
            return TargetsHandlers.loadTable({
                companyId,
            });
        }
    });
}

export function handleTargetRemovePopupClose(e) {
    return TargetsHandlers.closeRemovePopup();
}

export function handleTargetRemovePopupFieldClick(e, field, form, params) {
    switch (field.props.name) {
        case "cancel":
            return TargetsHandlers.closeRemovePopup();
    }
}

export function handleCompanyClick(e, params) {
    return TargetsHandlers.loadTable({
        companyId: params.item.id,
    });
}

export function handleActiveCompanyClick(e, params) {
    return LayoutHandlers.loadLayoutByName("home", {
        pushHistoryState: true,
    });
}

export function handleActionClick(e, action) {
    switch (action.name) {
        case "license":
            return LayoutHandlers.loadLayoutByName("cabinet", {
                pushHistoryState: true,
            }).then((response) => {
                return CabinetHandlers.loadMenuLayout("license");
            });
    }
}
