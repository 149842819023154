import Container from '../container.jsx';
import InvestmentsTable from '../../components/layouts/investments/table.jsx';
import * as ObjectsHelper from '../../../components/helpers/objects-helper.js';

export default class InvestmentsTableContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.investments');
    }

    render(){
        if (!this.state.state) {
            return null;
        }

        return <InvestmentsTable
                {...this.props}
                headers={ObjectsHelper.getNestedValue(this.state.state, 'tableHeaders')}
                list={ObjectsHelper.getNestedValue(this.state.state, 'list')} />;
    };
}
