import OperationsDatedList from "../transactions/operations-dated-list.jsx";
import Errors from "../../errors.jsx";

export default class Operations extends React.Component {
    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        let link;
        if (this.props.link) {
            link = (
                <div className="operations-button">
                    <button
                        onClick={this.handleOperationsButtonClick.bind(
                            this,
                            this.props.link
                        )}
                    >
                        {this.props.link.label}
                    </button>
                </div>
            );
        }

        return (
            <div className="operations">
                <p className="operations-header">{this.props.header}</p>
                <Errors list={this.props.errors} />
                <OperationsDatedList
                    list={this.props.list}
                    onItemClick={this.props.onOperationClick || function () {}}
                    onActionClick={
                        this.props.onOperationActionClick || function () {}
                    }
                />
                {/* link */}
            </div>
        );
    }

    handleOperationsButtonClick(button, e) {
        const handler = this.props.onOperationsActionClick || function () {};
        switch (button.name) {
            case "load-more":
                button.itemsCount = document
                    .querySelector(".operations")
                    .querySelectorAll(".operations-list-item").length;
                break;
        }
        handler(e, button);
    }

    handleScroll(e) {
        // для бесконечной подгрузки надо понимать, когда низ экрана ниже чем низ списка операций
        const windowTopPos =
            window.pageYOffset || document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const container = document.querySelector(".operations");
        const containerRect = container.getBoundingClientRect();
        const containerTopPos = containerRect.top + windowTopPos;
        const containerHeight = container.offsetHeight;

        if (windowTopPos + windowHeight >= containerTopPos + containerHeight) {
            const itemsCount = container.querySelectorAll(
                ".operations-list-item"
            ).length;
            if (itemsCount < this.props.totalCount) {
                const handler =
                    this.props.onOperationsScrollEdge || function () {};
                handler(itemsCount);
            }
        }
    }
}
