import Form from "../../forms/form.jsx";
import FormPopup from "../../form-popup.jsx";
import CompanyTabList from "../../../containers/cabinet/company-tab-list.jsx";
import CompanyTabForm from "../cabinet/company-tab-form.jsx";
import CompanyTabItemCard from "../cabinet/company-tab-item-card.jsx";
import { stretchLastPageElement } from "../../../../components/helpers/html-helper.js";

export default class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.activeTab || null,
            showConfirmDeletePopup: !!this.props.deleteForm,
            showConfirmRemoveIntegrationPopup:
                !!this.props.removeIntegrationForm,
        };
    }

    componentDidMount() {
        stretchLastPageElement(document.querySelector(".dictionary-content"));
    }

    componentDidUpdate() {
        stretchLastPageElement(document.querySelector(".dictionary-content"));
    }

    render() {
        let activeTab = this.getActiveTab();

        let popupForms = [];
        if (this.props.deleteForm) {
            popupForms.push(
                <FormPopup
                    key={"delete-form"}
                    className="confirm-delete-popup"
                    {...this.props.deleteForm}
                    show={this.state.showConfirmDeletePopup}
                    handleFieldClick={this.handleConfirmDeletePopupFieldClick.bind(
                        this
                    )}
                    handleSubmit={this.handleConfirmDeletePopupSubmit.bind(
                        this
                    )}
                    handleCloseButtonClick={this.handleConfirmDeletePopupCloseButtonClick.bind(
                        this
                    )}
                />
            );
        }
        if (this.props.confirmForm) {
            popupForms.push(
                <FormPopup
                    key={"confirm-form"}
                    className="confirm-popup"
                    {...this.props.confirmForm}
                    show={true}
                    handleFieldClick={this.handleConfirmPopupFieldClick.bind(
                        this
                    )}
                    handleSubmit={this.handleConfirmPopupSubmit.bind(this)}
                    handleCloseButtonClick={this.handleConfirmPopupCloseButtonClick.bind(
                        this
                    )}
                />
            );
        }
        if (this.props.paymentAccountAddBankIntegration) {
            popupForms.push(
                <FormPopup
                    key={"payment-account-add-bank-integration"}
                    className="payment-account-add-bank-integration-popup"
                    {...this.props.paymentAccountAddBankIntegration}
                    show={true}
                    handleSubmit={this.handleIntegrationPopupSubmit.bind(this)}
                    handleCloseButtonClick={this.handleIntegrationPopupCloseButtonClick.bind(
                        this
                    )}
                />
            );
        }
        if (this.props.removeIntegrationForm) {
            popupForms.push(
                <FormPopup
                    key={"remove-integration-form"}
                    className="confirm-delete-popup"
                    {...this.props.removeIntegrationForm}
                    show={this.state.showConfirmRemoveIntegrationPopup}
                    handleFieldClick={this.handleConfirmRemoveIntegrationPopupFieldClick.bind(
                        this
                    )}
                    handleSubmit={this.handleConfirmRemoveIntegrationPopupSubmit.bind(
                        this
                    )}
                    handleCloseButtonClick={this.handleConfirmRemoveIntegrationPopupCloseButtonClick.bind(
                        this
                    )}
                />
            );
        }

        let tabProps = this.getTabProps(activeTab) || {};
        let actions = [];
        if (tabProps.actions && tabProps.actions.length) {
            for (var i = 0; i < tabProps.actions.length; i++) {
                let action = tabProps.actions[i];
                if (action.name == "download" && action.url) {
                    actions.push(
                        <a
                            key={i}
                            href={action.url}
                            className={
                                "tab-action download name-" + action.name
                            }
                            target="_blank"
                        >
                            {action.label}
                        </a>
                    );
                } else {
                    actions.push(
                        <button
                            key={i}
                            className={"tab-action name-" + action.name}
                            onClick={this.handleActionClick.bind(
                                this,
                                action.name
                            )}
                        >
                            {action.label}
                        </button>
                    );
                }
            }
            actions = <div className="layout-actions">{actions}</div>;
        }

        let title;
        if (tabProps.header) {
            title = (
                <div className="layout-title">
                    <h1>{tabProps.header}</h1>
                </div>
            );
        }

        return (
            <div
                className={
                    "dictionary-content " +
                    activeTab +
                    (this.props.tabForm ? " layout-form" : "")
                }
            >
                {title}
                {actions}
                {this.renderBodyContent()}
                {popupForms}
            </div>
        );
    }

    renderBodyContent() {
        let activeTab = this.getActiveTab();

        if (this.props.tabForm) {
            return (
                <CompanyTabForm
                    {...this.props.tabForm}
                    onFormActionClick={this.handleTabFormActionClick.bind(this)}
                    onCancelButtonClick={
                        this.props.onTabFormCancelButtonClick || function () {}
                    }
                    onAlterActionButtonClick={this.handleTabFormAlterActionClick.bind(
                        this
                    )}
                    onFieldChange={
                        this.props.onTabFormFieldChange || function () {}
                    }
                    onSubmit={this.handleTabFormSubmit.bind(this)}
                />
            );
        }

        if (this.props.listItemCard) {
            return (
                <CompanyTabItemCard
                    {...this.props.listItemCard}
                    onActionClick={this.handleTabEditActionClick.bind(this)}
                    onBackLinkClick={this.handleTabItemCardBackLinkClick.bind(
                        this
                    )}
                />
            );
        }

        let props = this.getTabProps(activeTab);
        if (!props) {
            return null;
        }

        return (
            <CompanyTabList
                {...props}
                name={activeTab}
                onActionClick={this.handleTabActionClick.bind(this)}
                onEditActionClick={this.handleTabEditActionClick.bind(this)}
                onListItemClick={this.handleListItemClick.bind(this)}
            />
        );
    }

    handleActionClick(name, e) {
        return this.handleTabActionClick(e, name);
    }

    handleTabActionClick(e, name) {
        const handler = this.props.onTabActionClick || function () {};
        handler(this.props.companyId, this.getActiveTab(), name);
    }

    handleTabEditActionClick(actionName, itemId) {
        const handler = this.props.onTabEditActionClick || function () {};
        handler(this.props.companyId, this.getActiveTab(), actionName, itemId);

        switch (actionName) {
            case "delete":
                this.setState({
                    showConfirmDeletePopup: true,
                });
                break;
        }
    }

    handleTabFormActionClick(e, action) {
        const handler = this.props.onTabEditActionClick || function () {};
        handler(
            this.props.companyId,
            this.getActiveTab(),
            action.name,
            action.itemId
        );

        switch (action.name) {
            case "delete":
                this.setState({
                    showConfirmDeletePopup: true,
                });
                break;
            case "remove-integration":
                this.setState({
                    showConfirmRemoveIntegrationPopup: true,
                });
                break;
        }
    }

    handleListItemClick(itemId) {
        const handler = this.props.onTabListItemClick || function () {};
        switch (this.getActiveTab()) {
            case "accounts":
                handler(this.props.companyId, this.getActiveTab(), itemId);
                break;
        }
    }

    handleTabFormSubmit(e, form) {
        const handler = this.props.onTabFormSubmit || function () {};
        handler(e, form, this.props.companyId, this.getActiveTab());
    }

    handleConfirmDeletePopupFieldClick(e, field) {
        switch (field.getFieldName()) {
            case "cancel":
                this.setState({
                    showConfirmDeletePopup: false,
                });
                break;
        }
    }

    handleConfirmDeletePopupSubmit(e, form) {
        const handler = this.props.onConfirmDeletePopupSubmit || function () {};
        handler(e, form, this.props.companyId, this.getActiveTab());
    }

    handleConfirmDeletePopupCloseButtonClick(e) {
        this.setState({
            showConfirmDeletePopup: false,
        });
    }

    handleConfirmPopupFieldClick(e, field) {
        switch (field.getFieldName()) {
            case "cancel":
                return this.handleConfirmPopupCloseButtonClick(e);
        }
    }

    handleConfirmPopupSubmit(e, form) {
        const handler = this.props.onConfirmPopupSubmit || function () {};
        handler(e, form, this.props.companyId, this.getActiveTab());
    }

    handleConfirmPopupCloseButtonClick(e) {
        const handler = this.props.onConfirmPopupClose || function () {};
        handler(e);
    }

    handleTabItemCardBackLinkClick() {
        const handler = this.props.onTabClick || function () {};
        handler(this.props.companyId, this.getActiveTab());
    }

    handleTabFormAlterActionClick(e, name) {
        const handler =
            this.props.onTabFormAlterActionButtonClick || function () {};
        handler(e, name, {
            companyId: this.props.companyId,
        });
    }

    handleIntegrationPopupSubmit(e, form) {
        const handler =
            this.props.onBankIntegrationPopupSubmit || function () {};
        handler(e, form, this.props.companyId);
    }

    handleIntegrationPopupCloseButtonClick(e) {
        const handler =
            this.props.onBankIntegrationPopupClose || function () {};
        handler(e);
    }

    handleConfirmRemoveIntegrationPopupFieldClick(e, field) {
        switch (field.getFieldName()) {
            case "cancel":
                this.setState({
                    showConfirmRemoveIntegrationPopup: false,
                });
                break;
        }
    }

    handleConfirmRemoveIntegrationPopupSubmit(e, form) {
        const handler =
            this.props.onConfirmRemoveIntegrationPopupSubmit || function () {};
        handler(e, form, this.props.companyId, this.getActiveTab());
    }

    handleConfirmRemoveIntegrationPopupCloseButtonClick(e) {
        this.setState({
            showConfirmRemoveIntegrationPopup: false,
        });
    }

    getActiveTab() {
        return this.state.activeTab || this.props.activeTab;
    }

    getTabProps(tab) {
        let props;

        switch (tab) {
            case "targets":
                return this.props.targets;
            case "owners":
                return this.props.owners;
            case "workers":
                return this.props.workers;
            case "categories":
                return this.props.categories;
            case "partners":
                return this.props.partners;
            case "accounts":
                return this.props.accounts;
            default:
                return null;
        }
    }
}
