import Form from "../../forms/form.jsx";

export default class Feedback extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="cabinet-settings-layout">
                <div className="layout-title">
                    <p>{this.props.header}</p>
                </div>
                <div className="layout-body">
                    <div className="settings-form">
                        <Form
                            {...this.props.form}
                            onSubmit={this.props.onSubmit || function () {}}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
