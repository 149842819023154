import Container from "../container.jsx";
import FormLayout from "../../components/form-layout.jsx";

export default class TargetEditFormContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.targetEditForm");
    }

    render() {
        if (!this.state.state) {
            return null;
        }

        return <FormLayout {...this.props} {...this.state.state} />;
    }
}
