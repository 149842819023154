import * as Store from "../store.js";
import * as StoreHelper from "../helpers/store-helper.js";
import { request } from "../../components/repositories/repository.js";

let loading = false,
    timeout;

export function handleFieldChange(e, props, field, form, storeFormPath) {
    switch (props.type) {
        case "autocomplete":
            handleAutoloadFieldChange(e, props, field, form, storeFormPath);
            break;
    }
}
export function handleFieldBlur(e, props, field, form, storeFormPath) {
    field.validate();
}

function handleAutoloadFieldChange(e, props, field, form, storeFormPath) {
    let target = e.target;
    switch (target.tagName) {
        case "BUTTON":
            handleAutoloadDropdownSelect(e, props, field, form, storeFormPath);
            break;
        case "INPUT":
            switch (target.getAttribute("type")) {
                case "text":
                    handleAutoloadSearchInputChange(
                        e,
                        props,
                        field,
                        form,
                        storeFormPath
                    );
                    break;
            }
            break;
    }
}

function handleAutoloadDropdownSelect(e, props, field, form, storeFormPath) {
    let storeFieldPath = StoreHelper.getFieldPath(
        storeFormPath + ".fields",
        props.name
    );
    Store.setStore(
        e.target.getAttribute("data-value"),
        storeFieldPath + ".value"
    );
    Store.setStore(e.target.textContent, storeFieldPath + ".inputValue");
    Store.setStore(null, storeFieldPath + ".data.options");
}

function handleAutoloadSearchInputChange(e, props, field, form, storeFormPath) {
    if (loading) {
        return null;
    }

    let storeFieldPath = StoreHelper.getFieldPath(
        storeFormPath + ".fields",
        props.name
    );
    let value = props.value;

    Store.setStore("", storeFieldPath + ".value");
    Store.setStore(value, storeFieldPath + ".inputValue");

    if (value.length < 3) {
        Store.setStore(null, storeFieldPath + ".data.options");
        return null;
    }

    let url = props.data.searchUrl;
    if (!url) {
        Store.setStore(null, storeFieldPath + ".data.options");
        return null;
    }

    // clearTimeout(timeout);
    // timeout = setTimeout(function(){
    loading = true;
    Store.setStore(true, storeFieldPath + ".data.loading");
    request("post", url, {
        search_string: value,
    }).then((response) => {
        loading = false;
        Store.setStore(false, storeFieldPath + ".data.loading");
        if (response && response.length) {
            Store.setStore(response, storeFieldPath + ".data.options");
        } else {
            Store.setStore(null, storeFieldPath + ".data.options");
        }
    });
    // }, 1000);
}
