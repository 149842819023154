import Price from "../../price.jsx";
import LetterItemHelper from "../../../helpers/letter-item-helper.js";
import newId from "../../forms/utils/new-id.js";
import { rgba2hex } from "../../../../components/helpers/html-helper.js";

export default class Graph extends React.Component {
    constructor(props) {
        super(props);
        this.letterHelper = new LetterItemHelper(5);
        this.chartRef = React.createRef();
        this.colors = [
            "#B9E398",
            "#7BB0F9",
            "#C581DD",
            "#E9EB86",
            "#F671C3",
            "#EFD47B",
            "#9DA4FA",
        ];
    }

    componentDidMount() {
        this.handleChart();
    }

    componentDidUpdate() {
        this.handleChart();
    }

    render() {
        this.letterHelper.resetCounter();

        let markers = [];
        let colors = this.getColors(),
            colorCounter = 0;
        if (this.props.markers) {
            for (let i = 0; i < this.props.markers.length; i++) {
                let item = this.props.markers[i],
                    style;

                style = { backgroundColor: colors[colorCounter++] };

                // if (item.styleColor) {
                //     style = { backgroundColor: item.styleColor };
                // }

                markers.push(
                    <div key={i} className="markers-list-item">
                        <div
                            className={
                                "list-item-col icon " +
                                this.letterHelper.getLetterColor()
                            }
                            style={style}
                        ></div>
                        <button
                            className="list-item-col caption"
                            title={item.label}
                            onClick={this.handleMarkerClick.bind(this, item)}
                        >
                            {item.label}
                        </button>
                        <div className="list-item-col value">
                            <Price value={item.value} />
                        </div>
                    </div>
                );
            }
        }

        let chart = this.renderChart();

        let showAllButton;
        if (this.props.showAllLabel) {
            showAllButton = (
                <button
                    type="button"
                    className="show-all-button"
                    onClick={this.props.onShowAllButtonClick || function () {}}
                >
                    {this.props.showAllLabel}
                </button>
            );
        }

        let note;
        if (!this.props.chartData) {
            note = (
                <p className={"chart-note chart-type-" + this.props.viewName}>
                    {this.props.noDataLabel}
                </p>
            );
        }

        return (
            <div className="analytics-graph">
                <div className="graph-container">
                    <div className="graph-header">
                        <p className="header-title">{this.props.label}</p>
                        <Price value={this.props.totalPrice} />
                    </div>
                    <div className="graph-chart">
                        {chart}
                        {note}
                    </div>
                    <div className="graph-markers">{markers}</div>
                    {showAllButton}
                </div>
            </div>
        );
    }

    renderChart() {
        switch (this.props.viewName) {
            case "column":
                if (!this.props.chartData) {
                    return (
                        <div
                            className="column-chart-empty"
                            ref={this.chartRef}
                            key={newId("chart-")}
                        >
                            <div className="line"></div>
                            <div className="line mid"></div>
                            <div className="line"></div>
                            <div className="line mid"></div>
                            <div className="line"></div>
                            <div className="line mid"></div>
                            <div className="line"></div>
                            <div className="line mid"></div>
                            <div className="line bottom"></div>
                        </div>
                    );
                }
                return (
                    <div
                        className="column-chart"
                        data-chart={JSON.stringify(this.props.chartData)}
                        ref={this.chartRef}
                        key={newId("chart-")}
                    ></div>
                );
            case "table":
                return null;
            default:
                if (!this.props.chartData) {
                    return (
                        <div
                            className="pie-chart-empty"
                            ref={this.chartRef}
                            key={newId("chart-")}
                        ></div>
                    );
                }
                return (
                    <div
                        className="pie-chart"
                        data-chart={JSON.stringify(this.props.chartData)}
                        ref={this.chartRef}
                        key={newId("chart-")}
                    ></div>
                );
        }
    }

    handleChart() {
        if (!this.chartRef.current || typeof google == "undefined" || !google) {
            return false;
        }

        google.charts.load("current", { packages: ["corechart"] });
        google.charts.setOnLoadCallback(() => {
            this.drawChart();
        });
    }

    drawChart() {
        if (!this.chartRef.current) {
            return false;
        }

        switch (this.props.viewName) {
            case "column":
                return this.drawColumnChart(this.getColors());
            case "table":
                return this.drawTableChart();
            default:
                return this.drawPieChart(this.getColors());
        }
    }

    drawPieChart(colors) {
        const chart = new google.visualization.PieChart(this.chartRef.current);
        chart.clearChart();

        let chartData;
        try {
            chartData = JSON.parse(this.chartRef.current.dataset["chart"]);
        } catch (e) {
            // ignore
        }

        if (!chartData) {
            return null;
        }

        const data = google.visualization.arrayToDataTable(chartData.markers),
            options = {
                legend: "none",
                pieSliceText: "none",
                tooltip: { trigger: "none" },
                chartArea: {
                    width: "100%",
                    height: "100%",
                },
                enableInteractivity: false,
                pieHole: 0.8,
                slices: {},
            };

        for (let i = 0; i < colors.length; i++) {
            options.slices[i] = { color: colors[i] };
        }

        chart.draw(data, options);
    }

    drawColumnChart(colors) {
        const chart = new google.visualization.ColumnChart(
            this.chartRef.current
        );
        chart.clearChart();

        let chartData;
        try {
            chartData = JSON.parse(this.chartRef.current.dataset["chart"]);
        } catch (e) {
            // ignore
        }

        if (!chartData) {
            return null;
        }

        let markers = chartData.markers;
        if (markers.length) {
            let colorCounter = 0;
            markers[0].push({ role: "style" });
            for (let i = 1; i <= markers.length; i++) {
                if (colors[i - 1]) {
                    markers[i].push(colors[i - 1]);
                }
                colorCounter++;
            }
        }

        const data = google.visualization.arrayToDataTable(markers),
            options = {
                chartArea: {
                    width: "100%",
                    height: "100%",
                },
                enableInteractivity: false,
            };

        chart.draw(data, options);
    }

    drawTableChart() {
        return null;
    }

    handleMarkerClick(item, e) {
        const handler = this.props.onMarkerClick || function () {};
        handler(e, item);
    }

    getColors() {
        let colors = [],
            colorCounter = 0;
        if (this.props.markers) {
            for (let i = 0; i < this.props.markers.length; i++) {
                let item = this.props.markers[i];

                if (item.styleColor) {
                    let colorValue = item.styleColor;
                    if (colorValue.indexOf("rgb") > -1) {
                        colorValue = rgba2hex(colorValue);
                    }
                    colors.push(colorValue);
                } else {
                    colors.push(this.colors[colorCounter++]);
                }
            }
        }

        return colors;
    }
}
