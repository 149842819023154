import * as AuthHandlers from '../handlers/auth-handlers.js';

export function handleAuthFormSubmit(e, form) {
    e.preventDefault();
    AuthHandlers.authorizeForm(form);
}

export function handleRegistrationFormSubmit(e, form) {
    e.preventDefault();
    AuthHandlers.registerForm(form);
}

export function handleRestoreFormSubmit(e, form) {
    e.preventDefault();
    AuthHandlers.restoreForm(form);
}
