export default class LoadingLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className="loading-layout">
                <p>Загрузка...</p>
            </div>
        );
    }
}
