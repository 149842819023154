import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as AuthHandlers from "../handlers/auth-handlers.js";
import * as CabinetHandlers from "../handlers/cabinet-handlers.js";
import * as TransactionHandlers from "../handlers/transactions-handlers.js";
import { getNestedValue } from "../../components/helpers/objects-helper.js";

export function handlePageHeaderMenuItemClick(e, item) {
    if (!item) {
        return;
    }

    LayoutHandlers.loadLayoutByName(item.name, {
        pushHistoryState: true,
    });
}

export function handlePageHeaderUserbarClick(e) {
    // теперь у нас dropdown
    // LayoutHandlers.loadLayoutByName('cabinet', {
    //     pushHistoryState: true
    // });
}

export function handlePageHeaderUserbarMenuButtonClick(e, name, params) {
    params = params || {};
    switch (name) {
        case "logout":
            return AuthHandlers.logout();
        default:
            let layoutParams = {
                pushHistoryState: true,
            };
            if (name == "companies") {
                if (params.loadDefaultCompany) {
                    layoutParams.submenu = "companies";
                    layoutParams.loadDefaultCompany = true;
                    layoutParams.defaultCompanyTabName = "about";
                }
            }
            return LayoutHandlers.loadLayoutByName(
                "cabinet",
                layoutParams
            ).then((response) => {
                return CabinetHandlers.loadMenuLayout(name);
            });
    }
}

export function handleErrorsPopupCloseButtonClick(e) {
    LayoutHandlers.closeErrorsPopup();
}

export function handleMessagesPopupCloseButtonClick(e) {
    LayoutHandlers.closeMessagesPopup();
}

export function handlePageHeaderLogoClick(e) {
    LayoutHandlers.loadHomeLayout().then(() => {
        LayoutHandlers.setLayoutHistoryState();
    });
}

export function handlePageHeaderSearchSubmit(form, searchString) {
    LayoutHandlers.loadLayoutByName("transactions", {
        pushHistoryState: true,
    }).then((response) => {
        let companyId = getNestedValue(
            response,
            "data",
            "operations",
            "companyId"
        );
        const formData = new FormData(form);
        // параллельно заполним данные формы, чтоб было видно
        TransactionHandlers.applyFilterFormData(formData);
        return TransactionHandlers.filterOperations(companyId, formData);
    });
}

export function handlePageHeaderFeedbackBarMenuButtonClick(e, name, params) {
    params = params || {};
    switch (name) {
        case "feedback":
            let layoutParams = {
                pushHistoryState: true,
            };
            return LayoutHandlers.loadLayoutByName(
                "cabinet",
                layoutParams
            ).then((response) => {
                return CabinetHandlers.loadMenuLayout(name);
            });
    }
}

export function loadHomeLayout() {
    LayoutHandlers.loadHomeLayout().then(() => {
        LayoutHandlers.setLayoutHistoryState();
    });
}

export function handleCompanyMenuItemClick(e, companyId, item) {
    switch (item.name) {
        case "about":
            console.log(item);
            return LayoutHandlers.loadLayoutByName("company-about", {
                companyId: companyId,
                tab: item.name,
            }).then((response) => {
                LayoutHandlers.setActiveCompanyMenuItem(item);
                return response;
            });
        default:
            return LayoutHandlers.loadLayoutByName("company-dictionary", {
                companyId: companyId,
                tab: item.name,
            }).then((response) => {
                LayoutHandlers.setActiveCompanyMenuItem(item);
                return response;
            });
    }
}

export function handleLicenseExpiredPopupCloseButtonClick(e) {
    LayoutHandlers.closeLicenseExpiredPopup();
}

export function handleLicenseExpiredActionClick(e, action) {
    switch (action.name) {
        case "cabinet-license-layout":
            return LayoutHandlers.loadLayoutByName("cabinet", {
                pushHistoryState: true,
            }).then((response) => {
                return CabinetHandlers.loadMenuLayout("license");
            });
    }

    return Promise.resolve();
}
