export default class LetterItemHelper {
    constructor(limit, offset) {
        this.colorLimit = limit || 99;
        this.colorOffset = offset || 0;
        this.colorCounter = this.colorOffset;
    }

    getLetterSymbol(item) {
        if (!item.letter) {
            return this.getFirstLetter(item.caption || item.label);
        }

        switch (item.letter) {
            case "sum":
                return String.fromCharCode(8721);
        }
    }

    getLetterClass(item) {
        let className = this.getLetterColor(item);
        switch (item.letter) {
            case "sum":
                className += " icon-sub-correction";
        }

        return className;
    }

    getFirstLetter(string) {
        if (!string) {
            return null;
        }

        string = string + "";

        return string.charAt(0);
    }

    getLetterColor(item) {
        return item && item.color
            ? item.color
            : "color-" + this.getColorCounter();
    }

    getColorCounter() {
        if (this.colorCounter >= this.colorLimit) {
            this.colorCounter = 0;
        }

        return ++this.colorCounter;
    }

    resetCounter() {
        this.colorCounter = this.colorOffset;
    }
}
