import Container from "../container.jsx";
import Filter from "../../components/filter/filter.jsx";

export default class FilterContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.analyticsFilter");
        this.formRef = React.createRef();
    }

    render() {
        return (
            <Filter {...this.props} {...this.state.state} ref={this.formRef} />
        );
    }

    getFormData() {
        return this.formRef.current.getFormData();
    }
}
