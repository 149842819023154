import * as CompanyHandlers from "../handlers/company-handlers.js";
import * as OperationsHandlers from "../handlers/operations-handlers.js";
import * as TransactionsHandlers from "../handlers/transactions-handlers.js";
import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as AnalyticsHandlers from "../handlers/analytics-handlers.js";
import * as CabinetHandlers from "../handlers/cabinet-handlers.js";
import { getNestedValue } from "../../components/helpers/objects-helper.js";

export function handleCompanyClick(e, params) {
    TransactionsHandlers.loadCompany(params.item.id).then((response) => {
        TransactionsHandlers.setCompanyHistoryState(params.item.id, response);
    });
}

export function handleActiveCompanyClick(e, params) {
    return LayoutHandlers.loadLayoutByName("home", {
        pushHistoryState: true,
    });
}

export function handleOperationAddClick(e, companyId, operationName) {
    switch (operationName) {
        case "transfer":
            return OperationsHandlers.loadOperationTransferPopup({
                companyId: companyId,
            });
        default:
            // рудимент
            return OperationsHandlers.loadOperationAddPopup(
                { companyId: companyId },
                operationName
            );
    }
}

export function handleCloseOperationAddPopup() {
    OperationsHandlers.closeOperationAddPopup();
}

export function handleCloseOperationEditPopup() {
    OperationsHandlers.closeOperationEditPopup();
}

export function handleOperationAddPopupSubmit(e, form, companyId, filterForm) {
    e.preventDefault();
    OperationsHandlers.addOperationForm(form).then((response) => {
        // CompanyHandlers.refreshOperations(companyId);
        TransactionsHandlers.filterOperations(companyId, filterForm).then(
            () => {
                TransactionsHandlers.filterGraphs(companyId, filterForm).then(
                    () => {
                        TransactionsHandlers.loadCompany(companyId);
                    }
                );
            }
        );
    });
}

export function handleOperationEditPopupSubmit(e, form, companyId, filterForm) {
    e.preventDefault();
    OperationsHandlers.editOperationForm(form).then((response) => {
        // CompanyHandlers.refreshOperations(companyId);
        TransactionsHandlers.filterOperations(companyId, filterForm).then(
            () => {
                TransactionsHandlers.filterGraphs(companyId, filterForm);
            }
        );
    });
}

export function handleOperationEditPopupActionClick(e, params) {
    return OperationsHandlers.handleAction(params);
}

export function handleOperationAddPopupFieldClick(
    e,
    field,
    form,
    params,
    companyId
) {
    OperationsHandlers.rememberFormValues(form);
    OperationsHandlers.rememberFormLayout("transactions");
    const action = getNestedValue(params, "action", "name");
    handleOperationsAction(action, companyId);
}

export function handleOperationEditPopupFieldClick(
    e,
    field,
    form,
    params,
    companyId
) {
    OperationsHandlers.rememberFormValues(form);
    OperationsHandlers.rememberFormLayout("transactions");
    const action = getNestedValue(params, "action", "name");
    handleOperationsAction(action, companyId);
}

export function handleOperationActionClick(e, item, action) {
    switch (action.name) {
        case "edit":
            switch (item.type) {
                case "transfer-income":
                case "transfer-outcome":
                    return OperationsHandlers.loadOperationTransferPopup({
                        id: item.id,
                    });
                default:
                    return OperationsHandlers.loadOperationEditPopup({
                        id: item.id,
                    });
            }
        case "copy":
            return OperationsHandlers.loadOperationAddPopup(
                {
                    companyId: item.companyId,
                    copyOf: item.id,
                },
                item.type
            );
        case "delete":
            return OperationsHandlers.loadOperationRemovePopup(item);
    }
}

export function handleOperationsScrollEdge(companyId, listCount, form) {
    TransactionsHandlers.loadMoreOperations(companyId, listCount, form);
}

export function handleGraphsFilterDateChecklistClick(e, item) {
    CompanyHandlers.setGraphsFilterDateValue(item.value);
}

export function handleGraphsDatepickerSelectButtonClick(e, params) {
    CompanyHandlers.setGraphsFilterDateRange(params.range);
}

export function handleOperationClick(e, item) {
    switch (item.type) {
        case "transfer-income":
        case "transfer-outcome":
            return OperationsHandlers.loadOperationTransferPopup({
                id: item.id,
            });
        default:
            return OperationsHandlers.loadOperationEditPopup({ id: item.id }, 'edit');
    }
}

export function handleOperationsFilterFieldChange(e, params) {
    OperationsHandlers.setOperationsFilterParams(params);
}

export function handleOperationsFilterSubmit(e, form, companyId) {
    e.preventDefault();
    TransactionsHandlers.filterOperations(companyId, form).then(() => {
        TransactionsHandlers.filterGraphs(companyId, form);
    });
}

export function handleGraphsMoreButtonClick() {
    LayoutHandlers.loadLayoutByName("analytics", {
        pushHistoryState: true,
    });
}

export function handleGraphsMarkerClick(e, marker, companyId) {
    console.log(marker);
    if (!marker.id) {
        return null;
    }

    const formData = new FormData();

    // а на страницу транзакций попать с фильтром по категории
    formData.append("categories", marker.id);

    LayoutHandlers.loadLayoutByName("transactions", {
        pushHistoryState: true,
        params: {
            backlink: {
                name: "analytics",
            },
        },
    }).then((response) => {
        // параллельно заполним данные формы, чтоб было видно
        TransactionsHandlers.applyFilterFormData(formData);
        return TransactionsHandlers.filterOperations(companyId, formData);
    });
}

export function handleOperationRemovePopupSubmit(e, form, companyId) {
    e.preventDefault();
    OperationsHandlers.removeOperationForm(form).then((response) => {
        TransactionsHandlers.filterOperations(companyId);
    });
}

export function handleCloseOperationRemovePopup() {
    OperationsHandlers.closeOperationRemovePopup();
}

export function handleOperationTransferPopupSubmit(e, form, companyId) {
    e.preventDefault();
    OperationsHandlers.transferOperationForm(form).then((response) => {
        TransactionsHandlers.filterOperations(companyId);
    });
}

export function handleCloseOperationTransferPopup() {
    OperationsHandlers.closeOperationTransferPopup();
}

export function handleBacklinkClick(e, backlink, companyId) {
    const filter = getNestedValue(backlink, "params", "filter");
    if (filter) {
        AnalyticsHandlers.saveFilter(filter);
    }

    return LayoutHandlers.loadLayoutByName(backlink.layout, {
        pushHistoryState: true,
    });
}

export function loadChartHelpers() {
    CompanyHandlers.loadChartHelpers();
}

export function handleActionClick(e, action) {
    switch (action.name) {
        case "license":
            return LayoutHandlers.loadLayoutByName("cabinet", {
                pushHistoryState: true,
            }).then((response) => {
                return CabinetHandlers.loadMenuLayout("license");
            });
    }
}

function handleOperationsAction(name, companyId) {
    let menuNames = {
        "add-account": "accounts",
        "add-service": "categories",
        "add-client": "partners",
    };

    let menuName = menuNames[name];

    if (menuName) {
        return LayoutHandlers.loadLayoutByName("company-dictionary", {
            companyId: companyId,
            tab: menuName,
        }).then((response) => {
            LayoutHandlers.setActiveCompanyMenuItem({
                name: menuName,
            });
            return CabinetHandlers.loadCompanyTabAddForm(companyId, menuName);
        });
    }
}
