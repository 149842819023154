import {getNestedValue} from '../../../../components/helpers/objects-helper.js';
import CustomSelect from './custom-select.jsx';

export default class CustomActionsSelect extends CustomSelect {
    constructor(props) {
        super(props);

        this.valuableOptions = this.hasValuableOptions();
    }

    renderCustomList() {
        this.valuableOptions = this.hasValuableOptions();

        let list = [],
            optionsData = this.getOptions(),
            actionsData = this.getActions();

        if ((optionsData && optionsData.length) || (actionsData && actionsData.length)) {
            if (this.state.activeLabel) {
                list.push(this.renderCustomListButton('active', {
                    label: this.state.activeLabel,
                    value: this.state.activeValue
                }));
            }

            if (actionsData && actionsData.length) {
                for(let i=0; i<actionsData.length; i++) {
                    let item = actionsData[i];
                    list.push(this.renderCustomListAction(i, item));
                }
            }

            if (optionsData && optionsData.length) {
                for(let i=0; i<optionsData.length; i++) {
                    let item = optionsData[i];
                    list.push(this.renderCustomListButton(i, item));
                }
            }

            if (list.length) {
                list = <div className={'select-list' + (this.state.showList ? ' show' : '')}>{list}</div>;
            }
        }

        return list;
    }

    renderCustomListButton(index, item) {
        if (!item.value && this.state.activeValue) {
            return null;
        }
        if ((item.value == this.state.activeValue) && (index != 'active')) {
            return null;
        }

        return super.renderCustomListButton(index, item);
    }

    renderCustomListAction(index, item) {
        return <button key={'action-' + index}
            className={this.getListActionClass(item)}
            type="button"
            onClick={this.handleListActionClick.bind(this, item)}
        >
            <span className="pre-icon"></span>
            <span className="button-text">{item.label}</span>
            <span className="post-icon"></span>
        </button>
    }

    handleListActionClick(action, e) {
        const handler = this.props.onClick;
        if (handler) {
            handler(e, this, {
                action: action
            });
        }
    }

    getActions() {
        if (this.props.actions && this.props.actions.length) {
            return this.props.actions;
        }

        return getNestedValue(this.props, 'data', 'actions');
    }

    getListActionClass(action) {
        let className = 'select-list-action name-' + action.name;

        if (this.valuableOptions) {
            className += ' lesser-action';
        }

        return className;
    }

    hasValuableOptions() {
        const optionsData = this.getOptions();

        // по дизайну если не из чего выбирать - экшны должны быть побольше
        // считаем, что "есть из чего выбирать" - это когда у нас есть другие (не активные) элементы

        let length = 0;
        for(let i=0; i<optionsData.length; i++) {
            if (optionsData[i].value || (!optionsData[i].value && !this.state.activeValue)) {
                length++;
            }
        }

        return length > 1;
    }
}
