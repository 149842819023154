import * as Store from "../store.js";
import * as CompanyDictionaryRepository from "../../components/repositories/company-dictionary-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse, submitForm } from "../helpers/request-helper.js";

export function loadLayout(params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        CompanyDictionaryRepository.getLayout(params),
        (response) => {
            if (!response) {
                return false;
            }
            Store.setStore(response);
            Store.setStore(params.tab, "data.cabinetCompany.activeTab");
            Store.setStore(params.companyId, "data.companyId");

            return true;
        }
    );
}

export function setCompanyHistoryState(params, response) {
    history.pushState(
        params,
        null,
        "/company/dictionary/" + params.tab + "?id=" + params.companyId
    );
}
