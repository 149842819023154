import { isMobile } from "../helpers/layout-helper.js";

export default class PageHeader extends React.Component {
    constructor(props) {
        super(props);

        this.userbarRef = React.createRef();
        this.searchInputRef = React.createRef();
        this.searchFormRef = React.createRef();
        this.feedbackBarRef = React.createRef();

        this.state = {
            openSearch: false,
            userbarDropdownOpened: false,
            feedbackBarDropdownOpened: false,
            isMobile: isMobile(),
        };

        this.windowScrollHandler = (e) => {
            const isMobileWindow = isMobile();
            if (this.state.isMobile != isMobileWindow) {
                this.setState({
                    isMobile: isMobileWindow,
                });
            }
        };

        this.documentClickHandler = (e) => {
            if (
                this.state.userbarDropdownOpened &&
                (!this.userbarRef ||
                    !this.userbarRef.current ||
                    !this.userbarRef.current.contains(e.target))
            ) {
                this.setState({
                    userbarDropdownOpened: false,
                });
            }

            if (
                this.state.openSearch &&
                (!this.searchFormRef ||
                    !this.searchFormRef.current ||
                    !this.searchFormRef.current.contains(e.target))
            ) {
                this.setState({
                    openSearch: false,
                });
            }

            if (
                this.state.feedbackBarDropdownOpened &&
                (!this.feedbackBarRef ||
                    !this.feedbackBarRef.current ||
                    !this.feedbackBarRef.current.contains(e.target))
            ) {
                this.setState({
                    feedbackBarDropdownOpened: false,
                });
            }
        };

        window.addEventListener("resize", this.windowScrollHandler);
        window.addEventListener("click", this.documentClickHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.windowScrollHandler);
        window.removeEventListener("click", this.documentClickHandler);
    }

    render() {
        const menu = this.renderMenu();
        const mobileMenu = this.renderMobileMenu();

        let searchForm;
        if (this.props.searchForm) {
            searchForm = (
                <form
                    action=""
                    method="post"
                    onSubmit={this.handleSearchSubmit.bind(this)}
                    ref={this.searchFormRef}
                >
                    <input type="hidden" name="date_range" value="all-time" />
                    <div className="field-input">
                        <div className="form-control">
                            <input
                                type="text"
                                name="search_string"
                                className="form-input"
                                placeholder={this.props.searchForm.inputLabel}
                                ref={this.searchInputRef}
                            />
                        </div>
                    </div>
                    <button
                        className="button-submit"
                        onClick={this.handleSearchSubmit.bind(this)}
                    ></button>
                </form>
            );
        }

        const pageHeaderFeedbackBar = this.renderFeedbackBar();
        const pageHeaderUserbar = this.renderUserbar();

        return (
            <div className="page-header">
                <div className="page-header-limiter">
                    <div className="page-header-logo">
                        <button
                            type="button"
                            className="logo"
                            onClick={this.props.onLogoClick || function () {}}
                        ></button>
                    </div>
                    <div className="page-header-center">
                        {menu}
                        <div
                            className={
                                "page-header-search" +
                                (this.state.openSearch ? " opened" : "")
                            }
                        >
                            {searchForm}
                        </div>
                    </div>
                    {pageHeaderFeedbackBar}
                    {pageHeaderUserbar}
                    {mobileMenu}
                </div>
            </div>
        );
    }

    renderUserbar() {
        if (this.state.isMobile) {
            return null;
        }

        let userbarButton,
            userbarDropdown = [];
        if (this.props.userbar) {
            userbarButton = (
                <button
                    className="userbar-button"
                    onClick={this.handleUserbarClick.bind(this)}
                >
                    <span className="icon"></span>
                    <span className="text">{this.props.userbar.username}</span>
                    <span className="icon-close">
                        {String.fromCharCode(215)}
                    </span>
                </button>
            );

            userbarDropdown = this.renderUserbarDropdown();
        }

        let dropdownContainerClassName = "userbar-dropdown-container";
        if (this.state.userbarDropdownOpened) {
            dropdownContainerClassName += " opened";
        }

        return (
            <div className="page-header-userbar" ref={this.userbarRef}>
                <div className={dropdownContainerClassName}>
                    {userbarDropdown}
                    {userbarButton}
                </div>
            </div>
        );
    }

    renderMenu() {
        if (this.state.isMobile) {
            return null;
        }

        let menu = [],
            menuItem;
        if (this.props.menu) {
            for (let i = 0; i < this.props.menu.length; i++) {
                menuItem = this.props.menu[i];
                menu.push(
                    <button
                        key={i}
                        className={menuItem.active ? "active" : null}
                        onClick={this.handleMenuItemClick.bind(this, i)}
                    >
                        {menuItem.label}
                    </button>
                );
            }
        }

        return <div className="page-header-menu">{menu}</div>;
    }

    renderMobileMenu() {
        if (!this.state.isMobile) {
            return null;
        }

        let menu = [],
            menuItem;
        if (this.props.menu) {
            for (let i = 0; i < this.props.menu.length; i++) {
                menuItem = this.props.menu[i];
                menu.push(
                    <button
                        key={i}
                        className={
                            "name-" +
                            menuItem.name +
                            (menuItem.active ? " active" : "")
                        }
                        onClick={this.handleMenuItemClick.bind(this, i)}
                    >
                        <div className="item-icon-container">
                            <span className="item-icon"></span>
                        </div>
                        <span className="item-label">{menuItem.label}</span>
                    </button>
                );
            }
        }

        if (this.props.userbar) {
            menu.push(
                <button
                    key="account"
                    className={
                        "name-account" +
                        (this.props.userbar.active ? " active" : "")
                    }
                    onClick={this.handleUserbarMenuButtonClick.bind(
                        this,
                        null,
                        false
                    )}
                >
                    <div className="item-icon-container">
                        <span className="item-icon"></span>
                    </div>
                    <span className="item-label">
                        {this.props.userbar.label}
                    </span>
                </button>
            );
        }

        let containerClassName = "page-header-mobile-menu";
        if (this.state.userbarDropdownOpened) {
            containerClassName += " userbar-dropdown-opened";
        }

        return (
            <div className={containerClassName} ref={this.userbarRef}>
                <div className="menu-container">{menu}</div>
            </div>
        );
    }

    renderUserbarDropdown() {
        let userbarDropdown = [];
        const userbarMenu = this.props.userbar.menu;
        if (userbarMenu) {
            for (let i = 0; i < userbarMenu.length; i++) {
                userbarDropdown.push(
                    <button
                        key={i}
                        onClick={this.handleUserbarMenuButtonClick.bind(
                            this,
                            userbarMenu[i].name,
                            true
                        )}
                    >
                        <span
                            className={"icon icon-" + userbarMenu[i].name}
                        ></span>
                        <span className="text">{userbarMenu[i].caption}</span>
                    </button>
                );
            }
            userbarDropdown = (
                <div className="userbar-dropdown">{userbarDropdown}</div>
            );
        }

        return userbarDropdown;
    }

    renderFeedbackBar() {
        let button,
            dropdown = [];
        if (this.props.feedbackBar) {
            button = (
                <button
                    className="feedback-bar-button"
                    onClick={this.handleFeedbackBarClick.bind(this)}
                >
                    <span className="icon"></span>
                    <span className="icon-close">
                        {String.fromCharCode(215)}
                    </span>
                </button>
            );

            dropdown = this.renderFeedbackBarDropdown();
        }

        let dropdownContainerClassName = "feedback-bar-dropdown-container";
        if (this.state.feedbackBarDropdownOpened) {
            dropdownContainerClassName += " opened";
        }

        return (
            <div className="page-header-feedback-bar" ref={this.feedbackBarRef}>
                <div className={dropdownContainerClassName}>
                    {dropdown}
                    {button}
                </div>
            </div>
        );
    }

    renderFeedbackBarDropdown() {
        let dropdown = [];
        const menu = this.props.feedbackBar.menu;
        if (menu) {
            for (let i = 0; i < menu.length; i++) {
                switch (menu[i].type) {
                    case "foreign-link":
                        dropdown.push(
                            <a
                                key={i}
                                onClick={this.handleFeedbackBarMenuButtonClick.bind(
                                    this,
                                    menu[i].name
                                )}
                                className={"button-" + menu[i].name}
                                href={menu[i].url}
                                target="_blank"
                            >
                                <span
                                    className={"icon icon-" + menu[i].name}
                                ></span>
                                <span className="text">{menu[i].caption}</span>
                            </a>
                        );
                        break;
                    default:
                        dropdown.push(
                            <button
                                key={i}
                                onClick={this.handleFeedbackBarMenuButtonClick.bind(
                                    this,
                                    menu[i].name
                                )}
                                className={"button-" + menu[i].name}
                            >
                                <span
                                    className={"icon icon-" + menu[i].name}
                                ></span>
                                <span className="text">{menu[i].caption}</span>
                            </button>
                        );
                        break;
                }
            }
            dropdown = <div className="feedback-bar-dropdown">{dropdown}</div>;
        }

        return dropdown;
    }

    handleMenuItemClick(index, e) {
        const handler = this.props.onMenuItemClick;
        if (handler) {
            let item = this.props.menu[index];
            handler(e, item);
        }
    }

    handleUserbarClick(e) {
        const handler = this.props.onUserbarClick;
        if (handler) {
            handler(e);
        }

        this.setState({
            userbarDropdownOpened: !this.state.userbarDropdownOpened,
        });
    }

    handleUserbarMenuButtonClick(name, loadDefaultCompany, e) {
        const handler = this.props.onUserbarMenuButtonClick;
        if (handler) {
            handler(e, name, {
                loadDefaultCompany: loadDefaultCompany,
            });
        }

        this.setState({
            userbarDropdownOpened: false,
        });
    }

    handleSearchSubmit(e) {
        e.preventDefault();

        if (!this.state.openSearch) {
            this.setState(
                {
                    openSearch: true,
                },
                () => {
                    this.searchInputRef.current.focus();
                }
            );
            return;
        }

        const searchString = this.searchInputRef.current.value;
        const handler = this.props.onSearchSubmit || function () {};
        handler(this.searchFormRef.current, searchString);
    }

    handleFeedbackBarClick(e) {
        const handler = this.props.onFeedbackBarClick;
        if (handler) {
            handler(e);
        }

        this.setState({
            feedbackBarDropdownOpened: !this.state.feedbackBarDropdownOpened,
        });
    }

    handleFeedbackBarMenuButtonClick(name, e) {
        const handler = this.props.onFeedbackBarMenuButtonClick;
        if (handler) {
            handler(e, name);
        }

        this.setState({
            feedbackBarDropdownOpened: false,
        });
    }
}
