import * as Store from "../store.js";
import * as AnalyticsRepository from "../../components/repositories/analytics-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import * as StoreHelper from "../helpers/store-helper.js";
import * as FilterHelper from "../helpers/filter-helper.js";
import includeFiles from "../../components/helpers/include-files.js";
import { handleResponse, submitForm } from "../helpers/request-helper.js";
import { getNestedValue } from "../../components/helpers/objects-helper.js";

let loading = false,
    filter = null;

export function loadLayout(companyId, params) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        AnalyticsRepository.getLayout(companyId, params),
        (response) => {
            if (!response) {
                return false;
            }
            Store.setStore(response);
            Store.setStore(
                companyId || getDefaultCompanyId(response),
                "data.companyId"
            );
            return true;
        }
    ).then((response) => {
        if (filter) {
            applyFilterData(filter);
            return filterCharts(companyId, null, filter);
        }

        return response;
    });
}

export function loadCompany(companyId, form, params) {
    Store.setStore(true, "data.loading.show");

    params = params || {};
    params.companyId = companyId;
    params = appendFormParams(params, form);

    return handleResponse(
        AnalyticsRepository.getCompany(params),
        (response) => {
            loading = false;
            if (!getNestedValue(response, "analyticsGraphs")) {
                return false;
            }
            Store.setStore(companyId, "data.companyId");
            Store.setStore(response.analyticsGraphs, "data.analyticsGraphs");
            Store.setStore(response.analyticsFilter, "data.analyticsFilter");
            return true;
        }
    );
}

export function loadChartHelpers() {
    includeFiles("https://www.gstatic.com/charts/loader.js", () => {
        Store.setStore(true, "data.analyticsGraphs.helpersLoaded");
    });
}

export function setOperationsFilterParams(params) {
    if (!params.field) {
        return null;
    }

    const fieldpath = StoreHelper.getFieldPath(
        "data.analyticsFilter.fields",
        params.field.name
    );

    switch (params.field.type) {
        case "datepicker":
            let activeLabel, activeValue, dateFrom, dateTo;
            let range = getNestedValue(params, "datepickerParams", "range");
            if (range) {
                activeLabel =
                    range.from.dateString + " - " + range.to.dateString;
                activeValue = "datepicker";
                dateFrom = range.from.dateString;
                dateTo = range.to.dateString;
            } else {
                const checklistItem = params.checklistItem;
                if (checklistItem) {
                    activeLabel = checklistItem.label;
                    activeValue = checklistItem.value;
                }
            }

            // тут может быть или list или data.options зависит от того что пришло с бека
            StoreHelper.updateChecklist(
                fieldpath + ".list",
                activeValue,
                activeLabel
            );
            StoreHelper.updateChecklist(
                fieldpath + ".data.options",
                activeValue,
                activeLabel
            );
            Store.setStore(dateFrom, fieldpath + ".data.dateFrom");
            Store.setStore(dateTo, fieldpath + ".data.dateTo");
            break;
        case "checklist":
        case "filterable-checklist":
            switch (params.action) {
                case "reset":
                    // тут может быть или list или data.options зависит от того что пришло с бека
                    StoreHelper.resetChecklist(fieldpath + ".list");
                    StoreHelper.resetChecklist(fieldpath + ".data.options");
                    break;
                default:
                    // тут может быть или list или data.options зависит от того что пришло с бека
                    StoreHelper.toggleChecklist(
                        fieldpath + ".list",
                        getNestedValue(params.checklistItem, "value")
                    );
                    StoreHelper.toggleChecklist(
                        fieldpath + ".data.options",
                        getNestedValue(params.checklistItem, "value")
                    );
                    break;
            }
            break;
    }
}

export function filterCharts(companyId, form, params) {
    if (loading) {
        return Promise.resolve();
    }

    loading = true;

    Store.setStore(true, "data.loading.show");

    params = params || {};
    params.companyId = companyId;

    params = appendFormParams(params, form);

    return handleResponse(AnalyticsRepository.getGraphs(params), (response) => {
        loading = false;
        if (!getNestedValue(response, "analyticsGraphs")) {
            return false;
        }
        if (!getNestedValue(response, "analyticsSectionBar")) {
            return false;
        }

        Store.setStore(response.analyticsGraphs, "data.analyticsGraphs");
        Store.setStore(response.analyticsSectionBar, "data.analyticsSectionBar");
        return true;
    });
}

export function setLayoutHistoryState(response) {
    const title = getSectionTitle(response);

    document.title = title;
    history.pushState({}, title, "/analytics");
}

export function setCompanyHistoryState(id, response) {
    const data = { companyId: id };
    const title = getSectionTitle(response);

    document.title = title;
    history.pushState(data, title, "/analytics?id=" + id);
}

export function changeView(viewName) {
    let graphs = Store.getStore("data.analyticsGraphs");
    if (graphs && graphs.length) {
        for (let i = 0; i < graphs.length; i++) {
            Store.setStore(viewName, "data.analyticsGraphs." + i + ".viewName");
        }
    }
}

export function applyFilterData(data) {
    if (!data) {
        return null;
    }

    const form = document.querySelector(".analytics-filter"),
        fieldsPath = "data.analyticsFilter.fields";

    return FilterHelper.applyFilterData(form, fieldsPath, data);
}

export function saveFilter(filterData) {
    filter = filterData;
}

/* HELPERS */

function getSectionTitle(response) {
    let title = "Dogma";
    const menu = getNestedValue(response, "data", "pageHeader", "menu");
    if (menu && menu.length) {
        for (let item of menu) {
            if (item.name == "analytics") {
                title = item.label;
                break;
            }
        }
    }

    return title;
}

function appendFormParams(params, form) {
    if (!form) {
        return params;
    }

    const formData = form.getFormData();
    for (let pair of formData.entries()) {
        params[pair[0]] = pair[1];
    }

    return params;
}

function getDefaultCompanyId(response) {
    return getNestedValue(response.data, "companySidebar", "list", 0, "id");
}
