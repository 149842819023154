import { getNestedValue } from "../../components/helpers/objects-helper.js";

export default class PrintMenuCompanyHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let header = [this.getMenuCaption(), this.getCompanyCaption()];
        return (
            <div className="print-section">
                <p className="section-header">{header.join(" ")}</p>
            </div>
        );
    }

    getMenuCaption() {
        const menu = getNestedValue(this.props.pageHeader, "menu");
        if (!menu) {
            return null;
        }

        for (let i in menu) {
            if (menu[i].active) {
                return menu[i].label;
            }
        }
    }

    getCompanyCaption() {
        const menu = getNestedValue(this.props.companySidebar, "list"),
            activeId = this.props.companyId;
        if (!menu) {
            return null;
        }

        for (let i in menu) {
            if (menu[i].id == activeId) {
                return menu[i].caption;
            }
        }
    }
}
