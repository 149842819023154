import Container from "../container.jsx";
import Feedback from "../../components/layouts/cabinet/feedback.jsx";

export default class FeedbackContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.cabinetFeedbackLayout");
    }

    render() {
        return <Feedback {...this.props} {...this.state.state} />;
    }
}
