import Container from '../container.jsx';
import SidebarCompanies from '../../components/layouts/cabinet/sidebar-companies.jsx';

export default class SidebarCompaniesContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.cabinetSidebar.companies');
    }

    render(){
        return <SidebarCompanies
            {...this.props}
            {...this.state.state} />;
    };
}
