import * as Store from "../store.js";
import * as OperationsRepository from "../../components/repositories/operations-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import * as ObjectsHelper from "../../components/helpers/objects-helper.js";
import * as StoreHelper from "../helpers/store-helper.js";
import { handleResponse, submitForm } from "../helpers/request-helper.js";

export function loadOperationAddPopup(data, operationName, values) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        OperationsRepository.getOperationAddPopup(data),
        (response) => {
            if (!response) {
                return false;
            }
            LayoutHandlers.handlePopupErrors(response.operationAddPopup);
            response.operationAddPopup.popupName = operationName;
            let popup = prepareOperationPopup(
                response.operationAddPopup,
                values
            );
            Store.setStore(popup, "data.operationAddPopup");
            return true;
        }
    );
}

export function loadOperationEditPopup(data, operationName, values) {
    Store.setStore(true, "data.loading.show");
    if (data.id) {
        lastFormTransactionId = data.id;
    }
    return handleResponse(
        OperationsRepository.getOperationEditPopup(data),
        (response) => {
            if (!response) {
                return false;
            }
            LayoutHandlers.handlePopupErrors(response.operationEditPopup);
            response.operationEditPopup.popupName = operationName;
            LayoutHandlers.setDialogHistoryState();
            let popup = prepareOperationPopup(
                response.operationEditPopup,
                values
            );
            Store.setStore(popup, "data.operationEditPopup");
            Store.setStore(true, "data.operationEditPopup.show");
            return true;
        }
    );
}

export function loadOperationTransferPopup(data) {
    Store.setStore(true, "data.loading.show");

    return handleResponse(
        OperationsRepository.getOperationTransferPopup(data),
        (response) => {
            if (!response) {
                return false;
            }
            LayoutHandlers.handlePopupErrors(response.operationTransferPopup);
            LayoutHandlers.setDialogHistoryState();
            Store.setStore(
                response.operationTransferPopup,
                "data.operationTransferPopup"
            );
            Store.setStore(true, "data.operationTransferPopup.show");
            return true;
        }
    );
}

export function closeOperationAddPopup() {
    Store.setStore(null, "data.operationAddPopup");
    LayoutHandlers.unsetDialogHistoryState();
}

export function closeOperationEditPopup() {
    Store.setStore(null, "data.operationEditPopup");
    LayoutHandlers.unsetDialogHistoryState();
}

export function closeOperationRemovePopup() {
    Store.setStore(null, "data.operationRemovePopup");
    LayoutHandlers.unsetDialogHistoryState();
}

export function closeOperationTransferPopup() {
    Store.setStore(null, "data.operationTransferPopup");
}

export function addOperationForm(form) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupErrors(response);
        if (response && response.success) {
            // попросили убрать DOG-10
            // Store.setStore({list: response.messages}, 'data.popupMessages');
            Store.setStore(null, "data.operationAddPopup");
            LayoutHandlers.unsetDialogHistoryState();
            resetFormValues();
        }
        return response;
    });
}

export function editOperationForm(form) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupErrors(response);
        if (response && response.success) {
            // попросили убрать DOG-10
            // Store.setStore({list: response.messages}, 'data.popupMessages');
            Store.setStore(null, "data.operationEditPopup");
            LayoutHandlers.unsetDialogHistoryState();
            resetFormValues();
        }
        return response;
    });
}

export function removeOperationForm(form) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupErrors(response);
        if (response && response.success) {
            // попросили убрать DOG-10
            // Store.setStore({list: response.messages}, 'data.popupMessages');
            Store.setStore(null, "data.operationRemovePopup");
            Store.setStore(null, "data.operationEditPopup");
            LayoutHandlers.unsetDialogHistoryState();
        }
        return response;
    });
}

export function transferOperationForm(form) {
    return submitForm(form).then((response) => {
        LayoutHandlers.handlePopupErrors(response);
        if (response && response.success) {
            Store.setStore(null, "data.operationTransferPopup");
            LayoutHandlers.unsetDialogHistoryState();
        }
        return response;
    });
}

export function handleAction(params) {
    params = params || {};

    switch (params.name) {
        case "remove":
            return loadOperationRemovePopup(params);
    }
}

export function loadOperationRemovePopup(params) {
    Store.setStore(true, "data.loading.show");
    return handleResponse(
        OperationsRepository.getOperationRemovePopup({ id: params.id }),
        (response) => {
            if (!response) {
                return false;
            }
            LayoutHandlers.handlePopupErrors(response.operationRemovePopup);
            Store.setStore(
                response.operationRemovePopup,
                "data.operationRemovePopup"
            );
            Store.setStore(true, "data.operationRemovePopup.show");
            return true;
        }
    );
}

export function setOperationsFilterParams(params) {
    if (!params.field) {
        return null;
    }

    const fieldpath = StoreHelper.getFieldPath(
        "data.operations.filter.fields",
        params.field.name
    );

    switch (params.field.type) {
        case "datepicker":
            let activeLabel, activeValue, dateFrom, dateTo;
            let range = ObjectsHelper.getNestedValue(
                params,
                "datepickerParams",
                "range"
            );
            if (range) {
                activeLabel =
                    range.from.dateString + " - " + range.to.dateString;
                activeValue = "datepicker";
                dateFrom = range.from.dateString;
                dateTo = range.to.dateString;
            } else {
                const checklistItem = params.checklistItem;
                if (checklistItem) {
                    activeLabel = checklistItem.label;
                    activeValue = checklistItem.value;
                }
            }

            // тут может быть или list или data.options зависит от того что пришло с бека
            StoreHelper.updateChecklist(
                fieldpath + ".list",
                activeValue,
                activeLabel
            );
            StoreHelper.updateChecklist(
                fieldpath + ".data.options",
                activeValue,
                activeLabel
            );
            Store.setStore(dateFrom, fieldpath + ".data.dateFrom");
            Store.setStore(dateTo, fieldpath + ".data.dateTo");
            break;
        case "checklist":
        case "filterable-checklist":
            switch (params.action) {
                case "reset":
                    // тут может быть или list или data.options зависит от того что пришло с бека
                    StoreHelper.resetChecklist(fieldpath + ".list");
                    StoreHelper.resetChecklist(fieldpath + ".data.options");
                    break;
                default:
                    // тут может быть или list или data.options зависит от того что пришло с бека
                    StoreHelper.toggleChecklist(
                        fieldpath + ".list",
                        ObjectsHelper.getNestedValue(
                            params.checklistItem,
                            "value"
                        )
                    );
                    StoreHelper.toggleChecklist(
                        fieldpath + ".data.options",
                        ObjectsHelper.getNestedValue(
                            params.checklistItem,
                            "value"
                        )
                    );
                    break;
            }
            break;
        case "price-range":
            let priceRange = ObjectsHelper.getNestedValue(
                params.rangeParams,
                "range"
            );
            if (priceRange && (priceRange.from || priceRange.to)) {
                Store.setStore(
                    priceRange.from + " - " + priceRange.to,
                    fieldpath + ".labelValue"
                );
            } else {
                Store.setStore(null, fieldpath + ".labelValue");
            }
            break;
    }
}

let lastFormValues = {};
let lastFormName = "";
let lastFormLayout = "";
let lastFormTransactionId = "";

export function rememberFormValues(form) {
    let formData = form.getFormData();
    for (const pair of formData.entries()) {
        lastFormValues[pair[0]] = pair[1];
    }
    lastFormName = Store.getStore("data.operationAddPopup.popupName");
    if (!lastFormName) {
        lastFormName = Store.getStore("data.operationEditPopup.popupName");
    }
    console.log("--------------------", lastFormName);
}

export function rememberFormLayout(name) {
    lastFormLayout = name;
}

export function resetFormValues() {
    lastFormValues = {};
    lastFormName = "";
}

export function hasUnfinishedForm() {
    return lastFormValues && Object.keys(lastFormValues).length;
}

export function getLastFormName() {
    return lastFormName;
}

export function getLastFormLayout() {
    return lastFormLayout;
}

export function getLastFormTransactionId() {
    return lastFormTransactionId;
}

/* HELPERS */

function prepareOperationPopup(popup, values) {
    if (!hasUnfinishedForm()) {
        return popup;
    }
    for (let name in values) {
        lastFormValues[name] = values[name];
    }
    switch (lastFormName) {
        case "income":
            if (popup.incomePopup) {
                for (let i in popup.incomePopup.form.fields) {
                    let value =
                        lastFormValues[popup.incomePopup.form.fields[i].name];
                    if (value) {
                        popup.incomePopup.form.fields[i].value = value;
                    }
                }
            }
            break;
        case "outcome":
            if (popup.outcomePopup) {
                for (let i in popup.outcomePopup.form.fields) {
                    let value =
                        lastFormValues[popup.outcomePopup.form.fields[i].name];
                    if (value) {
                        popup.outcomePopup.form.fields[i].value = value;
                    }
                }
            }
            break;
        case "edit":
            if (popup) {
                for (let i in popup.form.fields) {
                    let value =
                        lastFormValues[popup.form.fields[i].name];
                    if (value) {
                        popup.form.fields[i].value = value;
                    }
                }
            }
            break;
    }
    return popup;
}
