import Datepicker from './datepicker.jsx';

export default class DatepickerPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="datepicker-popup show">
                <Datepicker
                    selectRange={this.props.selectRange}
                    selectButton={this.props.selectButton}
                    onDayClick={this.handleDayClick.bind(this)}
                    onSelectButtonClick={this.handleSelectButtonClick.bind(this)} />
                <button className="close-button" onClick={this.handleCloseButtonClick.bind(this)}></button>
            </div>
        );
    }

    handleCloseButtonClick(e) {
        const handler = this.props.onCloseButtonClick || function(){};
        handler(e);
    }

    handleDayClick(e, dateString, year, month, day) {
        const handler = this.props.onDayClick || function(){};
        handler(e, dateString, year, month, day);
    }

    handleSelectButtonClick(e, params) {
        const handler = this.props.onSelectButtonClick || function(){};
        handler(e, params);
    }
}
