import Fieldset from './fields/fieldset.jsx';
import newId from './utils/new-id.js';

export default class RepeaterSubform extends React.Component {
    constructor(props) {
        super(props);

        this.fieldsets = [];
        this.formsCounter = 0;
        this.formsData = {};

        this.state = {
            items: this.props.items || []
        };

        if (!this.state.items.length) {
            this.state.items = [{
                id: newId('new-')
            }];
        }

        for(let i=0; i<this.state.items.length; i++) {
            let item = this.copyObject(this.state.items[i]),
                id = item.id;
            this.formsData[id] = item;
        }
    }

    copyObject(item) {
        return JSON.parse(JSON.stringify(item));
    }

    handleAddButtonClick(e) {
        this.formsData[newId('new-')] = {};
        this.saveFormsState();
    }

    handleDeleteButtonClick(id, e) {
        delete this.formsData[id];
        this.saveFormsState();
    }

    handleFieldChange(id, e, props, field, index) {
        // т.к. у нас uncontrolled компоненты, будем хранить заполненную инфу тут
        // а при добавлении нового собственника или удалении будем обновлять стейт из этих данных
        if (!this.formsData[id]) {
            this.formsData[id] = {};
        }
        let fieldname = this.extractFieldName(props.name);
        this.formsData[id][fieldname] = props.value;

        const handler = this.props.onFieldChange || function(){};
        handler(e, props, field, index);
    }

    saveFormsState() {
        let items = [];
        for(let id in this.formsData) {
            let item = this.copyObject(this.formsData[id]);
            item.id = id;
            items.push(item);
        }

        this.setState({
            items: items
        });
    }

    extractFieldName(name) {
        let fieldName = name.split('[').pop();
        return fieldName.split(']').shift();
    }
}
