import CheckList from "./checklist.jsx";

export default class FilterableCheckList extends CheckList {
    constructor(props) {
        super(props);

        this.state = {
            filteredList: null
        };
    }

    renderPopupContent(list) {
        return [
            this.renderChecklistHeader(),
            this.renderCloseButton(),
            this.renderSearchField(),
            this.renderChecklist(this.state.filteredList ? this.state.filteredList : list),
            this.renderApplyButton(),
            this.renderResetButton(),
        ];
    }

    renderSearchField() {
        return (
            <div className="search-field" key="search-field">
                <input
                    type="text"
                    onChange={this.handleSearchInputChange.bind(this)}
                />
                <div className="icon"></div>
            </div>
        );
    }

    handleSearchInputChange(e) {
        const searchString = e.target.value.toLowerCase();
        if (searchString) {
            let filteredArr = [];
            let firstIndex = [];
            let otherIndex = [];
            this.props.data.options.forEach((item) => {
                let filtered = item.label.toLowerCase().indexOf(searchString);
                switch (true) {
                    case (filtered == 0):
                        firstIndex.push(item);
                        break;
                    case (filtered >= 1):
                        otherIndex.push(item);
                        break;
                    default:
                        break;
                }
            });
            if (firstIndex.length || otherIndex.length) {
                filteredArr = filteredArr.concat(firstIndex);
                filteredArr = filteredArr.concat(otherIndex);
            }
            if (filteredArr.length || filteredArr.length === 0) {
                this.setState({
                    filteredList: filteredArr
                });
            } 
        } else {
            this.setState({
                filteredList: null
            });
        }
    }
}
