import {getNestedValue} from '../../../../components/helpers/objects-helper.js';
import Select from './select.jsx';

export default class CustomSelect extends Select {
    constructor(props) {
        super(props);
        this.styledSelectRef = React.createRef();

        let activeLabel = null,
            activeValue = null,
            options = this.getOptions();

        if (options && options.length) {
            for(let i=0; i<options.length; i++) {
                let item = options[i];
                if (activeValue === null) {
                    activeValue = item.value;
                }
                if (activeLabel === null) {
                    activeLabel = item.label;
                }
                if (item.selected) {
                    activeValue = item.value;
                    activeLabel = item.label;
                }
                if (item.value == this.props.value) {
                    activeValue = item.value;
                    activeLabel = item.label;
                }
            }
        }

        this.state = {
            activeValue: activeValue,
            activeLabel: activeLabel,
            showList: false
        };

        this.handleDocumentClick = e => {
            if (this.hasCustomList() && this.styledSelectRef && !this.styledSelectRef.current.contains(e.target)) {
                this.setState({
                    showList: false
                });
            }
        };

        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    render() {
        let select = this.renderSelect();

        if (this.hasCustomButton() || this.hasCustomList()) {
            let button,
                list = [];

            if (this.hasCustomButton()) {
                button = this.renderCustomButton();
            }

            if (this.hasCustomList()) {
                list = this.renderCustomList();
            }

            return <div className={'styled-select' + (this.state.showList ? ' show-list' : '')} ref={this.styledSelectRef}>
                {select}
                {button}
                {list}
            </div>;
        }

        return select;
    }

    renderCustomButton() {
        return <button className="select-button" type="button" onClick={this.handleSelectButtonClick.bind(this)} >
            <span className="pre-icon"></span>
            <span className="button-text">{this.state.activeLabel}</span>
            <span className="post-icon"></span>
        </button>
    }

    renderCustomList() {
        let list = [],
            optionsData = this.getOptions();

        if (optionsData && optionsData.length) {
            for(let i=0; i<optionsData.length; i++) {
                let item = optionsData[i];
                list.push(this.renderCustomListButton(i, item));
            }
            if (list.length) {
                list = <div className={'select-list' + (this.state.showList ? ' show' : '')}>{list}</div>;
            }
        }

        return list;
    }

    renderCustomListButton(index, item) {
        return <button key={index}
            className={this.getListButtonClassName(item)}
            type="button"
            onClick={this.handleListButtonClick.bind(this, item.value)}
        >
            <span className="pre-icon"></span>
            <span className="button-text">{item.label}</span>
            <span className="post-icon"></span>
        </button>
    }

    handleChange(e) {
        if (this.hasCustomButton()) {
            const activeOption = this.selectRef.current.options[this.selectRef.current.selectedIndex];
            this.setState({
                activeValue: activeOption.value,
                activeLabel: activeOption.text
            });
        }

        super.handleChange(e);
    }

    handleSelectButtonClick(e) {
        this.setState({
            showList: !this.state.showList
        });
    }

    handleListButtonClick(value, e) {
        this.selectRef.current.value = value;
        this.selectRef.current.dispatchEvent(new Event('change', {bubbles: true}));
        this.setState({
            showList: false
        });
    }

    hasCustomButton() {
        return this.props.customButton || getNestedValue(this.props, 'data', 'customButton');
    }

    hasCustomList() {
        return this.props.customList || getNestedValue(this.props, 'data', 'customList');
    }

    getListButtonClassName(item) {
        return 'select-list-button' + (item.value == this.state.activeValue ? ' active' : '');
    }
}
