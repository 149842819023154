import Form from '../../forms/form.jsx';

export default class CompanyHeader extends React.Component {
    constructor(props) {
        super(props);

        this.actionEditRef = React.createRef();
        this.editActionsPopupRef = React.createRef();

        this.state = {
            showEditActionsPopup: false,
            showCaptionEditor: false,
            showDeletePopup: false
        };
    }

    componentDidUpdate() {
        this.positionEditActionsPopup();
    }

    render() {
        let actions = [];
        if (this.props.actions) {
            for(let i=0; i<this.props.actions.length; i++) {
                let action = this.props.actions[i];
                actions.push(
                    <button key={i}
                        className={'action action-' + action.name}
                        title={action.label}
                        data-name={action.name}
                        onClick={this.handleActionClick.bind(this, action.name)}
                        ref={elem => {
                            if (elem && elem.dataset.name == 'edit') {
                                this.actionEditRef = elem;
                            } else {
                                this.actionEditRef = null;
                            }
                        }}></button>
                );
            }
        }

        let editActionsPopup;
        if (this.state.showEditActionsPopup && this.props.editActions) {
            let editActions = [];
            for(let i=0; i<this.props.editActions.length; i++) {
                let action = this.props.editActions[i];
                editActions.push(
                    <button key={i}
                        className="edit-action"
                        onClick={this.handleEditActionClick.bind(this, action.name)}>{action.label}</button>
                );
            }

            editActionsPopup = <div className="edit-actions-popup" ref={this.editActionsPopupRef}>
                <button className="close-button"
                    onClick={this.handleCloseEditPopupClick.bind(this)}></button>
                {editActions}
            </div>;
        }

        let deletePopup;
        if (this.state.showDeletePopup && this.props.deleteForm) {
            deletePopup = <div className="cabinet-company-delete-popup show">
                <div className="popup-container">
                    <div className="popup-content">
                        <p className="header">{this.props.deletePopupHeader}</p>
                        <p className="body">{this.props.deletePopupBody}</p>
                        <Form {...this.props.deleteForm}
                            onSubmit={this.handleDeleteFormSubmit.bind(this)}
                            onFieldClick={this.handleDeleteFormFieldClick.bind(this)} />
                    </div>
                </div>
            </div>;
        }

        let header = [<p key="company-title" className="company-title">{this.props.title}</p>, actions];
        if (this.state.showCaptionEditor) {
            header = <div className="caption-editor">
                <Form {...this.props.captionForm}
                    onSubmit={this.handleCaptionFormSubmit.bind(this)}
                    onFieldClick={this.handleCaptionFormFieldClick.bind(this)} />
            </div>;
        }

        return (
            <div className="company-title-container">
                {header}
                {editActionsPopup}
                {deletePopup}
            </div>
        );
    }

    handleActionClick(name, e) {
        switch(name) {
            case 'edit':
                this.setState({
                    showEditActionsPopup: !this.state.showEditActionsPopup
                });
                break;
        }
    }

    handleEditActionClick(name, e) {
        this.setState({
            showEditActionsPopup: false
        });

        switch(name) {
            case 'edit':
                this.setState({
                    showCaptionEditor: true
                });
                break;
            case 'delete':
                this.setState({
                    showDeletePopup: true
                });
                break;
        }

        const handler = this.props.onEditActionClick || function(){};
        handler(e, name, this.props.companyId);
    }

    handleCloseEditPopupClick(e) {
        this.setState({
            showEditActionsPopup: false
        });
    }

    handleCaptionFormSubmit(e, form) {
        const handler = this.props.onCaptionFormSubmit || function(){};
        handler(e, form, this.props.companyId);

        this.setState({
            showCaptionEditor: false
        });
    }

    handleCaptionFormFieldClick(e, field, form) {
        switch(field.getFieldName()) {
            case 'cancel':
                this.setState({
                    showCaptionEditor: false
                });
                break;
        }
    }

    handleDeleteFormSubmit(e, form) {
        const handler = this.props.onDeleteFormSubmit || function(){};
        handler(e, form, this.props.companyId);
    }

    handleDeleteFormFieldClick(e, field, form) {
        switch(field.getFieldName()) {
            case 'cancel':
                this.setState({
                    showDeletePopup: false
                });
                break;
        }
    }

    positionEditActionsPopup() {
        if (!this.editActionsPopupRef.current || !this.actionEditRef) {
            return null;
        }

        const actionRect = this.actionEditRef.getBoundingClientRect();
        const popupWidth = this.editActionsPopupRef.current.clientWidth;
        let positionTop = actionRect.top /* + (window.pageYOffset || document.documentElement.scrollTop) + */;
        let positionLeft = actionRect.left + (window.pageXOffset || document.documentElement.scrollLeft);

        console.log(window.innerWidth, popupWidth, positionLeft);

        if (window.innerWidth < (popupWidth + positionLeft)) {
            positionLeft = window.innerWidth - popupWidth - 10;
        }

        this.editActionsPopupRef.current.style.top = positionTop + 'px';
        this.editActionsPopupRef.current.style.left = positionLeft + 'px';
    }
}
