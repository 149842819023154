export default class LoadingPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="loading-popup show">
                <div className="loading-icon" />
                <div className="loading-message">
                    <p>{this.props.message}</p>
                </div>
            </div>
        );
    }
}
