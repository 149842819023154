import Form from "../../forms/form.jsx";
import FormPopup from "../../form-popup.jsx";
import CompanyHeader from "./company-header.jsx";
import CompanyTabTable from "../../../containers/cabinet/company-tab-table.jsx";
import CompanyTabForm from "./company-tab-form.jsx";
import CompanyTabAbout from "../../../containers/cabinet/company-tab-about.jsx";
import CompanyTabItemCard from "./company-tab-item-card.jsx";
import newId from "../../../helpers/new-id.js";
import { stretchLastPageElement } from "../../../../components/helpers/html-helper.js";

export default class Company extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.activeTab || null,
            showConfirmDeletePopup: !!this.props.deleteForm,
        };
    }

    componentDidMount() {
        stretchLastPageElement(document.querySelector(".body-tab-content"));
    }

    componentDidUpdate() {
        stretchLastPageElement(document.querySelector(".body-tab-content"));
    }

    render() {
        let tabs = [],
            activeTab = this.getActiveTab();

        if (this.props.tabs) {
            for (let i = 0; i < this.props.tabs.length; i++) {
                let tab = this.props.tabs[i],
                    tabClassName = "tab tab-" + tab.name;

                if (activeTab == tab.name) {
                    tabClassName += " active";
                }

                tabs.push(
                    <button
                        key={i}
                        className={tabClassName}
                        onClick={this.handleTabClick.bind(this, tab.name)}
                    >
                        {tab.label}
                    </button>
                );
            }
            tabs = (
                <div className="body-tabs">
                    <div className="body-tabs-scroller">{tabs}</div>
                </div>
            );
        }

        let popupForms = [];
        if (this.props.deleteForm) {
            popupForms.push(
                <FormPopup
                    key={"delete-form"}
                    className="confirm-delete-popup"
                    {...this.props.deleteForm}
                    show={this.state.showConfirmDeletePopup}
                    handleFieldClick={this.handleConfirmDeletePopupFieldClick.bind(
                        this
                    )}
                    handleSubmit={this.handleConfirmDeletePopupSubmit.bind(
                        this
                    )}
                    handleCloseButtonClick={this.handleConfirmDeletePopupCloseButtonClick.bind(
                        this
                    )}
                />
            );
        }
        if (this.props.confirmForm) {
            popupForms.push(
                <FormPopup
                    key={"confirm-form"}
                    className="confirm-popup"
                    {...this.props.confirmForm}
                    show={true}
                    handleFieldClick={this.handleConfirmPopupFieldClick.bind(
                        this
                    )}
                    handleSubmit={this.handleConfirmPopupSubmit.bind(this)}
                    handleCloseButtonClick={this.handleConfirmPopupCloseButtonClick.bind(
                        this
                    )}
                />
            );
        }

        return (
            <div className="cabinet-company">
                <CompanyHeader
                    {...this.props}
                    onCaptionFormSubmit={this.handleCaptionFormSubmit.bind(
                        this
                    )}
                />
                {tabs}
                <div className={"body-tab-content " + activeTab}>
                    {this.getTabContent()}
                    {popupForms}
                </div>
            </div>
        );
    }

    getTabContent() {
        let activeTab = this.getActiveTab();

        if (this.props.tabForm) {
            return (
                <CompanyTabForm
                    {...this.props.tabForm}
                    onCancelButtonClick={
                        this.props.onTabFormCancelButtonClick || function () {}
                    }
                    onAlterActionButtonClick={this.handleTabFormAlterActionClick.bind(
                        this
                    )}
                    onFieldChange={
                        this.props.onTabFormFieldChange || function () {}
                    }
                    onSubmit={this.handleTabFormSubmit.bind(this)}
                />
            );
        }

        if (this.props.listItemCard) {
            return (
                <CompanyTabItemCard
                    {...this.props.listItemCard}
                    onActionClick={this.handleTabEditActionClick.bind(this)}
                    onBackLinkClick={this.handleTabItemCardBackLinkClick.bind(
                        this
                    )}
                />
            );
        }

        let props;

        switch (activeTab) {
            case "about":
                return <CompanyTabAbout {...this.props.about} />;
            case "targets":
                props = this.props.targets;
                break;
            case "owners":
                props = this.props.owners;
                break;
            case "workers":
                props = this.props.workers;
                break;
            case "categories":
                props = this.props.categories;
                break;
            case "partners":
                props = this.props.partners;
                break;
            case "accounts":
                props = this.props.accounts;
                break;
            default:
                return null;
        }

        return (
            <CompanyTabTable
                {...props}
                key={newId("cabinet-company-tab-content-")}
                name={activeTab}
                onActionClick={this.handleTabActionClick.bind(this)}
                onEditActionClick={this.handleTabEditActionClick.bind(this)}
                onListItemClick={this.handleListItemClick.bind(this)}
            />
        );
    }

    handleCaptionFormSubmit(e, form, companyId) {
        const handler = this.props.onCaptionFormSubmit || function () {};
        handler(e, form, companyId, this.getActiveTab());
    }

    handleTabClick(name, e) {
        this.setState({
            activeTab: name,
        });

        const handler = this.props.onTabClick || function () {};
        handler(this.props.companyId, name);
    }

    handleTabActionClick(e, name) {
        const handler = this.props.onTabActionClick || function () {};
        handler(this.props.companyId, this.getActiveTab(), name);
    }

    handleTabEditActionClick(actionName, itemId) {
        const handler = this.props.onTabEditActionClick || function () {};
        handler(this.props.companyId, this.getActiveTab(), actionName, itemId);

        switch (actionName) {
            case "delete":
                this.setState({
                    showConfirmDeletePopup: true,
                });
                break;
        }
    }

    handleListItemClick(itemId) {
        const handler = this.props.onTabListItemClick || function () {};
        switch (this.getActiveTab()) {
            case "accounts":
                handler(this.props.companyId, this.getActiveTab(), itemId);
                break;
        }
    }

    handleTabFormSubmit(e, form) {
        const handler = this.props.onTabFormSubmit || function () {};
        handler(e, form, this.props.companyId, this.getActiveTab());
    }

    handleConfirmDeletePopupFieldClick(e, field) {
        switch (field.getFieldName()) {
            case "cancel":
                this.setState({
                    showConfirmDeletePopup: false,
                });
                break;
        }
    }

    handleConfirmDeletePopupSubmit(e, form) {
        const handler = this.props.onConfirmDeletePopupSubmit || function () {};
        handler(e, form, this.props.companyId, this.getActiveTab());
    }

    handleConfirmDeletePopupCloseButtonClick(e) {
        this.setState({
            showConfirmDeletePopup: false,
        });
    }

    handleConfirmPopupFieldClick(e, field) {
        switch (field.getFieldName()) {
            case "cancel":
                return this.handleConfirmPopupCloseButtonClick(e);
        }
    }

    handleConfirmPopupSubmit(e, form) {
        const handler = this.props.onConfirmPopupSubmit || function () {};
        handler(e, form, this.props.companyId, this.getActiveTab());
    }

    handleConfirmPopupCloseButtonClick(e) {
        const handler = this.props.onConfirmPopupClose || function () {};
        handler(e);
    }

    handleTabItemCardBackLinkClick() {
        return this.handleTabClick(this.getActiveTab());
    }

    handleTabFormAlterActionClick(e, name) {
        const handler =
            this.props.onTabFormAlterActionButtonClick || function () {};
        handler(e, name, {
            companyId: this.props.companyId,
        });
    }

    getActiveTab() {
        return this.state.activeTab || this.props.activeTab;
    }
}
