import Container from '../container.jsx';
import GraphsIncomeOutcome from '../../components/layouts/companies/graphs-income-outcome.jsx';

export default class GraphsIncomeOutcomeContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.graphs');
    }

    render(){
        return <GraphsIncomeOutcome
            {...this.props}
            {...this.state.state} />;
    };
}
