import Container from "../container.jsx";
import FormPopup from "../../components/form-popup.jsx";

export default class InvestmentAddPopupContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.investmentAddPopup");
    }

    render() {
        if (!this.state.state) {
            return null;
        }

        return (
            <FormPopup
                {...this.state.state}
                className={this.props.className}
                handleCloseButtonClick={
                    this.props.handleCloseButtonClick || function () {}
                }
                handleSubmit={this.props.handleSubmit || function () {}}
                handleActionClick={
                    this.props.handleActionClick || function () {}
                }
            />
        );
    }
}
