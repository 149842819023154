import * as CompaniesHandlers from '../handlers/companies-handlers.js';
import * as CompanyHandlers from '../handlers/company-handlers.js';

export function handleCompaniesItemClick(e, index, item) {
    CompanyHandlers.loadCompanyLayout(item.id)
        .then(response => {
            CompanyHandlers.setCompanyHistoryState(item.id, response);
        });
}

export function handleCompaniesActionClick(e, name) {
    switch(name) {
        case 'add':
            CompanyHandlers.loadCompanyAddPopup();
            break;
    }
}

export function handleCompanyAddPopupClose(e) {
    CompanyHandlers.closeCompanyAddPopup();
}

export function handleCompanyAddPopupSubmit(e, form) {
    e.preventDefault();
    CompanyHandlers.addCompanyForm(form)
        .then(response => {
            CompaniesHandlers.refreshCompanies();
        });
}
