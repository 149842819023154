import OperationsList from "./operations-list.jsx";

export default class OperationsDatedList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.list) {
            return null;
        }

        let list = [];
        let prevDate;
        for (let i = 0; i < this.props.list.length; i++) {
            let item = this.props.list[i];
            if (item.date != prevDate) {
                list.push(
                    <p key={"date-" + i} className="operations-date">
                        {item.date}
                    </p>
                );
            }
            list.push(
                <OperationsList
                    key={"list-" + i}
                    list={item.list}
                    onItemClick={this.props.onItemClick || function () {}}
                    onActionClick={this.props.onActionClick || function () {}}
                />
            );
            prevDate = item.date;
        }
        return <div className="operations-dated-list">{list}</div>;
    }
}
