import newId from '../utils/new-id.js';
import Input from './input.jsx';

export default class PasswordInput extends Input {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.state = {
            type: this.props.type,
            defaultValue: this.props.value || ''
        };
    }

    render() {
            // все остальные компоненты (textarea, select, chekput) не нуждаются в новом свойстве key
            // они перерисовывются когда приходит новый value
            // но этот инпут почему-то не перерисовывается...
            return (
                <div className="password-input" type="password">
                    <input className="form-input"
                        type={this.state.type}
                        name={this.props.name}
                        defaultValue={this.props.value || this.state.defaultValue}
                        key={newId('input-key-')}
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        autoComplete={this.props.autocomplete ? "on" : "off"}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur} />
                        <button className="eye-icon fa fa-eye"
                            onClick={this.handleHidePassword.bind(this)}>
                        </button>
                </div>
            );
    }

    handleHidePassword(e) {
        e.preventDefault();
        let elem = document.getElementById(this.props.id);
        if (this.state.type === "password") {
            this.setState({
                type: "text",
                defaultValue: elem.value
            });
        } else {
            this.setState({
                type: "password",
                defaultValue: elem.value
            });
        }
    }
}
