import Price from '../../price.jsx';
import LetterItemHelper from '../../../helpers/letter-item-helper.js';

export default class CompaniesList extends React.Component {
    constructor(props) {
        super(props);
        this.letterHelper = new LetterItemHelper(3);
    }

    render() {
        if (!this.props.list) {
            return null;
        }

        this.letterHelper.resetCounter();

        let list = [];

        if (this.props.list) {
            for(let i=0; i<this.props.list.length; i++) {
                let item = this.props.list[i];
                list.push(
                    <button key={i} data-index={i} className="companies-list-item" onClick={this.handleItemClick.bind(this)}>
                        <span className="list-item-col icon">
                            <span className={'icon-content ' + this.letterHelper.getLetterColor(item)}>{this.letterHelper.getFirstLetter(item.caption)}</span>
                        </span>
                        <span className="list-item-col caption">
                            <span>{item.caption}</span>
                        </span>
                        <span className="list-item-col price">
                            <Price value={item.balance} />
                        </span>
                    </button>
                );
            }
        }

        return (
            <div className="companies-list">
                {list}
            </div>
        );
    }

    handleItemClick(e) {
        const handler = this.props.handleItemClick || function(){},
            target = e.target.closest('.companies-list-item'),
            index = target.dataset.index,
            item = this.props.list[index];
        handler(e, index, item);
    }
}
