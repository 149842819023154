import * as RequestHelper from "./request-helper.js";

export class Auth {
    constructor(config) {
        config = config || {};

        this.handler = config.handler;
        this.cryptor = config.cryptor;

        this.debug = config.debug || false;
        this.debugMessages = [];

        this.accessToken = null;
        this.accessTokenName =
            config.accessTokenName || "4b3623b8753354b7a00c054e1781c5a5";
        this.refreshTokenName =
            config.refreshTokenName || "9f369877d70c4a253c57490617efb8e2";
        this.expiresInName =
            config.expiresInName || "4e3673e919368433daba89a6cc5e071e";

        this.tokenParser = config.tokenParser;
        this.refreshTokenRequired =
            config.refreshTokenRequired === false ? false : true;

        this.on = config.on || {};
    }

    login(params) {
        return this.handler.getToken(params).then((response) => {
            if (this.authorizeResponse(response)) {
                return response;
                this.fireEvent("login", params);
            } else {
                throw new AuthError("auth.not-authorized", response);
            }
        });
    }

    logout(params) {
        return new Promise((resolve, reject) => {
            this.unsetToken();
            this.fireEvent("logout");
            resolve();
        });
    }

    request(url, options) {
        options = options || {};

        this.debugMessage("prepare request", {
            url: url,
            options: options,
        });

        return this.prepareRequest(url, options).then((options) => {
            return fetch(url, options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    // все хорошо
                    return response.json();
                } else if (response.status == 401) {
                    // если сдулся токен - пробуем его обновить
                    this.debugMessage("not authorized response");
                    if (options.refreshTokenTry) {
                        // ой, уже пробовали или нечем обновлять
                        this.debugMessage("already tried to refresh");
                        throw new AuthError("Not authorized");
                    } else {
                        this.debugMessage("refresh token try");
                        return this.refreshToken().then((response) => {
                            this.debugMessage("token refreshed, retry request");
                            // ого! обновили токен, давай еще разок сделаем запрос
                            options.refreshTokenTry = true; // устанавливаем флаг, чтобы снова не пытаться обновить токен
                            return this.request(url, options);
                        });
                    }
                }

                throw new AuthError("Request fail");
            });
        });
    }

    prepareRequest(url, options) {
        options = options || {};
        return new Promise((resolve, reject) => {
            options.body = RequestHelper.ensureFormData(options.body);

            if (this.debug) {
                options.url = RequestHelper.addUrlParam(
                    options.url,
                    "v",
                    Math.random()
                );
            }

            this.checkAuthorization()
                .then(() => {
                    let accessToken = this.getAccessToken();
                    if (accessToken) {
                        if (!options.headers) {
                            options.headers = {};
                        }
                        options.headers["Authorization"] =
                            "Bearer " + accessToken;
                    }
                    resolve(options);
                })
                .catch((error) => {
                    this.debugMessage("check authorization fail", error);
                    resolve(options);
                });
        });
    }

    authorizeResponse(response) {
        // if (response && response.data && response.data.token) {
        if (response) {
            // успешно авторизовались - сохраним токен
            return this.setToken(response);
        }
        return false;
    }

    /*
     * Ищем токен и обновляем, если протух
     */
    checkAuthorization() {
        this.debugMessage("check authorization");

        return new Promise((resolve, reject) => {
            const accessToken = this.getAccessToken();
            if (accessToken) {
                if (this.isAccessTokenExpired()) {
                    this.debugMessage("access token expired, refreshing");
                    // токен истек - надо запросить новый
                    this.refreshToken()
                        .then((response) => resolve())
                        .catch((error) => reject(error));
                } else {
                    this.debugMessage(
                        "access token exists: " +
                            accessToken +
                            "; expires in: " +
                            new Date(this.getExpiresIn())
                    );
                    resolve();
                }
            } else {
                this.debugMessage("no access token, refresh token");
                this.refreshToken()
                    .then((response) => resolve())
                    .catch((error) => reject(error));
            }
        });
    }

    refreshToken() {
        return new Promise((resolve, reject) => {
            const refreshToken = this.getRefreshToken();
            if (!refreshToken && this.refreshTokenRequired) {
                this.debugMessage("no refresh token");
                reject(new AuthError("auth.no-refresh-token"));
            } else {
                this.debugMessage(
                    "refresh token exists or not required",
                    refreshToken
                );
                this.handler.refreshToken(refreshToken).then((response) => {
                    if (this.authorizeResponse(response)) {
                        this.debugMessage("refresh token success");
                        resolve(response);
                    } else {
                        this.debugMessage("refresh token fail");
                        // чтобы в след раз снова не пытаться авторизовать плохой токен
                        this.unsetToken();
                        reject(
                            new AuthError("auth.refresh-token-fail", response)
                        );
                    }
                });
            }
        });
    }

    getAccessToken() {
        if (!this.accessToken) {
            this.accessToken = sessionStorage.getItem(this.accessTokenName);
        }

        return this.accessToken;
    }

    getRefreshToken() {
        return this.decrypt(localStorage.getItem(this.refreshTokenName));
    }

    getExpiresIn() {
        return parseInt(sessionStorage.getItem(this.expiresInName));
    }

    calculateExpiresIn(lifetime) {
        const now = new Date().getTime();
        return now + lifetime * 1000;
    }

    isAccessTokenExpired() {
        const expiresIn = this.getExpiresIn(),
            now = new Date().getTime();

        if (!expiresIn) {
            // бесконечный токен? неееееет
            return true;
        }

        return now > expiresIn;
    }

    setToken(token) {
        this.debugMessage("set token", token);
        token = this.parseToken(token);
        if (!token) {
            this.debugMessage("parse token fail");
            return null;
        }
        this.debugMessage("parsed token", token);
        this.accessToken = token.access_token;
        sessionStorage.setItem(this.accessTokenName, token.access_token);
        sessionStorage.setItem(
            this.expiresInName,
            this.calculateExpiresIn(token.expires_in)
        );
        if (token.refresh_token) {
            localStorage.setItem(
                this.refreshTokenName,
                this.encrypt(token.refresh_token)
            );
        }
        this.fireEvent("setToken", token);
        return true;
    }

    unsetToken() {
        this.debugMessage("unset token");
        sessionStorage.removeItem(this.accessTokenName);
        sessionStorage.removeItem(this.expiresInName);
        localStorage.removeItem(this.refreshTokenName);
        this.accessToken = null;
        this.fireEvent("unsetToken");
    }

    parseToken(response) {
        let token = response;
        if (this.tokenParser) {
            token = this.tokenParser(response);
        }
        if (!token || !token.access_token) {
            return null;
        }

        return token;
    }

    encrypt(string) {
        if (!string || !this.cryptor) {
            return string;
        }

        return this.cryptor.encrypt(string);
    }

    decrypt(string) {
        if (!string || !this.cryptor) {
            return string;
        }

        return this.cryptor.decrypt(string);
    }

    debugMessage(message, data) {
        if (data) {
            message += "; data: " + JSON.stringify(data);
        }
        this.debugMessages.push(message);
        this.fireEvent("debugMessage", message);
    }

    getDebugMessages() {
        return this.debugMessages;
    }

    fireEvent(name, params) {
        const handler = this.on[name];
        if (handler) {
            handler(params);
        }
    }
}

function AuthError(message, response) {
    this.name = "AuthError";
    this.message = message || "";
    this.response = response;
}
AuthError.prototype = Object.create(Error.prototype);
