import Form from "../../forms/form.jsx";
import FormPopup from "../../form-popup.jsx";
import { stretchLastPageElement } from "../../../../components/helpers/html-helper.js";
import CompanyTabAbout from "../../../containers/cabinet/company-tab-about.jsx";
import CompanyTabForm from "../cabinet/company-tab-form.jsx";
import Breadcrumbs from "../company-dictionary/breadcrumbs.jsx";

export default class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showConfirmDeletePopup: false,
        };
    }

    componentDidMount() {
        stretchLastPageElement(document.querySelector(".layout-content"));
    }

    componentDidUpdate() {
        stretchLastPageElement(document.querySelector(".layout-content"));
    }

    render() {
        let popupForms = [];
        if (this.props.deleteForm && this.state.showConfirmDeletePopup) {
            popupForms.push(
                <div className="cabinet-company-delete-popup show" key="delete">
                    <div className="popup-container">
                        <div className="popup-content">
                            <p className="header">
                                {this.props.deletePopupHeader}
                            </p>
                            <p className="body">{this.props.deletePopupBody}</p>
                            <Form
                                {...this.props.deleteForm}
                                onSubmit={this.handleDeleteFormSubmit.bind(
                                    this
                                )}
                                onFieldClick={this.handleDeleteFormFieldClick.bind(
                                    this
                                )}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="layout-content">
                {this.renderBreadcrumbs()}
                {this.renderBodyContent()}
                {popupForms}
            </div>
        );
    }

    renderBodyContent() {
        if (this.props.tabForm) {
            return (
                <CompanyTabForm
                    {...this.props.tabForm}
                    onCancelButtonClick={this.props.onTabFormCancelButtonClick}
                    onFieldChange={this.props.onTabFormFieldChange}
                    onFieldBlur={this.props.onTabFormFieldBlur}
                    onSubmit={this.handleTabFormSubmit.bind(this)}
                />
            );
        }

        return (
            <CompanyTabAbout
                {...this.props.about}
                onActionClick={this.handleCompanyActionClick.bind(this)}
            />
        );
    }

    renderBreadcrumbs() {
        return (
            <Breadcrumbs
                {...this.props}
                onCompanyClick={this.props.onCompanyClick || function () {}}
            />
        );
    }

    handleTabFormSubmit(e, form) {
        const handler = this.props.onTabFormSubmit || function () {};
        handler(e, form, this.props.companyId, "about");
    }

    handleCompanyActionClick(e, action) {
        const handler = this.props.onCompanyActionClick || function () {};
        handler(e, this.props.companyId, action);

        switch (action.name) {
            case "delete":
                this.setState({
                    showConfirmDeletePopup: true,
                });
                break;
        }
    }

    handleDeleteFormFieldClick(e, field) {
        switch (field.getFieldName()) {
            case "cancel":
                this.setState({
                    showConfirmDeletePopup: false,
                });
                break;
        }
    }

    handleDeleteFormSubmit(e, form) {
        const handler = this.props.onDeleteFormSubmit || function () {};
        handler(e, form, this.props.companyId);
    }

    handleConfirmDeletePopupCloseButtonClick(e) {
        this.setState({
            showConfirmDeletePopup: false,
        });
    }
}
