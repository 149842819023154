import Price from "../../price.jsx";
import Filter from "../../filter/filter.jsx";
import { getNestedValue } from "../../../../components/helpers/objects-helper.js";

export default class CompanyTabList extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.filterRef = React.createRef();

        this.state = {
            filterParams: {},
        };
    }

    render() {
        let list = [];

        if (this.props.list && this.props.list.length) {
            for (var i = 0; i < this.props.list.length; i++) {
                let item = this.props.list[i];
                let fields = [];

                if (item.fields) {
                    for (let name in item.fields) {
                        let fieldValue = item.fields[name],
                            fieldClass = "";
                        if (
                            typeof fieldValue === "object" &&
                            fieldValue !== null
                        ) {
                            fieldClass = fieldValue.class || "";
                            fieldValue = fieldValue.value;
                        }
                        let value = (
                            <p>
                                <span className="field-value">
                                    {fieldValue}
                                </span>
                            </p>
                        );
                        if (name == "amount") {
                            value = <Price value={fieldValue} integer={true} />;
                        }
                        if (name == "style") {
                            value = (
                                <div
                                    className="style-box"
                                    style={{
                                        backgroundColor: fieldValue || "none",
                                    }}
                                />
                            );
                        }
                        fields.push(
                            <div
                                key={name}
                                className={
                                    "li-field " + name + " " + fieldClass
                                }
                            >
                                {value}
                            </div>
                        );
                    }
                }

                list.push(
                    <div
                        key={i}
                        data-index={i}
                        className={"list-item " + this.getFilteredClass(item)}
                        onClick={this.handleListItemClick.bind(this, item.id)}
                    >
                        <div className="list-item-container">
                            <div className="li-title">
                                <p>{item.label}</p>
                            </div>
                            {fields}
                            {this.renderItemActions(item)}
                        </div>
                    </div>
                );
            }

            list = <div className="layout-cards-list">{list}</div>;
        }

        let filterForm;
        if (this.props.filterForm) {
            filterForm = (
                <Filter
                    className="table-filter"
                    {...this.props.filterForm}
                    onFieldChange={this.handleFilterFieldChange.bind(this)}
                    onSubmit={this.handleFilterSubmit.bind(this)}
                    ref={this.filterRef}
                />
            );
        }

        return (
            <div className="company-tab-list" ref={this.containerRef}>
                <div className="layout-plate">
                    {filterForm}
                    {list}
                </div>
            </div>
        );
    }

    renderItemActions(item) {
        if (!item.editActions) {
            return null;
        }

        let actions = [];

        item.editActions.map((action) => {
            actions.push(
                <button
                    className={"action-item action-" + action.name}
                    key={action.name}
                    onClick={this.handleActionClick.bind(this, item, action)}
                />
            );
        });

        return (
            <div
                className={
                    "li-actions" +
                    (item.id == this.state.openedActionsItemId ? " opened" : "")
                }
            >
                {actions}
            </div>
        );
    }

    handleListActionClick(name, e) {
        const handler = this.props.onActionClick || function () {};
        handler(e, name);
    }

    handleActionClick(item, action, e) {
        const handler = this.props.onEditActionClick || function () {};
        handler(action.name, item.id);
    }

    handleActionsButtonClick(item) {
        if (this.state.openedActionsItemId == item.id) {
            this.setState({
                openedActionsItemId: null,
            });
        } else {
            this.setState({
                openedActionsItemId: item.id,
            });
        }
    }

    handleListItemClick(itemId, e) {
        if (e.target.closest(".actions")) {
            return null;
        }
        const handler = this.props.onListItemClick || function () {};
        handler(itemId);
    }

    handleFilterFieldChange(e) {
        this.setFilterParams();
    }

    handleFilterSubmit(e) {
        e.preventDefault();
        this.setFilterParams();
    }

    setFilterParams(e) {
        if (!this.filterRef) {
            return null;
        }
        const formData = this.filterRef.current.getFormData();
        let filterParams = {};

        for (var pair of formData.entries()) {
            filterParams[pair[0]] = pair[1];
        }

        this.setState({
            filterParams: filterParams,
        });
    }

    getFilteredClass(item) {
        let searchString = getNestedValue(
            this.state.filterParams,
            "search_string"
        );

        if (!searchString) {
            return "";
        }

        searchString = searchString.toLowerCase();

        if (this.testString(item.label, searchString)) {
            return "";
        }

        for (let name in item.fields) {
            let value = item.fields[name];
            if (typeof value === "object" && value !== null) {
                value = value.value;
            }
            value = value || "";

            if (this.testString(value, searchString)) {
                return "";
            }
        }

        return "filter-out";
    }

    testString(string, searchString) {
        return string.toLowerCase().indexOf(searchString) > -1;
    }
}
