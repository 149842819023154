export default class LicenseExpiredPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.message) {
            return null;
        }

        let actions;
        if (this.props.actions) {
            actions = (
                <div className="actions">
                    {this.props.actions.map((action, index) => {
                        return (
                            <button
                                className="action"
                                key={index}
                                onClick={this.handleActionClick.bind(
                                    this,
                                    action
                                )}
                            >
                                {action.label}
                            </button>
                        );
                    })}
                </div>
            );
        }

        return (
            <div className="license-expired-popup show">
                <div className="popup-container">
                    <div className="popup-content">
                        <p className="message">{this.props.message}</p>
                        <p className="body">{this.props.body}</p>
                        {actions}
                    </div>
                    <button
                        className="close-button"
                        onClick={
                            this.props
                                .handleLicenseExpiredPopupCloseButtonClick ||
                            function () {}
                        }
                    ></button>
                </div>
            </div>
        );
    }

    handleActionClick(action, e) {
        const handler =
            this.props.handleLicenseExpiredActionClick || function () {};
        handler(e, action);
    }
}
