import FormPopup from "../../form-popup.jsx";
import CompanyHeader from "./company-header.jsx";
import Company from "./company.jsx";
import CabinetListMenu from "./cabinet-list-menu.jsx";

export default class CompanyMobile extends Company {
    render() {
        const activeTab = this.getActiveTab();

        let content;

        if (activeTab) {
            let popupForms = [];
            if (this.props.deleteForm) {
                popupForms.push(
                    <FormPopup
                        key={"delete-form"}
                        className="confirm-delete-popup"
                        {...this.props.deleteForm}
                        show={this.state.showConfirmDeletePopup}
                        handleFieldClick={this.handleConfirmDeletePopupFieldClick.bind(
                            this
                        )}
                        handleSubmit={this.handleConfirmDeletePopupSubmit.bind(
                            this
                        )}
                        handleCloseButtonClick={this.handleConfirmDeletePopupCloseButtonClick.bind(
                            this
                        )}
                    />
                );
            }

            content = [
                <div className="company-title-container">
                    <p key="company-title" className="company-title">
                        {this.getTabLabel(activeTab)}
                    </p>
                </div>,
                <div className={"body-tab-content " + activeTab}>
                    {this.getTabContent()}
                    {popupForms}
                </div>,
            ];
        } else {
            content = [
                <CompanyHeader
                    {...this.props}
                    onCaptionFormSubmit={this.handleCaptionFormSubmit.bind(
                        this
                    )}
                />,
                <CabinetListMenu
                    list={this.props.tabs}
                    onMenuItemClick={this.handleCompanyMenuClick.bind(this)}
                />,
            ];
        }

        return <div className="cabinet-company">{content}</div>;
    }

    handleCompanyMenuClick(e, item) {
        return this.handleTabClick(item.name, e);
    }

    getTabLabel(name) {
        const tabs = this.props.tabs;
        if (!tabs || !tabs.length) {
            return null;
        }

        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].name == name) {
                return tabs[i].label;
            }
        }
    }
}
