import PageHeader from "../../page-header.jsx";
import CompaniesSidebar from "../companies/companies-sidebar.jsx";
import InvestmentAddPopup from "../../../containers/investments/investment-add-popup.jsx";
import InvestmentRemovePopup from "../../../containers/investments/investment-remove-popup.jsx";
import InvestmentsTable from "../../../containers/investments/investments-table.jsx";
import * as ObjectsHelper from "../../../../components/helpers/objects-helper.js";
import { stretchLastPageElement } from "../../../../components/helpers/html-helper.js";

export default class InvestmentsLayout extends React.Component {
    constructor(props) {
        super(props);
        document.body.classList.add("dashboard");
    }

    componentDidMount() {
        stretchLastPageElement(document.querySelector(".investments-plate"));
    }

    componentDidUpdate() {
        stretchLastPageElement(document.querySelector(".investments-plate"));
    }

    render() {
        let actions = [],
            investments;
        if (this.props.investments) {
            if (this.props.investments.actions) {
                for (
                    let i = 0;
                    i < this.props.investments.actions.length;
                    i++
                ) {
                    let action = this.props.investments.actions[i];
                    actions.push(
                        <button
                            key={i}
                            onClick={this.handleActionClick.bind(
                                this,
                                action.name
                            )}
                        >
                            {action.label}
                        </button>
                    );
                }
                if (actions.length) {
                    actions = (
                        <div className="investments-actions">{actions}</div>
                    );
                }
            }

            investments = (
                <div className="investments-plate">
                    <InvestmentsTable
                        headers={ObjectsHelper.getNestedValue(
                            this.props.investments,
                            "tableHeaders"
                        )}
                        list={ObjectsHelper.getNestedValue(
                            this.props.investments,
                            "list"
                        )}
                        onEditClick={
                            this.props.handleInvestmentEditClick ||
                            function () {}
                        }
                        onInvestmentActionClick={
                            this.props.handleInvestmentActionClick ||
                            function () {}
                        }
                    />
                </div>
            );
        }

        return (
            <div>
                <PageHeader
                    {...this.props.pageHeader}
                    onMenuItemClick={
                        this.props.handlePageHeaderMenuItemClick ||
                        function () {}
                    }
                    onUserbarClick={
                        this.props.handlePageHeaderUserbarClick ||
                        function () {}
                    }
                    onLogoClick={
                        this.props.handlePageHeaderLogoClick || function () {}
                    }
                    onUserbarMenuButtonClick={
                        this.props.handlePageHeaderUserbarMenuButtonClick ||
                        function () {}
                    }
                    onSearchSubmit={
                        this.props.handlePageHeaderSearchSubmit ||
                        function () {}
                    }
                    onFeedbackBarMenuButtonClick={
                        this.props.handlePageHeaderFeedbackBarMenuButtonClick
                    }
                />

                <div className="page-limiter investments-layout">
                    {/*
                    <CompaniesSidebar
                        {...this.props.companySidebar}
                        activeId={this.props.companyId}
                        onCompanyClick={
                            this.props.handleCompanyClick || function () {}
                        }
                        onCompaniesActionClick={
                            this.props.handleCompaniesActionClick
                        }
                        onCompanyMenuItemClick={
                            this.props.handleCompanyMenuItemClick
                        }
                    />
                */}

                    <div className="investments-content">
                        {actions}
                        {investments}
                    </div>
                </div>
                <InvestmentAddPopup
                    className="add-investment-popup"
                    {...this.props.investmentAddPopup}
                    handleCloseButtonClick={
                        this.props.handleInvestmentAddPopupClose ||
                        function () {}
                    }
                    handleSubmit={
                        this.props.handleInvestmentAddPopupSubmit ||
                        function () {}
                    }
                    handleActionClick={
                        this.props.handleInvestmentPopupActionClick ||
                        function () {}
                    }
                />

                <InvestmentRemovePopup
                    {...this.props.investmentRemovePopup}
                    className="remove-investment-popup"
                    handleCloseButtonClick={
                        this.props.handleCloseInvestmentRemovePopup ||
                        function () {}
                    }
                    handleSubmit={
                        this.props.handleInvestmentRemovePopupSubmit ||
                        function () {}
                    }
                    handleFieldClick={
                        this.props.handleCloseInvestmentRemovePopup ||
                        function () {}
                    }
                />
            </div>
        );
    }

    handleActionClick(name, e) {
        switch (name) {
            case "add":
                return this.handleActionAddClick(e);
        }
    }

    handleActionAddClick(e) {
        const handler = this.props.handleInvestmentAddClick || function () {};
        handler(e);
    }
}
