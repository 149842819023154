import Container from '../container.jsx';
import AnalyticsLayout from '../../components/layouts/analytics/analytics.jsx';

export default class AnalyticsLayoutContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.companyId');
    }

    render(){
        return <AnalyticsLayout
            {...this.props}
            companyId={this.state.state} />;
    };
}
