export default class PriceRangeField extends React.Component {
    constructor(props) {
        super(props);
        this.inputFromRef = React.createRef();
        this.inputToRef = React.createRef();

        this.state = {
            openPopup: false
        };
    }

    render() {
        return (
            <div className="filter-price-range">
                <label className="filter-label">{this.props.label}</label>
                <div className="range-control">
                    <input type="text" name={this.props.name + '_from'} className="range-input range-from"/>
                    <span className="range-delim">&#8213;</span>
                    <input type="text" name={this.props.name + '_to'} className="range-input range-to"/>
                </div>
            </div>
        );
    }
}
