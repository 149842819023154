import aes from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

export class Cryptor {
    constructor(config) {
        config = config || {};
        this.key = config.key || '9c42aef5c8043240662921b9ac72378a';
    }

    encrypt(value) {
        if (!value) {
            return value;
        }

        return aes.encrypt(value, this.key).toString();
    }

    decrypt(value) {
        if (!value) {
            return value;
        }

        return aes.decrypt(value, this.key).toString(encUtf8);
    }
}
