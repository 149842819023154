import newId from '../utils/new-id.js';

export default class Input extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    render() {
            // все остальные компоненты (textarea, select, chekput) не нуждаются в новом свойстве key
            // они перерисовывются когда приходит новый value
            // но этот инпут почему-то не перерисовывается...
            return <input className="form-input"
                          type={this.props.type}
                          name={this.props.name}
                          defaultValue={this.props.value || ''}
                          key={newId('input-key-')}
                          id={this.props.id}
                          placeholder={this.props.placeholder}
                          autoComplete={this.props.autocomplete ? "on" : "off"}
                          onChange={this.handleChange}
                          onFocus={this.handleFocus}
                          onBlur={this.handleBlur} />;
    }

    handleChange(e) {
        const handler = this.props.onChange || function(){};
        handler(e);
    }

    handleFocus(e) {
        const handler = this.props.onFocus || function(){};
        handler(e);
    }

    handleBlur(e) {
        const handler = this.props.onBlur || function(){};
        handler(e);
    }
}
