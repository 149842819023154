import OperationsDatedList from "./operations-dated-list.jsx";
import Errors from "../../errors.jsx";
import Filter from "../../filter/filter.jsx";
import { isMobile } from "../../../helpers/layout-helper.js";

export default class Operations extends React.Component {
    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);
        window.addEventListener("scroll", this.handleScroll);

        this.filterRef = React.createRef();
        this.fieldChangeSubmitTimeout;
        this.fieldChangeSubmitTimeoutDelay = 500;

        this.state = {
            openFilter: false,
        };
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        let className = "operations";
        if (!this.state.openFilter) {
            className += " filter-closed";
        }
        return (
            <div className={className}>
                <Errors list={this.props.errors} />
                <Filter
                    className="operations-filter"
                    {...this.props.filter}
                    onFieldChange={this.handleFilterFieldChange.bind(this)}
                    onFieldClick={this.handleFilterFieldClick.bind(this)}
                    onSubmit={this.props.onFilterSubmit || function () {}}
                    ref={this.filterRef}
                />
                <OperationsDatedList
                    list={this.props.list}
                    onItemClick={this.props.onOperationClick || function () {}}
                    onActionClick={
                        this.props.onOperationActionClick || function () {}
                    }
                />
            </div>
        );
    }

    handleFilterFieldChange(e, params) {
        const handler = this.props.onFilterFieldChange || function () {};
        handler(e, params);
        const form = e.target.closest("form");
        if (form) {
            if (
                params.checklistItem &&
                params.checklistItem.value == "datepicker"
            ) {
                // мы нажали на выбор диапазона дат, но они еще не выбраны, фильтровать ничего не надо
                return;
            }
            if (
                !isMobile(450) ||
                params.action == "apply"
            ) {
                // мобилка фильтрует только кнопкой Применить
                clearTimeout(this.fieldChangeSubmitTimeout);
                this.fieldChangeSubmitTimeout = setTimeout(() => {
                    const handler = this.props.onFilterSubmit || function () {};
                    handler(e, this.filterRef.current);
                }, this.fieldChangeSubmitTimeoutDelay);
            }
        }
    }

    handleFilterFieldClick(e, field) {
        if (field.name == "open_filter") {
            this.setState({
                openFilter: !this.state.openFilter,
            });
        }
        if (field.name == "download_xlsx") {
            let url = this.props.xlsxLink;
            if (url) {
                window.open(url, '_blank');
            }
        }
    }

    handleScroll(e) {
        // для бесконечной подгрузки надо понимать, когда низ экрана ниже чем низ списка операций
        const windowTopPos =
            window.pageYOffset || document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const container = document.querySelector(".operations");
        const containerRect = container.getBoundingClientRect();
        const containerTopPos = containerRect.top + windowTopPos;
        const containerHeight = container.offsetHeight;

        if (windowTopPos + windowHeight >= containerTopPos + containerHeight) {
            const itemsCount = container.querySelectorAll(
                ".operations-list-item"
            ).length;
            if (itemsCount < this.props.totalCount) {
                const handler =
                    this.props.onOperationsScrollEdge || function () {};
                handler(
                    this.props.companyId,
                    itemsCount,
                    this.filterRef.current
                );
            }
        }
    }

    getFilterForm() {
        return this.filterRef.current;
    }
}
