import Price from "../../price.jsx";
import CheckList from "../../check-list.jsx";
import CompanyMenu from "./company-menu.jsx";
import CompaniesCompactList from "./companies-compact-list.jsx";
import LetterItemHelper from "../../../helpers/letter-item-helper";
import FormPopup from "../../form-popup.jsx";

export default class CompaniesHeader extends React.Component {
    constructor(props) {
        super(props);

        this.letterHelper = new LetterItemHelper(1);

        this.state = {
            openSelectorPopup: this.props.openSelectorPopup || false,
            showOtherCompanies: false,
            openedActions: false,
            showConfirmDeletePopup: false,
        };

        this.handleDocumentClick = (e) => {
            if (!e.target.closest(".actions-button")) {
                if (this.state.openedActions !== false) {
                    this.setState({
                        openedActions: false,
                    });
                }
            }
        };

        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    render() {
        let content = [];
        if (this.props.balanceLabel || this.props.balanceValue) {
            let value;
            if (this.props.balanceValue) {
                value = (
                    <p className="price-value">
                        <Price value={this.props.balanceValue} />
                    </p>
                );
            }

            if (this.props.dateValue) {
                value = <p className="date-value">{this.props.dateValue}</p>;
            }

            content.push(
                <div className="header-content" key="header-content">
                    <label>{this.props.balanceLabel}</label>
                    {value}
                </div>
            );
        }

        if (this.props.actions) {
            let actions = [];
            this.props.actions.map((action, index) => {
                actions.push(
                    <button
                        key={index}
                        onClick={this.handleActionClick.bind(this, action)}
                    >
                        {action.caption}
                    </button>
                );
            });

            content.push(
                <div className="header-actions" key="header-actions">
                    {actions}
                </div>
            );
        }

        let selector, selectorPopup, activeLabel;
        if (
            this.props.selector &&
            this.props.selector.list &&
            this.props.selector.list.length
        ) {
            for (var i = 0; i < this.props.selector.list.length; i++) {
                let item = this.props.selector.list[i];
                if (!activeLabel) {
                    activeLabel = item.label;
                }
                if (item.active) {
                    activeLabel = item.label;
                }
            }

            selector = (
                <div className="companies-selector">
                    <button onClick={this.handleSelectorClick.bind(this)}>
                        <span className="icon"></span>
                        <span className="text">{activeLabel}</span>
                    </button>
                </div>
            );

            let selectorAction;
            if (this.props.selector.action) {
                selectorAction = (
                    <div
                        className={
                            "selector-action-container" +
                            (this.state.showOtherCompanies ? " show" : "")
                        }
                    >
                        <button
                            className="selector-action"
                            onClick={this.handleSelectorActionClick.bind(this)}
                        >
                            {this.props.selector.action.label}
                        </button>
                    </div>
                );
            }

            let selectorDelim, selectorBalance;
            if (this.props.totalBalanceValue) {
                selectorDelim = <div className="selector-delim"></div>;
                selectorBalance = (
                    <div className="selector-balance-container">
                        <p className="balance-label">
                            {this.props.totalBalanceLabel}
                        </p>
                        <p className="balance-value">
                            <Price value={this.props.totalBalanceValue} />
                        </p>
                    </div>
                );
            }

            let dropdownButton;
            if (this.props.selector.moreLabel) {
                dropdownButton = (
                    <button
                        className="dropdown-button"
                        onClick={this.handleCompaniesDropdownClick.bind(this)}
                    >
                        {this.props.selector.moreLabel}
                    </button>
                );
            }

            let list, activeCompany;
            if (this.props.selector.list) {
                list = (
                    <CompaniesCompactList
                        className={
                            "companies-compact-list" +
                            (this.state.showOtherCompanies ? " show" : "")
                        }
                        list={this.props.selector.list}
                        activeId={this.props.selector.activeId}
                        onItemClick={this.handleCompactCompanyClick.bind(this)}
                    />
                );

                this.props.selector.list.map((item) => {
                    if (item.id == this.props.selector.activeId) {
                        activeCompany = (
                            <div className="active-company" title={item.label}>
                                <span className="list-item-col icon">
                                    <span
                                        className={
                                            "icon-content " +
                                            this.letterHelper.getLetterClass(
                                                item
                                            )
                                        }
                                    >
                                        {this.letterHelper.getLetterSymbol(
                                            item
                                        )}
                                    </span>
                                </span>
                                <span className="list-item-col info">
                                    <span className="caption">
                                        <span>{item.label}</span>
                                    </span>
                                    <span className="price">
                                        <Price value={item.balance} />
                                    </span>
                                </span>
                            </div>
                        );
                    }
                });
            }

            selectorPopup = (
                <div
                    className={
                        "companies-selector-popup" +
                        (this.state.openSelectorPopup ? " show" : "")
                    }
                >
                    <button
                        className="selector-button"
                        onClick={this.handleSelectorClick.bind(this)}
                    >
                        <span className="icon"></span>
                        <span className="text">{activeLabel}</span>
                    </button>
                    {/*
                    <button
                        className="close-button"
                        onClick={this.handleSelectorClick.bind(this)}
                    ></button>
                    <CheckList
                        list={this.props.selector.list}
                        onItemClick={this.handleCompanySelect.bind(this)}
                    />
                    {selectorAction}
                    {selectorDelim}
                    {selectorBalance}
                    */}
                    <div className="selector-limiter">
                        {dropdownButton}
                        <label className="companies-label">
                            {this.props.selector.label}
                        </label>
                        {list}
                        {selectorAction}
                        {activeCompany}
                        <CompanyMenu
                            list={this.props.companyMenu}
                            onMenuClick={this.handleCompanyMenuButtonClick.bind(
                                this
                            )}
                        />
                    </div>
                </div>
            );
        }

        let companyActions = [];
        if (this.props.companyActions && this.props.companyActions.length) {
            this.props.companyActions.map((action, index) => {
                companyActions.push(
                    <button
                        key={index}
                        onClick={this.handleActionClick.bind(this, action)}
                        className={"action-item action-" + action.name}
                    >
                        {action.label}
                    </button>
                );
            });

            companyActions = (
                <div
                    className={
                        "header-actions" +
                        (this.state.openedActions ? " opened" : "")
                    }
                >
                    <button
                        className="actions-button"
                        onClick={this.handleCompanyActionsButtonClick.bind(
                            this
                        )}
                    >
                        {String.fromCharCode(8943)}
                    </button>
                    <div className="actions-popup">{companyActions}</div>
                </div>
            );
        }

        return (
            <div className="companies-header">
                {selector}
                {selectorPopup}
                {content}
                {companyActions}

                <FormPopup
                    className="confirm-delete-popup"
                    {...this.props.deleteForm}
                    show={this.state.showConfirmDeletePopup}
                    handleFieldClick={this.handleConfirmDeletePopupFieldClick.bind(
                        this
                    )}
                    handleSubmit={this.handleConfirmDeletePopupSubmit.bind(
                        this
                    )}
                    handleCloseButtonClick={this.handleConfirmDeletePopupCloseButtonClick.bind(
                        this
                    )}
                />
            </div>
        );
    }

    handleCompactCompanyClick(e, params) {
        const handler = this.props.onCompanyClick || function () {};
        handler(e, params);
        this.setState({
            showOtherCompanies: false,
        });
    }

    handleSelectorClick(e) {
        this.setState({
            openSelectorPopup: !this.state.openSelectorPopup,
        });
    }

    handleCompanySelect(e, item) {
        this.setState({
            openSelectorPopup: false,
        });
        const handler = this.props.onCompanySelect || function () {};
        handler(e, item);
    }

    handleActionClick(action, e) {
        switch (action.name) {
            case "delete":
                this.setState({
                    showConfirmDeletePopup: true,
                });
                break;
        }

        const handler = this.props.onActionClick || function () {};
        handler(e, action);
    }

    handleSelectorActionClick(e) {
        const handler = this.props.onSelectorActionClick || function () {};
        handler(e, "add");
    }

    handleCompanyMenuButtonClick(e, item) {
        const handler = this.props.onCompanyMenuItemClick || function () {};
        handler(e, this.getActiveCompanyId(), item);
    }

    handleCompaniesDropdownClick(e) {
        this.setState({
            showOtherCompanies: !this.state.showOtherCompanies,
        });
    }

    handleCompanyActionsButtonClick() {
        this.setState({
            openedActions: !this.state.openedActions,
        });
    }

    handleConfirmDeletePopupFieldClick(e, field) {
        switch (field.getFieldName()) {
            case "cancel":
                this.setState({
                    showConfirmDeletePopup: false,
                });
                break;
        }
    }

    handleConfirmDeletePopupSubmit(e, form) {
        const handler = this.props.onConfirmDeletePopupSubmit || function () {};
        handler(e, form, this.props.companyId);
    }

    handleConfirmDeletePopupCloseButtonClick(e) {
        this.setState({
            showConfirmDeletePopup: false,
        });
    }

    getActiveCompanyId() {
        let activeItem;

        if (
            this.props.selector &&
            this.props.selector.list &&
            this.props.selector.list.length
        ) {
            for (var i = 0; i < this.props.selector.list.length; i++) {
                let item = this.props.selector.list[i];
                if (!activeItem) {
                    activeItem = item;
                }
                if (item.active) {
                    activeItem = item;
                }
            }
        }

        if (activeItem) {
            return activeItem.id;
        }
    }
}
