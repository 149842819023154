import Form from "../../forms/form.jsx";

export default class CompanyTabForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let backButton;
        if (this.props.backButton) {
            backButton = (
                <div className="back-button">
                    <button
                        onClick={this.handleFormActionClick.bind(
                            this,
                            this.props.backButton
                        )}
                    >
                        {this.props.backButton.label}
                    </button>
                </div>
            );
        }

        let actions = [];
        if (this.props.actions) {
            this.props.actions.map((action, i) => {
                actions.push(
                    <button
                        key={i}
                        className={"form-" + action.name}
                        onClick={this.handleFormActionClick.bind(this, action)}
                    >
                        {action.label}
                    </button>
                );
            });

            actions = <div className="form-actions">{actions}</div>;
        }

        let alterActionButton;
        if (this.props.alterAction) {
            alterActionButton = (
                <button
                    className="form-alter-action"
                    onClick={this.handleAlterActionClick.bind(
                        this,
                        this.props.alterAction.name
                    )}
                >
                    {this.props.alterAction.label}
                </button>
            );
        }

        let description = [];
        if (this.props.description) {
            this.props.description.map((text, i) => {
                description.push(<p key={i}>{text}</p>);
            });
            description = <div className="form-description">{description}</div>;
        }

        return (
            <div className="company-tab-form">
                <div className="form-content">
                    {backButton}
                    <div className="tab-form-label-container">
                        <p className="tab-form-label">{this.props.label}</p>
                        {alterActionButton}
                    </div>
                    {description}
                    <Form
                        {...this.props.form}
                        onFieldChange={
                            this.props.onFieldChange || function () {}
                        }
                        onFieldBlur={this.props.onFieldBlur || function () {}}
                        onSubmit={this.props.onSubmit || function () {}}
                    />
                    {actions}
                </div>
            </div>
        );
    }

    handleAlterActionClick(name, e) {
        const handler = this.props.onAlterActionButtonClick || function () {};
        handler(e, name);
    }

    handleFormActionClick(action, e) {
        const handler = this.props.onFormActionClick || function () {};
        handler(e, action);
    }
}
