import Container from "../container.jsx";
import Targets from "../../components/layouts/targets/targets.jsx";

export default class TargetsContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("data.layout");
    }

    render() {
        return <Targets {...this.props} layout={this.state.state} />;
    }
}
