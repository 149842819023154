import StatsGraph from "./stats-graph.jsx";
import Price from "../../price.jsx";

export default class StatsGraphCompact extends StatsGraph {
    constructor(props) {
        super(props);
    }

    render() {
        this.letterHelper.resetCounter();

        let chart;
        if (this.props.chartData) {
            chart = (
                <div
                    className="pie-chart"
                    data-chart={JSON.stringify(this.props.chartData)}
                    ref={this.chartRef}
                ></div>
            );
        }

        let moreButton;
        if (this.props.moreButton) {
            moreButton = (
                <div className="more-link">
                    <button
                        onClick={this.props.onMoreButtonClick || function () {}}
                    >
                        {this.props.label} {String.fromCharCode(8594)}
                    </button>
                </div>
            );
        }

        return (
            <div className="stats-graph-compact">
                {chart}
                {moreButton}
                <Price value={this.props.totalPrice} />
            </div>
        );
    }
}
