import {request, queryRequest} from './repository.js';

let config = {};

export function setConfig(newConfig) {
    if (newConfig) {
        for(let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function getOperationAddPopup(data) {
    return queryRequest({
        operationAddPopup: data || {}
    });
}

export function getOperationEditPopup(data) {
    return queryRequest({
        operationEditPopup: data || {}
    });
}

export function getOperationRemovePopup(data) {
    return queryRequest({
        operationRemovePopup: data || {}
    });
}

export function getOperationTransferPopup(data) {
    return queryRequest({
        operationTransferPopup: data || {}
    });
}
