export default class Bar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeView: "pie",
            actionsDropdownOpened: false,
        };
    }

    render() {
        let dateRange = [],
            viewList = [],
            actions = [];

        if (this.props.dateRange) {
            dateRange = <span className="bar-date-range">{this.props.dateRange}</span>;
        }

        if (this.props.viewBar) {
            for (let i = 0; i < this.props.viewBar.length; i++) {
                let item = this.props.viewBar[i],
                    itemClassname = item.name;

                if (this.state.activeView == item.name) {
                    itemClassname += " active";
                }

                viewList.push(
                    <button
                        key={i}
                        type="button"
                        title={item.label}
                        onClick={this.handleViewClick.bind(this, item.name)}
                        className={itemClassname}
                    >
                        <span className="icon"></span>
                    </button>
                );
            }

            viewList = <div className="bar-views">{viewList}</div>;
        }

        let actionsButton,
        actionsDropdown = [];
        if (this.props.actions) {
            let actions = this.props.actions;
            actionsButton = (
                <button
                    className={actions.button.name}
                    type="button"
                    title={actions.button.label}
                    onClick={this.handleActionsMenuButtonClick.bind(this)}
                >
                    <span className="icon"></span>
                    <span className="text">{actions.button.label}</span>
                    <div>
                        <span className="dropdown-title">Выбрать</span>
                        <span className="icon-close">
                            {String.fromCharCode(215)}
                        </span>
                    </div>

                </button>
            );

            actionsDropdown = this.renderActionsDropdown();
        }

        if (this.props.actions) {
            let dropdownContainerClassName = "bar-actions";
            if (this.state.actionsDropdownOpened) {
                dropdownContainerClassName += " opened";
            }


            actions = <div className={dropdownContainerClassName}>
                    {actionsDropdown}
                    {actionsButton}
                </div>;
        }

        return (
            <div className="analytics-bar">
                {dateRange}
                {viewList}
                {actions}
            </div>
        );
    }

    renderActionsDropdown() {
        let actionsDropdown = [];
        const actionsMenu = this.props.actions.menu;
        if (actionsMenu) {
            for (let i = 0; i < this.props.actions.menu.length; i++) {
                let item = this.props.actions.menu[i];
    
                actionsDropdown.push(
                    <button
                        key={i}
                        onClick={this.handleActionClick.bind(this, item)}
                        className="actions-dropdown-button"
                    >
                        <span
                            className={"icon icon-" + item.name}
                        ></span>
                        <span className="text">{item.label}</span>
                    </button>
                );
            }
            actionsDropdown = (
                <div className="actions-dropdown">{actionsDropdown}</div>
            );
        }

        return actionsDropdown;
    }

    handleViewClick(name, e) {
        this.setState({
            activeView: name,
        });

        const handler = this.props.onViewChange || function () {};
        handler(name);
    }

    handleActionClick(name, e) {
        const handler = this.props.onActionClick || function () {};
        handler(name);
        this.setState({
            actionsDropdownOpened: !this.state.actionsDropdownOpened,
        });
    }

    handleActionsMenuButtonClick(e) {
        const handler = this.props.onClick;
        if (handler) {
            handler(e);
        }

        this.setState({
            actionsDropdownOpened: !this.state.actionsDropdownOpened,
        });
    }
}
