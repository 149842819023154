import Container from '../container.jsx';
import CompaniesHeader from '../../components/layouts/companies/companies-header.jsx';

export default class CompanyHeaderContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore('data.companyHeader');
    }

    render(){
        if (!this.state.state) {
            return null;
        }

        return <CompaniesHeader {...this.props} {...this.state.state} />;
    };
}
